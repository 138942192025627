var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c(
        "el-popover",
        {
          ref: "pop",
          attrs: { placement: _vm.placement, width: "300", trigger: "click" },
          model: {
            value: _vm.pop,
            callback: function($$v) {
              _vm.pop = $$v
            },
            expression: "pop"
          }
        },
        [
          _vm.clearable
            ? _c(
                "el-row",
                {
                  staticClass: "d2-mb-10",
                  attrs: { type: "flex", justify: "end" }
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "d2-fr",
                      attrs: {
                        type: "danger",
                        icon: "el-icon-delete",
                        size: "mini"
                      },
                      on: {
                        click: function($event) {
                          return _vm.selectIcon()
                        }
                      }
                    },
                    [_vm._v(" 清空 ")]
                  )
                ],
                1
              )
            : _vm._e(),
          _c("el-input", {
            attrs: {
              clearable: true,
              placeholder: "搜索 比如 'plus'",
              "prefix-icon": "el-icon-search"
            },
            model: {
              value: _vm.searchText,
              callback: function($$v) {
                _vm.searchText = $$v
              },
              expression: "searchText"
            }
          }),
          !_vm.searchMode
            ? _c(
                "el-collapse",
                {
                  staticClass: "d2-icon-select--group",
                  model: {
                    value: _vm.collapseActive,
                    callback: function($$v) {
                      _vm.collapseActive = $$v
                    },
                    expression: "collapseActive"
                  }
                },
                _vm._l(_vm.icon, function(item, index) {
                  return _c(
                    "el-collapse-item",
                    {
                      key: index,
                      staticClass: "d2-icon-select--class",
                      attrs: { title: item.title, name: index }
                    },
                    [
                      _c(
                        "el-row",
                        { staticClass: "d2-icon-select--class-row" },
                        _vm._l(item.icon, function(iconName, iconIndex) {
                          return _c(
                            "el-col",
                            {
                              key: iconIndex,
                              staticClass: "d2-icon-select--class-col",
                              attrs: { span: 4 },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.selectIcon(iconName)
                                }
                              }
                            },
                            [_c("i", { class: "fa fa-" + iconName })]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e(),
          _vm.searchMode
            ? _c(
                "div",
                { staticClass: "d2-icon-select--group" },
                _vm._l(_vm.iconFilted, function(item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "d2-icon-select--class" },
                    [
                      _c(
                        "div",
                        { staticClass: "d2-icon-select--class-title" },
                        [_vm._v(_vm._s(item.title))]
                      ),
                      _c(
                        "el-row",
                        { staticClass: "d2-icon-select--class-row" },
                        _vm._l(item.icon, function(iconName, iconIndex) {
                          return _c(
                            "el-col",
                            {
                              key: iconIndex,
                              staticClass: "d2-icon-select--class-col",
                              attrs: { span: 4 },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.selectIcon(iconName)
                                }
                              }
                            },
                            [_c("i", { class: "fa fa-" + iconName })]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e()
        ],
        1
      ),
      _vm.userInput
        ? _c(
            "el-input",
            _vm._b(
              {
                staticStyle: { "max-width": "240px" },
                model: {
                  value: _vm.currentValue,
                  callback: function($$v) {
                    _vm.currentValue = $$v
                  },
                  expression: "currentValue"
                }
              },
              "el-input",
              _vm.bind,
              false
            ),
            [
              _vm.value
                ? _c("template", { slot: "prepend" }, [
                    _c("i", { class: "fa fa-" + _vm.value })
                  ])
                : _vm._e(),
              _c(
                "el-button",
                {
                  directives: [
                    { name: "popover", rawName: "v-popover:pop", arg: "pop" }
                  ],
                  attrs: { slot: "append" },
                  slot: "append"
                },
                [_c("i", { staticClass: "fa fa-list" })]
              )
            ],
            2
          )
        : _vm._e(),
      !_vm.userInput
        ? _c(
            "el-button",
            {
              directives: [
                { name: "popover", rawName: "v-popover:pop", arg: "pop" }
              ]
            },
            [
              _vm.value ? [_c("i", { class: "fa fa-" + _vm.value })] : _vm._e(),
              _vm._v(
                " " + _vm._s(_vm.value ? _vm.value : _vm.placeholder) + " "
              )
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }