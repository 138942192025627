import "core-js/modules/es.function.name";
import "core-js/modules/web.dom-collections.for-each";
//
//
//
//
//
//
//
//
import areaConditionGroupCell from './areaConditionGroupCell';
import conditionGroupCell from './conditionGroupCell';
export default {
  components: {
    areaConditionGroupCell: areaConditionGroupCell,
    conditionGroupCell: conditionGroupCell
  },
  props: {
    gradeList: {
      type: Array
    },
    levelList: {
      type: Array
    },
    type: {
      type: String
    }
  },
  data: function data() {
    return {
      relationCondition: {
        op: 'or',
        data: []
      },
      content: '',
      areaConditionList: [],
      conditionList: [{
        id: 0,
        name: '团队业绩',
        period: 0,
        type: [1, 2, 3],
        order: 0,
        myself: 0,
        params: [{
          name: '团队业绩',
          value: 0,
          op: '>=',
          unit: 'pv'
        }]
      }, {
        id: 1,
        name: '个人业绩',
        period: 0,
        type: [1, 2, 3],
        order: 0,
        params: [{
          name: '个人业绩',
          value: 0,
          op: '>=',
          unit: 'pv'
        }]
      }, {
        id: 2,
        name: '大市场业绩',
        period: 0,
        type: [1, 2, 3],
        order: 0,
        params: [{
          name: '大市场业绩',
          value: 0,
          op: '>=',
          unit: 'pv'
        }]
      }, {
        id: 3,
        name: '小市场业绩',
        period: 0,
        type: [1, 2, 3],
        order: 0,
        params: [{
          name: '小市场业绩',
          value: 0,
          op: '>=',
          unit: 'pv'
        }]
      }, {
        id: 4,
        name: '直推人数',
        params: [{
          name: '直推人数',
          value: 0,
          op: '>=',
          unit: '人'
        }]
      }, {
        id: 5,
        name: '团队人数',
        params: [{
          name: '团队人数',
          value: 0,
          op: '>=',
          unit: '人'
        }]
      }, {
        id: 6,
        name: '会员等级',
        params: [{
          name: '会员等级',
          value: '',
          op: '>=',
          grade: 1,
          unit: '级别'
        }]
      }, {
        id: 7,
        name: '会员资格',
        params: [{
          name: '会员资格',
          value: '',
          op: '>=',
          level: 1,
          unit: '资格'
        }]
      }, {
        id: 8,
        name: '直推资格人数',
        params: [{
          name: '直推人数',
          value: 0,
          op: '>=',
          unit: '人'
        }, {
          name: '直推会员资格',
          value: '',
          op: '>=',
          level: 1,
          unit: '资格'
        }]
      }, {
        id: 9,
        name: '直推等级人数',
        params: [{
          name: '直推人数',
          value: 0,
          op: '>=',
          unit: '个'
        }, {
          name: '直推会员等级',
          value: '',
          op: '>=',
          grade: 1,
          unit: '等级'
        }]
      }, {
        id: 12,
        name: '市场等级人数',
        params: [{
          name: '市场人数',
          value: 0,
          op: '>=',
          unit: '个'
        }, {
          name: '市场会员等级',
          value: '',
          op: '>=',
          grade: 1,
          unit: '等级'
        }]
      }, {
        id: 10,
        name: '一次购买商品数(秒结可用)',
        params: [{
          name: '商品数量',
          value: 0,
          op: '>=',
          unit: '个'
        }]
      }, {
        id: 11,
        name: '只判断订单人(秒结可用)',
        params: []
      }, {
        id: 13,
        name: '直推等级团队业绩',
        period: 0,
        type: [1, 2, 3],
        order: 0,
        myself: 0,
        params: [{
          name: '会员等级',
          value: 0,
          op: '>=',
          grade: 1,
          unit: '等级'
        }, {
          name: '团队业绩',
          value: '',
          op: '>=',
          unit: 'pv'
        }, {
          name: '会员数量',
          value: 0,
          op: '>=',
          unit: '人'
        }]
      }, {
        id: 14,
        name: '网下等级团队业绩',
        period: 0,
        type: [1, 2, 3],
        order: 0,
        myself: 0,
        params: [{
          name: '会员等级',
          value: 0,
          op: '>=',
          grade: 1,
          unit: '等级'
        }, {
          name: '团队业绩',
          value: '',
          op: '>=',
          unit: 'pv'
        }, {
          name: '会员数量',
          value: 0,
          op: '>=',
          unit: '人'
        }]
      }, {
        id: 15,
        name: '网下等级团队业绩(等级不同线)',
        period: 0,
        type: [1, 2, 3],
        order: 0,
        myself: 0,
        params: [{
          name: '会员等级',
          value: 0,
          op: '>=',
          grade: 1,
          unit: '等级'
        }, {
          name: '团队业绩',
          value: '',
          op: '>=',
          unit: 'pv'
        }, {
          name: '会员数量',
          value: 0,
          op: '>=',
          unit: '人'
        }]
      }]
    };
  },
  mounted: function mounted() {
    var _this = this;

    this.areaConditionList = [];
    console.log(this.gradeList);
    this.gradeList.forEach(function (item, index) {
      var condition = {
        id: index,
        name: item.name,
        period: 0,
        type: [1, 2, 3],
        // order: 0,
        // myself: 0,
        grade: _this.gradeList[0].tag,
        isNew: 0
      };
      var params = [{
        name: '团队业绩',
        value: 0,
        op: '>=',
        unit: 'pv'
      }, {
        name: item.tag,
        value: 0,
        op: '=',
        unit: '个'
      }];
      condition.params = params;

      _this.areaConditionList.push(condition);
    });
    console.log("areaConditionList", this.areaConditionList);
  },
  methods: {
    getCondData: function getCondData() {
      console.log('getCondData', this.relationCondition);
      return this.relationCondition;
    },
    clickReset: function clickReset() {
      this.relationCondition = {
        op: 'and',
        data: []
      };
    },
    setCondData: function setCondData(value) {
      console.log('setCondData', value);

      if (value.data) {
        this.relationCondition = value;
      }
    }
  }
};