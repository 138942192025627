import "core-js/modules/es.array.index-of";
import "core-js/modules/es.array.last-index-of";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { systemWebConfig, systemWebConfigAdd } from '../../../../api/apis';
import upload from '@/plugin/upload';
export default {
  name: 'baseConfig',
  data: function data() {
    return {
      desc: []
    };
  },
  mounted: function mounted() {
    var _this = this;

    var params = {
      where: {
        classify: 1
      }
    };
    systemWebConfig(params).then(function _callee(res) {
      return regeneratorRuntime.async(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _this.desc = res;

            case 1:
            case "end":
              return _context.stop();
          }
        }
      });
    });
  },
  methods: {
    isRadio: function isRadio(key) {
      return this.desc[key].com === 'radio';
    },
    isCheckBox: function isCheckBox(key) {
      return this.desc[key].com === 'checkbox';
    },
    isDateTime: function isDateTime(key) {
      return this.desc[key].com === 'dateTimeRange';
    },
    isInput: function isInput(key) {
      return this.desc[key].com === 'input';
    },
    isUpload: function isUpload(key) {
      return this.desc[key].com === 'upload';
    },
    getFileList: function getFileList(tag) {
      var tmp = [];

      if (this.desc[tag].value !== null && this.desc[tag].value !== '') {
        var dic = {};
        dic.name = this.desc[tag].value.substring(this.desc[tag].value.lastIndexOf('/') + 1);
        dic.url = this.desc[tag].value;
        tmp.push(dic);
      }

      return tmp;
    },
    clickSubmit: function clickSubmit() {
      var _this2 = this;

      var tmp = this.$tempModel.deepCopy(this.desc);
      var params = tmp;
      systemWebConfigAdd(params).then(function _callee2(res) {
        return regeneratorRuntime.async(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                _this2.$message({
                  message: '保存成功',
                  type: 'success'
                });

              case 1:
              case "end":
                return _context2.stop();
            }
          }
        });
      });
    },
    onBeforeUpload: function onBeforeUpload(tag, file) {
      var size = file.size;

      if (size > 10 * 1024 * 1024) {
        this.$message.error('文件大于10MB');
        return false;
      }

      var extensionName = file.name.substr(file.name.lastIndexOf('.')); // 文件扩展名

      console.log('onBeforeUpload', extensionName);

      if (['.png', '.jpg', '.jpeg'].indexOf(extensionName) < 0) {
        this.$message.error('文件类型不正确');
        return false;
      }

      return true;
    },
    onUpload: function onUpload(tag, option) {
      console.log('ccc', option);
      upload.upload(option);
    },
    onSuccess: function onSuccess(tag, response, file, fileList) {
      // let pathList = []
      // pathList.push(response.url)
      this.desc[tag].value = response.url;
      console.log('aaa', response.url);
    },
    onRemove: function onRemove(tag, file, fileList) {
      this.desc[tag].value = '';
      console.log('onRemove'); // let pathList = []
      // for (let i in fileList) {
      //   pathList.push(fileList[i].url)
      // }
      // item.value = pathList
    },
    onError: function onError(tag, err, file, fileList) {
      this.$message.error(err.msg);
    }
  }
};