import "core-js/modules/es.object.to-string";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tempForm from '../tempForm';
export default {
  components: {
    tempForm: tempForm
  },
  props: {
    title: {
      type: String,
      required: true
    },
    tableModel: {
      type: Array,
      required: true
    },
    width: {
      type: String,
      default: '60%'
    },
    switchProps: {
      type: Object,
      default: function _default() {
        return {
          activeValue: '1',
          inactiveValue: '0'
        };
      }
    },
    selectProps: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    radioProps: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    cascaderProps: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    onSubmit: {
      type: Function,
      default: function _default() {
        return null;
      }
    },
    remoteFunc: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      loading: false,
      isEdit: false,
      dialogVisible: false,
      formMode: []
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (!val) {
        this.clearForm();
      }
    }
  },
  created: function created() {},
  methods: {
    clearForm: function clearForm() {
      this.isEdit = false;
      this.loading = false;
      this.$refs.tempForm.reloadForm(null);
    },
    show: function show(isEdit) {
      var _this = this;

      var row = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      this.row = row;
      var formData = null;
      var tmpFormModel = [];

      for (var j in this.tableModel) {
        var model = this.tableModel[j];

        if (!model.add) {
          continue;
        }

        var formItem = {};
        formItem.required = model.required;
        formItem.label = model.label;
        formItem.type = model.type;
        formItem.prop = model.prop;
        formItem.desc = model.desc;
        formItem.rules = model.rules;
        formItem.min = model.min;
        formItem.max = model.max;
        formItem.relation = model.relation;
        formItem.relationProps = model.relationProps;
        formItem.exProps = {};

        if (model.defaultValue != null) {
          formItem.defaultValue = model.defaultValue;
        } else {
          formItem.defaultValue = '';
        }

        if (!isEdit) {
          if (row) {
            if (model.rowAddField && model.rowAddField != '') {
              formItem.defaultValue = row[model.rowAddField];
            }
          }
        } else {
          if (model.show == 0 && model.detail == 0) {
            //密码类型
            continue;
          }
        }

        if (model.type === this.$tempModel.showType.image || model.type === this.$tempModel.showType.images || model.type === this.$tempModel.showType.avatar || model.type === this.$tempModel.showType.video || model.type === this.$tempModel.showType.audio) {
          formItem.exProps = model.file;
        } else if (model.type === this.$tempModel.showType.select) {
          formItem.exProps = this.selectProps[model.prop];
        } else if (model.type === this.$tempModel.showType.switch) {
          formItem.exProps = this.switchProps[model.prop];
        } else if (model.type === this.$tempModel.showType.cascader) {
          formItem.exProps = this.cascaderProps[model.prop];
        } else if (model.type === this.$tempModel.showType.radio) {
          formItem.exProps = this.radioProps[model.prop];
        }

        tmpFormModel.push(formItem);
      }

      if (isEdit) {
        formData = row;
      }

      this.formMode = tmpFormModel;
      this.isEdit = isEdit;
      this.dialogVisible = true;
      this.$nextTick(function () {
        _this.$refs.tempForm.reloadForm(formData);
      });
    },
    clickSubmit: function clickSubmit() {
      var _this2 = this;

      this.$refs.tempForm.submitForm(function (valid, form) {
        if (!valid) {
          return;
        }

        if (_this2.onSubmit) {
          _this2.loading = true;
          new Promise(function (resolve) {
            _this2.onSubmit(_this2.isEdit, form, _this2.row, resolve);
          }).then(function (error) {
            _this2.loading = false;

            if (!error) {
              _this2.dialogVisible = false;

              _this2.$message({
                type: 'success',
                message: _this2.isEdit ? '编辑成功' : '添加成功'
              });
            }
          });
        }
      });
    }
  }
};