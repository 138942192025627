var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            size: "mini",
            model: _vm.config.baseData,
            "label-width": "250px",
            "label-position": "left",
            "label-suffix": ": "
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "系统名称" } },
            [
              _c("el-input", {
                staticStyle: { width: "300px" },
                attrs: { size: "mini" },
                model: {
                  value: _vm.config.baseData.systemName,
                  callback: function($$v) {
                    _vm.$set(_vm.config.baseData, "systemName", $$v)
                  },
                  expression: "config.baseData.systemName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "报单类型" } },
            [
              _c(
                "el-radio-group",
                {
                  on: { change: _vm.centerBdOnlyChange },
                  model: {
                    value: _vm.config.baseData.bdMode,
                    callback: function($$v) {
                      _vm.$set(_vm.config.baseData, "bdMode", $$v)
                    },
                    expression: "config.baseData.bdMode"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("自己报单")]),
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("他人报单")]),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("混合报单")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "会员本人可以报的单型" } },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.config.baseData.memberBdType,
                    callback: function($$v) {
                      _vm.$set(_vm.config.baseData, "memberBdType", $$v)
                    },
                    expression: "config.baseData.memberBdType"
                  }
                },
                [
                  _c("el-checkbox", { attrs: { label: 1, name: "type" } }, [
                    _vm._v("注册单")
                  ]),
                  _c("el-checkbox", { attrs: { label: 2, name: "type" } }, [
                    _vm._v("复销单")
                  ]),
                  _c("el-checkbox", { attrs: { label: 3, name: "type" } }, [
                    _vm._v("升级单")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "服务中心可以报的单型" } },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.config.baseData.centerBdType,
                    callback: function($$v) {
                      _vm.$set(_vm.config.baseData, "centerBdType", $$v)
                    },
                    expression: "config.baseData.centerBdType"
                  }
                },
                [
                  _c("el-checkbox", { attrs: { label: 1, name: "type" } }, [
                    _vm._v("注册单")
                  ]),
                  _c("el-checkbox", { attrs: { label: 2, name: "type" } }, [
                    _vm._v("复销单")
                  ]),
                  _c("el-checkbox", { attrs: { label: 3, name: "type" } }, [
                    _vm._v("升级单")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "普通会员是否可以汇款充值" } },
            [
              _c(
                "el-radio-group",
                {
                  on: { change: _vm.centerBdOnlyChange },
                  model: {
                    value: _vm.config.baseData.memberCanRecharge,
                    callback: function($$v) {
                      _vm.$set(_vm.config.baseData, "memberCanRecharge", $$v)
                    },
                    expression: "config.baseData.memberCanRecharge"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("否")]),
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "服务中心可以给非网下会员报单" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.config.baseData.canCrossBd,
                    callback: function($$v) {
                      _vm.$set(_vm.config.baseData, "canCrossBd", $$v)
                    },
                    expression: "config.baseData.canCrossBd"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("否")]),
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "服务中心是否可以撤单" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.config.baseData.storeCancelOrder,
                    callback: function($$v) {
                      _vm.$set(_vm.config.baseData, "storeCancelOrder", $$v)
                    },
                    expression: "config.baseData.storeCancelOrder"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("否")]),
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "等级自然晋级生效时间" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.config.baseData.gradeValidType,
                    callback: function($$v) {
                      _vm.$set(_vm.config.baseData, "gradeValidType", $$v)
                    },
                    expression: "config.baseData.gradeValidType"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("当日")]),
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("次日")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "资格是否允许跨级升" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.config.baseData.levelCanSkip,
                    callback: function($$v) {
                      _vm.$set(_vm.config.baseData, "levelCanSkip", $$v)
                    },
                    expression: "config.baseData.levelCanSkip"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("否")]),
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "资格升级模式" } },
            [
              _c(
                "el-select",
                {
                  attrs: { size: "mini" },
                  model: {
                    value: _vm.config.baseData.levelModel,
                    callback: function($$v) {
                      _vm.$set(_vm.config.baseData, "levelModel", $$v)
                    },
                    expression: "config.baseData.levelModel"
                  }
                },
                [
                  _c("el-option", { attrs: { label: "不限", value: 0 } }),
                  _c("el-option", { attrs: { label: "补差升级", value: 1 } }),
                  _c("el-option", { attrs: { label: "全额升级", value: 2 } })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "提现时扣除提现手续费" } },
            [
              _c(
                "el-row",
                [
                  _c("el-input-number", {
                    attrs: { size: "mini", min: 0, max: 100 },
                    model: {
                      value: _vm.config.baseData.takeTax,
                      callback: function($$v) {
                        _vm.$set(_vm.config.baseData, "takeTax", $$v)
                      },
                      expression: "config.baseData.takeTax"
                    }
                  }),
                  _c("span", { staticStyle: { "margin-left": "10px" } }, [
                    _vm._v("%")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "每个人可设置区域个数" } },
            [
              _c("el-input-number", {
                attrs: { size: "mini", min: 0, max: 10000 },
                model: {
                  value: _vm.config.baseData.agentAreaCount,
                  callback: function($$v) {
                    _vm.$set(_vm.config.baseData, "agentAreaCount", $$v)
                  },
                  expression: "config.baseData.agentAreaCount"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "每个区域可设置的人数" } },
            [
              _c("el-input-number", {
                attrs: { size: "mini", min: 0, max: 10000 },
                model: {
                  value: _vm.config.baseData.agentPeopleCount,
                  callback: function($$v) {
                    _vm.$set(_vm.config.baseData, "agentPeopleCount", $$v)
                  },
                  expression: "config.baseData.agentPeopleCount"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "每个人是否可以设置多个区域" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.config.baseData.agentCanSame,
                    callback: function($$v) {
                      _vm.$set(_vm.config.baseData, "agentCanSame", $$v)
                    },
                    expression: "config.baseData.agentCanSame"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("否")]),
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "区域代理是否可以指定商品" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.config.baseData.agentProduct,
                    callback: function($$v) {
                      _vm.$set(_vm.config.baseData, "agentProduct", $$v)
                    },
                    expression: "config.baseData.agentProduct"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("否")]),
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }