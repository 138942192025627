var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        "background-color": "white",
        padding: "15px 20px",
        height: "100vh"
      }
    },
    [
      _vm.row
        ? _c(
            "div",
            { staticStyle: { height: "80vh", "overflow-y": "auto" } },
            [
              _c(
                "div",
                [
                  _c(
                    "el-tabs",
                    { attrs: { "tab-position": "left", type: "border-card" } },
                    [
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "基础信息配置" } },
                        [_c("base-config", { attrs: { config: _vm.config } })],
                        1
                      ),
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "资格信息配置" } },
                        [_c("level-config", { attrs: { config: _vm.config } })],
                        1
                      ),
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "店补信息配置" } },
                        [_c("store-config", { attrs: { config: _vm.config } })],
                        1
                      ),
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "等级信息配置" } },
                        [_c("grade-config", { attrs: { config: _vm.config } })],
                        1
                      ),
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "奖金信息配置" } },
                        [_c("bonus-config", { attrs: { config: _vm.config } })],
                        1
                      ),
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "管理税配置" } },
                        [
                          _c("manage-tax-config", {
                            attrs: { config: _vm.config }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "复销税配置" } },
                        [
                          _c("resale-tax-config", {
                            attrs: { config: _vm.config }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-row",
                { staticStyle: { float: "right", "margin-top": "20px" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.clickSubmit }
                    },
                    [_vm._v("保存模板")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger" },
                      on: { click: _vm.clickCreate }
                    },
                    [_vm._v("生成/更新系统")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }