var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "区域代理",
        visible: _vm.dialogVisible,
        width: "60%",
        "append-to-body": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "show-close": false
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            "max-height": "50vh",
            "overflow-y": "auto",
            padding: "15px 30px",
            "background-color": "#f6f6f6"
          }
        },
        [
          _c(
            "el-tag",
            {
              staticStyle: { "margin-bottom": "20px" },
              attrs: { type: "danger" }
            },
            [_vm._v("请先搜索会员，查看会员是否已设置过区域代理")]
          ),
          _c(
            "el-tag",
            {
              staticStyle: { "margin-bottom": "20px" },
              attrs: { type: "danger" }
            },
            [_vm._v("如果会员编号已存在，则会更新这个会员的代理区域")]
          ),
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.form, "label-width": "120px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "会员编号" } },
                [
                  _c(
                    "el-row",
                    [_c("el-input", { attrs: { value: _vm.form.number } })],
                    1
                  ),
                  _c(
                    "el-row",
                    [_c("el-tag", [_vm._v(_vm._s(_vm.form.curArea))])],
                    1
                  )
                ],
                1
              ),
              _vm.isXz
                ? _c(
                    "el-form-item",
                    { attrs: { label: "乡镇名称" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.townName,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "townName", $$v)
                          },
                          expression: "form.townName"
                        }
                      })
                    ],
                    1
                  )
                : _c(
                    "el-form-item",
                    { attrs: { label: "选择区域" } },
                    [
                      _vm.isShowInfo
                        ? _c("el-cascader", {
                            attrs: { filterable: "", props: _vm.props },
                            model: {
                              value: _vm.form.area,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "area", $$v)
                              },
                              expression: "form.area"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.dialogVisible = false
                }
              }
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.clickSubmit } },
            [_vm._v("提交")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }