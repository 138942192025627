import "core-js/modules/es.object.to-string";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import area from '@/plugin/area';
import { checkMembers, memberAreaAgent, areaList } from '../../../../api/apis';
export default {
  data: function data() {
    return {
      dialogVisible: false,
      form: {
        number: '',
        region: '',
        remark: ''
      },
      options: []
    };
  },
  mounted: function mounted() {// let areaList1 = area.region
    // let tmp = []
    // for (let i in areaList1){
    //   let item = areaList1[i]
    //   let children = []
    //   for (let j in item.mallCityList){
    //     let cityItem = item.mallCityList[j]
    //     let subChildren = []
    //     for (let k in cityItem.mallAreaList){
    //       let areaItem = cityItem.mallAreaList[k]
    //       // subChildren.push({value:areaItem.areaCode,label:areaItem.areaName})
    //       subChildren.push({value:areaItem.areaName,label:areaItem.areaName})
    //     }
    //     // children.push({value:cityItem.cityCode,label:cityItem.cityName,children:subChildren})
    //     children.push({value:cityItem.cityName,label:cityItem.cityName,children:subChildren})
    //   }
    //   // tmp.push({value:item.provinceCode,label:item.provinceName,children:children})
    //   tmp.push({value:item.provinceName,label:item.provinceName,children:children})
    // }
    // console.log('tmp',tmp)
    // this.options = tmp
  },
  methods: {
    show: function show(number, finish) {
      var _this = this;

      this.dialogVisible = true;
      this.form.number = number;
      this.finish = finish;
      areaList({}).then(function _callee(res) {
        return regeneratorRuntime.async(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                _this.options = res;

              case 1:
              case "end":
                return _context.stop();
            }
          }
        });
      });
    },
    onChange: function onChange(value, b, c) {
      console.log('onChange', value, b, c);
    },
    clickSubmit: function clickSubmit() {
      var _this2 = this;

      memberAreaAgent({
        number: this.form.number[0],
        direct_city: this.form.region[1],
        direct_district: this.form.region[2]
      }).then(function _callee2(res) {
        return regeneratorRuntime.async(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                _this2.$message({
                  message: '设置成功',
                  type: 'success'
                });

                _this2.dialogVisible = false;

                _this2.finish();

              case 3:
              case "end":
                return _context2.stop();
            }
          }
        });
      });
    }
  }
};