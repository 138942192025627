import "core-js/modules/es.array.map";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.search";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tempTable from "@/components/rw/tempTable/index";
import tempSearch from "@/components/rw/tempSearch/index";
import tempForm from "@/components/rw/tempForm/index";
import tempChart from "@/components/rw/tempChart/index";
import { configGradeList, productCategory, productCategoryLimit, productCategoryLimitAdd, productCategoryLimitDel } from '@/api/apis';
export default {
  components: {
    tempTable: tempTable,
    tempSearch: tempSearch,
    tempForm: tempForm,
    tempChart: tempChart
  },
  data: function data() {
    var _this = this;

    return {
      tableModel_1000: {
        "border": true,
        "del": false,
        "headerButton": [{
          "id": "",
          "tag": "add",
          "title": "添加",
          "type": "primary"
        }],
        "id": "id_1000",
        "isGroup": false,
        "isTree": false,
        "rowButton": [{
          "id": "",
          "tag": "edit",
          "title": "编辑",
          "type": "primary"
        }, {
          "id": "",
          "tag": "del",
          "title": "删除",
          "type": "danger"
        }],
        "showPagination": true,
        "showSelection": false,
        "showSummary": false,
        "stripe": true,
        "tableAutocompleteProps": {},
        "tableCascaderProps": {},
        "tableFileProps": {},
        "tableModel": [{
          "label": "ID",
          "show": 1,
          "add": 0,
          "detail": 1,
          "width": "80",
          "type": "text",
          "desc": "",
          "groupId": "",
          "rules": [],
          "excel": 1,
          "prop": "id"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "商品分类",
          "required": 1,
          "rules": [],
          "show": 1,
          "type": "select",
          "width": "100",
          "prop": "cate_id"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "会员等级",
          "required": 1,
          "rules": [],
          "show": 1,
          "type": "select",
          "width": "250",
          "prop": "grade"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "限制购买数量",
          "required": 1,
          "rules": [{
            "name": "integer"
          }],
          "show": 1,
          "type": "text",
          "width": "100",
          "prop": "num"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "限制开始日期",
          "required": 1,
          "rules": [],
          "show": 1,
          "type": "datetime",
          "width": "160",
          "prop": "limit_start"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "限制结束日期",
          "required": 1,
          "rules": [],
          "show": 1,
          "type": "datetime",
          "width": "160",
          "prop": "limit_end"
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "添加日期",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "160",
          "prop": "add_time"
        }],
        "tableName": "",
        "tableRadioProps": {},
        "tableSelectProps": {
          "grade": {
            "data": [{
              "key": null,
              "value": ""
            }],
            "id": 0,
            "multiple": 1,
            "relation": false,
            "relationProps": [],
            "remote": true,
            "remoteFunc": "tableFunc_select_grade"
          },
          "cate_id": {
            "data": [{
              "key": null,
              "value": ""
            }],
            "id": 1,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": true,
            "remoteFunc": "tableFunc_select_cate_id"
          }
        },
        "tableSwitchProps": {},
        "title": "分类限购",
        "type": "tableTemplate"
      },
      searchModel_1001: {
        "type": "searchTemplate",
        "id": "id_1001",
        "del": false,
        "searchData": [{
          "title": "商品分类",
          "select": 0,
          "type": 3,
          "id": 0,
          "relation": false,
          "relationId": "",
          "remote": true,
          "remoteType": 1,
          "children": [{
            "type": "select",
            "multiple": false,
            "field": [{
              "key": "type",
              "value": "0",
              "desc": "类型一"
            }, {
              "key": "type",
              "value": "1",
              "desc": "类型二"
            }]
          }],
          "remoteFunc": ["modelConditionFunc_0"]
        }],
        "operateData": [{
          "title": "搜索",
          "type": "primary",
          "tag": "search",
          "icon": "el-icon-search"
        }, {
          "title": "导出",
          "type": "primary",
          "tag": "export",
          "icon": "el-icon-position"
        }],
        "isCollapse": false
      },
      remoteFunc: {
        tableFunc_select_grade: function tableFunc_select_grade(tag, relationData, resolve) {
          //筛选项模型数组
          configGradeList({}).then(function _callee(res) {
            var data, i;
            return regeneratorRuntime.async(function _callee$(_context) {
              while (1) {
                switch (_context.prev = _context.next) {
                  case 0:
                    data = [];

                    for (i in res.rows) {
                      data.push({
                        key: res.rows[i].tag,
                        value: res.rows[i].name
                      });
                    }

                    resolve(tag, data);

                  case 3:
                  case "end":
                    return _context.stop();
                }
              }
            });
          });
        },
        modelConditionFunc_0: function modelConditionFunc_0(tag, relationData, resolve) {
          //筛选条件数据
          productCategory({
            page: 1,
            limit: 10000
          }).then(function _callee2(res) {
            var list, data, i;
            return regeneratorRuntime.async(function _callee2$(_context2) {
              while (1) {
                switch (_context2.prev = _context2.next) {
                  case 0:
                    list = _this.$tempModel.treeToList(res, 'sub', function (parent, item) {
                      if (parent)
                        /*';P.L,M */
                        {
                          item.name = parent.cate_name + '-' + item.cate_name;
                        } else {
                        item.name = item.cate_name;
                      }
                    });
                    data = [];

                    for (i in list) {
                      // if (list[i].pid !== 0){
                      data.push({
                        key: 'cate_id',
                        value: list[i].id,
                        desc: list[i].name
                      }); // }
                    }

                    resolve(tag, [data]);

                  case 4:
                  case "end":
                    return _context2.stop();
                }
              }
            });
          });
        },
        tableFunc_select_cate_id: function tableFunc_select_cate_id(tag, relationData, resolve) {
          //筛选项模型数组
          productCategory({
            page: 1,
            limit: 10000
          }).then(function _callee3(res) {
            var list, data, i;
            return regeneratorRuntime.async(function _callee3$(_context3) {
              while (1) {
                switch (_context3.prev = _context3.next) {
                  case 0:
                    list = _this.$tempModel.treeToList(res, 'sub', function (parent, item) {
                      if (parent)
                        /*';P.L,M */
                        {
                          item.name = parent.cate_name + '-' + item.cate_name;
                        } else {
                        item.name = item.cate_name;
                      }
                    });
                    data = [];

                    for (i in list) {
                      // if (list[i].pid !== 0){
                      data.push({
                        key: list[i].id,
                        value: list[i].name
                      }); // }
                    }

                    resolve(tag, data);

                  case 4:
                  case "end":
                    return _context3.stop();
                }
              }
            });
          });
        }
      }
    };
  },
  mounted: function mounted() {
    this.table = this.$refs.ref_1000;
    this.search = this.$refs.ref_1001;
  },
  methods: {
    reqTableData: function reqTableData(params, resolve) {
      var where = {};

      if (this.search) {
        where = this.search.parseWhere();
        params.where = where;
      }

      productCategoryLimit(params).then(function _callee4(res) {
        return regeneratorRuntime.async(function _callee4$(_context4) {
          while (1) {
            switch (_context4.prev = _context4.next) {
              case 0:
                resolve({
                  total: res.count,
                  data: res.rows
                });

              case 1:
              case "end":
                return _context4.stop();
            }
          }
        });
      });
    },
    reqAdd: function reqAdd(isEdit, params, row, resolve) {
      if (isEdit) {
        params.id = row.id;
      }

      productCategoryLimitAdd(params).then(function _callee5(res) {
        return regeneratorRuntime.async(function _callee5$(_context5) {
          while (1) {
            switch (_context5.prev = _context5.next) {
              case 0:
                resolve();

              case 1:
              case "end":
                return _context5.stop();
            }
          }
        });
      });
    },
    reqDel: function reqDel(params, resolve) {
      var ids = params.map(function (item) {
        return item.id;
      });
      productCategoryLimitDel({
        id: ids[0]
      }).then(function _callee6(res) {
        return regeneratorRuntime.async(function _callee6$(_context6) {
          while (1) {
            switch (_context6.prev = _context6.next) {
              case 0:
                resolve();

              case 1:
              case "end":
                return _context6.stop();
            }
          }
        });
      });
    },
    onClickLink: function onClickLink(prop, row) {//点击列表link类型回调
    },
    onClickRowButton: function onClickRowButton(btnIndex, row) {//点击列表按钮回调
    },
    onClickHeaderButton: function onClickHeaderButton(btnIndex, row) {//点击列表头部按钮回调
    },
    onSummary: function onSummary(param) {//列表尾部合计回调
    },
    onSwitchChange: function onSwitchChange(val, prop, row) {//列表中switch变化事件
    },
    onClickSearchOperate: function onClickSearchOperate(btnIndex, btnData) {
      //条件菜单按钮点击事件
      if (btnData.tag === this.$tempModel.buttonTag.search) {
        this.table.reloadData();
      } else if (btnData.tag === this.$tempModel.buttonTag.export) {
        this.table.exportExcel();
      } else if (btnData.tag === this.$tempModel.buttonTag.import) {
        var header = btnData.header;
        var results = btnData.results;
      }
    },
    onConditionChange: function onConditionChange(tag, index) {
      this.table.reloadData();
    },
    onRowButtonShow: function onRowButtonShow(btnIndex, row) {
      return true;
    }
  }
};