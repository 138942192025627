var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c("phone-view", {
                ref: "phone",
                attrs: {
                  "phone-url": _vm.phoneUrl,
                  name: "home",
                  "on-load": _vm.reloadData
                }
              })
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 16 } },
            [
              _c(
                "el-card",
                [
                  _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                    _vm._v(" 主页选项配置 ")
                  ]),
                  _c(
                    "el-card",
                    [
                      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                        _vm._v(" 幻灯样式 ")
                      ]),
                      _c(
                        "el-radio-group",
                        {
                          on: { change: _vm.reloadData },
                          model: {
                            value: _vm.config.bannerTag,
                            callback: function($$v) {
                              _vm.$set(_vm.config, "bannerTag", $$v)
                            },
                            expression: "config.bannerTag"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("样式一")
                          ]),
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("样式二")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-card",
                    { staticStyle: { "margin-top": "15px" } },
                    [
                      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                        _vm._v(" 分类样式 ")
                      ]),
                      _c(
                        "el-radio-group",
                        {
                          on: { change: _vm.reloadData },
                          model: {
                            value: _vm.config.classifyTag,
                            callback: function($$v) {
                              _vm.$set(_vm.config, "classifyTag", $$v)
                            },
                            expression: "config.classifyTag"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("样式一")
                          ]),
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("样式二")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-card",
                    { staticStyle: { "margin-top": "15px" } },
                    [
                      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                        _vm._v(" 产品样式 ")
                      ]),
                      _c(
                        "el-radio-group",
                        {
                          on: { change: _vm.reloadData },
                          model: {
                            value: _vm.config.productTag,
                            callback: function($$v) {
                              _vm.$set(_vm.config, "productTag", $$v)
                            },
                            expression: "config.productTag"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("样式一")
                          ]),
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("样式二")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        float: "right",
                        "margin-top": "20px",
                        "margin-bottom": "20px"
                      }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.clickSubmit }
                        },
                        [_vm._v("保存")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }