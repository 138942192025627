import "core-js/modules/es.array.map";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.search";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tempTable from '@/components/rw/tempTable/index';
import tempSearch from '@/components/rw/tempSearch/index';
import tempForm from '@/components/rw/tempForm/index';
import tempChart from '@/components/rw/tempChart/index';
import { configLevelList, expectNumList, levelLog } from '../../../../api/apis';
export default {
  components: {
    tempTable: tempTable,
    tempSearch: tempSearch,
    tempForm: tempForm,
    tempChart: tempChart
  },
  data: function data() {
    var _this = this;

    return {
      searchModel_1000: {
        'del': false,
        'id': 'id_1000',
        'isCollapse': false,
        'operateData': [{
          'icon': 'el-icon-search',
          'tag': 'search',
          'title': '搜索',
          'type': 'primary'
        }, {
          'icon': 'el-icon-position',
          'tag': 'export',
          'title': '导出',
          'type': 'primary'
        }],
        'searchData': [{
          'children': [{
            'field': [{
              'desc': '类型一',
              'key': 'type',
              'value': '0'
            }, {
              'desc': '类型二',
              'key': 'type',
              'value': '1'
            }],
            'multiple': false,
            'type': 'select'
          }],
          'id': 1,
          'relation': false,
          'relationId': '',
          'remote': true,
          'remoteType': 1,
          'select': 0,
          'title': '定期前资格',
          'type': 3,
          'remoteFunc': ['modelConditionFunc_1']
        }, {
          'children': [{
            'field': [{
              'desc': '类型一',
              'key': 'type',
              'value': '0'
            }, {
              'desc': '类型二',
              'key': 'type',
              'value': '1'
            }],
            'multiple': false,
            'type': 'select'
          }],
          'id': 2,
          'relation': false,
          'relationId': '',
          'remote': true,
          'remoteType': 1,
          'select': 0,
          'title': '定级后资格',
          'type': 3,
          'remoteFunc': ['modelConditionFunc_2']
        }, {
          'children': [{
            'field': [{
              'desc': '类型一',
              'key': 'type',
              'value': '0'
            }, {
              'desc': '类型二',
              'key': 'type',
              'value': '1'
            }],
            'multiple': false,
            'type': 'select'
          }],
          'id': 3,
          'relation': false,
          'relationId': '',
          'remote': true,
          'remoteType': 1,
          'select': 0,
          'title': '按期数',
          'type': 3,
          'remoteFunc': ['modelConditionFunc_3']
        }, {
          'title': '定级日期',
          'select': 0,
          'type': 1,
          'id': 4,
          'relation': false,
          'relationId': '',
          'remote': true,
          'remoteType': 1,
          'children': [{
            'type': 'button',
            'title': '全部',
            'field': []
          }, {
            'type': 'button',
            'title': '今天',
            'field': []
          }, {
            'type': 'button',
            'title': '昨天',
            'field': []
          }, {
            'type': 'button',
            'title': '最近7天',
            'field': []
          }, {
            'type': 'button',
            'title': '本月',
            'field': []
          }, {
            'type': 'button',
            'title': '本年',
            'field': []
          }, {
            'type': 'custom-datetime-range',
            'title': '自定义',
            'field': []
          }],
          'remoteFunc': ['modelConditionFunc_4']
        }, {
          'title': '按条件',
          'select': 0,
          'type': 2,
          'id': 5,
          'relation': false,
          'relationId': '',
          'remote': false,
          'remoteType': 0,
          'children': [{
            'type': 'input-complex',
            'placeholder': '请输入',
            'field': [{
              'key': 'number',
              'value': '',
              'desc': '会员编号'
            }],
            'operator': ['gte', 'lte', 'gt', 'lt', 'eq', 'ne', 'like', 'notLike', 'in', 'between']
          }, {
            'type': 'input-complex',
            'placeholder': '请输入',
            'field': [{
              'key': 'operator_man',
              'desc': '操作者编号'
            }, {
              'key': 'operator_ip',
              'value': '',
              'desc': '操作者IP'
            }, {
              'key': 'op',
              'value': '',
              'desc': '操作者ID'
            }],
            'operator': []
          }],
          'remoteFunc': []
        }],
        'type': 'searchTemplate'
      },
      tableModel_1001: {
        'type': 'tableTemplate',
        'id': 'id_1001',
        'del': false,
        'tableModel': [{
          'label': 'ID',
          'show': 1,
          'add': 1,
          'detail': 1,
          'excel': 1,
          'width': 80,
          'type': 'text',
          'rowAddField': '',
          'groupId': '',
          'rules': [],
          'prop': 'id'
        }, {
          'label': '会员编号',
          'show': 1,
          'add': 1,
          'detail': 1,
          'excel': 1,
          'width': 101,
          'type': 'text',
          'rowAddField': '',
          'groupId': '',
          'rules': [],
          'prop': 'number'
        }, {
          'label': '定级前级别',
          'show': 1,
          'add': 1,
          'detail': 1,
          'excel': 1,
          'width': '90',
          'type': 'select',
          'rowAddField': '',
          'groupId': '',
          'rules': [],
          'prop': 'before_level'
        }, {
          'label': '定级后级别',
          'show': 1,
          'add': 1,
          'detail': 1,
          'excel': 1,
          'width': '90',
          'type': 'select',
          'rowAddField': '',
          'groupId': '',
          'rules': [],
          'prop': 'after_level'
        }, {
          'label': '定级时间',
          'show': 1,
          'add': 1,
          'detail': 1,
          'excel': 1,
          'width': '150',
          'type': 'text',
          'rowAddField': '',
          'groupId': '',
          'rules': [],
          'prop': 'add_time'
        }, {
          'label': '定级类型',
          'show': 1,
          'add': 1,
          'detail': 1,
          'excel': 1,
          'width': '80',
          'type': 'select',
          'rowAddField': '',
          'groupId': '',
          'rules': [],
          'prop': 'type'
        }, {
          'label': '期数',
          'show': 1,
          'add': 1,
          'detail': 1,
          'excel': 1,
          'width': 80,
          'type': 'text',
          'rowAddField': '',
          'groupId': '',
          'rules': [],
          'prop': 'expect_num'
        }, {
          'label': '操作者编号',
          'show': 1,
          'add': 1,
          'detail': 1,
          'excel': 1,
          'width': '100',
          'type': 'text',
          'rowAddField': '',
          'groupId': '',
          'rules': [],
          'prop': 'operator_man'
        }, {
          'label': '操作者IP',
          'show': 1,
          'add': 1,
          'detail': 1,
          'excel': 1,
          'width': '120',
          'type': 'text',
          'rowAddField': '',
          'groupId': '',
          'rules': [],
          'prop': 'operator_ip'
        }, {
          'label': '操作者ID',
          'show': 1,
          'add': 1,
          'detail': 1,
          'excel': 1,
          'width': '80',
          'type': 'text',
          'rowAddField': '',
          'groupId': '',
          'rules': [],
          'prop': 'op'
        }, {
          'label': '系统id',
          'show': 0,
          'add': 1,
          'detail': 1,
          'excel': 1,
          'width': 89,
          'type': 'text',
          'rowAddField': '',
          'groupId': '',
          'rules': [],
          'prop': 'sys'
        }, {
          'label': '备注',
          'show': 1,
          'add': 1,
          'detail': 1,
          'excel': 1,
          'width': '160',
          'type': 'text',
          'rowAddField': '',
          'groupId': '',
          'rules': [],
          'prop': 'remark'
        }],
        'tableSwitchProps': {},
        'tableSelectProps': {
          'before_level': {
            'multiple': 0,
            'data': [{
              'key': null,
              'value': ''
            }],
            'remote': true,
            'id': 0,
            'relation': false,
            'relationProps': [],
            'remoteFunc': 'tableFunc_select_before'
          },
          'after_level': {
            'multiple': 0,
            'data': [{
              'key': null,
              'value': ''
            }],
            'remote': true,
            'id': 1,
            'relation': false,
            'relationProps': [],
            'remoteFunc': 'tableFunc_select_after'
          },
          'type': {
            'multiple': 0,
            'data': [{
              'key': 0,
              'value': '手动'
            }, {
              'key': 1,
              'value': '自动'
            }, {
              'key': 3,
              'value': '报单'
            }],
            'remote': false,
            'id': 2,
            'relation': false,
            'relationProps': []
          }
        },
        'tableCascaderProps': {},
        'tableFileProps': {},
        'rowButton': [],
        'stripe': false,
        'border': true,
        'showPagination': true,
        'showSelection': false,
        'showSummary': false,
        'title': '资格定级',
        'tableName': 't_level_log',
        'isGroup': false,
        'isTree': false,
        'headerButton': [],
        'tableAutocompleteProps': {},
        'tableRadioProps': {}
      },
      remoteFunc: {
        modelConditionFunc_3: function modelConditionFunc_3(tag, relationData, resolve) {
          //筛选条件数据
          expectNumList({
            page: 1,
            limit: _this.$g.maxExpectNum
          }).then(function _callee(res) {
            var data, i, item;
            return regeneratorRuntime.async(function _callee$(_context) {
              while (1) {
                switch (_context.prev = _context.next) {
                  case 0:
                    data = [];

                    for (i = 0; i < res.length; i++) {
                      item = res[i];

                      if (i > 0) {
                        data.push({
                          key: 'expect_num',
                          value: item.expect_num,
                          desc: '第' + item.expect_num + '期(' + item.add_time.substring(0, 10) + ')'
                        });
                      }
                    }

                    resolve(tag, [data]);

                  case 3:
                  case "end":
                    return _context.stop();
                }
              }
            });
          });
        },
        modelConditionFunc_1: function modelConditionFunc_1(tag, relationData, resolve) {
          //筛选条件数据
          configLevelList({}).then(function _callee2(res) {
            var data, i, item;
            return regeneratorRuntime.async(function _callee2$(_context2) {
              while (1) {
                switch (_context2.prev = _context2.next) {
                  case 0:
                    data = [];

                    for (i in res.rows) {
                      item = res.rows[i];
                      data.push({
                        key: 'before_level',
                        value: item.tag,
                        desc: item.name
                      });
                    }

                    resolve(tag, [data]);

                  case 3:
                  case "end":
                    return _context2.stop();
                }
              }
            });
          });
        },
        modelConditionFunc_2: function modelConditionFunc_2(tag, relationData, resolve) {
          //筛选条件数据
          configLevelList({}).then(function _callee3(res) {
            var data, i, item;
            return regeneratorRuntime.async(function _callee3$(_context3) {
              while (1) {
                switch (_context3.prev = _context3.next) {
                  case 0:
                    data = [];

                    for (i in res.rows) {
                      item = res.rows[i];
                      data.push({
                        key: 'after_level',
                        value: item.tag,
                        desc: item.name
                      });
                    }

                    resolve(tag, [data]);

                  case 3:
                  case "end":
                    return _context3.stop();
                }
              }
            });
          });
        },
        modelConditionFunc_4: function modelConditionFunc_4(tag, relationData, resolve) {
          //筛选条件数据
          //[[{key:"type",value:"1"}]]
          var data = [];
          data = [[{
            key: 'add_time',
            value: []
          }], [{
            key: 'add_time',
            value: _this.$g.fun.getDateRange(new Date(), 0, true)
          }], [{
            key: 'add_time',
            value: _this.$g.fun.getDateRange(new Date(), 1, true)
          }], [{
            key: 'add_time',
            value: _this.$g.fun.getDateRange(new Date(), 7, true)
          }], [{
            key: 'add_time',
            value: _this.$g.fun.getDateRange(new Date(), 30, true)
          }], [{
            key: 'add_time',
            value: _this.$g.fun.getDateRange(new Date(), 365, true)
          }], [{
            key: 'add_time',
            value: []
          }]];
          resolve(tag, data);
        },
        tableFunc_select_before: function tableFunc_select_before(tag, relationData, resolve) {
          //筛选项模型数组
          configLevelList({}).then(function _callee4(res) {
            var data, i, item;
            return regeneratorRuntime.async(function _callee4$(_context4) {
              while (1) {
                switch (_context4.prev = _context4.next) {
                  case 0:
                    data = [];

                    for (i in res.rows) {
                      item = res.rows[i];
                      data.push({
                        key: item.tag,
                        value: item.name
                      });
                    }

                    resolve(tag, data);

                  case 3:
                  case "end":
                    return _context4.stop();
                }
              }
            });
          });
        },
        tableFunc_select_after: function tableFunc_select_after(tag, relationData, resolve) {
          //筛选项模型数组
          configLevelList({}).then(function _callee5(res) {
            var data, i, item;
            return regeneratorRuntime.async(function _callee5$(_context5) {
              while (1) {
                switch (_context5.prev = _context5.next) {
                  case 0:
                    data = [];

                    for (i in res.rows) {
                      item = res.rows[i];
                      data.push({
                        key: item.tag,
                        value: item.name
                      });
                    }

                    resolve(tag, data);

                  case 3:
                  case "end":
                    return _context5.stop();
                }
              }
            });
          });
        }
      }
    };
  },
  mounted: function mounted() {
    this.table = this.$refs.ref_1001;
    this.search = this.$refs.ref_1000;
  },
  methods: {
    reqTableData: function reqTableData(params, resolve) {
      var where = {};

      if (this.search) {
        where = this.search.parseWhere();
        params.where = where;
      }

      levelLog(params).then(function _callee6(res) {
        return regeneratorRuntime.async(function _callee6$(_context6) {
          while (1) {
            switch (_context6.prev = _context6.next) {
              case 0:
                resolve({
                  total: res.count,
                  data: res.rows
                });

              case 1:
              case "end":
                return _context6.stop();
            }
          }
        });
      });
    },
    reqAdd: function reqAdd(isEdit, params, row, resolve) {//if (isEdit){
      //  params.id = row.id
      //}
      //xxxAdd(params).then(async res=>{
      //  resolve()
      //})
    },
    reqDel: function reqDel(params, resolve) {
      var ids = params.map(function (item) {
        return item.id;
      }); //xxxDel({id:ids}).then(async res=>{
      //  resolve()
      //})
    },
    onClickLink: function onClickLink(prop, row) {//点击列表link类型回调
    },
    onClickRowButton: function onClickRowButton(btnIndex, row) {//点击列表按钮回调
    },
    onClickHeaderButton: function onClickHeaderButton(btnIndex, row) {//点击列表头部按钮回调
    },
    onSummary: function onSummary(param) {//列表尾部合计回调
    },
    onSwitchChange: function onSwitchChange(val, prop, row) {//列表中switch变化事件
    },
    onClickSearchOperate: function onClickSearchOperate(btnIndex, btnData) {
      //条件菜单按钮点击事件
      if (btnData.tag === this.$tempModel.buttonTag.search) {
        this.table.reloadData();
      } else if (btnData.tag === this.$tempModel.buttonTag.export) {
        this.table.exportExcel();
      } else if (btnData.tag === this.$tempModel.buttonTag.import) {
        var header = btnData.header;
        var results = btnData.results;
      }
    },
    onConditionChange: function onConditionChange(tag, index) {
      this.table.reloadData();
    },
    onRowButtonShow: function onRowButtonShow(btnIndex, row) {
      return true;
    }
  }
};