import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.replace";
import "regenerator-runtime/runtime";
import Vue from 'vue';
import router from '@/router';
export default {
  namespaced: true,
  state: {
    // 尺寸
    value: '' // medium small mini

  },
  actions: {
    /**
     * @description 将当前的设置应用到 element
     * @param {Object} context
     * @param {Boolean} refresh 是否在设置之后刷新页面
     */
    apply: function apply(_ref, refresh) {
      var state = _ref.state,
          commit = _ref.commit;
      Vue.prototype.$ELEMENT.size = state.value;

      if (refresh) {
        commit('d2admin/page/keepAliveClean', null, {
          root: true
        });
        router.replace('/refresh');
      }
    },

    /**
     * @description 确认已经加载组件尺寸设置 https://github.com/d2-projects/d2-admin/issues/198
     * @param {Object} context
     */
    isLoaded: function isLoaded(_ref2) {
      var state = _ref2.state;
      if (state.value) return Promise.resolve();
      return new Promise(function (resolve) {
        var timer = setInterval(function () {
          if (state.value) {
            resolve(clearInterval(timer));
          }
        }, 10);
      });
    },

    /**
     * @description 设置尺寸
     * @param {Object} context
     * @param {String} size 尺寸
     */
    set: function set(_ref3, size) {
      var state = _ref3.state,
          dispatch = _ref3.dispatch;
      return new Promise(function _callee(resolve) {
        return regeneratorRuntime.async(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                // store 赋值
                state.value = size; // 应用

                dispatch('apply', true); // 持久化

                _context.next = 4;
                return regeneratorRuntime.awrap(dispatch('d2admin/db/set', {
                  dbName: 'sys',
                  path: 'size.value',
                  value: state.value,
                  user: true
                }, {
                  root: true
                }));

              case 4:
                // end
                resolve();

              case 5:
              case "end":
                return _context.stop();
            }
          }
        });
      });
    },

    /**
     * @description 从持久化数据读取尺寸设置
     * @param {Object} context
     */
    load: function load(_ref4) {
      var state = _ref4.state,
          dispatch = _ref4.dispatch;
      return new Promise(function _callee2(resolve) {
        return regeneratorRuntime.async(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                _context2.next = 2;
                return regeneratorRuntime.awrap(dispatch('d2admin/db/get', {
                  dbName: 'sys',
                  path: 'size.value',
                  defaultValue: 'default',
                  user: true
                }, {
                  root: true
                }));

              case 2:
                state.value = _context2.sent;
                // 应用
                dispatch('apply'); // end

                resolve();

              case 5:
              case "end":
                return _context2.stop();
            }
          }
        });
      });
    }
  }
};