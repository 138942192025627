'use strict';

import "core-js/modules/es.object.to-string";
import setting from '@/setting';

var OSS = require('ali-oss');

export default {
  /**
   * 创建oss客户端对象
   * @returns {*}
   */
  createOssClient: function createOssClient() {
    return new Promise(function (resolve, reject) {
      var client = new OSS(setting.ossConfig.aliYunConfig);
      resolve(client);
    });
  },

  /**
   * 文件上传
   * @param option 参考csdn: https://blog.csdn.net/qq_27626333/article/details/81463139
   */
  uploadFile: function uploadFile(file, fileName, _progress, suc, fail) {
    this.createOssClient().then(function (client) {
      // 上传处理
      // 分片上传文件
      client.multipartUpload(fileName, file, {
        progress: function progress(p) {
          if (_progress) {
            _progress(p);
          }
        }
      }).then(function (val) {
        if (val.res.statusCode === 200) {
          if (suc) {
            suc(val);
          }
        } else {
          if (fail) {
            fail({
              code: -100,
              msg: '上传失败'
            });
          }
        }
      }, function (err) {
        if (fail) {
          fail({
            code: err.code,
            msg: err.message
          });
        }
      });
    });
  }
};