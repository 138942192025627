import "core-js/modules/es.regexp.constructor";
import "core-js/modules/es.regexp.to-string";
//
//
//
//
//
//
//
//
export default {
  props: {
    type: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    field: {
      type: Array,
      default: function _default() {
        return [{
          key: 'no',
          value: '编号'
        }];
      }
    }
  },
  computed: {
    selectWidth: function selectWidth() {
      var maxWidth = 0;
      var pattern = new RegExp("[\u4E00-\u9FA5]+");

      for (var i in this.field) {
        var v = this.field[i].desc;
        var width = 45;

        for (var j in v) {
          if (pattern.test(v[j])) {
            //是中文
            width += 14;
          } else {
            width += 8;
          }
        }

        if (width > maxWidth) {
          maxWidth = width;
        }
      }

      if (maxWidth < 80) {
        maxWidth = 80;
      }

      return maxWidth;
    }
  },
  watch: {
    field: function field(nVal, oVal) {
      this.key = nVal.length > 0 ? nVal[0].key : '';
    }
  },
  data: function data() {
    return {
      value: '',
      key: this.field.length > 0 ? this.field[0].key : ''
    };
  },
  methods: {
    where: function where() {
      var where = {};

      if (this.value === '') {
        return where;
      }

      for (var i in this.field) {
        var item = this.field[i];

        if (this.key === item.key) {
          where[item.key] = this.value;
        }
      }

      return where;
    }
  }
};