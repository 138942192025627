import "core-js/modules/es.array.map";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.search";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tempTable from "@/components/rw/tempTable/index";
import tempSearch from "@/components/rw/tempSearch/index";
import tempForm from "@/components/rw/tempForm/index";
import tempChart from "@/components/rw/tempChart/index";
import areaHandle from '@/views/pages/member/memberAreaApply/areaHandle.vue';
import areaAdd from '@/views/pages/member/memberAreaApply/areaAdd.vue';
import { areaApplyList, areaApplyHandle, areaApplyDel, areaApplyReject, areaApplyPass, areaApplyExamine, areaApplyAllList } from '@/api/apis';
export default {
  components: {
    tempTable: tempTable,
    tempSearch: tempSearch,
    tempForm: tempForm,
    tempChart: tempChart,
    areaHandle: areaHandle,
    areaAdd: areaAdd
  },
  name: 'memberAreaApply',
  data: function data() {
    var _this = this;

    return {
      area_id: '',
      props: {
        lazy: true,
        checkStrictly: true,
        filterable: true,
        lazyLoad: function lazyLoad(node, resolve) {
          var level = node.level;
          console.log('node', node);
          areaApplyAllList({
            parent: level === 0 ? 0 : node.data.value
          }).then(function _callee(res) {
            var ary, i, item;
            return regeneratorRuntime.async(function _callee$(_context) {
              while (1) {
                switch (_context.prev = _context.next) {
                  case 0:
                    ary = [];

                    for (i in res) {
                      item = res[i];
                      ary.push({
                        value: item.id,
                        label: item.name,
                        // disabled:item.lock==0?false:true,
                        leaf: level >= 2
                      });
                    }

                    resolve(ary);

                  case 3:
                  case "end":
                    return _context.stop();
                }
              }
            });
          });
        }
      },
      searchModel_1000: {
        "del": false,
        "id": "id_1000",
        "isCollapse": false,
        "operateData": [{
          "icon": "el-icon-search",
          "tag": "search",
          "title": "搜索",
          "type": "primary"
        }, {
          "icon": "el-icon-position",
          "tag": "export",
          "title": "导出",
          "type": "primary"
        }],
        "searchData": [{
          "children": [{
            "field": [{
              "desc": "省",
              "key": "area_full_name",
              "value": ""
            }],
            "operator": [{
              "key": "like",
              "value": "模糊"
            }],
            "placeholder": "请输入",
            "type": "input-mul-complex"
          }, {
            "field": [{
              "desc": "市",
              "key": "area_full_name"
            }],
            "operator": [{
              "key": "like",
              "value": "模糊"
            }],
            "placeholder": "请输入",
            "type": "input-mul-complex"
          }, {
            "field": [{
              "desc": "区",
              "key": "area_full_name"
            }],
            "operator": [{
              "key": "like",
              "value": "模糊"
            }],
            "placeholder": "请输入",
            "type": "input-mul-complex"
          }],
          "id": 4,
          "relation": false,
          "relationId": "",
          "remote": false,
          "remoteType": 0,
          "select": 0,
          "title": "按省市区",
          "type": 2,
          "remoteFunc": []
        }, {
          "children": [{
            "field": [],
            "title": "全部",
            "type": "button"
          }, {
            "field": [{
              "key": "status",
              "value": "0"
            }],
            "title": "待初审",
            "type": "button"
          }, {
            "field": [{
              "key": "status",
              "value": "1"
            }],
            "title": "初审通过",
            "type": "button"
          }, {
            "field": [{
              "key": "status",
              "value": "-1"
            }],
            "title": "初审拒绝",
            "total": 0,
            "type": "button"
          }, {
            "field": [{
              "key": "status",
              "value": "2"
            }],
            "title": "待终审",
            "total": 0,
            "type": "button"
          }, {
            "field": [{
              "key": "status",
              "value": "3"
            }],
            "title": "终审通过",
            "total": 0,
            "type": "button"
          }, {
            "field": [{
              "key": "status",
              "value": "4"
            }],
            "title": "冻结中",
            "total": 0,
            "type": "button"
          }, {
            "field": [{
              "key": "status",
              "value": "-2"
            }],
            "title": "已失效",
            "total": 0,
            "type": "button"
          }],
          "id": 0,
          "relation": false,
          "relationId": "",
          "remote": false,
          "remoteType": 0,
          "select": 0,
          "title": "条件名称",
          "type": 0,
          "remoteFunc": []
        }, {
          "children": [{
            "field": [],
            "title": "全部",
            "type": "button"
          }, {
            "field": [],
            "title": "今天",
            "type": "button"
          }, {
            "field": [],
            "title": "昨天",
            "type": "button"
          }, {
            "field": [],
            "title": "最近7天",
            "type": "button"
          }, {
            "field": [],
            "title": "本月",
            "type": "button"
          }, {
            "field": [],
            "title": "本年",
            "type": "button"
          }, {
            "field": [],
            "title": "自定义",
            "type": "custom-datetime-range"
          }],
          "id": 1,
          "relation": false,
          "relationId": "",
          "remote": true,
          "remoteType": 1,
          "select": 0,
          "title": "申请日期",
          "type": 1,
          "remoteFunc": ["modelConditionFunc_1"]
        }, {
          "children": [{
            "field": [{
              "desc": "用户编号",
              "key": "user_number",
              "value": ""
            }],
            "operator": ["gte", "lte", "gt", "lt", "eq", "ne", "like", "notLike", "in", "between"],
            "placeholder": "请输入",
            "type": "input-complex"
          }, {
            "field": [{
              "desc": "店号",
              "key": "shop_no"
            }],
            "operator": [],
            "placeholder": "请输入",
            "type": "input-complex"
          }, {
            "field": [{
              "desc": "顶点搜索",
              "key": "top_number"
            }],
            "operator": [],
            "placeholder": "请输入",
            "type": "input-complex"
          }, {
            "field": [{
              "desc": "手机号",
              "key": "t_user.phone"
            }],
            "operator": [],
            "placeholder": "请输入",
            "type": "input-complex"
          }],
          "id": 2,
          "relation": false,
          "relationId": "",
          "remote": false,
          "remoteType": 0,
          "select": 0,
          "title": "按条件",
          "type": 2,
          "remoteFunc": []
        }, {
          "children": [{
            "field": [],
            "title": "全部",
            "type": "button"
          }, {
            "field": [{
              "key": "is_change",
              "value": "0"
            }],
            "title": "否",
            "type": "button"
          }, {
            "field": [{
              "key": "is_change",
              "value": "1"
            }],
            "title": "是",
            "type": "button"
          }],
          "id": 3,
          "relation": false,
          "relationId": "",
          "remote": false,
          "remoteType": 0,
          "select": 0,
          "title": "是否是更换的区域",
          "type": 0,
          "remoteFunc": []
        }, {
          "children": [{
            "field": [],
            "title": "全部",
            "type": "button"
          }, {
            "type": "button",
            "title": "乡级",
            "total": 0,
            "field": [{
              "key": "area_type",
              "value": "0"
            }]
          }, {
            "field": [{
              "key": "area_type",
              "value": "1"
            }],
            "title": "地级",
            "type": "button"
          }, {
            "field": [{
              "key": "area_type",
              "value": "2"
            }],
            "title": "市级",
            "type": "button"
          }, {
            "field": [{
              "key": "area_type",
              "value": "3"
            }],
            "title": "省级",
            "total": 0,
            "type": "button"
          }],
          "id": 5,
          "relation": false,
          "relationId": "",
          "remote": false,
          "remoteType": 0,
          "select": 0,
          "title": "申请区域类型",
          "type": 0,
          "remoteFunc": []
        }],
        "type": "searchTemplate"
      },
      tableModel_1001: {
        "border": true,
        "del": false,
        "headerButton": [{
          "icon": "",
          "id": "",
          "tag": "default",
          "title": "添加",
          "type": "primary"
        }],
        "id": "id_1001",
        "isGroup": true,
        "isTree": false,
        "rowButton": [{
          "id": "186",
          "tag": "default",
          "title": "初审通过",
          "type": "success"
        }, {
          "icon": "",
          "id": "187",
          "tag": "default",
          "title": "初审拒绝",
          "type": "danger"
        }, {
          "icon": "",
          "id": "188",
          "tag": "default",
          "title": "终审通过",
          "type": "success"
        }, {
          "icon": "",
          "id": "189",
          "tag": "default",
          "title": "终审拒绝",
          "type": "danger"
        }, {
          "icon": "",
          "id": "190",
          "tag": "default",
          "title": "变更",
          "type": "warning"
        }, {
          "icon": "",
          "id": "192",
          "tag": "edit",
          "title": "资料查看修改",
          "type": "primary"
        }, {
          "id": "191",
          "tag": "del",
          "title": "失效",
          "type": "danger"
        }],
        "showPagination": true,
        "showSelection": false,
        "showSummary": false,
        "stripe": true,
        "tableAutocompleteProps": {},
        "tableCascaderProps": {},
        "tableFileProps": {},
        "tableGroupProps": [{
          "id": 7,
          "name": "申请信息"
        }, {
          "id": 8,
          "name": "申请人信息"
        }, {
          "id": 9,
          "name": "营业执照"
        }, {
          "id": 10,
          "name": "法人信息"
        }, {
          "id": 11,
          "name": "开户行信息"
        }, {
          "id": 14,
          "name": "牌匾设置"
        }, {
          "id": 15,
          "name": "委托书管理"
        }],
        "tableModel": [{
          "add": 0,
          "defaultValue": null,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "7",
          "label": "ID",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "id"
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "7",
          "label": "店号",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "100",
          "prop": "shop_no"
        }, {
          "add": 0,
          "defaultValue": null,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "7",
          "label": "用户编号",
          "required": 0,
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "90",
          "prop": "user_number"
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "7",
          "label": "姓名",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "t_user.real_name"
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "7",
          "label": "手机号",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "90",
          "prop": "t_user.phone"
        }, {
          "add": 0,
          "desc": "请参考中国省市区编码",
          "detail": 1,
          "excel": 1,
          "groupId": "7",
          "label": "区域编码",
          "required": 0,
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "area_id"
        }, {
          "add": 0,
          "defaultValue": null,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "7",
          "label": "区域全名",
          "required": 0,
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "150",
          "prop": "area_full_name"
        }, {
          "add": 0,
          "defaultValue": null,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "7",
          "label": "区域类型",
          "required": 0,
          "rules": [],
          "show": 1,
          "type": "select",
          "width": null,
          "prop": "area_type"
        }, {
          "add": 0,
          "defaultValue": null,
          "desc": "代理地区名称",
          "detail": 1,
          "excel": 1,
          "groupId": "7",
          "label": "区域名称",
          "required": 0,
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "120",
          "prop": "area_name"
        }, {
          "add": 0,
          "defaultValue": null,
          "desc": "",
          "detail": 0,
          "excel": 0,
          "groupId": "7",
          "label": "开始时间",
          "rules": [],
          "show": 0,
          "type": "date",
          "width": null,
          "prop": "start_time"
        }, {
          "add": 0,
          "defaultValue": null,
          "desc": "",
          "detail": 0,
          "excel": 0,
          "groupId": "7",
          "label": "结束时间",
          "rules": [],
          "show": 0,
          "type": "date",
          "width": null,
          "prop": "end_time"
        }, {
          "add": 1,
          "defaultValue": null,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "7",
          "label": "申请日期",
          "required": 1,
          "rules": [],
          "show": 1,
          "type": "datetime",
          "width": "160",
          "prop": "created_at"
        }, {
          "add": 0,
          "defaultValue": null,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "7",
          "label": "状态变更日期",
          "rules": [],
          "show": 1,
          "type": "datetime",
          "width": "160",
          "prop": "updated_at"
        }, {
          "add": 0,
          "defaultValue": null,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "7",
          "label": "状态",
          "required": 0,
          "rules": [],
          "show": 1,
          "type": "select",
          "width": "100",
          "prop": "status"
        }, {
          "add": 1,
          "defaultValue": null,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "8",
          "label": "姓名",
          "rules": [],
          "show": 0,
          "type": "text",
          "width": null,
          "prop": "user_name"
        }, {
          "add": 1,
          "defaultValue": null,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "8",
          "label": "性别",
          "rules": [],
          "show": 0,
          "type": "select",
          "width": null,
          "prop": "sex"
        }, {
          "add": 1,
          "defaultValue": null,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "8",
          "label": "身份证号",
          "rules": [],
          "show": 0,
          "type": "text",
          "width": null,
          "prop": "idcard"
        }, {
          "add": 1,
          "defaultValue": null,
          "desc": "建议尺寸640x320 不超过256kb",
          "detail": 1,
          "excel": 1,
          "groupId": "8",
          "label": "身份证正面",
          "rules": [],
          "show": 1,
          "type": "image",
          "width": null,
          "prop": "idcard_pic",
          "file": {
            "checkSize": 0,
            "exData": {
              "type": "default"
            },
            "fileType": ".png,.jpg,.jpeg",
            "height": 320,
            "id": 2,
            "key": "idcard_pic",
            "maxNum": 1,
            "maxSize": 256,
            "width": 640
          }
        }, {
          "add": 1,
          "defaultValue": null,
          "desc": "建议尺寸640x320 不超过256kb",
          "detail": 1,
          "excel": 1,
          "groupId": "8",
          "label": "身份证背面",
          "rules": [],
          "show": 1,
          "type": "image",
          "width": null,
          "prop": "idcard_pic_f",
          "file": {
            "checkSize": 0,
            "exData": {
              "type": "default"
            },
            "fileType": ".png,.jpg,.jpeg",
            "height": 320,
            "id": 4,
            "key": "idcard_pic_f",
            "maxNum": 1,
            "maxSize": 256,
            "width": 640
          }
        }, {
          "add": 1,
          "defaultValue": null,
          "desc": "建议尺寸640x320 不超过256kb",
          "detail": 1,
          "excel": 1,
          "groupId": "9",
          "label": "营业执照",
          "rules": [],
          "show": 1,
          "type": "image",
          "width": null,
          "prop": "business_pic",
          "file": {
            "checkSize": 0,
            "exData": {
              "type": "default"
            },
            "fileType": ".png,.jpg,.jpeg",
            "height": 320,
            "id": 3,
            "key": "business_pic",
            "maxNum": 1,
            "maxSize": 256,
            "width": 640
          }
        }, {
          "add": 1,
          "defaultValue": null,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "11",
          "label": "开户行账号",
          "rules": [],
          "show": 0,
          "type": "text",
          "width": null,
          "prop": "bank_account_no"
        }, {
          "add": 1,
          "defaultValue": null,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "11",
          "label": "银行名称",
          "rules": [],
          "show": 0,
          "type": "text",
          "width": null,
          "prop": "bank_name"
        }, {
          "add": 1,
          "defaultValue": null,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "7",
          "label": "拒绝原因",
          "rules": [],
          "show": 1,
          "type": "textarea",
          "width": "200",
          "prop": "reject_reason"
        }, {
          "add": 0,
          "defaultValue": null,
          "desc": "",
          "detail": 1,
          "excel": 0,
          "groupId": "7",
          "label": "审核人",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "audit_user"
        }, {
          "add": 0,
          "defaultValue": null,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "7",
          "label": "审核时间",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "160",
          "prop": "audit_time"
        }, {
          "add": 0,
          "defaultValue": null,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "7",
          "label": "申请期数",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "expect_num"
        }, {
          "add": 1,
          "defaultValue": null,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "8",
          "label": "身份证过期日期",
          "rules": [],
          "show": 0,
          "type": "text",
          "width": null,
          "prop": "idcard_expire"
        }, {
          "add": 1,
          "defaultValue": null,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "8",
          "label": "身份证地址",
          "rules": [],
          "show": 0,
          "type": "text",
          "width": null,
          "prop": "idcard_address"
        }, {
          "add": 1,
          "defaultValue": null,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "8",
          "label": "种族",
          "rules": [],
          "show": 0,
          "type": "text",
          "width": null,
          "prop": "idcard_ethnicity"
        }, {
          "add": 1,
          "defaultValue": null,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "8",
          "label": "身份证生日",
          "rules": [],
          "show": 0,
          "type": "text",
          "width": null,
          "prop": "idcard_birth"
        }, {
          "add": 1,
          "defaultValue": null,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "8",
          "label": "身份证有效期",
          "rules": [],
          "show": 0,
          "type": "text",
          "width": null,
          "prop": "idcard_issue"
        }, {
          "add": 1,
          "defaultValue": null,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "9",
          "label": "公司名称",
          "rules": [],
          "show": 0,
          "type": "text",
          "width": null,
          "prop": "company_name"
        }, {
          "add": 1,
          "defaultValue": null,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "9",
          "label": "执照编号",
          "rules": [],
          "show": 0,
          "type": "text",
          "width": null,
          "prop": "company_no"
        }, {
          "add": 1,
          "defaultValue": null,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "9",
          "label": "业务范围",
          "rules": [],
          "show": 0,
          "type": "text",
          "width": null,
          "prop": "company_business"
        }, {
          "add": 1,
          "defaultValue": null,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "9",
          "label": "营业范围",
          "rules": [],
          "show": 0,
          "type": "text",
          "width": null,
          "prop": "business_scope"
        }, {
          "add": 1,
          "defaultValue": null,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "9",
          "label": "注册日期有效日期",
          "rules": [],
          "show": 0,
          "type": "text",
          "width": null,
          "prop": "business_term"
        }, {
          "add": 1,
          "defaultValue": null,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "9",
          "label": "公司类型",
          "rules": [],
          "show": 0,
          "type": "text",
          "width": null,
          "prop": "business_type"
        }, {
          "add": 1,
          "defaultValue": null,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "9",
          "label": "公司地址",
          "rules": [],
          "show": 0,
          "type": "text",
          "width": null,
          "prop": "company_address"
        }, {
          "add": 1,
          "defaultValue": null,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "10",
          "label": "法人姓名",
          "rules": [],
          "show": 0,
          "type": "text",
          "width": null,
          "prop": "legal_representative"
        }, {
          "add": 1,
          "defaultValue": null,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "10",
          "label": "法人身份证号码",
          "rules": [],
          "show": 0,
          "type": "text",
          "width": null,
          "prop": "legal_idcard"
        }, {
          "add": 1,
          "defaultValue": null,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "10",
          "label": "法人身份证姓名",
          "rules": [],
          "show": 0,
          "type": "text",
          "width": null,
          "prop": "legal_idcard_name"
        }, {
          "add": 1,
          "defaultValue": null,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "10",
          "label": "法人身份证有效期",
          "rules": [],
          "show": 0,
          "type": "text",
          "width": null,
          "prop": "legal_idcard_expire"
        }, {
          "add": 1,
          "defaultValue": null,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "10",
          "label": "身份证有效期",
          "rules": [],
          "show": 0,
          "type": "text",
          "width": null,
          "prop": "legal_idcard_issue"
        }, {
          "add": 1,
          "defaultValue": null,
          "desc": "建议尺寸640x320 不超过256kb",
          "detail": 1,
          "excel": 1,
          "groupId": "10",
          "label": "法人身份证照片",
          "rules": [],
          "show": 1,
          "type": "image",
          "width": null,
          "prop": "legal_idcard_pic",
          "file": {
            "checkSize": 0,
            "exData": {
              "type": "default"
            },
            "fileType": ".png,.jpg,.jpeg",
            "height": 320,
            "id": 5,
            "key": "legal_idcard_pic",
            "maxNum": 1,
            "maxSize": 256,
            "width": 640
          }
        }, {
          "add": 1,
          "defaultValue": null,
          "desc": "建议尺寸640x320 不超过256kb",
          "detail": 1,
          "excel": 1,
          "groupId": "10",
          "label": "法人身份证照片（反面）",
          "rules": [],
          "show": 1,
          "type": "image",
          "width": null,
          "prop": "legal_idcard_pic_f",
          "file": {
            "checkSize": 0,
            "exData": {
              "type": "default"
            },
            "fileType": ".png,.jpg,.jpeg",
            "height": 320,
            "id": 6,
            "key": "legal_idcard_pic_f",
            "maxNum": 1,
            "maxSize": 256,
            "width": 640
          }
        }, {
          "add": 1,
          "defaultValue": null,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "10",
          "label": "法人身份证地址",
          "rules": [],
          "show": 0,
          "type": "text",
          "width": null,
          "prop": "legal_idcard_address"
        }, {
          "add": 1,
          "defaultValue": null,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "10",
          "label": "法人种族",
          "rules": [],
          "show": 0,
          "type": "text",
          "width": null,
          "prop": "legal_idcard_ethnicity"
        }, {
          "add": 1,
          "defaultValue": null,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "10",
          "label": "法人性别",
          "rules": [],
          "show": 0,
          "type": "text",
          "width": null,
          "prop": "legal_sex"
        }, {
          "add": 1,
          "defaultValue": null,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "7",
          "label": "备注信息",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "remark"
        }, {
          "add": 1,
          "defaultValue": null,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "7",
          "label": "公司拒绝原因",
          "rules": [],
          "show": 1,
          "type": "textarea",
          "width": "200",
          "prop": "reject2_reason"
        }, {
          "add": 0,
          "defaultValue": null,
          "desc": "",
          "detail": 0,
          "excel": 0,
          "groupId": "7",
          "label": "认证用户",
          "rules": [],
          "show": 0,
          "type": "text",
          "width": null,
          "prop": "audit2_user"
        }, {
          "add": 0,
          "defaultValue": null,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "7",
          "label": "公司审核时间",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "160",
          "prop": "audit2_time"
        }, {
          "add": 0,
          "defaultValue": null,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "7",
          "label": "公司认证提交时间",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "160",
          "prop": "company_time"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "7",
          "label": "是否是更换区域",
          "rules": [],
          "show": 1,
          "type": "select",
          "width": "130",
          "prop": "is_change"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "7",
          "label": "更换前ID",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "100",
          "prop": "change_source"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "7",
          "label": "解冻日期",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "160",
          "prop": "change_expire"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "14",
          "label": "牌匾长度",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "board_length"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "14",
          "label": "牌匾宽度",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "board_width"
        }, {
          "add": 0,
          "desc": "",
          "detail": 0,
          "excel": 0,
          "groupId": "14",
          "label": "牌匾高度",
          "rules": [],
          "show": 0,
          "type": "text",
          "width": "80",
          "prop": "board_height"
        }, {
          "add": 1,
          "desc": "建议尺寸640x320 不超过256kb",
          "detail": 1,
          "groupId": "15",
          "label": "委托书",
          "rules": [],
          "show": 1,
          "type": "image",
          "width": null,
          "prop": "contract_pic",
          "file": {
            "checkSize": 0,
            "exData": {
              "type": "default"
            },
            "fileType": ".png,.jpg,.jpeg",
            "height": 320,
            "id": 16,
            "key": "contract_pic",
            "maxNum": 1,
            "maxSize": 256,
            "width": 640
          }
        }],
        "tableName": "",
        "tableRadioProps": {},
        "tableSelectProps": {
          "area_type": {
            "data": [{
              "key": 1,
              "value": "区级"
            }, {
              "key": 2,
              "value": "地级"
            }, {
              "key": 3,
              "value": "省级"
            }, {
              "key": 0,
              "value": "乡级"
            }],
            "id": 0,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": false
          },
          "status": {
            "data": [{
              "key": 0,
              "value": "待初审"
            }, {
              "key": 1,
              "value": "初审通过"
            }, {
              "key": -1,
              "value": "初审拒绝"
            }, {
              "key": 2,
              "value": "待终审"
            }, {
              "key": 3,
              "value": "终审通过"
            }, {
              "key": 4,
              "value": "冻结中"
            }, {
              "key": -2,
              "value": "已失效"
            }],
            "id": 1,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": false
          },
          "sex": {
            "data": [{
              "key": 1,
              "value": "男"
            }, {
              "key": 2,
              "value": "女"
            }],
            "id": 12,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": false
          },
          "is_change": {
            "data": [{
              "key": 0,
              "value": "否"
            }, {
              "key": 1,
              "value": "是"
            }],
            "id": 13,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": false
          }
        },
        "tableSwitchProps": {},
        "title": "区域申请",
        "type": "tableTemplate"
      },
      remoteFunc: {
        modelConditionFunc_1: function modelConditionFunc_1(tag, relationData, resolve) {
          //筛选条件数据
          //[[{key:"type",value:"1"}]]
          var data = [];
          data = [[{
            key: "created_at",
            value: []
          }], [{
            key: "created_at",
            value: _this.$g.fun.getDateRange(new Date(), 0, true)
          }], [{
            key: "created_at",
            value: _this.$g.fun.getDateRange(new Date(), 1, true)
          }], [{
            key: "created_at",
            value: _this.$g.fun.getDateRange(new Date(), 7, true)
          }], [{
            key: "created_at",
            value: _this.$g.fun.getDateRange(new Date(), 30, true)
          }], [{
            key: "created_at",
            value: _this.$g.fun.getDateRange(new Date(), 365, true)
          }], [{
            key: "created_at",
            value: []
          }]];
          resolve(tag, data);
        }
      }
    };
  },
  mounted: function mounted() {
    this.table = this.$refs.ref_1001;
    this.search = this.$refs.ref_1000;
  },
  methods: {
    reqTableData: function reqTableData(params, resolve) {
      var where = {};

      if (this.search) {
        where = this.search.parseWhere();
      }

      params.where = where;

      if (this.area_id !== "") {
        params.where.area_id = this.area_id[this.area_id.length - 1];
      }

      areaApplyList(params).then(function _callee2(res) {
        return regeneratorRuntime.async(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                resolve({
                  total: res.count,
                  data: res.rows
                });

              case 1:
              case "end":
                return _context2.stop();
            }
          }
        });
      });
    },
    reqAdd: function reqAdd(isEdit, params, row, resolve) {
      if (isEdit) {
        params.id = row.id;
      }

      areaApplyHandle(params).then(function _callee3(res) {
        return regeneratorRuntime.async(function _callee3$(_context3) {
          while (1) {
            switch (_context3.prev = _context3.next) {
              case 0:
                resolve();

              case 1:
              case "end":
                return _context3.stop();
            }
          }
        });
      });
    },
    reqDel: function reqDel(params, resolve) {
      var ids = params.map(function (item) {
        return item.id;
      });
      areaApplyDel({
        id: ids
      }).then(function _callee4(res) {
        return regeneratorRuntime.async(function _callee4$(_context4) {
          while (1) {
            switch (_context4.prev = _context4.next) {
              case 0:
                resolve();

              case 1:
              case "end":
                return _context4.stop();
            }
          }
        });
      });
    },
    onClickLink: function onClickLink(prop, row) {//点击列表link类型回调
    },
    onClickRowButton: function onClickRowButton(btnIndex, row) {
      var _this2 = this;

      //点击列表按钮回调
      if (btnIndex == 0) {
        this.$confirm('确定执行此操作？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          areaApplyExamine({
            id: row.id,
            status: 1
          }).then(function _callee5(res) {
            return regeneratorRuntime.async(function _callee5$(_context5) {
              while (1) {
                switch (_context5.prev = _context5.next) {
                  case 0:
                    _this2.$alert('审核通过', '提示', {
                      confirmButtonText: '确定',
                      callback: function callback(action) {
                        _this2.table.reloadData();
                      }
                    });

                  case 1:
                  case "end":
                    return _context5.stop();
                }
              }
            });
          });
        });
      } else if (btnIndex == 1) {
        this.$prompt('请输入拒绝原因', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        }).then(function (_ref) {
          var value = _ref.value;
          areaApplyExamine({
            id: row.id,
            status: -1,
            reject_reason: value
          }).then(function _callee6(res) {
            return regeneratorRuntime.async(function _callee6$(_context6) {
              while (1) {
                switch (_context6.prev = _context6.next) {
                  case 0:
                    _this2.$alert('拒绝成功', '提示', {
                      confirmButtonText: '确定',
                      callback: function callback(action) {
                        _this2.table.reloadData();
                      }
                    });

                  case 1:
                  case "end":
                    return _context6.stop();
                }
              }
            });
          });
        });
      }

      if (btnIndex == 2) {
        this.$confirm('确定执行此操作？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          areaApplyExamine({
            id: row.id,
            status: 3
          }).then(function _callee7(res) {
            return regeneratorRuntime.async(function _callee7$(_context7) {
              while (1) {
                switch (_context7.prev = _context7.next) {
                  case 0:
                    _this2.$alert('审核通过', '提示', {
                      confirmButtonText: '确定',
                      callback: function callback(action) {
                        _this2.table.reloadData();
                      }
                    });

                  case 1:
                  case "end":
                    return _context7.stop();
                }
              }
            });
          });
        });
      } else if (btnIndex == 3) {
        this.$prompt('请输入拒绝原因', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        }).then(function (_ref2) {
          var value = _ref2.value;
          areaApplyExamine({
            id: row.id,
            status: 1,
            reject_reason: value
          }).then(function _callee8(res) {
            return regeneratorRuntime.async(function _callee8$(_context8) {
              while (1) {
                switch (_context8.prev = _context8.next) {
                  case 0:
                    _this2.$alert('拒绝成功', '提示', {
                      confirmButtonText: '确定',
                      callback: function callback(action) {
                        _this2.table.reloadData();
                      }
                    });

                  case 1:
                  case "end":
                    return _context8.stop();
                }
              }
            });
          });
        });
      } else if (btnIndex == 4) {
        this.$refs['areaHandle'].show(row, function () {
          _this2.table.reloadData();
        });
      }
    },
    onClickHeaderButton: function onClickHeaderButton(btnIndex, row) {
      var _this3 = this;

      //点击列表头部按钮回调
      this.$refs['areaAdd'].show(row, function () {
        _this3.table.reloadData();
      });
    },
    onSummary: function onSummary(param) {//列表尾部合计回调
    },
    onSwitchChange: function onSwitchChange(val, prop, row) {//列表中switch变化事件
    },
    onClickSearchOperate: function onClickSearchOperate(btnIndex, btnData) {
      //条件菜单按钮点击事件
      if (btnData.tag === this.$tempModel.buttonTag.search) {
        this.table.reloadData();
      } else if (btnData.tag === this.$tempModel.buttonTag.export) {
        this.table.exportExcel();
      } else if (btnData.tag === this.$tempModel.buttonTag.import) {
        var header = btnData.header;
        var results = btnData.results;
      }
    },
    onConditionChange: function onConditionChange(tag, index) {
      this.table.reloadData();
    },
    onRowButtonShow: function onRowButtonShow(btnIndex, row) {
      //       -1 第一步拒绝
      //       0审核中
      //       1审核通过（第一步）
      //       2 提交第二次审核
      //       3 第二次通过（拒绝其实就回退到1）
      if (btnIndex == 0 || btnIndex == 1) {
        if (row.status == 0) {
          return true;
        }
      } else if (btnIndex == 2 || btnIndex == 3) {
        if (row.status == 2) {
          return true;
        }
      } else if (btnIndex == 4) {
        if (row.status >= 1) {
          return true;
        }
      } else if (btnIndex == 6) {
        return true;
      } else {
        return true;
      }

      return false;
    }
  }
};