var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row" },
    _vm._l(_vm.model[_vm.prop], function(item, index) {
      return index < 5
        ? _c(
            "el-image",
            {
              staticClass: "item",
              style: { left: index * 18 + "px" },
              attrs: {
                src: item,
                fit: "contain",
                "preview-src-list": _vm.model[_vm.prop]
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "image-slot",
                  attrs: { slot: "error" },
                  slot: "error"
                },
                [_c("i", { staticClass: "el-icon-picture-outline" })]
              )
            ]
          )
        : _vm._e()
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }