var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-button", {
        staticStyle: { "font-size": "20px" },
        attrs: { size: "mini", icon: "el-icon-video-camera-solid" },
        on: {
          click: function($event) {
            return _vm.clickVideo()
          }
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "视频播放",
            visible: _vm.dialogVisible,
            width: "50%",
            "append-to-body": true,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "show-close": true
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticStyle: { "background-color": "black", padding: "15px" } },
            [
              _c(
                "video",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    src: _vm.model[_vm.prop],
                    muted: "muted",
                    controls: "controls"
                  },
                  domProps: { muted: true }
                },
                [_vm._v(" 您的浏览器不支持视频播放 ")]
              )
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }