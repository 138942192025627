import "core-js/modules/es.array.map";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.search";
import "core-js/modules/web.dom-collections.iterator";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tempTable from "@/components/rw/tempTable/index";
import tempSearch from "@/components/rw/tempSearch/index";
import tempForm from "@/components/rw/tempForm/index";
import tempChart from "@/components/rw/tempChart/index";
import { questionnaireList, questionnaireStatic } from '../../../../api/apis';
export default {
  components: {
    tempTable: tempTable,
    tempSearch: tempSearch,
    tempForm: tempForm,
    tempChart: tempChart
  },
  data: function data() {
    var _this = this;

    return {
      searchModel_1000: {
        "del": false,
        "id": "id_1000",
        "isCollapse": false,
        "operateData": [{
          "icon": "el-icon-search",
          "tag": "search",
          "title": "搜索",
          "type": "primary"
        }, {
          "icon": "el-icon-position",
          "tag": "export",
          "title": "导出",
          "type": "primary"
        }],
        "searchData": [{
          "title": "按条件",
          "select": 0,
          "type": 2,
          "id": 0,
          "relation": false,
          "relationId": "",
          "remote": false,
          "remoteType": 0,
          "children": [{
            "type": "input-complex",
            "placeholder": "请输入",
            "field": [{
              "key": "number",
              "value": "",
              "desc": "会员编号"
            }],
            "operator": ["gte", "lte", "gt", "lt", "eq", "ne", "like", "notLike", "in", "between"]
          }],
          "remoteFunc": []
        }, {
          "title": "答卷日期",
          "select": 0,
          "type": 1,
          "id": 1,
          "relation": false,
          "relationId": "",
          "remote": true,
          "remoteType": 1,
          "children": [{
            "type": "button",
            "title": "全部",
            "field": []
          }, {
            "type": "button",
            "title": "今天",
            "field": []
          }, {
            "type": "button",
            "title": "昨天",
            "field": []
          }, {
            "type": "button",
            "title": "最近7天",
            "field": []
          }, {
            "type": "button",
            "title": "本月",
            "field": []
          }, {
            "type": "button",
            "title": "本年",
            "field": []
          }, {
            "type": "custom-datetime-range",
            "title": "自定义",
            "field": []
          }],
          "remoteFunc": ["modelConditionFunc_1"]
        }],
        "type": "searchTemplate"
      },
      tableModel_1001: {
        "border": true,
        "del": false,
        "headerButton": [],
        "id": "id_1001",
        "isGroup": false,
        "isTree": false,
        "rowButton": [],
        "showPagination": true,
        "showSelection": false,
        "showSummary": false,
        "stripe": false,
        "tableAutocompleteProps": {},
        "tableCascaderProps": {},
        "tableFileProps": {},
        "tableModel": [{
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "会员编号",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "number"
        }, {
          "label": "会员姓名",
          "show": 1,
          "add": 1,
          "detail": 1,
          "width": null,
          "type": "text",
          "desc": "",
          "groupId": "",
          "rules": [],
          "excel": 1,
          "prop": "t_user.real_name"
        }, {
          "label": "手机号",
          "show": 1,
          "add": 1,
          "detail": 1,
          "width": null,
          "type": "text",
          "desc": "",
          "groupId": "",
          "rules": [],
          "excel": 1,
          "prop": "t_user.phone"
        }, {
          "label": "会员级别",
          "show": 1,
          "add": 1,
          "detail": 1,
          "width": null,
          "type": "text",
          "desc": "",
          "groupId": "",
          "rules": [],
          "excel": 1,
          "prop": "grade"
        }, {
          "label": "会员资格",
          "show": 1,
          "add": 1,
          "detail": 1,
          "width": null,
          "type": "text",
          "desc": "",
          "groupId": "",
          "rules": [],
          "excel": 1,
          "prop": "level"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "网站访问速度",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "q0"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "页面美观度",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "q1"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "整体功能是否合理",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "q2"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "商品展示信息是否满足您的需要",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "q3"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "是否能正常接收短信",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "q4"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "对物流订单追踪是否满意",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "q5"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "对网站的其他意见建议",
          "rules": [],
          "show": 1,
          "type": "textarea",
          "width": null,
          "prop": "q6"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "平时自己操作还是其他人代为操作",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "q7"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "对系统整体体验是否满意",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "q8"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "您一般多久登录一次系统",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "q9"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "您最常用的功能是什么",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "q10"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "是否了解系统中所有操作功能和流程",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "q11"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "批次号",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "batch"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "答题时间",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "add_time"
        }],
        "tableName": "",
        "tableRadioProps": {},
        "tableSelectProps": {},
        "tableSwitchProps": {},
        "title": "问卷",
        "type": "tableTemplate"
      },
      tableModel_1000: {
        "border": true,
        "del": false,
        "headerButton": [],
        "id": "id_1000",
        "isGroup": false,
        "isTree": false,
        "rowButton": [],
        "showPagination": false,
        "showSelection": false,
        "showSummary": false,
        "stripe": false,
        "tableAutocompleteProps": {},
        "tableCascaderProps": {},
        "tableFileProps": {},
        "tableModel": [{
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "网站访问速度",
          "rules": [],
          "show": 1,
          "type": "custom",
          "width": null,
          "prop": "q0"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "页面美观度",
          "rules": [],
          "show": 1,
          "type": "custom",
          "width": null,
          "prop": "q1"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "整体功能是否合理",
          "rules": [],
          "show": 1,
          "type": "custom",
          "width": null,
          "prop": "q2"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "商品展示信息是否满足您的需要",
          "rules": [],
          "show": 1,
          "type": "custom",
          "width": null,
          "prop": "q3"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "是否能正常接收短信",
          "rules": [],
          "show": 1,
          "type": "custom",
          "width": null,
          "prop": "q4"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "对物流订单追踪是否满意",
          "rules": [],
          "show": 1,
          "type": "custom",
          "width": null,
          "prop": "q5"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "对网站的其他意见建议",
          "rules": [],
          "show": 1,
          "type": "custom",
          "width": null,
          "prop": "q6"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "平时自己操作还是其他人代为操作",
          "rules": [],
          "show": 1,
          "type": "custom",
          "width": null,
          "prop": "q7"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "您一般多久登录一次系统",
          "rules": [],
          "show": 1,
          "type": "custom",
          "width": null,
          "prop": "q8"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "您最常用的功能是什么",
          "rules": [],
          "show": 1,
          "type": "custom",
          "width": null,
          "prop": "q9"
        }],
        "tableName": "",
        "tableRadioProps": {},
        "tableSelectProps": {},
        "tableSwitchProps": {},
        "title": "统计",
        "type": "tableTemplate"
      },
      remoteFunc: {
        modelConditionFunc_1: function modelConditionFunc_1(tag, relationData, resolve) {
          //筛选条件数据
          //[[{key:"type",value:"1"}]]
          var data = [];
          data = [[{
            key: "add_time",
            value: []
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 0, true)
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 1, true)
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 7, true)
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 30, true)
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 365, true)
          }], [{
            key: "add_time",
            value: []
          }]];
          resolve(tag, data);
        }
      }
    };
  },
  mounted: function mounted() {
    this.table = this.$refs.ref_1001;
    this.search = this.$refs.ref_1000;
  },
  methods: {
    reqTableData0: function reqTableData0(params, resolve) {
      var where = {};

      if (this.search) {
        where = this.search.parseWhere();
        params.where = where;
      }

      params.allSys = 1;
      questionnaireList(params).then(function _callee(res) {
        return regeneratorRuntime.async(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                resolve({
                  total: res.count,
                  data: res.rows
                });

              case 1:
              case "end":
                return _context.stop();
            }
          }
        });
      });
    },
    reqTableData1: function reqTableData1(params, resolve) {
      var _this2 = this;

      params.allSys = 1;
      questionnaireStatic(params).then(function _callee2(res) {
        var tmp, i, a, msg, j, item, _j;

        return regeneratorRuntime.async(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                tmp = {};
                _context2.t0 = regeneratorRuntime.keys(res);

              case 2:
                if ((_context2.t1 = _context2.t0()).done) {
                  _context2.next = 18;
                  break;
                }

                i = _context2.t1.value;
                a = res[i].a;
                msg = '';

                for (j in a) {
                  item = a[j];
                  msg += '<div><span>' + item.title + '：' + item.count + '人 ' + item.range + '%</span></div>';
                }

                tmp['q' + i] = msg;
                _context2.t2 = regeneratorRuntime.keys(_this2.tableModel_1000.tableModel);

              case 9:
                if ((_context2.t3 = _context2.t2()).done) {
                  _context2.next = 16;
                  break;
                }

                _j = _context2.t3.value;

                if (!(_this2.tableModel_1000.tableModel[_j].prop === 'q' + i)) {
                  _context2.next = 14;
                  break;
                }

                _this2.tableModel_1000.tableModel[_j].label = res[i].title;
                return _context2.abrupt("break", 16);

              case 14:
                _context2.next = 9;
                break;

              case 16:
                _context2.next = 2;
                break;

              case 18:
                console.log('aaaa', tmp);
                resolve({
                  total: 0,
                  data: [tmp]
                });

              case 20:
              case "end":
                return _context2.stop();
            }
          }
        });
      });
    },
    reqAdd: function reqAdd(isEdit, params, row, resolve) {//if (isEdit){
      //  params.id = row.id
      //}
      //xxxAdd(params).then(async res=>{
      //  resolve()
      //})
    },
    reqDel: function reqDel(params, resolve) {
      var ids = params.map(function (item) {
        return item.id;
      }); //xxxDel({id:ids}).then(async res=>{
      //  resolve()
      //})
    },
    onClickLink: function onClickLink(prop, row) {//点击列表link类型回调
    },
    onClickRowButton: function onClickRowButton(btnIndex, row) {//点击列表按钮回调
    },
    onClickHeaderButton: function onClickHeaderButton(btnIndex, row) {//点击列表头部按钮回调
    },
    onSummary: function onSummary(param) {//列表尾部合计回调
    },
    onSwitchChange: function onSwitchChange(val, prop, row) {//列表中switch变化事件
    },
    onClickSearchOperate: function onClickSearchOperate(btnIndex, btnData) {
      //条件菜单按钮点击事件
      if (btnData.tag === this.$tempModel.buttonTag.search) {
        this.table.reloadData();
      } else if (btnData.tag === this.$tempModel.buttonTag.export) {
        this.table.exportExcel();
      } else if (btnData.tag === this.$tempModel.buttonTag.import) {
        var header = btnData.header;
        var results = btnData.results;
      }
    },
    onConditionChange: function onConditionChange(tag, index) {
      this.table.reloadData();
    },
    onRowButtonShow: function onRowButtonShow(btnIndex, row) {
      return true;
    }
  }
};