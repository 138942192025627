var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.curTarget ? "角色编辑" : "角色添加",
        visible: _vm.dialogVisible,
        width: "50%",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "show-close": false
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            "max-height": "50vh",
            "overflow-y": "auto",
            padding: "0 30px"
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: {
                size: "mini",
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "100px"
              }
            },
            [
              _c("el-row", { staticStyle: { "line-height": "50px" } }, [
                _c(
                  "span",
                  { staticStyle: { "font-size": "13px", color: "#fa5959" } },
                  [_vm._v("选择子菜单时务必要把父菜单也勾选上")]
                )
              ]),
              _c(
                "el-form-item",
                { attrs: { label: "角色名称", prop: "role_name" } },
                [
                  _c("el-input", {
                    attrs: { size: "mini" },
                    model: {
                      value: _vm.ruleForm.role_name,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "role_name", $$v)
                      },
                      expression: "ruleForm.role_name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "选择权限" } },
                [
                  _c("el-tree", {
                    ref: "tree",
                    attrs: {
                      "default-expand-all": "",
                      "check-strictly": true,
                      "show-checkbox": true,
                      "check-on-click-node": true,
                      "expand-on-click-node": false,
                      data: _vm.data,
                      "show-checkbox": "",
                      "node-key": "id",
                      "default-checked-keys": _vm.ruleForm.rules,
                      props: _vm.defaultProps
                    },
                    on: { "check-change": _vm.checkChange },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var node = ref.node
                          var data = ref.data
                          return _c(
                            "span",
                            { staticClass: "custom-tree-node" },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    (data.is_show === 1 ? "[菜单]" : "[权限]") +
                                      node.label
                                  )
                                )
                              ])
                            ]
                          )
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.dialogVisible = false
                }
              }
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.clickSubmit }
            },
            [_vm._v(_vm._s(_vm.curTarget ? "保存" : "添加"))]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }