import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import "core-js/modules/web.dom-collections.iterator";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import gradeConfig from './systemConfig/gradeConfig/index';
import baseConfig from './systemConfig/baseConfig/index';
import bonusConfig from './systemConfig/bonusConfig/index';
import levelConfig from './systemConfig/levelConfig/index';
import manageTaxConfig from './systemConfig/manageTaxConfig/index';
import resaleTaxConfig from './systemConfig/resaleTaxConfig/index';
import storeConfig from './systemConfig/storeConfig/index';
import areaConfig from './systemConfig/areaConfig/index';
import { systemTempAdd, systemTempDetail, systemTempDetailAdd, systemTempList } from '../../../../api/apis';
export default {
  name: 'systemCreator',
  components: {
    gradeConfig: gradeConfig,
    baseConfig: baseConfig,
    bonusConfig: bonusConfig,
    levelConfig: levelConfig,
    manageTaxConfig: manageTaxConfig,
    resaleTaxConfig: resaleTaxConfig,
    storeConfig: storeConfig,
    areaConfig: areaConfig
  },
  data: function data() {
    return {
      row: null,
      dialogVisible: false,
      activeName: '-1',
      config: null
    };
  },
  mounted: function mounted() {
    var _this = this;

    systemTempList({
      where: {
        id: 4
      }
    }).then(function _callee(res) {
      var i;
      return regeneratorRuntime.async(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.t0 = regeneratorRuntime.keys(res);

            case 1:
              if ((_context.t1 = _context.t0()).done) {
                _context.next = 9;
                break;
              }

              i = _context.t1.value;

              if (!(res[i].id == 4)) {
                _context.next = 7;
                break;
              }

              _this.row = res[i];

              if (_this.row.config === '' || _this.row.config == null) {
                _this.initConfig();
              } else {
                _this.config = JSON.parse(_this.row.config);

                if (!_this.config.areaData) {
                  _this.config.areaData = [];
                  _this.config.areaId = 0;
                }
              }

              return _context.abrupt("break", 9);

            case 7:
              _context.next = 1;
              break;

            case 9:
            case "end":
              return _context.stop();
          }
        }
      });
    });
  },
  methods: {
    initConfig: function initConfig() {
      this.config = {
        baseData: {
          systemName: '',
          systemType: 1,
          bdMode: 0,
          //报单类型，自己报单、他人报单
          memberBdType: [],
          centerBdType: [],
          canCrossBd: 0,
          memberCanRecharge: 0,
          takeTax: 0,
          levelCanSkip: 0,
          //是否允许跨级升
          levelModel: 0,
          //升级模式，补差、全额
          gradeValidType: 0,
          //升级生效类型 0当日 1次日
          manageTax: [],
          resaleTax: [],
          resaleMultiple: 1,
          storeCancelOrder: 0,
          //服务中心是否可以撤单
          agentAreaCount: 1,
          //每个人可设置区域个数
          agentPeopleCount: 1,
          //每个区域可设置的人数
          agentCanSame: 0,
          //每个人是否可以设置多个区域
          agentProduct: 0 //区域代理是否可以指定产品

        },
        levelId: 0,
        levelData: [],
        storeId: 0,
        storeData: [],
        //店补等级
        gradeId: 0,
        gradeData: [],
        bonusId: 0,
        bonusData: [],
        areaData: [],
        areaId: 0
      };
    },
    clickSubmit: function clickSubmit() {
      var _this2 = this;

      this.$confirm('确定更新当前模板?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        console.log('this.config', _this2.config);
        systemTempAdd({
          id: _this2.row.id,
          config: JSON.stringify(_this2.config),
          name: _this2.row.name,
          remark: _this2.row.remark
        }).then(function _callee2(res) {
          return regeneratorRuntime.async(function _callee2$(_context2) {
            while (1) {
              switch (_context2.prev = _context2.next) {
                case 0:
                  _this2.$alert('保存成功', '保存模板', {
                    confirmButtonText: '确定',
                    callback: function callback(action) {
                      _this2.isSaved = true;

                      _this2.finish();
                    }
                  });

                case 1:
                case "end":
                  return _context2.stop();
              }
            }
          });
        });
      });
    },
    clickCreate: function clickCreate() {
      var _this3 = this;

      if (!this.isSaved) {
        this.$alert('请先保存模板然后在提交', '提示', {
          confirmButtonText: '确定',
          callback: function callback(action) {}
        });
        return;
      }

      this.$confirm('确定生成或更新当前系统么?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        systemTempDetailAdd({
          id: _this3.row.id
        }).then(function _callee3(res) {
          return regeneratorRuntime.async(function _callee3$(_context3) {
            while (1) {
              switch (_context3.prev = _context3.next) {
                case 0:
                  _this3.$alert('生成成功', '生成系统', {
                    confirmButtonText: '确定',
                    callback: function callback(action) {
                      _this3.isSaved = false;
                    }
                  });

                case 1:
                case "end":
                  return _context3.stop();
              }
            }
          });
        });
      });
    }
  }
};