import "core-js/modules/es.object.to-string";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import store from "@/store/index";
export default {
  data: function data() {
    return {
      dialogVisible: false,
      model: [],
      isSave: false,
      isAll: false
    };
  },
  methods: {
    show: function show(key, model, finish) {
      this.key = key;
      var tmp = [];

      for (var i in model) {
        tmp.push({
          show: model[i].show,
          label: model[i].label,
          prop: model[i].prop
        });
      }

      this.finish = finish;
      this.model = tmp;
      this.dialogVisible = true;
      this.load();
    },
    clickSubmit: function clickSubmit(tag) {
      if (this.isSave) {
        this.save();
      }

      this.dialogVisible = false;
      this.finish(true, tag, this.model);
    },
    clickCancel: function clickCancel() {
      this.dialogVisible = false;
      this.finish(false);
    },
    clickAll: function clickAll() {
      for (var i in this.model) {
        this.model[i].show = this.isAll ? 1 : 0;
      }
    },
    save: function save() {
      store.dispatch('d2admin/db/set', {
        dbName: 'sys',
        path: this.key,
        value: this.model,
        user: true
      }, {
        root: true
      });
    },
    load: function load() {
      var _this = this;

      store.dispatch('d2admin/db/get', {
        dbName: 'sys',
        path: this.key,
        defaultValue: [],
        user: true
      }, {
        root: true
      }).then(function _callee(res) {
        var i, j;
        return regeneratorRuntime.async(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                for (i in _this.model) {
                  for (j in res) {
                    if (_this.model[i].prop === res[j].prop) {
                      _this.model[i].show = res[j].show;
                    }
                  }
                }

              case 1:
              case "end":
                return _context.stop();
            }
          }
        });
      });
    }
  }
};