import "core-js/modules/es.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import conditionCreator from '../conditionCreator';
export default {
  components: {
    conditionCreator: conditionCreator
  },
  props: {
    gradeList: {
      type: Array
    },
    levelList: {
      type: Array
    }
  },
  data: function data() {
    return {
      name: '',
      row: null,
      dialogVisible: false,
      exaCond: []
    };
  },
  mounted: function mounted() {},
  methods: {
    onClose: function onClose() {
      this.finish();
    },
    show: function show(row, finish) {
      var _this = this;

      this.row = row;
      this.name = row.name;

      if (this.row.exaCond && this.row.exaCond !== '') {
        this.exaCond = this.$tempModel.deepCopy(this.row.exaCond);
      }

      this.dialogVisible = true;
      this.finish = finish;
      this.$nextTick(function () {
        _this.$refs.creator.setCondData(_this.$tempModel.deepCopy(_this.exaCond));
      });
    },
    clickSubmit: function clickSubmit() {
      this.row.exaCond = this.$refs.creator.getCondData();
      this.dialogVisible = false;
      this.finish();
    }
  }
};