//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    type: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: '标题'
    },
    selected: {
      type: Boolean,
      default: false
    },
    onClick: {
      type: Function
    },
    field: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  computed: {
    buttonType: function buttonType() {
      if (this.selected) {
        return 'primary';
      }

      return null;
    },
    datePickerType: function datePickerType() {
      if (this.type === this.$tempModel.searchType.customDateRange) {
        return 'daterange';
      } else if (this.type === this.$tempModel.searchType.customDatetimeRange) {
        return 'datetimerange';
      } else {
        return 'daterange';
      }
    }
  },
  data: function data() {
    return {
      value: []
    };
  },
  methods: {
    where: function where() {
      var where = {};

      for (var i in this.field) {
        var item = this.field[i];

        if (this.value.length > 0) {
          where[item.key] = this.value;
        }
      }

      return where;
    }
  }
};