import "core-js/modules/es.regexp.constructor";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/es.string.split";
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    type: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    field: {
      type: Array,
      default: function _default() {
        return [{
          key: 'no',
          value: '编号'
        }];
      }
    },
    operator: {
      type: Array,
      default: function _default() {
        return [{
          key: 'gte',
          value: '>='
        }, {
          key: 'lte',
          value: '<='
        }, {
          key: 'gt',
          value: '>'
        }, {
          key: 'lt',
          value: '<'
        }, {
          key: 'eq',
          value: '='
        }, {
          key: 'ne',
          value: '!='
        }, {
          key: 'like',
          value: 'like'
        }, {
          key: 'notLike',
          value: 'notLike'
        }, {
          key: 'in',
          value: 'in'
        }, {
          key: 'between',
          value: 'between'
        }];
      }
    }
  },
  watch: {
    field: function field(nVal, oVal) {
      this.key = nVal.length > 0 ? nVal[0].key : '';
    }
  },
  computed: {
    selectWidth: function selectWidth() {
      console.log('aaa', this.operator);
      var maxWidth = 0;
      var pattern = new RegExp("[\u4E00-\u9FA5]+");

      for (var i in this.field) {
        var v = this.field[i].desc;
        var width = 45;

        for (var j in v) {
          if (pattern.test(v[j])) {
            //是中文
            width += 14;
          } else {
            width += 8;
          }
        }

        if (width > maxWidth) {
          maxWidth = width;
        }
      }

      if (maxWidth < 80) {
        maxWidth = 80;
      }

      return maxWidth;
    }
  },
  data: function data() {
    return {
      value: '',
      key: this.field.length > 0 ? this.field[0].key : '',
      opKey: this.operator.length > 0 ? this.operator[0].key : ''
    };
  },
  methods: {
    where: function where() {
      var where = {};

      if (this.value === '') {
        return where;
      }

      for (var i in this.field) {
        var item = this.field[i];

        if (this.key === item.key) {
          for (var j in this.operator) {
            var op = this.operator[j];

            if (this.opKey === op.key) {
              var value = this.value;

              if (op.key === 'like' || op.key === 'notLike') {
                value = '%' + this.value + '%';
              } else if (op.key === 'in' || op.key === 'between') {
                value = this.value.split(',');
              }

              where[item.key] = {
                op: op.key,
                value: value
              };
            }
          }
        }
      }

      return where;
    }
  }
};