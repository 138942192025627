var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-card", [
    _c(
      "div",
      { staticStyle: { height: "80vh", "overflow-y": "auto" } },
      [
        _c(
          "el-form",
          { ref: "form", attrs: { "label-width": "160px" } },
          [
            _vm._l(_vm.tableData, function(item) {
              return _c(
                "el-form-item",
                { attrs: { label: item.name } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          item.type === 0
                            ? _c("el-switch", {
                                attrs: {
                                  "active-value": 1,
                                  "inactive-value": 0
                                },
                                model: {
                                  value: item.value,
                                  callback: function($$v) {
                                    _vm.$set(item, "value", $$v)
                                  },
                                  expression: "item.value"
                                }
                              })
                            : _vm._e(),
                          item.type === 1
                            ? _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择活动区域" },
                                  model: {
                                    value: item.value,
                                    callback: function($$v) {
                                      _vm.$set(item, "value", $$v)
                                    },
                                    expression: "item.value"
                                  }
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "常关", value: 0 }
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "常开", value: 1 }
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "指定时间关闭", value: 2 }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          item.type === 2
                            ? _c("el-date-picker", {
                                attrs: {
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  type: "datetime",
                                  placeholder: ""
                                },
                                model: {
                                  value: item.value,
                                  callback: function($$v) {
                                    _vm.$set(item, "value", $$v)
                                  },
                                  expression: "item.value"
                                }
                              })
                            : _vm._e(),
                          item.type === 3
                            ? _c("el-input", {
                                model: {
                                  value: item.value,
                                  callback: function($$v) {
                                    _vm.$set(item, "value", $$v)
                                  },
                                  expression: "item.value"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 16 } },
                        [
                          _c("el-tag", { staticClass: "desc" }, [
                            _vm._v(_vm._s("| " + item.desc))
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  {
                    staticStyle: { width: "140px" },
                    attrs: { type: "primary", size: "small" },
                    on: { click: _vm.clickSubmit }
                  },
                  [_vm._v("保 存")]
                )
              ],
              1
            )
          ],
          2
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }