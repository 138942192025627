//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    tableModel: {
      type: Array,
      required: true
    },
    width: {
      type: String,
      default: '50%'
    },
    detailProps: {
      type: Object,
      default: function _default() {
        return {
          'label': 'label',
          'value': 'value'
        };
      }
    },
    switchProps: {
      type: Object,
      default: function _default() {
        return {
          activeValue: 1,
          inactiveValue: 0
        };
      }
    }
  },
  data: function data() {
    return {
      detailData: [],
      dialogVisible: false
    };
  },
  mounted: function mounted() {},
  methods: {
    show: function show(data) {
      var tmp = [];

      for (var j in this.tableModel) {
        var item = this.tableModel[j];

        if (!item.detail) {
          continue;
        }

        var tmpItem = {};
        tmpItem.label = item.label;
        tmpItem.value = data[item.prop];
        tmpItem.type = item.type;
        tmp.push(tmpItem);
      }

      this.dialogVisible = true;
      this.detailData = tmp;
    }
  }
};