//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'notifyPage',
  data: function data() {
    return {
      dialogVisible: true
    };
  },
  methods: {
    clickSubmit: function clickSubmit() {}
  }
};