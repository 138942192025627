//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tempTable from "@/components/rw/tempTable/index";
import tempSearch from "@/components/rw/tempSearch/index";
export default {
  components: {
    tempTable: tempTable,
    tempSearch: tempSearch
  },
  data: function data() {
    var _this = this;

    return {
      searchModel_1000: {
        "id": "id_1000",
        "operateData": [{
          "icon": "el-icon-search",
          "title": "搜索",
          "type": "primary"
        }, {
          "icon": "el-icon-position",
          "title": "导出",
          "type": "primary"
        }],
        "searchData": [{
          "children": [{
            "field": [],
            "title": "全部",
            "type": "button"
          }, {
            "field": [],
            "title": "分类一",
            "type": "button"
          }, {
            "field": [],
            "title": "分类二",
            "type": "button"
          }],
          "remote": true,
          "remoteFunc": ["modelFunc_0"],
          "remoteType": 0,
          "select": 0,
          "title": "按部门",
          "type": 0
        }, {
          "children": [{
            "field": [],
            "title": "全部",
            "type": "button"
          }, {
            "field": [],
            "title": "今天",
            "type": "button"
          }, {
            "field": [],
            "title": "昨天",
            "type": "button"
          }, {
            "field": [],
            "title": "最近7天",
            "type": "button"
          }, {
            "field": [],
            "title": "本月",
            "type": "button"
          }, {
            "field": [],
            "title": "本年",
            "type": "button"
          }, {
            "field": [],
            "title": "自定义",
            "type": "custom-datetime-range"
          }],
          "remote": true,
          "remoteFunc": ["modelConditionFunc_1"],
          "remoteType": 1,
          "select": 0,
          "title": "按添加日期",
          "type": 1
        }, {
          "children": [{
            "field": [{
              "key": "account",
              "value": "工号"
            }, {
              "key": "real_name",
              "value": "姓名"
            }],
            "placeHolder": "请输入",
            "placeholder": "请输入工号、姓名",
            "title": "",
            "type": "input-complex"
          }],
          "remote": false,
          "remoteFunc": [],
          "remoteType": 0,
          "select": 0,
          "title": "按工号姓名",
          "type": 2
        }],
        "type": "searchTemplate"
      },
      tableModel_1001: {
        "border": true,
        "headerButton": [{
          "title": "添加",
          "type": "primary",
          "tag": "add",
          "id": ""
        }],
        "id": "id_1001",
        "rowButton": [{
          "title": "详情",
          "tag": "detail",
          "id": ""
        }, {
          "title": "编辑",
          "type": "primary",
          "tag": "edit",
          "id": ""
        }, {
          "title": "删除",
          "type": "danger",
          "tag": "del",
          "id": ""
        }],
        "showPagination": false,
        "showSelection": false,
        "showSummary": false,
        "stripe": false,
        "tableModel": [],
        "tableName": "",
        "tableSelectProps": {},
        "tableSwitchProps": {},
        "title": "",
        "type": "tableTemplate"
      },
      remoteFunc: {
        modelFunc_0: function modelFunc_0(tag, resolve) {
          //筛选项模型数组
          //[{ type: this.$tempModel.searchType.button, title: "全部", field: [] }]
          var data = [];
          resolve(tag, data);
        },
        modelConditionFunc_1: function modelConditionFunc_1(tag, resolve) {
          //筛选条件数据
          //[[{key:"type",value:"1"}]]
          var data = [[{
            key: "add_time",
            value: []
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 0, true)
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 1, true)
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 7, true)
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 30, true)
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 365, true)
          }], [{
            key: "add_time",
            value: []
          }]];
          resolve(tag, data);
        }
      }
    };
  },
  mounted: function mounted() {},
  methods: {
    reqTableData: function reqTableData(params, resolve) {
      var where = this.$refs["ref_1000"].paresWhere();
      params.where = where;
      resolve(0, []);
    },
    reqAdd: function reqAdd(params, resolve) {
      resolve(0, []);
    },
    reqDel: function reqDel(params, resolve) {
      resolve(0, []);
    },
    onClickLink: function onClickLink(prop, row) {},
    onClickRowButton: function onClickRowButton(btnIndex, row) {},
    onSummary: function onSummary(param) {},
    onSwitchChange: function onSwitchChange(val, prop, row) {},
    onClickSearchOperate: function onClickSearchOperate(btnIndex) {},
    onConditionChange: function onConditionChange(tag, index) {
      this.$refs["ref_1001"].reloadData();
    }
  }
};