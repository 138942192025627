import "core-js/modules/es.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: ['phoneUrl', 'onLoad', 'name'],
  data: function data() {
    return {
      forceUpdatePhone: true //强制刷新phone UI

    };
  },
  mounted: function mounted() {
    var _this = this;

    var iframe = document.querySelector('#phone-iframe' + this.name);

    iframe.onload = function () {
      if (_this.onLoad) {
        _this.onLoad();
      }
    };
  },
  methods: {
    /**
     * 发送最新数据到phone UI,更新界面
     */
    reloadPhone: function reloadPhone(data) {
      var params = {
        data: data
      };
      console.log('params', this.phoneUrl, JSON.stringify(params));
      document.getElementById('phone-iframe' + this.name).contentWindow.postMessage(params, this.phoneUrl);
    },

    /**
     * 强制刷新phone UI
     */
    refreshPhone: function refreshPhone() {
      var _this2 = this;

      this.forceUpdatePhone = false;
      this.$nextTick(function () {
        _this2.forceUpdatePhone = true;
        setTimeout(function () {
          _this2.reloadPhone();
        }, 1000);
      });
    }
  }
};