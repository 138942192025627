import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.index-of";
import "core-js/modules/es.array.map";
import "core-js/modules/es.array.splice";
import "core-js/modules/es.map";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.iterator";
import "core-js/modules/es.string.search";
import "core-js/modules/web.dom-collections.for-each";
import "core-js/modules/web.dom-collections.iterator";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tempTable from '@/components/rw/tempTable/index';
import tempSearch from '@/components/rw/tempSearch/index';
import tempForm from '@/components/rw/tempForm/index';
import tempChart from '@/components/rw/tempChart/index';
import exportCustomExcel from '@/components/rw/plugin/exportCustomExcel/index';
import { expressCheckout, expressCheckoutAll, expressDeliver, expressDeliverAll, expressImport, expressLock, expressLockAll, expressReset, expressResetAll, orderAdd, orderGlassList, orderProduct, productEx, productList } from '../../../../api/apis';
import NP from 'number-precision';
export default {
  name: 'orderGlassStock',
  components: {
    tempTable: tempTable,
    tempSearch: tempSearch,
    tempForm: tempForm,
    tempChart: tempChart
  },
  data: function data() {
    var _this = this;

    return {
      componentKey: 0,
      isShow: true,
      productAttr: [],
      activeNames: 'search',
      searchModel_1000: {
        'del': false,
        'id': 'id_1000',
        'operateData': [{
          'icon': 'el-icon-search',
          'tag': 'search',
          'title': '搜索',
          'type': 'primary'
        }, //   {
        //   'icon': 'el-icon-position',
        //   'tag': 'export',
        //   'title': '导出发货单',
        //   'type': 'primary'
        // },
        {
          'icon': 'el-icon-position',
          'id': '',
          'tag': 'export',
          'title': '导出列表',
          'type': 'primary'
        } // { 'icon': 'el-icon-postcard', 'id': '139', 'tag': 'export', 'title': '导出财务', 'type': 'primary' }
        ],
        'searchData': [{
          'children': [{
            'field': [{
              'desc': '会员编号',
              'key': 't_order.number',
              'value': ''
            }, {
              'desc': '收货人',
              'key': 't_order.real_name',
              'value': ''
            }, {
              'desc': '推荐人',
              'key': 't_order.direct',
              'value': ''
            }, {
              'desc': '收货电话',
              'key': 't_order.user_phone',
              'value': ''
            }, {
              'desc': '订单编号',
              'key': 't_order.order_id',
              'value': ''
            }],
            'operator': [],
            'placeholder': '请输入',
            'type': 'input-complex'
          }, {
            'field': [{
              'desc': '订单金额',
              'key': 't_order.total_price'
            }],
            'operator': [{
              'key': 'gte',
              'value': '>='
            }, {
              'key': 'lte',
              'value': '<='
            }, {
              'key': 'gt',
              'value': '>'
            }, {
              'key': 'lt',
              'value': '<'
            }, {
              'key': 'eq',
              'value': '='
            }, {
              'key': 'ne',
              'value': '!='
            }],
            'placeholder': '请输入',
            'type': 'input-mul-complex'
          }, {
            'field': [{
              'desc': '商品名称',
              'key': 't_product.store_name'
            }],
            'operator': [{
              'key': 'like',
              'value': '模糊'
            }],
            'placeholder': '请输入',
            'type': 'input-mul-complex'
          }, {
            'type': 'input-complex',
            'placeholder': '请输入',
            'field': [{
              'key': 'ex_number.phone',
              'desc': '会员手机'
            }],
            'operator': []
          }],
          'id': 0,
          'relation': false,
          'relationId': '',
          'remote': false,
          'remoteType': 0,
          'select': 0,
          'title': '按条件',
          'type': 2,
          'remoteFunc': []
        }, {
          'children': [{
            'field': [],
            'title': '全部',
            'type': 'button'
          }, {
            'field': [{
              'key': 'status',
              'value': '0'
            }, {
              'key': 't_order.paid',
              'value': '1'
            }],
            'title': '未出库',
            'type': 'button'
          }, {
            'field': [{
              'key': 'status',
              'value': '1'
            }, {
              'key': 't_order.paid',
              'value': '1'
            }],
            'title': '已出库',
            'type': 'button'
          }, {
            'field': [{
              'key': 'status',
              'value': '2'
            }, {
              'key': 't_order.paid',
              'value': '1'
            }],
            'title': '已发货',
            'total': 0,
            'type': 'button'
          }],
          'id': 2,
          'relation': false,
          'relationId': '',
          'remote': false,
          'remoteType': 0,
          'select': 0,
          'title': '订单状态',
          'type': 0,
          'remoteFunc': []
        }, {
          'children': [{
            'field': [],
            'title': '全部',
            'type': 'button'
          }, {
            'field': [],
            'title': '今天',
            'type': 'button'
          }, {
            'field': [],
            'title': '昨天',
            'type': 'button'
          }, {
            'field': [],
            'title': '最近7天',
            'type': 'button'
          }, {
            'field': [],
            'title': '本月',
            'type': 'button'
          }, {
            'field': [],
            'title': '本年',
            'type': 'button'
          }, {
            'field': [],
            'title': '自定义',
            'type': 'custom-datetime-range'
          }],
          'id': 3,
          'relation': false,
          'relationId': '',
          'remote': true,
          'remoteType': 1,
          'select': 0,
          'title': '订单日期',
          'type': 1,
          'remoteFunc': ['modelConditionFunc_3']
        }, {
          'children': [{
            'field': [],
            'title': '全部',
            'type': 'button'
          }, {
            'field': [],
            'title': '今天',
            'type': 'button'
          }, {
            'field': [],
            'title': '昨天',
            'type': 'button'
          }, {
            'field': [],
            'title': '最近7天',
            'type': 'button'
          }, {
            'field': [],
            'title': '本月',
            'type': 'button'
          }, {
            'field': [],
            'title': '本年',
            'type': 'button'
          }, {
            'field': [],
            'title': '自定义',
            'type': 'custom-datetime-range'
          }],
          'id': 8,
          'relation': false,
          'relationId': '',
          'remote': true,
          'remoteType': 1,
          'select': 0,
          'title': '支付日期',
          'type': 1,
          'remoteFunc': ['modelConditionFunc_8']
        }, {
          'children': [{
            'field': [{
              'desc': '类型一',
              'key': 'type',
              'value': '0'
            }, {
              'desc': '类型二',
              'key': 'type',
              'value': '1'
            }],
            'multiple': false,
            'type': 'select'
          }],
          'id': 7,
          'relation': false,
          'relationId': '',
          'remote': true,
          'remoteType': 1,
          'select': 0,
          'title': '包含套餐',
          'type': 3,
          'remoteFunc': ['modelConditionFunc_7']
        }, {
          'children': [{
            'field': [{
              'desc': '类型一',
              'key': 'type',
              'value': '0'
            }, {
              'desc': '类型二',
              'key': 'type',
              'value': '1'
            }],
            'multiple': false,
            'type': 'select'
          }],
          'id': 6,
          'relation': false,
          'relationId': '',
          'remote': true,
          'remoteType': 1,
          'select': 0,
          'title': '包含产品',
          'type': 3,
          'remoteFunc': ['modelConditionFunc_6']
        }],
        'type': 'searchTemplate'
      },
      tableModel_1001: {
        'border': true,
        'del': false,
        'headerButton': [//   { 'id': '119', 'tag': 'default', 'title': '批量出库', 'type': null }, {
          //   'id': '120',
          //   'tag': 'default',
          //   'title': '批量发货',
          //   'type': null
          // }, { 'id': '121', 'tag': 'default', 'title': '全部出库', 'type': 'primary' }, {
          //   'id': '122',
          //   'tag': 'default',
          //   'title': '全部发货',
          //   'type': 'primary'
          // }, { 'icon': '', 'id': '158', 'tag': 'default', 'title': '批量恢复状态', 'type': 'success' }
        ],
        'id': 'id_1001',
        'isTree': false,
        'rowButton': [],
        'showPagination': true,
        'showSelection': true,
        'stripe': false,
        'tableAutocompleteProps': {},
        'tableCascaderProps': {},
        'tableFileProps': {},
        'tableModel': [{
          'add': 0,
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': 'ID',
          'required': 0,
          'rowAddField': '',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '70',
          'prop': 'id'
        }, {
          'add': 0,
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '物流状态',
          'required': 0,
          'rowAddField': '',
          'rules': [],
          'show': 1,
          'type': 'select',
          'width': '80',
          'prop': 'status'
        }, {
          'add': 0,
          'desc': '',
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '支付日期',
          'rules': [],
          'show': 0,
          'type': 'datetime',
          'width': '150',
          'prop': 't_order.pay_time'
        }, {
          'add': 1,
          'desc': '',
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '期数',
          'rules': [],
          'show': 0,
          'type': 'text',
          'width': '70',
          'prop': 't_order.expect_num'
        }, {
          'add': 0,
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '订单号',
          'required': 0,
          'rowAddField': '',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '150',
          'prop': 'order_id'
        }, {
          'add': 1,
          'desc': '',
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '会员编号',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '120',
          'prop': 't_order.number'
        }, {
          'add': 1,
          'desc': '',
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '报单人编号',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '100',
          'prop': 't_order.store_number'
        }, {
          'add': 1,
          'desc': '',
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '报单人姓名',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '100',
          'prop': 't_order.ex_store_number.real_name'
        }, {
          'add': 1,
          'desc': '',
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '套餐全称',
          'rules': [],
          'show': 1,
          'type': 'textarea',
          'width': '200',
          'prop': 'ex_main_product.store_name'
        }, {
          'add': 1,
          'desc': '',
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '套餐编码',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '120',
          'prop': 'ex_main_product.code'
        }, {
          'add': 1,
          'desc': '',
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '商品编码',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '100',
          'prop': 't_product.code'
        }, {
          'add': 1,
          'desc': '',
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '商品名称',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '120',
          'prop': 't_product.store_name'
        }, {
          'add': 1,
          'desc': '',
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '商品简称',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '100',
          'prop': 't_product.shrink_name'
        }, {
          'add': 1,
          'desc': '',
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '套餐价格',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '80',
          'prop': 'ex_main_product.price'
        }, {
          'add': 1,
          'desc': '',
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '价格',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '80',
          'prop': 't_product.price'
        }, {
          'add': 1,
          'desc': '',
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '数量',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '80',
          'prop': 'quantity'
        }, {
          'add': 1,
          'desc': '',
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '小计',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '80',
          'prop': 'total_price'
        }, {
          'add': 1,
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '收货人',
          'required': 0,
          'rowAddField': '',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '100',
          'prop': 't_order.real_name'
        }, {
          'add': 1,
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '收货电话',
          'required': 0,
          'rowAddField': '',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '100',
          'prop': 't_order.user_phone'
        }, {
          'add': 1,
          'desc': '',
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '省',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '80',
          'prop': 't_order.address_province'
        }, {
          'add': 1,
          'desc': '',
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '市',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '80',
          'prop': 't_order.address_city'
        }, {
          'add': 1,
          'desc': '',
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '区',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '80',
          'prop': 't_order.address_district'
        }, {
          'add': 1,
          'desc': '',
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '收货地址',
          'rules': [],
          'show': 1,
          'type': 'textarea',
          'width': '150',
          'prop': 't_order.address_detail'
        }, {
          'add': 1,
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '出库时间',
          'required': 0,
          'rowAddField': '',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '150',
          'prop': 'checkout_time'
        }, {
          'add': 0,
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '订单类型',
          'required': 0,
          'rowAddField': '',
          'rules': [],
          'show': 0,
          'type': 'select',
          'width': '100',
          'prop': 't_order.order_type'
        }, {
          'add': 0,
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '订单日期',
          'required': 0,
          'rowAddField': '',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '150',
          'prop': 't_order.add_time'
        }, {
          'add': 1,
          'desc': '',
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '支付日期',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '150',
          'prop': 't_order.pay_time'
        }],
        'tableName': 't_order',
        'tableRadioProps': {},
        'tableSelectProps': {
          'status': {
            'data': [{
              'key': 0,
              'value': '未出库'
            }, {
              'key': 1,
              'value': '已出库'
            }, {
              'key': 2,
              'value': '已发货'
            }],
            'id': 1,
            'multiple': 0,
            'relation': false,
            'relationId': '',
            'remote': false
          },
          't_order.order_type': {
            'data': [{
              'key': 1,
              'value': '注册单'
            }, {
              'key': 2,
              'value': '重购单'
            }, {
              'key': 3,
              'value': '升级单'
            }, {
              'key': 4,
              'value': '激活单'
            }, {
              'key': 5,
              'value': '店体自营'
            }],
            'id': 2,
            'multiple': 0,
            'relation': false,
            'relationProps': [],
            'remote': false
          }
        },
        'tableSwitchProps': {},
        'title': '订单',
        'type': 'tableTemplate'
      },
      remoteFunc: {
        modelConditionFunc_3: function modelConditionFunc_3(tag, relationData, resolve) {
          //筛选条件数据
          //[[{key:"type",value:"1"}]]
          var data = [];
          data = [[{
            key: 't_order.add_time',
            value: []
          }], [{
            key: 't_order.add_time',
            value: _this.$g.fun.getDateRange(new Date(), 0, true)
          }], [{
            key: 't_order.add_time',
            value: _this.$g.fun.getDateRange(new Date(), 1, true)
          }], [{
            key: 't_order.add_time',
            value: _this.$g.fun.getDateRange(new Date(), 7, true)
          }], [{
            key: 't_order.add_time',
            value: _this.$g.fun.getDateRange(new Date(), 30, true)
          }], [{
            key: 't_order.add_time',
            value: _this.$g.fun.getDateRange(new Date(), 365, true)
          }], [{
            key: 't_order.add_time',
            value: []
          }]];
          resolve(tag, data);
        },
        modelConditionFunc_8: function modelConditionFunc_8(tag, relationData, resolve) {
          //筛选条件数据
          //[[{key:"type",value:"1"}]]
          var data = [];
          data = [[{
            key: 't_order.pay_time',
            value: []
          }], [{
            key: 't_order.pay_time',
            value: _this.$g.fun.getDateRange(new Date(), 0, true)
          }], [{
            key: 't_order.pay_time',
            value: _this.$g.fun.getDateRange(new Date(), 1, true)
          }], [{
            key: 't_order.pay_time',
            value: _this.$g.fun.getDateRange(new Date(), 7, true)
          }], [{
            key: 't_order.pay_time',
            value: _this.$g.fun.getDateRange(new Date(), 30, true)
          }], [{
            key: 't_order.pay_time',
            value: _this.$g.fun.getDateRange(new Date(), 365, true)
          }], [{
            key: 't_order.pay_time',
            value: []
          }]];
          resolve(tag, data);
        },
        modelConditionFunc_6: function modelConditionFunc_6(tag, relationData, resolve) {
          //筛选条件数据
          var data = [];
          productList({
            page: 1,
            limit: 1000,
            where: {
              is_main: 0
            }
          }).then(function _callee(res) {
            var i, item;
            return regeneratorRuntime.async(function _callee$(_context) {
              while (1) {
                switch (_context.prev = _context.next) {
                  case 0:
                    for (i in res.rows) {
                      item = res.rows[i];
                      data.push({
                        key: 'product_id',
                        value: item.id,
                        desc: item.code + '-' + item.store_name
                      });
                    }

                    resolve(tag, [data]);

                  case 2:
                  case "end":
                    return _context.stop();
                }
              }
            });
          });
        },
        modelConditionFunc_7: function modelConditionFunc_7(tag, relationData, resolve) {
          //筛选条件数据
          var data = [];
          productList({
            page: 1,
            limit: 1000,
            where: {
              is_main: 1
            }
          }).then(function _callee2(res) {
            var i, item;
            return regeneratorRuntime.async(function _callee2$(_context2) {
              while (1) {
                switch (_context2.prev = _context2.next) {
                  case 0:
                    for (i in res.rows) {
                      item = res.rows[i];
                      data.push({
                        key: 'main_product',
                        value: item.id,
                        desc: item.code + '-' + item.store_name
                      });
                    }

                    resolve(tag, [data]);

                  case 2:
                  case "end":
                    return _context2.stop();
                }
              }
            });
          });
        }
      }
    };
  },
  mounted: function mounted() {
    var _this2 = this;

    this.table = this.$refs.ref_1001;
    this.search = this.$refs.ref_1000;
    this.curWhere = {};
    productEx().then(function _callee3(res) {
      var ret, dic, i, key, _key;

      return regeneratorRuntime.async(function _callee3$(_context3) {
        while (1) {
          switch (_context3.prev = _context3.next) {
            case 0:
              ret = [];
              dic = {};

              for (i in res) {
                key = res[i].title;

                if (!dic.hasOwnProperty(key)) {
                  dic[key] = res[i];
                } else {
                  if (res[i].type == 'choose') {
                    (function () {
                      var mergedArray = dic[key].values.concat(res[i].values);
                      var seen = new Map();
                      var newValues = [];
                      mergedArray.forEach(function (item) {
                        if (!seen.has(item.desc)) {
                          seen.set(item.desc, true);
                          newValues.push(item);
                        }
                      });
                      dic[key].values = newValues;
                    })();
                  }
                }
              } // let model = this.$tempModel.deepCopy(this.tableModel_1001.tableModel)


              for (_key in dic) {
                dic[_key].value = '';
                ret.push(dic[_key]);

                _this2.tableModel_1001.tableModel.splice(1, 0, {
                  'add': 0,
                  'desc': '',
                  'detail': 1,
                  'excel': 1,
                  'groupId': '',
                  'label': _key,
                  'rules': [],
                  'show': 1,
                  'type': 'text',
                  'width': '120',
                  'prop': _key
                });
              } // this.tableModel_1001.tableModel = model


              _this2.componentKey += 1;
              _this2.isShow = true;
              _this2.productAttr = ret;

            case 7:
            case "end":
              return _context3.stop();
          }
        }
      });
    });
    this.isShow = false;
  },
  methods: {
    reqTableData: function reqTableData(params, resolve) {
      var where = {};

      if (this.search) {
        where = this.search.parseWhere();
      }

      params.where = where;

      if (!params.where.hasOwnProperty('is_lock')) {
        params.where.is_lock = 0;
      }

      var productEx = [];

      for (var i in this.productAttr) {
        if (this.productAttr[i].value != '') {
          productEx.push({
            title: this.productAttr[i].title,
            value: this.productAttr[i].value
          });
        }
      }

      params.ex1 = productEx;
      this.curWhere = where;
      orderGlassList(params).then(function _callee4(res) {
        var _i, item, ex, key;

        return regeneratorRuntime.async(function _callee4$(_context4) {
          while (1) {
            switch (_context4.prev = _context4.next) {
              case 0:
                for (_i in res.rows) {
                  item = res.rows[_i];
                  item.total_price = item.t_product.price * item.quantity;
                  ex = item.t_order_packages[0].ex1;

                  for (key in ex) {
                    item[key] = ex[key];
                  }
                }

                resolve({
                  total: res.count,
                  data: res.rows
                });

              case 2:
              case "end":
                return _context4.stop();
            }
          }
        });
      });
    },
    reqAdd: function reqAdd(isEdit, params, row, resolve) {
      if (isEdit) {
        params.id = row.id;
      }

      orderAdd(params).then(function _callee5(res) {
        return regeneratorRuntime.async(function _callee5$(_context5) {
          while (1) {
            switch (_context5.prev = _context5.next) {
              case 0:
                resolve();

              case 1:
              case "end":
                return _context5.stop();
            }
          }
        });
      });
    },
    reqDel: function reqDel(params, resolve) {
      var ids = params.map(function (item) {
        return item.id;
      }); //xxxDel({id:ids}).then(async res=>{
      //  resolve()
      //})
    },
    onClickLink: function onClickLink(prop, row) {//点击列表link类型回调
    },
    onClickRowButton: function onClickRowButton(btnIndex, row) {//点击列表按钮回调
    },
    onClickHeaderButton: function onClickHeaderButton(btnIndex, row) {
      var _this3 = this;

      //点击列表头部按钮回调
      var ids = row.map(function (item) {
        return item.id;
      });

      if ([0, 1, 4].indexOf(btnIndex) >= 0) {
        if (row.length <= 0) {
          this.$message({
            message: '请至少选择一个商品',
            type: 'warning'
          });
          return;
        }

        this.confirm('确定要将选中的' + ids.length + '件商品进行' + this.tableModel_1001.headerButton[btnIndex].title + '么?', function () {
          if (btnIndex === 0) {
            expressCheckout({
              id: ids
            }).then(function _callee6(res) {
              return regeneratorRuntime.async(function _callee6$(_context6) {
                while (1) {
                  switch (_context6.prev = _context6.next) {
                    case 0:
                      _this3.$message({
                        message: '操作成功',
                        type: 'success'
                      });

                      _this3.table.reloadData();

                    case 2:
                    case "end":
                      return _context6.stop();
                  }
                }
              });
            });
          } else if (btnIndex === 1) {
            expressDeliver({
              id: ids
            }).then(function _callee7(res) {
              return regeneratorRuntime.async(function _callee7$(_context7) {
                while (1) {
                  switch (_context7.prev = _context7.next) {
                    case 0:
                      _this3.$message({
                        message: '操作成功',
                        type: 'success'
                      });

                      _this3.table.reloadData();

                    case 2:
                    case "end":
                      return _context7.stop();
                  }
                }
              });
            });
          } // else if (btnIndex === 2) {
          //   let ids = row.map(item => item.order_id)
          //   expressLock({
          //     id: ids,
          //     is_lock: 1
          //   }).then(async res => {
          //     this.$message({ message: '操作成功', type: 'success' })
          //     this.table.reloadData()
          //   })
          // } else if (btnIndex === 3) {
          //   let ids = row.map(item => item.order_id)
          //   expressLock({
          //     id: ids,
          //     is_lock: 0
          //   }).then(async res => {
          //     this.$message({ message: '操作成功', type: 'success' })
          //     this.table.reloadData()
          //   })
          // }
          else if (btnIndex === 4) {
              var _ids = row.map(function (item) {
                return item.order_id;
              });

              expressReset({
                id: _ids
              }).then(function _callee8(res) {
                return regeneratorRuntime.async(function _callee8$(_context8) {
                  while (1) {
                    switch (_context8.prev = _context8.next) {
                      case 0:
                        _this3.$message({
                          message: '操作成功',
                          type: 'success'
                        });

                        _this3.table.reloadData();

                      case 2:
                      case "end":
                        return _context8.stop();
                    }
                  }
                });
              });
            }
        });
      } else {
        this.confirm('确定根据当前筛选条件进行' + this.tableModel_1001.headerButton[btnIndex].title + '么?', function () {
          var tmp = _this3.$tempModel.deepCopy(_this3.curWhere);

          delete tmp['t_order.paid'];

          if (btnIndex === 2) {
            expressCheckoutAll({
              where: tmp
            }).then(function _callee9(res) {
              return regeneratorRuntime.async(function _callee9$(_context9) {
                while (1) {
                  switch (_context9.prev = _context9.next) {
                    case 0:
                      _this3.$message({
                        message: '操作成功',
                        type: 'success'
                      });

                      _this3.table.reloadData();

                    case 2:
                    case "end":
                      return _context9.stop();
                  }
                }
              });
            });
          } else if (btnIndex === 3) {
            expressDeliverAll({
              where: tmp
            }).then(function _callee10(res) {
              return regeneratorRuntime.async(function _callee10$(_context10) {
                while (1) {
                  switch (_context10.prev = _context10.next) {
                    case 0:
                      _this3.$message({
                        message: '操作成功',
                        type: 'success'
                      });

                      _this3.table.reloadData();

                    case 2:
                    case "end":
                      return _context10.stop();
                  }
                }
              });
            });
          } // else if (btnIndex === 7) {
          //   expressLockAll({ where: tmp, is_lock: 1 }).then(async res => {
          //     this.$message({ message: '操作成功', type: 'success' })
          //     this.table.reloadData()
          //   })
          // } else if (btnIndex === 8) {
          //   expressLockAll({ where: tmp, is_lock: 0 }).then(async res => {
          //     this.$message({ message: '操作成功', type: 'success' })
          //     this.table.reloadData()
          //   })
          // }

        });
      }
    },
    onSummary: function onSummary(param) {//列表尾部合计回调
    },
    onSwitchChange: function onSwitchChange(val, prop, row) {//列表中switch变化事件
    },
    onClickSearchOperate: function onClickSearchOperate(btnIndex, btnData) {
      //条件菜单按钮点击事件
      if (btnData.tag === this.$tempModel.buttonTag.search) {
        this.componentKey += 1;
        this.$refs.ref_1001.reloadData();
      } else if (btnData.tag === this.$tempModel.buttonTag.export) {
        this.$refs.ref_1001.exportExcel();
      } else if (btnData.tag === this.$tempModel.buttonTag.import) {
        var header = btnData.header;
        var results = btnData.results;
      }
    },
    onConditionChange: function onConditionChange(tag, index) {
      this.componentKey += 1;
      this.table.reloadData();
    },
    confirm: function confirm(msg, handle) {
      this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        if (handle) {
          handle();
        }
      }).catch(function () {});
    }
  }
};