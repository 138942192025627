import "core-js/modules/es.array.map";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.search";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tempTable from "@/components/rw/tempTable/index";
import tempSearch from "@/components/rw/tempSearch/index";
import tempForm from "@/components/rw/tempForm/index";
import tempChart from "@/components/rw/tempChart/index";
import confirmForm from './confirmForm';
import { fileDel, fileList, sendCmd } from '../../../../api/apis';
export default {
  components: {
    tempTable: tempTable,
    tempSearch: tempSearch,
    tempForm: tempForm,
    tempChart: tempChart,
    confirmForm: confirmForm
  },
  data: function data() {
    var _this = this;

    return {
      tableModel_1000: {
        "type": "tableTemplate",
        "id": "id_1000",
        "del": false,
        "tableModel": [{
          "label": "ID",
          "show": 1,
          "add": 1,
          "detail": 1,
          "excel": 1,
          "width": 80,
          "type": "text",
          "rowAddField": "",
          "groupId": "",
          "rules": [],
          "prop": "id"
        }, {
          "label": "文件名",
          "show": 1,
          "add": 1,
          "detail": 1,
          "excel": 1,
          "width": "300",
          "type": "text",
          "rowAddField": "",
          "groupId": "",
          "rules": [],
          "prop": "file_name"
        }, {
          "label": "创建日期",
          "show": 1,
          "add": 1,
          "detail": 1,
          "excel": 1,
          "width": 160,
          "type": "text",
          "rowAddField": "",
          "groupId": "",
          "rules": [],
          "prop": "add_time"
        }, {
          "label": "下载地址",
          "show": 0,
          "add": 1,
          "detail": 1,
          "excel": 1,
          "width": 160,
          "type": "text",
          "rowAddField": "",
          "groupId": "",
          "rules": [],
          "prop": "file_url"
        }, {
          "label": "文件分类",
          "show": 0,
          "add": 1,
          "detail": 1,
          "excel": 1,
          "width": 160,
          "type": "text",
          "rowAddField": "",
          "groupId": "",
          "rules": [],
          "prop": "file_type"
        }],
        "tableSwitchProps": {},
        "tableSelectProps": {},
        "tableCascaderProps": {},
        "tableFileProps": {},
        "rowButton": [{
          "title": "下载",
          "tag": "default",
          "id": "",
          "type": "success"
        }],
        "stripe": false,
        "border": true,
        "showPagination": true,
        "showSelection": true,
        "showSummary": false,
        "title": "文件",
        "tableName": "t_file_manage",
        "isGroup": false,
        "isTree": false,
        "headerButton": [{
          "title": "删除选中项",
          "type": "danger",
          "tag": "mul-del",
          "id": ""
        }],
        "tableAutocompleteProps": {},
        "tableRadioProps": {}
      },
      remoteFunc: {},
      buttons: [{
        title: '系统报表',
        cmd: 'systbb',
        params: [{
          title: '报表起始日期',
          remark: '',
          defaultValue: '',
          placeholder: '2020-10-10',
          key: 'sdate'
        }, {
          title: '报表结束如期',
          remark: '不包含当前日期',
          defaultValue: '',
          placeholder: '2020-10-10',
          key: 'edate'
        }]
      }]
    };
  },
  mounted: function mounted() {
    this.table = this.$refs.ref_1000;
  },
  methods: {
    reqTableData: function reqTableData(params, resolve) {
      var where = {};

      if (this.search) {
        where = this.search.parseWhere();
        params.where = where;
      }

      fileList(params).then(function _callee(res) {
        return regeneratorRuntime.async(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                resolve({
                  total: res.count,
                  data: res.rows
                });

              case 1:
              case "end":
                return _context.stop();
            }
          }
        });
      });
    },
    clickRefresh: function clickRefresh() {
      this.table.reloadData();
    },
    clickItem: function clickItem(tag) {
      var _this2 = this;

      var data = this.buttons[tag];
      this.$refs['form'].show(data.params, function (form) {
        var params = [data.cmd];

        for (var key in form) {
          params.push('-' + key);
          params.push(form[key]);
        }

        sendCmd(params).then(function _callee2(res) {
          return regeneratorRuntime.async(function _callee2$(_context2) {
            while (1) {
              switch (_context2.prev = _context2.next) {
                case 0:
                  _this2.$alert('提交成功，请等待60秒后刷新文件列表', '提示', {
                    confirmButtonText: '确定'
                  });

                case 1:
                case "end":
                  return _context2.stop();
              }
            }
          });
        });
      });
    },
    reqAdd: function reqAdd(isEdit, params, row, resolve) {//if (isEdit){
      //  params.id = row.id
      //}
      //xxxAdd(params).then(async res=>{
      //  resolve()
      //})
    },
    reqDel: function reqDel(params, resolve) {
      var ids = params.map(function (item) {
        return item.id;
      });
      fileDel({
        id: ids
      }).then(function _callee3(res) {
        return regeneratorRuntime.async(function _callee3$(_context3) {
          while (1) {
            switch (_context3.prev = _context3.next) {
              case 0:
                resolve();

              case 1:
              case "end":
                return _context3.stop();
            }
          }
        });
      });
    },
    onClickLink: function onClickLink(prop, row) {//点击列表link类型回调
    },
    onClickRowButton: function onClickRowButton(btnIndex, row) {//点击列表按钮回调
    },
    onClickHeaderButton: function onClickHeaderButton(btnIndex, row) {//点击列表头部按钮回调
    },
    onSummary: function onSummary(param) {//列表尾部合计回调
    },
    onSwitchChange: function onSwitchChange(val, prop, row) {//列表中switch变化事件
    },
    onClickSearchOperate: function onClickSearchOperate(btnIndex, btnData) {
      //条件菜单按钮点击事件
      if (btnData.tag === this.$tempModel.buttonTag.search) {
        this.table.reloadData();
      } else if (btnData.tag === this.$tempModel.buttonTag.export) {
        this.table.exportExcel();
      } else if (btnData.tag === this.$tempModel.buttonTag.import) {
        var header = btnData.header;
        var results = btnData.results;
      }
    },
    onConditionChange: function onConditionChange(tag, index) {
      this.table.reloadData();
    },
    onRowButtonShow: function onRowButtonShow(btnIndex, row) {
      return true;
    }
  }
};