import "core-js/modules/es.array.join";
import "core-js/modules/es.object.to-string";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { gradeMulUpdate, memberBatchStore } from '../../../../api/apis';
export default {
  props: {
    storeList: {
      type: Array,
      required: true
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      form: {
        numbers: '',
        store: 0
      }
    };
  },
  mounted: function mounted() {},
  methods: {
    show: function show(numbers, finish) {
      this.dialogVisible = true;
      this.form.numbers = numbers.join(',');
      this.numberAry = numbers;
      this.finish = finish;
    },
    clickSubmit: function clickSubmit() {
      var _this = this;

      memberBatchStore({
        number: this.numberAry,
        store: this.form.store
      }).then(function _callee(res) {
        return regeneratorRuntime.async(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                _this.$message({
                  message: '批量定级成功',
                  type: 'success'
                });

                _this.dialogVisible = false;

                _this.finish();

              case 3:
              case "end":
                return _context.stop();
            }
          }
        });
      });
    }
  }
};