//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import bs from './mixins/bs';
export default {
  name: 'd2-container-card-bs',
  mixins: [bs]
};