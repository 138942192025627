import "core-js/modules/es.array.sort";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import "core-js/modules/web.dom-collections.iterator";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import bonusBase from './bonusBase';
import { bonusSourceList, configBonusList, configGradeList, configLevelList, expectNumList, memberBalance } from '../../../../api/apis';
export default {
  name: 'reportsbonus',
  components: {
    bonusBase: bonusBase
  },
  data: function data() {
    var _this = this;

    return {
      activeName: '',
      bonusList: [],
      bonusData: null,
      levelList: [],
      gradeList: [],
      topNumber: '',
      expectNum: '',
      topNumberBalance: null,
      tableProps: [{
        prop: 'order_id',
        label: '订单ID'
      }, {
        prop: 'number',
        label: '来源会员'
      }, {
        prop: 'name',
        label: '会员姓名'
      }, {
        prop: 'level',
        label: '资格'
      }, {
        prop: 'grade',
        label: '等级'
      }, {
        prop: 'bonus',
        label: '奖金额'
      }, {
        prop: 'order_pv',
        label: '订单PV'
      }, {
        prop: 'bonus_rate',
        label: '奖金比率'
      }, {
        prop: 'compute_mode',
        label: '计算方式'
      }, {
        prop: 'layer',
        label: '层数'
      }]
    };
  },
  mounted: function mounted() {
    var _this2 = this;

    configBonusList({
      page: 1,
      limit: 10000
    }).then(function _callee(res) {
      var i;
      return regeneratorRuntime.async(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _this2.bonusList = res.rows.sort(function (item1, item2) {
                if (item1.tag > item2.tag) {
                  return 1;
                } else {
                  return -1;
                }
              });
              _this2.bonusData = {};

              for (i in _this2.bonusList) {
                _this2.bonusData[_this2.bonusList[i].tag] = [];

                if (i == 0) {
                  _this2.activeName = 'name' + _this2.bonusList[i].tag;
                }
              }

            case 3:
            case "end":
              return _context.stop();
          }
        }
      });
    });
    configGradeList({}).then(function _callee2(res) {
      return regeneratorRuntime.async(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              _this2.gradeList = res.rows;

            case 1:
            case "end":
              return _context2.stop();
          }
        }
      });
    });
    configLevelList({}).then(function _callee3(res) {
      return regeneratorRuntime.async(function _callee3$(_context3) {
        while (1) {
          switch (_context3.prev = _context3.next) {
            case 0:
              _this2.levelList = res.rows;

            case 1:
            case "end":
              return _context3.stop();
          }
        }
      });
    });
  },
  methods: {
    dataCount: function dataCount(item) {
      var ret = item.name + '(' + (this.bonusData ? this.bonusData[item.tag].length : 0) + ')[' + (this.topNumberBalance ? this.topNumberBalance['bonus' + item.tag] : 0) + ']';
      return ret;
    },
    onSearch: function onSearch() {
      var _this3 = this;

      if (this.topNumber === '' || this.expectNum === '') {
        this.$message({
          message: '请输入会员编号和结算期数',
          type: 'warning'
        });
        return;
      }

      this.$g.fun.startLoading('计算中');
      memberBalance({
        where: {
          number: this.topNumber,
          expect_num: this.expectNum
        }
      }).then(function _callee4(res) {
        return regeneratorRuntime.async(function _callee4$(_context4) {
          while (1) {
            switch (_context4.prev = _context4.next) {
              case 0:
                if (res.rows.length > 0) {
                  _this3.topNumberBalance = res.rows[0];
                }

                console.log('memberBalance', res);

              case 2:
              case "end":
                return _context4.stop();
            }
          }
        });
      });
      bonusSourceList({
        where: {
          top_number: this.topNumber,
          expect_num: this.expectNum
        }
      }).then(function _callee5(res) {
        var tmp, j, tag, i, k, _k;

        return regeneratorRuntime.async(function _callee5$(_context5) {
          while (1) {
            switch (_context5.prev = _context5.next) {
              case 0:
                tmp = {};
                _context5.t0 = regeneratorRuntime.keys(_this3.bonusList);

              case 2:
                if ((_context5.t1 = _context5.t0()).done) {
                  _context5.next = 31;
                  break;
                }

                j = _context5.t1.value;
                tag = _this3.bonusList[j].tag;
                tmp[tag] = [];
                _context5.t2 = regeneratorRuntime.keys(res);

              case 7:
                if ((_context5.t3 = _context5.t2()).done) {
                  _context5.next = 29;
                  break;
                }

                i = _context5.t3.value;
                _context5.t4 = regeneratorRuntime.keys(_this3.gradeList);

              case 10:
                if ((_context5.t5 = _context5.t4()).done) {
                  _context5.next = 17;
                  break;
                }

                k = _context5.t5.value;

                if (!(res[i].grade == _this3.gradeList[k].tag)) {
                  _context5.next = 15;
                  break;
                }

                res[i].grade = _this3.gradeList[k].name;
                return _context5.abrupt("break", 17);

              case 15:
                _context5.next = 10;
                break;

              case 17:
                _context5.t6 = regeneratorRuntime.keys(_this3.levelList);

              case 18:
                if ((_context5.t7 = _context5.t6()).done) {
                  _context5.next = 25;
                  break;
                }

                _k = _context5.t7.value;

                if (!(res[i].level == _this3.levelList[_k].tag)) {
                  _context5.next = 23;
                  break;
                }

                res[i].level = _this3.levelList[_k].name;
                return _context5.abrupt("break", 25);

              case 23:
                _context5.next = 18;
                break;

              case 25:
                res[i]['name'] = res[i]['t_user.real_name'];

                if (res[i].bonus_tag === tag) {
                  tmp[tag].push(res[i]);
                }

                _context5.next = 7;
                break;

              case 29:
                _context5.next = 2;
                break;

              case 31:
                _this3.bonusData = tmp;

                _this3.$g.fun.endLoading();

              case 33:
              case "end":
                return _context5.stop();
            }
          }
        });
      });
    },
    getBonusNameByTag: function getBonusNameByTag(tag) {
      for (var i in this.bonusList) {
        if (this.bonusList[i].tag == tag) {
          return this.bonusList[i].name;
        }
      }

      return '';
    },
    onExport: function onExport() {
      var _this4 = this;

      var columns = this.tableProps;
      var data = [];

      for (var tag in this.bonusData) {
        var sub = this.bonusData[tag];
        data.push({
          order_id: this.getBonusNameByTag(tag)
        });

        for (var i in sub) {
          data.push(sub[i]);
        }

        data.push({
          order_id: ''
        });
      }

      console.log(data);
      this.$export.excel({
        columns: columns,
        data: data,
        title: '奖金详情'
      }).then(function () {
        _this4.$g.fun.endLoading();
      });
    }
  }
};