//
//
//
export default {
  props: {
    model: {
      type: Object,
      required: true
    },
    prop: {
      type: String,
      required: true
    },
    selectProps: {
      type: Object,
      required: true
    }
  },
  methods: {
    selectValue: function selectValue(prop, value) {
      // if (prop === 'level'){
      // }
      if (!this.selectProps.hasOwnProperty(prop)) {
        return '';
      }

      var sp = this.selectProps[prop];

      if (sp.multiple === 0) {
        for (var i in sp.data) {
          if (this.selectProps[prop].data[i].key == value) {
            return this.selectProps[prop].data[i].value;
          }
        }
      } else {
        var content = '';

        for (var _i in sp.data) {
          for (var j in value) {
            if (this.selectProps[prop].data[_i].key == value[j]) {
              content += this.selectProps[prop].data[_i].value + ',';
            }
          }
        }

        return content.substr(0, content.length - 1);
      }

      return '';
    }
  }
};