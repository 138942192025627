var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "el-card",
        { staticStyle: { width: "600px" } },
        [
          _c("span", { attrs: { slot: "header" }, slot: "header" }, [
            _vm._v("点位创建")
          ]),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "120px",
                size: "small"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "推荐人编号", prop: "direct" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.direct,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "direct", $$v)
                      },
                      expression: "form.direct"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "会员编号", prop: "number" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.number,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "number", $$v)
                      },
                      expression: "form.number"
                    }
                  }),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.clickNumberMake }
                    },
                    [_vm._v("生成编号")]
                  ),
                  _c(
                    "el-tag",
                    {
                      staticStyle: { "margin-left": "20px" },
                      attrs: { type: "danger" }
                    },
                    [_vm._v("自动生成编号，编号可更改")]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "会员姓名", prop: "real_name" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.real_name,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "real_name", $$v)
                      },
                      expression: "form.real_name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "手机号码", prop: "phone" } },
                [
                  _c(
                    "el-input",
                    {
                      on: { input: _vm.onPhoneChange },
                      model: {
                        value: _vm.form.phone,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "phone", $$v)
                        },
                        expression: "form.phone"
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "180px" },
                          attrs: { slot: "prepend", placeholder: "区号" },
                          slot: "prepend",
                          model: {
                            value: _vm.form.country_code,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "country_code", $$v)
                            },
                            expression: "form.country_code"
                          }
                        },
                        _vm._l(_vm.countryCodes, function(item) {
                          return _c("el-option", {
                            attrs: { label: item.value, value: item.key }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "登录密码", prop: "pwd" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "自动填充手机后后6位" },
                    model: {
                      value: _vm.form.pwd,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "pwd", $$v)
                      },
                      expression: "form.pwd"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "支付密码", prop: "pay_pass" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "自动填充手机后后6位" },
                    model: {
                      value: _vm.form.pay_pass,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "pay_pass", $$v)
                      },
                      expression: "form.pay_pass"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-button",
                {
                  staticStyle: { float: "right" },
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.clickCreate }
                },
                [_vm._v("创建")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }