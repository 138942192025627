export default {
  region: [{
    'provinceCode': '110000',
    'provinceName': '北京市',
    'mallCityList': [{
      'cityCode': '110100',
      'cityName': '北京市',
      'mallAreaList': [{
        'areaCode': '110101',
        'areaName': '东城区'
      }, {
        'areaCode': '110102',
        'areaName': '西城区'
      }, {
        'areaCode': '110105',
        'areaName': '朝阳区'
      }, {
        'areaCode': '110106',
        'areaName': '丰台区'
      }, {
        'areaCode': '110107',
        'areaName': '石景山区'
      }, {
        'areaCode': '110108',
        'areaName': '海淀区'
      }, {
        'areaCode': '110109',
        'areaName': '门头沟区'
      }, {
        'areaCode': '110111',
        'areaName': '房山区'
      }, {
        'areaCode': '110112',
        'areaName': '通州区'
      }, {
        'areaCode': '110113',
        'areaName': '顺义区'
      }, {
        'areaCode': '110114',
        'areaName': '昌平区'
      }, {
        'areaCode': '110115',
        'areaName': '大兴区'
      }, {
        'areaCode': '110116',
        'areaName': '怀柔区'
      }, {
        'areaCode': '110117',
        'areaName': '平谷区'
      }, {
        'areaCode': '110118',
        'areaName': '密云区'
      }]
    }, {
      'cityCode': '110200',
      'cityName': '北京市辖县',
      'mallAreaList': [{
        'areaCode': '110228',
        'areaName': '密云县'
      }, {
        'areaCode': '110229',
        'areaName': '延庆县'
      }]
    }]
  }, {
    'provinceCode': '120000',
    'provinceName': '天津市',
    'mallCityList': [{
      'cityCode': '120100',
      'cityName': '天津市',
      'mallAreaList': [{
        'areaCode': '120101',
        'areaName': '和平区'
      }, {
        'areaCode': '120102',
        'areaName': '河东区'
      }, {
        'areaCode': '120103',
        'areaName': '河西区'
      }, {
        'areaCode': '120104',
        'areaName': '南开区'
      }, {
        'areaCode': '120105',
        'areaName': '河北区'
      }, {
        'areaCode': '120106',
        'areaName': '红桥区'
      }, {
        'areaCode': '120110',
        'areaName': '东丽区'
      }, {
        'areaCode': '120111',
        'areaName': '西青区'
      }, {
        'areaCode': '120112',
        'areaName': '津南区'
      }, {
        'areaCode': '120113',
        'areaName': '北辰区'
      }, {
        'areaCode': '120114',
        'areaName': '武清区'
      }, {
        'areaCode': '120115',
        'areaName': '宝坻区'
      }, {
        'areaCode': '120116',
        'areaName': '滨海新区'
      }, {
        'areaCode': '120117',
        'areaName': '蓟州区'
      }, {
        'areaCode': '120118',
        'areaName': '静海区'
      }, {
        'areaCode': '120119',
        'areaName': '宁河区'
      }]
    }, {
      'cityCode': '120200',
      'cityName': '天津市辖县',
      'mallAreaList': [{
        'areaCode': '120221',
        'areaName': '宁河县'
      }, {
        'areaCode': '120223',
        'areaName': '静海县'
      }, {
        'areaCode': '120225',
        'areaName': '蓟县'
      }]
    }]
  }, {
    'provinceCode': '130000',
    'provinceName': '河北省',
    'mallCityList': [{
      'cityCode': '130100',
      'cityName': '石家庄市',
      'mallAreaList': [{
        'areaCode': '130102',
        'areaName': '长安区'
      }, {
        'areaCode': '130104',
        'areaName': '桥西区'
      }, {
        'areaCode': '130105',
        'areaName': '新华区'
      }, {
        'areaCode': '130107',
        'areaName': '井陉矿区'
      }, {
        'areaCode': '130108',
        'areaName': '裕华区'
      }, {
        'areaCode': '130109',
        'areaName': '藁城区'
      }, {
        'areaCode': '130110',
        'areaName': '鹿泉区'
      }, {
        'areaCode': '130111',
        'areaName': '栾城区'
      }, {
        'areaCode': '130121',
        'areaName': '井陉县'
      }, {
        'areaCode': '130123',
        'areaName': '正定县'
      }, {
        'areaCode': '130125',
        'areaName': '行唐县'
      }, {
        'areaCode': '130126',
        'areaName': '灵寿县'
      }, {
        'areaCode': '130127',
        'areaName': '高邑县'
      }, {
        'areaCode': '130128',
        'areaName': '深泽县'
      }, {
        'areaCode': '130129',
        'areaName': '赞皇县'
      }, {
        'areaCode': '130130',
        'areaName': '无极县'
      }, {
        'areaCode': '130131',
        'areaName': '平山县'
      }, {
        'areaCode': '130132',
        'areaName': '元氏县'
      }, {
        'areaCode': '130133',
        'areaName': '赵县'
      }, {
        'areaCode': '130181',
        'areaName': '辛集市'
      }, {
        'areaCode': '130183',
        'areaName': '晋州市'
      }, {
        'areaCode': '130184',
        'areaName': '新乐市'
      }]
    }, {
      'cityCode': '130200',
      'cityName': '唐山市',
      'mallAreaList': [{
        'areaCode': '130202',
        'areaName': '路南区'
      }, {
        'areaCode': '130203',
        'areaName': '路北区'
      }, {
        'areaCode': '130204',
        'areaName': '古冶区'
      }, {
        'areaCode': '130205',
        'areaName': '开平区'
      }, {
        'areaCode': '130207',
        'areaName': '丰南区'
      }, {
        'areaCode': '130208',
        'areaName': '丰润区'
      }, {
        'areaCode': '130209',
        'areaName': '曹妃甸区'
      }, {
        'areaCode': '130223',
        'areaName': '滦县'
      }, {
        'areaCode': '130224',
        'areaName': '滦南县'
      }, {
        'areaCode': '130225',
        'areaName': '乐亭县'
      }, {
        'areaCode': '130227',
        'areaName': '迁西县'
      }, {
        'areaCode': '130229',
        'areaName': '玉田县'
      }, {
        'areaCode': '130281',
        'areaName': '遵化市'
      }, {
        'areaCode': '130283',
        'areaName': '迁安市'
      }]
    }, {
      'cityCode': '130300',
      'cityName': '秦皇岛市',
      'mallAreaList': [{
        'areaCode': '130302',
        'areaName': '海港区'
      }, {
        'areaCode': '130303',
        'areaName': '山海关区'
      }, {
        'areaCode': '130304',
        'areaName': '北戴河区'
      }, {
        'areaCode': '130321',
        'areaName': '青龙满族自治县'
      }, {
        'areaCode': '130322',
        'areaName': '昌黎县'
      }, {
        'areaCode': '130323',
        'areaName': '抚宁县'
      }, {
        'areaCode': '130324',
        'areaName': '卢龙县'
      }]
    }, {
      'cityCode': '130400',
      'cityName': '邯郸市',
      'mallAreaList': [{
        'areaCode': '130402',
        'areaName': '邯山区'
      }, {
        'areaCode': '130403',
        'areaName': '丛台区'
      }, {
        'areaCode': '130404',
        'areaName': '复兴区'
      }, {
        'areaCode': '130406',
        'areaName': '峰峰矿区'
      }, {
        'areaCode': '130421',
        'areaName': '邯郸县'
      }, {
        'areaCode': '130423',
        'areaName': '临漳县'
      }, {
        'areaCode': '130424',
        'areaName': '成安县'
      }, {
        'areaCode': '130425',
        'areaName': '大名县'
      }, {
        'areaCode': '130426',
        'areaName': '涉县'
      }, {
        'areaCode': '130427',
        'areaName': '磁县'
      }, {
        'areaCode': '130428',
        'areaName': '肥乡县'
      }, {
        'areaCode': '130429',
        'areaName': '永年县'
      }, {
        'areaCode': '130430',
        'areaName': '邱县'
      }, {
        'areaCode': '130431',
        'areaName': '鸡泽县'
      }, {
        'areaCode': '130432',
        'areaName': '广平县'
      }, {
        'areaCode': '130433',
        'areaName': '馆陶县'
      }, {
        'areaCode': '130434',
        'areaName': '魏县'
      }, {
        'areaCode': '130435',
        'areaName': '曲周县'
      }, {
        'areaCode': '130481',
        'areaName': '武安市'
      }]
    }, {
      'cityCode': '130500',
      'cityName': '邢台市',
      'mallAreaList': [{
        'areaCode': '130502',
        'areaName': '桥东区'
      }, {
        'areaCode': '130503',
        'areaName': '桥西区'
      }, {
        'areaCode': '130521',
        'areaName': '邢台县'
      }, {
        'areaCode': '130522',
        'areaName': '临城县'
      }, {
        'areaCode': '130523',
        'areaName': '内丘县'
      }, {
        'areaCode': '130524',
        'areaName': '柏乡县'
      }, {
        'areaCode': '130525',
        'areaName': '隆尧县'
      }, {
        'areaCode': '130526',
        'areaName': '任县'
      }, {
        'areaCode': '130527',
        'areaName': '南和县'
      }, {
        'areaCode': '130528',
        'areaName': '宁晋县'
      }, {
        'areaCode': '130529',
        'areaName': '巨鹿县'
      }, {
        'areaCode': '130530',
        'areaName': '新河县'
      }, {
        'areaCode': '130531',
        'areaName': '广宗县'
      }, {
        'areaCode': '130532',
        'areaName': '平乡县'
      }, {
        'areaCode': '130533',
        'areaName': '威县'
      }, {
        'areaCode': '130534',
        'areaName': '清河县'
      }, {
        'areaCode': '130535',
        'areaName': '临西县'
      }, {
        'areaCode': '130581',
        'areaName': '南宫市'
      }, {
        'areaCode': '130582',
        'areaName': '沙河市'
      }]
    }, {
      'cityCode': '130600',
      'cityName': '保定市',
      'mallAreaList': [{
        'areaCode': '130602',
        'areaName': '新市区'
      }, {
        'areaCode': '130603',
        'areaName': '北市区'
      }, {
        'areaCode': '130604',
        'areaName': '南市区'
      }, {
        'areaCode': '130621',
        'areaName': '满城县'
      }, {
        'areaCode': '130622',
        'areaName': '清苑县'
      }, {
        'areaCode': '130623',
        'areaName': '涞水县'
      }, {
        'areaCode': '130624',
        'areaName': '阜平县'
      }, {
        'areaCode': '130625',
        'areaName': '徐水区'
      }, {
        'areaCode': '130626',
        'areaName': '定兴县'
      }, {
        'areaCode': '130627',
        'areaName': '唐县'
      }, {
        'areaCode': '130628',
        'areaName': '高阳县'
      }, {
        'areaCode': '130629',
        'areaName': '容城县'
      }, {
        'areaCode': '130630',
        'areaName': '涞源县'
      }, {
        'areaCode': '130631',
        'areaName': '望都县'
      }, {
        'areaCode': '130632',
        'areaName': '安新县'
      }, {
        'areaCode': '130633',
        'areaName': '易县'
      }, {
        'areaCode': '130634',
        'areaName': '曲阳县'
      }, {
        'areaCode': '130635',
        'areaName': '蠡县'
      }, {
        'areaCode': '130636',
        'areaName': '顺平县'
      }, {
        'areaCode': '130637',
        'areaName': '博野县'
      }, {
        'areaCode': '130638',
        'areaName': '雄县'
      }, {
        'areaCode': '130681',
        'areaName': '涿州市'
      }, {
        'areaCode': '130682',
        'areaName': '定州市'
      }, {
        'areaCode': '130683',
        'areaName': '安国市'
      }, {
        'areaCode': '130684',
        'areaName': '高碑店市'
      }]
    }, {
      'cityCode': '130700',
      'cityName': '张家口市',
      'mallAreaList': [{
        'areaCode': '130702',
        'areaName': '桥东区'
      }, {
        'areaCode': '130703',
        'areaName': '桥西区'
      }, {
        'areaCode': '130705',
        'areaName': '宣化区'
      }, {
        'areaCode': '130706',
        'areaName': '下花园区'
      }, {
        'areaCode': '130721',
        'areaName': '宣化县'
      }, {
        'areaCode': '130722',
        'areaName': '张北县'
      }, {
        'areaCode': '130723',
        'areaName': '康保县'
      }, {
        'areaCode': '130724',
        'areaName': '沽源县'
      }, {
        'areaCode': '130725',
        'areaName': '尚义县'
      }, {
        'areaCode': '130726',
        'areaName': '蔚县'
      }, {
        'areaCode': '130727',
        'areaName': '阳原县'
      }, {
        'areaCode': '130728',
        'areaName': '怀安县'
      }, {
        'areaCode': '130729',
        'areaName': '万全县'
      }, {
        'areaCode': '130730',
        'areaName': '怀来县'
      }, {
        'areaCode': '130731',
        'areaName': '涿鹿县'
      }, {
        'areaCode': '130732',
        'areaName': '赤城县'
      }, {
        'areaCode': '130733',
        'areaName': '崇礼县'
      }]
    }, {
      'cityCode': '130800',
      'cityName': '承德市',
      'mallAreaList': [{
        'areaCode': '130802',
        'areaName': '双桥区'
      }, {
        'areaCode': '130803',
        'areaName': '双滦区'
      }, {
        'areaCode': '130804',
        'areaName': '鹰手营子矿区'
      }, {
        'areaCode': '130821',
        'areaName': '承德县'
      }, {
        'areaCode': '130822',
        'areaName': '兴隆县'
      }, {
        'areaCode': '130823',
        'areaName': '平泉县'
      }, {
        'areaCode': '130824',
        'areaName': '滦平县'
      }, {
        'areaCode': '130825',
        'areaName': '隆化县'
      }, {
        'areaCode': '130826',
        'areaName': '丰宁满族自治县'
      }, {
        'areaCode': '130827',
        'areaName': '宽城满族自治县'
      }, {
        'areaCode': '130828',
        'areaName': '围场满族蒙古族自治县'
      }]
    }, {
      'cityCode': '130900',
      'cityName': '沧州市',
      'mallAreaList': [{
        'areaCode': '130902',
        'areaName': '新华区'
      }, {
        'areaCode': '130903',
        'areaName': '运河区'
      }, {
        'areaCode': '130921',
        'areaName': '沧县'
      }, {
        'areaCode': '130922',
        'areaName': '青县'
      }, {
        'areaCode': '130923',
        'areaName': '东光县'
      }, {
        'areaCode': '130924',
        'areaName': '海兴县'
      }, {
        'areaCode': '130925',
        'areaName': '盐山县'
      }, {
        'areaCode': '130926',
        'areaName': '肃宁县'
      }, {
        'areaCode': '130927',
        'areaName': '南皮县'
      }, {
        'areaCode': '130928',
        'areaName': '吴桥县'
      }, {
        'areaCode': '130929',
        'areaName': '献县'
      }, {
        'areaCode': '130930',
        'areaName': '孟村回族自治县'
      }, {
        'areaCode': '130981',
        'areaName': '泊头市'
      }, {
        'areaCode': '130982',
        'areaName': '任丘市'
      }, {
        'areaCode': '130983',
        'areaName': '黄骅市'
      }, {
        'areaCode': '130984',
        'areaName': '河间市'
      }]
    }, {
      'cityCode': '131000',
      'cityName': '廊坊市',
      'mallAreaList': [{
        'areaCode': '131002',
        'areaName': '安次区'
      }, {
        'areaCode': '131003',
        'areaName': '广阳区'
      }, {
        'areaCode': '131022',
        'areaName': '固安县'
      }, {
        'areaCode': '131023',
        'areaName': '永清县'
      }, {
        'areaCode': '131024',
        'areaName': '香河县'
      }, {
        'areaCode': '131025',
        'areaName': '大城县'
      }, {
        'areaCode': '131026',
        'areaName': '文安县'
      }, {
        'areaCode': '131028',
        'areaName': '大厂回族自治县'
      }, {
        'areaCode': '131081',
        'areaName': '霸州市'
      }, {
        'areaCode': '131082',
        'areaName': '三河市'
      }]
    }, {
      'cityCode': '131100',
      'cityName': '衡水市',
      'mallAreaList': [{
        'areaCode': '131102',
        'areaName': '桃城区'
      }, {
        'areaCode': '131121',
        'areaName': '枣强县'
      }, {
        'areaCode': '131122',
        'areaName': '武邑县'
      }, {
        'areaCode': '131123',
        'areaName': '武强县'
      }, {
        'areaCode': '131124',
        'areaName': '饶阳县'
      }, {
        'areaCode': '131125',
        'areaName': '安平县'
      }, {
        'areaCode': '131126',
        'areaName': '故城县'
      }, {
        'areaCode': '131127',
        'areaName': '景县'
      }, {
        'areaCode': '131128',
        'areaName': '阜城县'
      }, {
        'areaCode': '131181',
        'areaName': '冀州市'
      }, {
        'areaCode': '131182',
        'areaName': '深州市'
      }]
    }]
  }, {
    'provinceCode': '140000',
    'provinceName': '山西省',
    'mallCityList': [{
      'cityCode': '140100',
      'cityName': '太原市',
      'mallAreaList': [{
        'areaCode': '140105',
        'areaName': '小店区'
      }, {
        'areaCode': '140106',
        'areaName': '迎泽区'
      }, {
        'areaCode': '140107',
        'areaName': '杏花岭区'
      }, {
        'areaCode': '140108',
        'areaName': '尖草坪区'
      }, {
        'areaCode': '140109',
        'areaName': '万柏林区'
      }, {
        'areaCode': '140110',
        'areaName': '晋源区'
      }, {
        'areaCode': '140121',
        'areaName': '清徐县'
      }, {
        'areaCode': '140122',
        'areaName': '阳曲县'
      }, {
        'areaCode': '140123',
        'areaName': '娄烦县'
      }, {
        'areaCode': '140181',
        'areaName': '古交市'
      }]
    }, {
      'cityCode': '140200',
      'cityName': '大同市',
      'mallAreaList': [{
        'areaCode': '140202',
        'areaName': '城区'
      }, {
        'areaCode': '140203',
        'areaName': '矿区'
      }, {
        'areaCode': '140211',
        'areaName': '南郊区'
      }, {
        'areaCode': '140212',
        'areaName': '新荣区'
      }, {
        'areaCode': '140221',
        'areaName': '阳高县'
      }, {
        'areaCode': '140222',
        'areaName': '天镇县'
      }, {
        'areaCode': '140223',
        'areaName': '广灵县'
      }, {
        'areaCode': '140224',
        'areaName': '灵丘县'
      }, {
        'areaCode': '140225',
        'areaName': '浑源县'
      }, {
        'areaCode': '140226',
        'areaName': '左云县'
      }, {
        'areaCode': '140227',
        'areaName': '大同县'
      }]
    }, {
      'cityCode': '140300',
      'cityName': '阳泉市',
      'mallAreaList': [{
        'areaCode': '140302',
        'areaName': '城区'
      }, {
        'areaCode': '140303',
        'areaName': '矿区'
      }, {
        'areaCode': '140311',
        'areaName': '郊区'
      }, {
        'areaCode': '140321',
        'areaName': '平定县'
      }, {
        'areaCode': '140322',
        'areaName': '盂县'
      }]
    }, {
      'cityCode': '140400',
      'cityName': '长治市',
      'mallAreaList': [{
        'areaCode': '140402',
        'areaName': '城区'
      }, {
        'areaCode': '140411',
        'areaName': '郊区'
      }, {
        'areaCode': '140421',
        'areaName': '长治县'
      }, {
        'areaCode': '140423',
        'areaName': '襄垣县'
      }, {
        'areaCode': '140424',
        'areaName': '屯留县'
      }, {
        'areaCode': '140425',
        'areaName': '平顺县'
      }, {
        'areaCode': '140426',
        'areaName': '黎城县'
      }, {
        'areaCode': '140427',
        'areaName': '壶关县'
      }, {
        'areaCode': '140428',
        'areaName': '长子县'
      }, {
        'areaCode': '140429',
        'areaName': '武乡县'
      }, {
        'areaCode': '140430',
        'areaName': '沁县'
      }, {
        'areaCode': '140431',
        'areaName': '沁源县'
      }, {
        'areaCode': '140481',
        'areaName': '潞城市'
      }]
    }, {
      'cityCode': '140500',
      'cityName': '晋城市',
      'mallAreaList': [{
        'areaCode': '140502',
        'areaName': '城区'
      }, {
        'areaCode': '140521',
        'areaName': '沁水县'
      }, {
        'areaCode': '140522',
        'areaName': '阳城县'
      }, {
        'areaCode': '140524',
        'areaName': '陵川县'
      }, {
        'areaCode': '140525',
        'areaName': '泽州县'
      }, {
        'areaCode': '140581',
        'areaName': '高平市'
      }]
    }, {
      'cityCode': '140600',
      'cityName': '朔州市',
      'mallAreaList': [{
        'areaCode': '140602',
        'areaName': '朔城区'
      }, {
        'areaCode': '140603',
        'areaName': '平鲁区'
      }, {
        'areaCode': '140621',
        'areaName': '山阴县'
      }, {
        'areaCode': '140622',
        'areaName': '应县'
      }, {
        'areaCode': '140623',
        'areaName': '右玉县'
      }, {
        'areaCode': '140624',
        'areaName': '怀仁县'
      }]
    }, {
      'cityCode': '140700',
      'cityName': '晋中市',
      'mallAreaList': [{
        'areaCode': '140702',
        'areaName': '榆次区'
      }, {
        'areaCode': '140721',
        'areaName': '榆社县'
      }, {
        'areaCode': '140722',
        'areaName': '左权县'
      }, {
        'areaCode': '140723',
        'areaName': '和顺县'
      }, {
        'areaCode': '140724',
        'areaName': '昔阳县'
      }, {
        'areaCode': '140725',
        'areaName': '寿阳县'
      }, {
        'areaCode': '140726',
        'areaName': '太谷县'
      }, {
        'areaCode': '140727',
        'areaName': '祁县'
      }, {
        'areaCode': '140728',
        'areaName': '平遥县'
      }, {
        'areaCode': '140729',
        'areaName': '灵石县'
      }, {
        'areaCode': '140781',
        'areaName': '介休市'
      }]
    }, {
      'cityCode': '140800',
      'cityName': '运城市',
      'mallAreaList': [{
        'areaCode': '140802',
        'areaName': '盐湖区'
      }, {
        'areaCode': '140821',
        'areaName': '临猗县'
      }, {
        'areaCode': '140822',
        'areaName': '万荣县'
      }, {
        'areaCode': '140823',
        'areaName': '闻喜县'
      }, {
        'areaCode': '140824',
        'areaName': '稷山县'
      }, {
        'areaCode': '140825',
        'areaName': '新绛县'
      }, {
        'areaCode': '140826',
        'areaName': '绛县'
      }, {
        'areaCode': '140827',
        'areaName': '垣曲县'
      }, {
        'areaCode': '140828',
        'areaName': '夏县'
      }, {
        'areaCode': '140829',
        'areaName': '平陆县'
      }, {
        'areaCode': '140830',
        'areaName': '芮城县'
      }, {
        'areaCode': '140881',
        'areaName': '永济市'
      }, {
        'areaCode': '140882',
        'areaName': '河津市'
      }]
    }, {
      'cityCode': '140900',
      'cityName': '忻州市',
      'mallAreaList': [{
        'areaCode': '140902',
        'areaName': '忻府区'
      }, {
        'areaCode': '140921',
        'areaName': '定襄县'
      }, {
        'areaCode': '140922',
        'areaName': '五台县'
      }, {
        'areaCode': '140923',
        'areaName': '代县'
      }, {
        'areaCode': '140924',
        'areaName': '繁峙县'
      }, {
        'areaCode': '140925',
        'areaName': '宁武县'
      }, {
        'areaCode': '140926',
        'areaName': '静乐县'
      }, {
        'areaCode': '140927',
        'areaName': '神池县'
      }, {
        'areaCode': '140928',
        'areaName': '五寨县'
      }, {
        'areaCode': '140929',
        'areaName': '岢岚县'
      }, {
        'areaCode': '140930',
        'areaName': '河曲县'
      }, {
        'areaCode': '140931',
        'areaName': '保德县'
      }, {
        'areaCode': '140932',
        'areaName': '偏关县'
      }, {
        'areaCode': '140981',
        'areaName': '原平市'
      }]
    }, {
      'cityCode': '141000',
      'cityName': '临汾市',
      'mallAreaList': [{
        'areaCode': '141002',
        'areaName': '尧都区'
      }, {
        'areaCode': '141021',
        'areaName': '曲沃县'
      }, {
        'areaCode': '141022',
        'areaName': '翼城县'
      }, {
        'areaCode': '141023',
        'areaName': '襄汾县'
      }, {
        'areaCode': '141024',
        'areaName': '洪洞县'
      }, {
        'areaCode': '141025',
        'areaName': '古县'
      }, {
        'areaCode': '141026',
        'areaName': '安泽县'
      }, {
        'areaCode': '141027',
        'areaName': '浮山县'
      }, {
        'areaCode': '141028',
        'areaName': '吉县'
      }, {
        'areaCode': '141029',
        'areaName': '乡宁县'
      }, {
        'areaCode': '141030',
        'areaName': '大宁县'
      }, {
        'areaCode': '141031',
        'areaName': '隰县'
      }, {
        'areaCode': '141032',
        'areaName': '永和县'
      }, {
        'areaCode': '141033',
        'areaName': '蒲县'
      }, {
        'areaCode': '141034',
        'areaName': '汾西县'
      }, {
        'areaCode': '141081',
        'areaName': '侯马市'
      }, {
        'areaCode': '141082',
        'areaName': '霍州市'
      }]
    }, {
      'cityCode': '141100',
      'cityName': '吕梁市',
      'mallAreaList': [{
        'areaCode': '141102',
        'areaName': '离石区'
      }, {
        'areaCode': '141121',
        'areaName': '文水县'
      }, {
        'areaCode': '141122',
        'areaName': '交城县'
      }, {
        'areaCode': '141123',
        'areaName': '兴县'
      }, {
        'areaCode': '141124',
        'areaName': '临县'
      }, {
        'areaCode': '141125',
        'areaName': '柳林县'
      }, {
        'areaCode': '141126',
        'areaName': '石楼县'
      }, {
        'areaCode': '141127',
        'areaName': '岚县'
      }, {
        'areaCode': '141128',
        'areaName': '方山县'
      }, {
        'areaCode': '141129',
        'areaName': '中阳县'
      }, {
        'areaCode': '141130',
        'areaName': '交口县'
      }, {
        'areaCode': '141181',
        'areaName': '孝义市'
      }, {
        'areaCode': '141182',
        'areaName': '汾阳市'
      }]
    }]
  }, {
    'provinceCode': '150000',
    'provinceName': '内蒙古自治区',
    'mallCityList': [{
      'cityCode': '150100',
      'cityName': '呼和浩特市',
      'mallAreaList': [{
        'areaCode': '150102',
        'areaName': '新城区'
      }, {
        'areaCode': '150103',
        'areaName': '回民区'
      }, {
        'areaCode': '150104',
        'areaName': '玉泉区'
      }, {
        'areaCode': '150105',
        'areaName': '赛罕区'
      }, {
        'areaCode': '150121',
        'areaName': '土默特左旗'
      }, {
        'areaCode': '150122',
        'areaName': '托克托县'
      }, {
        'areaCode': '150123',
        'areaName': '和林格尔县'
      }, {
        'areaCode': '150124',
        'areaName': '清水河县'
      }, {
        'areaCode': '150125',
        'areaName': '武川县'
      }]
    }, {
      'cityCode': '150200',
      'cityName': '包头市',
      'mallAreaList': [{
        'areaCode': '150202',
        'areaName': '东河区'
      }, {
        'areaCode': '150208',
        'areaName': '稀土高新区'
      }, {
        'areaCode': '150203',
        'areaName': '昆都仑区'
      }, {
        'areaCode': '150204',
        'areaName': '青山区'
      }, {
        'areaCode': '150205',
        'areaName': '石拐区'
      }, {
        'areaCode': '150206',
        'areaName': '白云鄂博矿区'
      }, {
        'areaCode': '150207',
        'areaName': '九原区'
      }, {
        'areaCode': '150221',
        'areaName': '土默特右旗'
      }, {
        'areaCode': '150222',
        'areaName': '固阳县'
      }, {
        'areaCode': '150223',
        'areaName': '达尔罕茂明安联合旗'
      }]
    }, {
      'cityCode': '150300',
      'cityName': '乌海市',
      'mallAreaList': [{
        'areaCode': '150302',
        'areaName': '海勃湾区'
      }, {
        'areaCode': '150303',
        'areaName': '海南区'
      }, {
        'areaCode': '150304',
        'areaName': '乌达区'
      }]
    }, {
      'cityCode': '150400',
      'cityName': '赤峰市',
      'mallAreaList': [{
        'areaCode': '150402',
        'areaName': '红山区'
      }, {
        'areaCode': '150403',
        'areaName': '元宝山区'
      }, {
        'areaCode': '150404',
        'areaName': '松山区'
      }, {
        'areaCode': '150421',
        'areaName': '阿鲁科尔沁旗'
      }, {
        'areaCode': '150422',
        'areaName': '巴林左旗'
      }, {
        'areaCode': '150423',
        'areaName': '巴林右旗'
      }, {
        'areaCode': '150424',
        'areaName': '林西县'
      }, {
        'areaCode': '150425',
        'areaName': '克什克腾旗'
      }, {
        'areaCode': '150426',
        'areaName': '翁牛特旗'
      }, {
        'areaCode': '150428',
        'areaName': '喀喇沁旗'
      }, {
        'areaCode': '150429',
        'areaName': '宁城县'
      }, {
        'areaCode': '150430',
        'areaName': '敖汉旗'
      }]
    }, {
      'cityCode': '150500',
      'cityName': '通辽市',
      'mallAreaList': [{
        'areaCode': '150502',
        'areaName': '科尔沁区'
      }, {
        'areaCode': '150521',
        'areaName': '科尔沁左翼中旗'
      }, {
        'areaCode': '150522',
        'areaName': '科尔沁左翼后旗'
      }, {
        'areaCode': '150523',
        'areaName': '开鲁县'
      }, {
        'areaCode': '150524',
        'areaName': '库伦旗'
      }, {
        'areaCode': '150525',
        'areaName': '奈曼旗'
      }, {
        'areaCode': '150526',
        'areaName': '扎鲁特旗'
      }, {
        'areaCode': '150581',
        'areaName': '霍林郭勒市'
      }]
    }, {
      'cityCode': '150600',
      'cityName': '鄂尔多斯市',
      'mallAreaList': [{
        'areaCode': '150602',
        'areaName': '东胜区'
      }, {
        'areaCode': '150621',
        'areaName': '达拉特旗'
      }, {
        'areaCode': '150622',
        'areaName': '准格尔旗'
      }, {
        'areaCode': '150623',
        'areaName': '鄂托克前旗'
      }, {
        'areaCode': '150624',
        'areaName': '鄂托克旗'
      }, {
        'areaCode': '150625',
        'areaName': '杭锦旗'
      }, {
        'areaCode': '150626',
        'areaName': '乌审旗'
      }, {
        'areaCode': '150627',
        'areaName': '伊金霍洛旗'
      }]
    }, {
      'cityCode': '150700',
      'cityName': '呼伦贝尔市',
      'mallAreaList': [{
        'areaCode': '150702',
        'areaName': '海拉尔区'
      }, {
        'areaCode': '150703',
        'areaName': '扎赉诺尔区'
      }, {
        'areaCode': '150721',
        'areaName': '阿荣旗'
      }, {
        'areaCode': '150722',
        'areaName': '莫力达瓦达斡尔族自治旗'
      }, {
        'areaCode': '150723',
        'areaName': '鄂伦春自治旗'
      }, {
        'areaCode': '150724',
        'areaName': '鄂温克族自治旗'
      }, {
        'areaCode': '150725',
        'areaName': '陈巴尔虎旗'
      }, {
        'areaCode': '150726',
        'areaName': '新巴尔虎左旗'
      }, {
        'areaCode': '150727',
        'areaName': '新巴尔虎右旗'
      }, {
        'areaCode': '150781',
        'areaName': '满洲里市'
      }, {
        'areaCode': '150782',
        'areaName': '牙克石市'
      }, {
        'areaCode': '150783',
        'areaName': '扎兰屯市'
      }, {
        'areaCode': '150784',
        'areaName': '额尔古纳市'
      }, {
        'areaCode': '150785',
        'areaName': '根河市'
      }]
    }, {
      'cityCode': '150800',
      'cityName': '巴彦淖尔市',
      'mallAreaList': [{
        'areaCode': '150802',
        'areaName': '临河区'
      }, {
        'areaCode': '150821',
        'areaName': '五原县'
      }, {
        'areaCode': '150822',
        'areaName': '磴口县'
      }, {
        'areaCode': '150823',
        'areaName': '乌拉特前旗'
      }, {
        'areaCode': '150824',
        'areaName': '乌拉特中旗'
      }, {
        'areaCode': '150825',
        'areaName': '乌拉特后旗'
      }, {
        'areaCode': '150826',
        'areaName': '杭锦后旗'
      }]
    }, {
      'cityCode': '150900',
      'cityName': '乌兰察布市',
      'mallAreaList': [{
        'areaCode': '150902',
        'areaName': '集宁区'
      }, {
        'areaCode': '150921',
        'areaName': '卓资县'
      }, {
        'areaCode': '150922',
        'areaName': '化德县'
      }, {
        'areaCode': '150923',
        'areaName': '商都县'
      }, {
        'areaCode': '150924',
        'areaName': '兴和县'
      }, {
        'areaCode': '150925',
        'areaName': '凉城县'
      }, {
        'areaCode': '150926',
        'areaName': '察哈尔右翼前旗'
      }, {
        'areaCode': '150927',
        'areaName': '察哈尔右翼中旗'
      }, {
        'areaCode': '150928',
        'areaName': '察哈尔右翼后旗'
      }, {
        'areaCode': '150929',
        'areaName': '四子王旗'
      }, {
        'areaCode': '150981',
        'areaName': '丰镇市'
      }]
    }, {
      'cityCode': '152200',
      'cityName': '兴安盟',
      'mallAreaList': [{
        'areaCode': '152201',
        'areaName': '乌兰浩特市'
      }, {
        'areaCode': '152202',
        'areaName': '阿尔山市'
      }, {
        'areaCode': '152221',
        'areaName': '科尔沁右翼前旗'
      }, {
        'areaCode': '152222',
        'areaName': '科尔沁右翼中旗'
      }, {
        'areaCode': '152223',
        'areaName': '扎赉特旗'
      }, {
        'areaCode': '152224',
        'areaName': '突泉县'
      }]
    }, {
      'cityCode': '152500',
      'cityName': '锡林郭勒盟',
      'mallAreaList': [{
        'areaCode': '152501',
        'areaName': '二连浩特市'
      }, {
        'areaCode': '152502',
        'areaName': '锡林浩特市'
      }, {
        'areaCode': '152522',
        'areaName': '阿巴嘎旗'
      }, {
        'areaCode': '152523',
        'areaName': '苏尼特左旗'
      }, {
        'areaCode': '152524',
        'areaName': '苏尼特右旗'
      }, {
        'areaCode': '152525',
        'areaName': '东乌珠穆沁旗'
      }, {
        'areaCode': '152526',
        'areaName': '西乌珠穆沁旗'
      }, {
        'areaCode': '152527',
        'areaName': '太仆寺旗'
      }, {
        'areaCode': '152528',
        'areaName': '镶黄旗'
      }, {
        'areaCode': '152529',
        'areaName': '正镶白旗'
      }, {
        'areaCode': '152530',
        'areaName': '正蓝旗'
      }, {
        'areaCode': '152531',
        'areaName': '多伦县'
      }]
    }, {
      'cityCode': '152900',
      'cityName': '阿拉善盟',
      'mallAreaList': [{
        'areaCode': '152921',
        'areaName': '阿拉善左旗'
      }, {
        'areaCode': '152922',
        'areaName': '阿拉善右旗'
      }, {
        'areaCode': '152923',
        'areaName': '额济纳旗'
      }]
    }]
  }, {
    'provinceCode': '210000',
    'provinceName': '辽宁省',
    'mallCityList': [{
      'cityCode': '210100',
      'cityName': '沈阳市',
      'mallAreaList': [{
        'areaCode': '210102',
        'areaName': '和平区'
      }, {
        'areaCode': '210103',
        'areaName': '沈河区'
      }, {
        'areaCode': '210104',
        'areaName': '大东区'
      }, {
        'areaCode': '210105',
        'areaName': '皇姑区'
      }, {
        'areaCode': '210106',
        'areaName': '铁西区'
      }, {
        'areaCode': '210111',
        'areaName': '苏家屯区'
      }, {
        'areaCode': '210112',
        'areaName': '浑南区'
      }, {
        'areaCode': '210113',
        'areaName': '沈北新区'
      }, {
        'areaCode': '210114',
        'areaName': '于洪区'
      }, {
        'areaCode': '210122',
        'areaName': '辽中县'
      }, {
        'areaCode': '210123',
        'areaName': '康平县'
      }, {
        'areaCode': '210124',
        'areaName': '法库县'
      }, {
        'areaCode': '210181',
        'areaName': '新民市'
      }]
    }, {
      'cityCode': '210200',
      'cityName': '大连市',
      'mallAreaList': [{
        'areaCode': '210202',
        'areaName': '中山区'
      }, {
        'areaCode': '210203',
        'areaName': '西岗区'
      }, {
        'areaCode': '210204',
        'areaName': '沙河口区'
      }, {
        'areaCode': '210211',
        'areaName': '甘井子区'
      }, {
        'areaCode': '210212',
        'areaName': '旅顺口区'
      }, {
        'areaCode': '210213',
        'areaName': '金州区'
      }, {
        'areaCode': '210224',
        'areaName': '长海县'
      }, {
        'areaCode': '210281',
        'areaName': '瓦房店市'
      }, {
        'areaCode': '210282',
        'areaName': '普兰店市'
      }, {
        'areaCode': '210283',
        'areaName': '庄河市'
      }]
    }, {
      'cityCode': '210300',
      'cityName': '鞍山市',
      'mallAreaList': [{
        'areaCode': '210302',
        'areaName': '铁东区'
      }, {
        'areaCode': '210303',
        'areaName': '铁西区'
      }, {
        'areaCode': '210304',
        'areaName': '立山区'
      }, {
        'areaCode': '210311',
        'areaName': '千山区'
      }, {
        'areaCode': '210321',
        'areaName': '台安县'
      }, {
        'areaCode': '210323',
        'areaName': '岫岩满族自治县'
      }, {
        'areaCode': '210381',
        'areaName': '海城市'
      }]
    }, {
      'cityCode': '210400',
      'cityName': '抚顺市',
      'mallAreaList': [{
        'areaCode': '210402',
        'areaName': '新抚区'
      }, {
        'areaCode': '210403',
        'areaName': '东洲区'
      }, {
        'areaCode': '210404',
        'areaName': '望花区'
      }, {
        'areaCode': '210411',
        'areaName': '顺城区'
      }, {
        'areaCode': '210421',
        'areaName': '抚顺县'
      }, {
        'areaCode': '210422',
        'areaName': '新宾满族自治县'
      }, {
        'areaCode': '210423',
        'areaName': '清原满族自治县'
      }]
    }, {
      'cityCode': '210500',
      'cityName': '本溪市',
      'mallAreaList': [{
        'areaCode': '210502',
        'areaName': '平山区'
      }, {
        'areaCode': '210503',
        'areaName': '溪湖区'
      }, {
        'areaCode': '210504',
        'areaName': '明山区'
      }, {
        'areaCode': '210505',
        'areaName': '南芬区'
      }, {
        'areaCode': '210521',
        'areaName': '本溪满族自治县'
      }, {
        'areaCode': '210522',
        'areaName': '桓仁满族自治县'
      }]
    }, {
      'cityCode': '210600',
      'cityName': '丹东市',
      'mallAreaList': [{
        'areaCode': '210602',
        'areaName': '元宝区'
      }, {
        'areaCode': '210603',
        'areaName': '振兴区'
      }, {
        'areaCode': '210604',
        'areaName': '振安区'
      }, {
        'areaCode': '210624',
        'areaName': '宽甸满族自治县'
      }, {
        'areaCode': '210681',
        'areaName': '东港市'
      }, {
        'areaCode': '210682',
        'areaName': '凤城市'
      }]
    }, {
      'cityCode': '210700',
      'cityName': '锦州市',
      'mallAreaList': [{
        'areaCode': '210702',
        'areaName': '古塔区'
      }, {
        'areaCode': '210703',
        'areaName': '凌河区'
      }, {
        'areaCode': '210711',
        'areaName': '太和区'
      }, {
        'areaCode': '210726',
        'areaName': '黑山县'
      }, {
        'areaCode': '210727',
        'areaName': '义县'
      }, {
        'areaCode': '210781',
        'areaName': '凌海市'
      }, {
        'areaCode': '210782',
        'areaName': '北镇市'
      }]
    }, {
      'cityCode': '210800',
      'cityName': '营口市',
      'mallAreaList': [{
        'areaCode': '210802',
        'areaName': '站前区'
      }, {
        'areaCode': '210803',
        'areaName': '西市区'
      }, {
        'areaCode': '210804',
        'areaName': '鲅鱼圈区'
      }, {
        'areaCode': '210811',
        'areaName': '老边区'
      }, {
        'areaCode': '210881',
        'areaName': '盖州市'
      }, {
        'areaCode': '210882',
        'areaName': '大石桥市'
      }]
    }, {
      'cityCode': '210900',
      'cityName': '阜新市',
      'mallAreaList': [{
        'areaCode': '210902',
        'areaName': '海州区'
      }, {
        'areaCode': '210903',
        'areaName': '新邱区'
      }, {
        'areaCode': '210904',
        'areaName': '太平区'
      }, {
        'areaCode': '210905',
        'areaName': '清河门区'
      }, {
        'areaCode': '210911',
        'areaName': '细河区'
      }, {
        'areaCode': '210921',
        'areaName': '阜新蒙古族自治县'
      }, {
        'areaCode': '210922',
        'areaName': '彰武县'
      }]
    }, {
      'cityCode': '211000',
      'cityName': '辽阳市',
      'mallAreaList': [{
        'areaCode': '211002',
        'areaName': '白塔区'
      }, {
        'areaCode': '211003',
        'areaName': '文圣区'
      }, {
        'areaCode': '211004',
        'areaName': '宏伟区'
      }, {
        'areaCode': '211005',
        'areaName': '弓长岭区'
      }, {
        'areaCode': '211011',
        'areaName': '太子河区'
      }, {
        'areaCode': '211021',
        'areaName': '辽阳县'
      }, {
        'areaCode': '211081',
        'areaName': '灯塔市'
      }]
    }, {
      'cityCode': '211100',
      'cityName': '盘锦市',
      'mallAreaList': [{
        'areaCode': '211102',
        'areaName': '双台子区'
      }, {
        'areaCode': '211103',
        'areaName': '兴隆台区'
      }, {
        'areaCode': '211121',
        'areaName': '大洼县'
      }, {
        'areaCode': '211122',
        'areaName': '盘山县'
      }]
    }, {
      'cityCode': '211200',
      'cityName': '铁岭市',
      'mallAreaList': [{
        'areaCode': '211202',
        'areaName': '银州区'
      }, {
        'areaCode': '211204',
        'areaName': '清河区'
      }, {
        'areaCode': '211221',
        'areaName': '铁岭县'
      }, {
        'areaCode': '211223',
        'areaName': '西丰县'
      }, {
        'areaCode': '211224',
        'areaName': '昌图县'
      }, {
        'areaCode': '211281',
        'areaName': '调兵山市'
      }, {
        'areaCode': '211282',
        'areaName': '开原市'
      }]
    }, {
      'cityCode': '211300',
      'cityName': '朝阳市',
      'mallAreaList': [{
        'areaCode': '211302',
        'areaName': '双塔区'
      }, {
        'areaCode': '211303',
        'areaName': '龙城区'
      }, {
        'areaCode': '211321',
        'areaName': '朝阳县'
      }, {
        'areaCode': '211322',
        'areaName': '建平县'
      }, {
        'areaCode': '211324',
        'areaName': '喀喇沁左翼蒙古族自治县'
      }, {
        'areaCode': '211381',
        'areaName': '北票市'
      }, {
        'areaCode': '211382',
        'areaName': '凌源市'
      }]
    }, {
      'cityCode': '211400',
      'cityName': '葫芦岛市',
      'mallAreaList': [{
        'areaCode': '211402',
        'areaName': '连山区'
      }, {
        'areaCode': '211403',
        'areaName': '龙港区'
      }, {
        'areaCode': '211404',
        'areaName': '南票区'
      }, {
        'areaCode': '211421',
        'areaName': '绥中县'
      }, {
        'areaCode': '211422',
        'areaName': '建昌县'
      }, {
        'areaCode': '211481',
        'areaName': '兴城市'
      }]
    }]
  }, {
    'provinceCode': '220000',
    'provinceName': '吉林省',
    'mallCityList': [{
      'cityCode': '220100',
      'cityName': '长春市',
      'mallAreaList': [{
        'areaCode': '220102',
        'areaName': '南关区'
      }, {
        'areaCode': '220103',
        'areaName': '宽城区'
      }, {
        'areaCode': '220104',
        'areaName': '朝阳区'
      }, {
        'areaCode': '220105',
        'areaName': '二道区'
      }, {
        'areaCode': '220106',
        'areaName': '绿园区'
      }, {
        'areaCode': '220112',
        'areaName': '双阳区'
      }, {
        'areaCode': '220113',
        'areaName': '九台区'
      }, {
        'areaCode': '220122',
        'areaName': '农安县'
      }, {
        'areaCode': '220182',
        'areaName': '榆树市'
      }, {
        'areaCode': '220183',
        'areaName': '德惠市'
      }]
    }, {
      'cityCode': '220200',
      'cityName': '吉林市',
      'mallAreaList': [{
        'areaCode': '220202',
        'areaName': '昌邑区'
      }, {
        'areaCode': '220203',
        'areaName': '龙潭区'
      }, {
        'areaCode': '220204',
        'areaName': '船营区'
      }, {
        'areaCode': '220211',
        'areaName': '丰满区'
      }, {
        'areaCode': '220221',
        'areaName': '永吉县'
      }, {
        'areaCode': '220281',
        'areaName': '蛟河市'
      }, {
        'areaCode': '220282',
        'areaName': '桦甸市'
      }, {
        'areaCode': '220283',
        'areaName': '舒兰市'
      }, {
        'areaCode': '220284',
        'areaName': '磐石市'
      }]
    }, {
      'cityCode': '220300',
      'cityName': '四平市',
      'mallAreaList': [{
        'areaCode': '220302',
        'areaName': '铁西区'
      }, {
        'areaCode': '220303',
        'areaName': '铁东区'
      }, {
        'areaCode': '220322',
        'areaName': '梨树县'
      }, {
        'areaCode': '220323',
        'areaName': '伊通满族自治县'
      }, {
        'areaCode': '220381',
        'areaName': '公主岭市'
      }, {
        'areaCode': '220382',
        'areaName': '双辽市'
      }]
    }, {
      'cityCode': '220400',
      'cityName': '辽源市',
      'mallAreaList': [{
        'areaCode': '220402',
        'areaName': '龙山区'
      }, {
        'areaCode': '220403',
        'areaName': '西安区'
      }, {
        'areaCode': '220421',
        'areaName': '东丰县'
      }, {
        'areaCode': '220422',
        'areaName': '东辽县'
      }]
    }, {
      'cityCode': '220500',
      'cityName': '通化市',
      'mallAreaList': [{
        'areaCode': '220502',
        'areaName': '东昌区'
      }, {
        'areaCode': '220503',
        'areaName': '二道江区'
      }, {
        'areaCode': '220521',
        'areaName': '通化县'
      }, {
        'areaCode': '220523',
        'areaName': '辉南县'
      }, {
        'areaCode': '220524',
        'areaName': '柳河县'
      }, {
        'areaCode': '220581',
        'areaName': '梅河口市'
      }, {
        'areaCode': '220582',
        'areaName': '集安市'
      }]
    }, {
      'cityCode': '220600',
      'cityName': '白山市',
      'mallAreaList': [{
        'areaCode': '220602',
        'areaName': '浑江区'
      }, {
        'areaCode': '220605',
        'areaName': '江源区'
      }, {
        'areaCode': '220621',
        'areaName': '抚松县'
      }, {
        'areaCode': '220622',
        'areaName': '靖宇县'
      }, {
        'areaCode': '220623',
        'areaName': '长白朝鲜族自治县'
      }, {
        'areaCode': '220681',
        'areaName': '临江市'
      }]
    }, {
      'cityCode': '220700',
      'cityName': '松原市',
      'mallAreaList': [{
        'areaCode': '220702',
        'areaName': '宁江区'
      }, {
        'areaCode': '220721',
        'areaName': '前郭尔罗斯蒙古族自治县'
      }, {
        'areaCode': '220722',
        'areaName': '长岭县'
      }, {
        'areaCode': '220723',
        'areaName': '乾安县'
      }, {
        'areaCode': '220781',
        'areaName': '扶余市'
      }]
    }, {
      'cityCode': '220800',
      'cityName': '白城市',
      'mallAreaList': [{
        'areaCode': '220802',
        'areaName': '洮北区'
      }, {
        'areaCode': '220821',
        'areaName': '镇赉县'
      }, {
        'areaCode': '220822',
        'areaName': '通榆县'
      }, {
        'areaCode': '220881',
        'areaName': '洮南市'
      }, {
        'areaCode': '220882',
        'areaName': '大安市'
      }]
    }, {
      'cityCode': '222400',
      'cityName': '延边朝鲜族自治州',
      'mallAreaList': [{
        'areaCode': '222401',
        'areaName': '延吉市'
      }, {
        'areaCode': '222402',
        'areaName': '图们市'
      }, {
        'areaCode': '222403',
        'areaName': '敦化市'
      }, {
        'areaCode': '222404',
        'areaName': '珲春市'
      }, {
        'areaCode': '222405',
        'areaName': '龙井市'
      }, {
        'areaCode': '222406',
        'areaName': '和龙市'
      }, {
        'areaCode': '222424',
        'areaName': '汪清县'
      }, {
        'areaCode': '222426',
        'areaName': '安图县'
      }]
    }]
  }, {
    'provinceCode': '230000',
    'provinceName': '黑龙江省',
    'mallCityList': [{
      'cityCode': '230100',
      'cityName': '哈尔滨市',
      'mallAreaList': [{
        'areaCode': '230102',
        'areaName': '道里区'
      }, {
        'areaCode': '230103',
        'areaName': '南岗区'
      }, {
        'areaCode': '230104',
        'areaName': '道外区'
      }, {
        'areaCode': '230108',
        'areaName': '平房区'
      }, {
        'areaCode': '230109',
        'areaName': '松北区'
      }, {
        'areaCode': '230110',
        'areaName': '香坊区'
      }, {
        'areaCode': '230111',
        'areaName': '呼兰区'
      }, {
        'areaCode': '230112',
        'areaName': '阿城区'
      }, {
        'areaCode': '230123',
        'areaName': '依兰县'
      }, {
        'areaCode': '230124',
        'areaName': '方正县'
      }, {
        'areaCode': '230125',
        'areaName': '宾县'
      }, {
        'areaCode': '230126',
        'areaName': '巴彦县'
      }, {
        'areaCode': '230127',
        'areaName': '木兰县'
      }, {
        'areaCode': '230128',
        'areaName': '通河县'
      }, {
        'areaCode': '230129',
        'areaName': '延寿县'
      }, {
        'areaCode': '230182',
        'areaName': '双城区'
      }, {
        'areaCode': '230183',
        'areaName': '尚志区'
      }, {
        'areaCode': '230184',
        'areaName': '五常区'
      }]
    }, {
      'cityCode': '230200',
      'cityName': '齐齐哈尔市',
      'mallAreaList': [{
        'areaCode': '230202',
        'areaName': '龙沙区'
      }, {
        'areaCode': '230203',
        'areaName': '建华区'
      }, {
        'areaCode': '230204',
        'areaName': '铁锋区'
      }, {
        'areaCode': '230205',
        'areaName': '昂昂溪区'
      }, {
        'areaCode': '230206',
        'areaName': '富拉尔基区'
      }, {
        'areaCode': '230207',
        'areaName': '碾子山区'
      }, {
        'areaCode': '230208',
        'areaName': '梅里斯达斡尔族区'
      }, {
        'areaCode': '230221',
        'areaName': '龙江县'
      }, {
        'areaCode': '230223',
        'areaName': '依安县'
      }, {
        'areaCode': '230224',
        'areaName': '泰来县'
      }, {
        'areaCode': '230225',
        'areaName': '甘南县'
      }, {
        'areaCode': '230227',
        'areaName': '富裕县'
      }, {
        'areaCode': '230229',
        'areaName': '克山县'
      }, {
        'areaCode': '230230',
        'areaName': '克东县'
      }, {
        'areaCode': '230231',
        'areaName': '拜泉县'
      }, {
        'areaCode': '230281',
        'areaName': '讷河市'
      }]
    }, {
      'cityCode': '230300',
      'cityName': '鸡西市',
      'mallAreaList': [{
        'areaCode': '230302',
        'areaName': '鸡冠区'
      }, {
        'areaCode': '230303',
        'areaName': '恒山区'
      }, {
        'areaCode': '230304',
        'areaName': '滴道区'
      }, {
        'areaCode': '230305',
        'areaName': '梨树区'
      }, {
        'areaCode': '230306',
        'areaName': '城子河区'
      }, {
        'areaCode': '230307',
        'areaName': '麻山区'
      }, {
        'areaCode': '230321',
        'areaName': '鸡东县'
      }, {
        'areaCode': '230381',
        'areaName': '虎林市'
      }, {
        'areaCode': '230382',
        'areaName': '密山市'
      }]
    }, {
      'cityCode': '230400',
      'cityName': '鹤岗市',
      'mallAreaList': [{
        'areaCode': '230402',
        'areaName': '向阳区'
      }, {
        'areaCode': '230403',
        'areaName': '工农区'
      }, {
        'areaCode': '230404',
        'areaName': '南山区'
      }, {
        'areaCode': '230405',
        'areaName': '兴安区'
      }, {
        'areaCode': '230406',
        'areaName': '东山区'
      }, {
        'areaCode': '230407',
        'areaName': '兴山区'
      }, {
        'areaCode': '230421',
        'areaName': '萝北县'
      }, {
        'areaCode': '230422',
        'areaName': '绥滨县'
      }]
    }, {
      'cityCode': '230500',
      'cityName': '双鸭山市',
      'mallAreaList': [{
        'areaCode': '230502',
        'areaName': '尖山区'
      }, {
        'areaCode': '230503',
        'areaName': '岭东区'
      }, {
        'areaCode': '230505',
        'areaName': '四方台区'
      }, {
        'areaCode': '230506',
        'areaName': '宝山区'
      }, {
        'areaCode': '230521',
        'areaName': '集贤县'
      }, {
        'areaCode': '230522',
        'areaName': '友谊县'
      }, {
        'areaCode': '230523',
        'areaName': '宝清县'
      }, {
        'areaCode': '230524',
        'areaName': '饶河县'
      }]
    }, {
      'cityCode': '230600',
      'cityName': '大庆市',
      'mallAreaList': [{
        'areaCode': '230602',
        'areaName': '萨尔图区'
      }, {
        'areaCode': '230603',
        'areaName': '龙凤区'
      }, {
        'areaCode': '230604',
        'areaName': '让胡路区'
      }, {
        'areaCode': '230605',
        'areaName': '红岗区'
      }, {
        'areaCode': '230606',
        'areaName': '大同区'
      }, {
        'areaCode': '230621',
        'areaName': '肇州县'
      }, {
        'areaCode': '230622',
        'areaName': '肇源县'
      }, {
        'areaCode': '230623',
        'areaName': '林甸县'
      }, {
        'areaCode': '230624',
        'areaName': '杜尔伯特蒙古族自治县'
      }]
    }, {
      'cityCode': '230700',
      'cityName': '伊春市',
      'mallAreaList': [{
        'areaCode': '230702',
        'areaName': '伊春区'
      }, {
        'areaCode': '230703',
        'areaName': '南岔区'
      }, {
        'areaCode': '230704',
        'areaName': '友好区'
      }, {
        'areaCode': '230705',
        'areaName': '西林区'
      }, {
        'areaCode': '230706',
        'areaName': '翠峦区'
      }, {
        'areaCode': '230707',
        'areaName': '新青区'
      }, {
        'areaCode': '230708',
        'areaName': '美溪区'
      }, {
        'areaCode': '230709',
        'areaName': '金山屯区'
      }, {
        'areaCode': '230710',
        'areaName': '五营区'
      }, {
        'areaCode': '230711',
        'areaName': '乌马河区'
      }, {
        'areaCode': '230712',
        'areaName': '汤旺河区'
      }, {
        'areaCode': '230713',
        'areaName': '带岭区'
      }, {
        'areaCode': '230714',
        'areaName': '乌伊岭区'
      }, {
        'areaCode': '230715',
        'areaName': '红星区'
      }, {
        'areaCode': '230716',
        'areaName': '上甘岭区'
      }, {
        'areaCode': '230722',
        'areaName': '嘉荫县'
      }, {
        'areaCode': '230781',
        'areaName': '铁力市'
      }]
    }, {
      'cityCode': '230800',
      'cityName': '佳木斯市',
      'mallAreaList': [{
        'areaCode': '230803',
        'areaName': '向阳区'
      }, {
        'areaCode': '230804',
        'areaName': '前进区'
      }, {
        'areaCode': '230805',
        'areaName': '东风区'
      }, {
        'areaCode': '230811',
        'areaName': '郊区'
      }, {
        'areaCode': '230822',
        'areaName': '桦南县'
      }, {
        'areaCode': '230826',
        'areaName': '桦川县'
      }, {
        'areaCode': '230828',
        'areaName': '汤原县'
      }, {
        'areaCode': '230833',
        'areaName': '抚远县'
      }, {
        'areaCode': '230881',
        'areaName': '同江市'
      }, {
        'areaCode': '230882',
        'areaName': '富锦市'
      }]
    }, {
      'cityCode': '230900',
      'cityName': '七台河市',
      'mallAreaList': [{
        'areaCode': '230902',
        'areaName': '新兴区'
      }, {
        'areaCode': '230903',
        'areaName': '桃山区'
      }, {
        'areaCode': '230904',
        'areaName': '茄子河区'
      }, {
        'areaCode': '230921',
        'areaName': '勃利县'
      }]
    }, {
      'cityCode': '231000',
      'cityName': '牡丹江市',
      'mallAreaList': [{
        'areaCode': '231002',
        'areaName': '东安区'
      }, {
        'areaCode': '231003',
        'areaName': '阳明区'
      }, {
        'areaCode': '231004',
        'areaName': '爱民区'
      }, {
        'areaCode': '231005',
        'areaName': '西安区'
      }, {
        'areaCode': '231024',
        'areaName': '东宁县'
      }, {
        'areaCode': '231025',
        'areaName': '林口县'
      }, {
        'areaCode': '231081',
        'areaName': '绥芬河市'
      }, {
        'areaCode': '231083',
        'areaName': '海林市'
      }, {
        'areaCode': '231084',
        'areaName': '宁安市'
      }, {
        'areaCode': '231085',
        'areaName': '穆棱市'
      }]
    }, {
      'cityCode': '231100',
      'cityName': '黑河市',
      'mallAreaList': [{
        'areaCode': '231102',
        'areaName': '爱辉区'
      }, {
        'areaCode': '231121',
        'areaName': '嫩江县'
      }, {
        'areaCode': '231123',
        'areaName': '逊克县'
      }, {
        'areaCode': '231124',
        'areaName': '孙吴县'
      }, {
        'areaCode': '231181',
        'areaName': '北安市'
      }, {
        'areaCode': '231182',
        'areaName': '五大连池市'
      }]
    }, {
      'cityCode': '231200',
      'cityName': '绥化市',
      'mallAreaList': [{
        'areaCode': '231202',
        'areaName': '北林区'
      }, {
        'areaCode': '231221',
        'areaName': '望奎县'
      }, {
        'areaCode': '231222',
        'areaName': '兰西县'
      }, {
        'areaCode': '231223',
        'areaName': '青冈县'
      }, {
        'areaCode': '231224',
        'areaName': '庆安县'
      }, {
        'areaCode': '231225',
        'areaName': '明水县'
      }, {
        'areaCode': '231226',
        'areaName': '绥棱县'
      }, {
        'areaCode': '231281',
        'areaName': '安达市'
      }, {
        'areaCode': '231282',
        'areaName': '肇东市'
      }, {
        'areaCode': '231283',
        'areaName': '海伦市'
      }]
    }, {
      'cityCode': '232700',
      'cityName': '大兴安岭地区',
      'mallAreaList': [{
        'areaCode': '232721',
        'areaName': '呼玛县'
      }, {
        'areaCode': '232722',
        'areaName': '塔河县'
      }, {
        'areaCode': '232723',
        'areaName': '漠河县'
      }]
    }]
  }, {
    'provinceCode': '310000',
    'provinceName': '上海市',
    'mallCityList': [{
      'cityCode': '310100',
      'cityName': '上海市',
      'mallAreaList': [{
        'areaCode': '310101',
        'areaName': '黄浦区'
      }, {
        'areaCode': '310104',
        'areaName': '徐汇区'
      }, {
        'areaCode': '310105',
        'areaName': '长宁区'
      }, {
        'areaCode': '310106',
        'areaName': '静安区'
      }, {
        'areaCode': '310107',
        'areaName': '普陀区'
      }, {
        'areaCode': '310108',
        'areaName': '闸北区'
      }, {
        'areaCode': '310109',
        'areaName': '虹口区'
      }, {
        'areaCode': '310110',
        'areaName': '杨浦区'
      }, {
        'areaCode': '310112',
        'areaName': '闵行区'
      }, {
        'areaCode': '310113',
        'areaName': '宝山区'
      }, {
        'areaCode': '310114',
        'areaName': '嘉定区'
      }, {
        'areaCode': '310115',
        'areaName': '浦东新区'
      }, {
        'areaCode': '310116',
        'areaName': '金山区'
      }, {
        'areaCode': '310117',
        'areaName': '松江区'
      }, {
        'areaCode': '310118',
        'areaName': '青浦区'
      }, {
        'areaCode': '310120',
        'areaName': '奉贤区'
      }]
    }, {
      'cityCode': '310200',
      'cityName': '上海市辖县',
      'mallAreaList': [{
        'areaCode': '310230',
        'areaName': '崇明县'
      }]
    }]
  }, {
    'provinceCode': '320000',
    'provinceName': '江苏省',
    'mallCityList': [{
      'cityCode': '320100',
      'cityName': '南京市',
      'mallAreaList': [{
        'areaCode': '320102',
        'areaName': '玄武区'
      }, {
        'areaCode': '320104',
        'areaName': '秦淮区'
      }, {
        'areaCode': '320105',
        'areaName': '建邺区'
      }, {
        'areaCode': '320106',
        'areaName': '鼓楼区'
      }, {
        'areaCode': '320111',
        'areaName': '浦口区'
      }, {
        'areaCode': '320113',
        'areaName': '栖霞区'
      }, {
        'areaCode': '320114',
        'areaName': '雨花台区'
      }, {
        'areaCode': '320115',
        'areaName': '江宁区'
      }, {
        'areaCode': '320116',
        'areaName': '六合区'
      }, {
        'areaCode': '320117',
        'areaName': '溧水区'
      }, {
        'areaCode': '320118',
        'areaName': '高淳区'
      }]
    }, {
      'cityCode': '320200',
      'cityName': '无锡市',
      'mallAreaList': [{
        'areaCode': '320202',
        'areaName': '崇安区'
      }, {
        'areaCode': '320203',
        'areaName': '南长区'
      }, {
        'areaCode': '320204',
        'areaName': '北塘区'
      }, {
        'areaCode': '320205',
        'areaName': '锡山区'
      }, {
        'areaCode': '320206',
        'areaName': '惠山区'
      }, {
        'areaCode': '320211',
        'areaName': '滨湖区'
      }, {
        'areaCode': '320281',
        'areaName': '江阴市'
      }, {
        'areaCode': '320282',
        'areaName': '宜兴市'
      }]
    }, {
      'cityCode': '320300',
      'cityName': '徐州市',
      'mallAreaList': [{
        'areaCode': '320302',
        'areaName': '鼓楼区'
      }, {
        'areaCode': '320303',
        'areaName': '云龙区'
      }, {
        'areaCode': '320305',
        'areaName': '贾汪区'
      }, {
        'areaCode': '320311',
        'areaName': '泉山区'
      }, {
        'areaCode': '320312',
        'areaName': '铜山区'
      }, {
        'areaCode': '320321',
        'areaName': '丰县'
      }, {
        'areaCode': '320322',
        'areaName': '沛县'
      }, {
        'areaCode': '320324',
        'areaName': '睢宁县'
      }, {
        'areaCode': '320381',
        'areaName': '新沂市'
      }, {
        'areaCode': '320382',
        'areaName': '邳州市'
      }]
    }, {
      'cityCode': '320400',
      'cityName': '常州市',
      'mallAreaList': [{
        'areaCode': '320402',
        'areaName': '天宁区'
      }, {
        'areaCode': '320404',
        'areaName': '钟楼区'
      }, {
        'areaCode': '320405',
        'areaName': '戚墅堰区'
      }, {
        'areaCode': '320411',
        'areaName': '新北区'
      }, {
        'areaCode': '320412',
        'areaName': '武进区'
      }, {
        'areaCode': '320481',
        'areaName': '溧阳市'
      }, {
        'areaCode': '320482',
        'areaName': '金坛市'
      }]
    }, {
      'cityCode': '320500',
      'cityName': '苏州市',
      'mallAreaList': [{
        'areaCode': '320505',
        'areaName': '虎丘区'
      }, {
        'areaCode': '320506',
        'areaName': '吴中区'
      }, {
        'areaCode': '320507',
        'areaName': '相城区'
      }, {
        'areaCode': '320508',
        'areaName': '姑苏区'
      }, {
        'areaCode': '320509',
        'areaName': '吴江区'
      }, {
        'areaCode': '320581',
        'areaName': '常熟市'
      }, {
        'areaCode': '320582',
        'areaName': '张家港市'
      }, {
        'areaCode': '320583',
        'areaName': '昆山市'
      }, {
        'areaCode': '320585',
        'areaName': '太仓市'
      }]
    }, {
      'cityCode': '320600',
      'cityName': '南通市',
      'mallAreaList': [{
        'areaCode': '320602',
        'areaName': '崇川区'
      }, {
        'areaCode': '320611',
        'areaName': '港闸区'
      }, {
        'areaCode': '320612',
        'areaName': '通州区'
      }, {
        'areaCode': '320621',
        'areaName': '海安县'
      }, {
        'areaCode': '320623',
        'areaName': '如东县'
      }, {
        'areaCode': '320681',
        'areaName': '启东市'
      }, {
        'areaCode': '320682',
        'areaName': '如皋市'
      }, {
        'areaCode': '320684',
        'areaName': '海门市'
      }]
    }, {
      'cityCode': '320700',
      'cityName': '连云港市',
      'mallAreaList': [{
        'areaCode': '320703',
        'areaName': '连云区'
      }, {
        'areaCode': '320705',
        'areaName': '新浦'
      }, {
        'areaCode': '320706',
        'areaName': '海州区'
      }, {
        'areaCode': '320707',
        'areaName': '赣榆区'
      }, {
        'areaCode': '320722',
        'areaName': '东海县'
      }, {
        'areaCode': '320723',
        'areaName': '灌云县'
      }, {
        'areaCode': '320724',
        'areaName': '灌南县'
      }]
    }, {
      'cityCode': '320800',
      'cityName': '淮安市',
      'mallAreaList': [{
        'areaCode': '320802',
        'areaName': '清河区'
      }, {
        'areaCode': '320803',
        'areaName': '淮安区'
      }, {
        'areaCode': '320804',
        'areaName': '淮阴区'
      }, {
        'areaCode': '320811',
        'areaName': '清浦区'
      }, {
        'areaCode': '320826',
        'areaName': '涟水县'
      }, {
        'areaCode': '320829',
        'areaName': '洪泽县'
      }, {
        'areaCode': '320830',
        'areaName': '盱眙县'
      }, {
        'areaCode': '320831',
        'areaName': '金湖县'
      }]
    }, {
      'cityCode': '320900',
      'cityName': '盐城市',
      'mallAreaList': [{
        'areaCode': '320902',
        'areaName': '亭湖区'
      }, {
        'areaCode': '320903',
        'areaName': '盐都区'
      }, {
        'areaCode': '320921',
        'areaName': '响水县'
      }, {
        'areaCode': '320922',
        'areaName': '滨海县'
      }, {
        'areaCode': '320923',
        'areaName': '阜宁县'
      }, {
        'areaCode': '320924',
        'areaName': '射阳县'
      }, {
        'areaCode': '320925',
        'areaName': '建湖县'
      }, {
        'areaCode': '320981',
        'areaName': '东台市'
      }, {
        'areaCode': '320982',
        'areaName': '大丰市'
      }]
    }, {
      'cityCode': '321000',
      'cityName': '扬州市',
      'mallAreaList': [{
        'areaCode': '321002',
        'areaName': '广陵区'
      }, {
        'areaCode': '321003',
        'areaName': '邗江区'
      }, {
        'areaCode': '321012',
        'areaName': '江都区'
      }, {
        'areaCode': '321023',
        'areaName': '宝应县'
      }, {
        'areaCode': '321081',
        'areaName': '仪征市'
      }, {
        'areaCode': '321084',
        'areaName': '高邮市'
      }]
    }, {
      'cityCode': '321100',
      'cityName': '镇江市',
      'mallAreaList': [{
        'areaCode': '321102',
        'areaName': '京口区'
      }, {
        'areaCode': '321111',
        'areaName': '润州区'
      }, {
        'areaCode': '321112',
        'areaName': '丹徒区'
      }, {
        'areaCode': '321181',
        'areaName': '丹阳市'
      }, {
        'areaCode': '321182',
        'areaName': '扬中市'
      }, {
        'areaCode': '321183',
        'areaName': '句容市'
      }]
    }, {
      'cityCode': '321200',
      'cityName': '泰州市',
      'mallAreaList': [{
        'areaCode': '321202',
        'areaName': '海陵区'
      }, {
        'areaCode': '321203',
        'areaName': '高港区'
      }, {
        'areaCode': '321204',
        'areaName': '姜堰区'
      }, {
        'areaCode': '321281',
        'areaName': '兴化市'
      }, {
        'areaCode': '321282',
        'areaName': '靖江市'
      }, {
        'areaCode': '321283',
        'areaName': '泰兴市'
      }]
    }, {
      'cityCode': '321300',
      'cityName': '宿迁市',
      'mallAreaList': [{
        'areaCode': '321302',
        'areaName': '宿城区'
      }, {
        'areaCode': '321311',
        'areaName': '宿豫区'
      }, {
        'areaCode': '321322',
        'areaName': '沭阳县'
      }, {
        'areaCode': '321323',
        'areaName': '泗阳县'
      }, {
        'areaCode': '321324',
        'areaName': '泗洪县'
      }]
    }]
  }, {
    'provinceCode': '330000',
    'provinceName': '浙江省',
    'mallCityList': [{
      'cityCode': '330100',
      'cityName': '杭州市',
      'mallAreaList': [{
        'areaCode': '330102',
        'areaName': '上城区'
      }, {
        'areaCode': '330103',
        'areaName': '下城区'
      }, {
        'areaCode': '330104',
        'areaName': '江干区'
      }, {
        'areaCode': '330105',
        'areaName': '拱墅区'
      }, {
        'areaCode': '330106',
        'areaName': '西湖区'
      }, {
        'areaCode': '330108',
        'areaName': '滨江区'
      }, {
        'areaCode': '330109',
        'areaName': '萧山区'
      }, {
        'areaCode': '330110',
        'areaName': '余杭区'
      }, {
        'areaCode': '330122',
        'areaName': '桐庐县'
      }, {
        'areaCode': '330127',
        'areaName': '淳安县'
      }, {
        'areaCode': '330182',
        'areaName': '建德市'
      }, {
        'areaCode': '330183',
        'areaName': '富阳市'
      }, {
        'areaCode': '330185',
        'areaName': '临安市'
      }]
    }, {
      'cityCode': '330200',
      'cityName': '宁波市',
      'mallAreaList': [{
        'areaCode': '330203',
        'areaName': '海曙区'
      }, {
        'areaCode': '330204',
        'areaName': '江东区'
      }, {
        'areaCode': '330205',
        'areaName': '江北区'
      }, {
        'areaCode': '330206',
        'areaName': '北仑区'
      }, {
        'areaCode': '330211',
        'areaName': '镇海区'
      }, {
        'areaCode': '330212',
        'areaName': '鄞州区'
      }, {
        'areaCode': '330225',
        'areaName': '象山县'
      }, {
        'areaCode': '330226',
        'areaName': '宁海县'
      }, {
        'areaCode': '330281',
        'areaName': '余姚市'
      }, {
        'areaCode': '330282',
        'areaName': '慈溪市'
      }, {
        'areaCode': '330283',
        'areaName': '奉化市'
      }]
    }, {
      'cityCode': '330300',
      'cityName': '温州市',
      'mallAreaList': [{
        'areaCode': '330302',
        'areaName': '鹿城区'
      }, {
        'areaCode': '330303',
        'areaName': '龙湾区'
      }, {
        'areaCode': '330304',
        'areaName': '瓯海区'
      }, {
        'areaCode': '330322',
        'areaName': '洞头县'
      }, {
        'areaCode': '330324',
        'areaName': '永嘉县'
      }, {
        'areaCode': '330326',
        'areaName': '平阳县'
      }, {
        'areaCode': '330327',
        'areaName': '苍南县'
      }, {
        'areaCode': '330328',
        'areaName': '文成县'
      }, {
        'areaCode': '330329',
        'areaName': '泰顺县'
      }, {
        'areaCode': '330381',
        'areaName': '瑞安市'
      }, {
        'areaCode': '330382',
        'areaName': '乐清市'
      }]
    }, {
      'cityCode': '330400',
      'cityName': '嘉兴市',
      'mallAreaList': [{
        'areaCode': '330402',
        'areaName': '南湖区'
      }, {
        'areaCode': '330411',
        'areaName': '秀洲区'
      }, {
        'areaCode': '330421',
        'areaName': '嘉善县'
      }, {
        'areaCode': '330424',
        'areaName': '海盐县'
      }, {
        'areaCode': '330481',
        'areaName': '海宁市'
      }, {
        'areaCode': '330482',
        'areaName': '平湖市'
      }, {
        'areaCode': '330483',
        'areaName': '桐乡市'
      }]
    }, {
      'cityCode': '330500',
      'cityName': '湖州市',
      'mallAreaList': [{
        'areaCode': '330502',
        'areaName': '吴兴区'
      }, {
        'areaCode': '330503',
        'areaName': '南浔区'
      }, {
        'areaCode': '330521',
        'areaName': '德清县'
      }, {
        'areaCode': '330522',
        'areaName': '长兴县'
      }, {
        'areaCode': '330523',
        'areaName': '安吉县'
      }]
    }, {
      'cityCode': '330600',
      'cityName': '绍兴市',
      'mallAreaList': [{
        'areaCode': '330602',
        'areaName': '越城区'
      }, {
        'areaCode': '330603',
        'areaName': '柯桥区'
      }, {
        'areaCode': '330604',
        'areaName': '上虞区'
      }, {
        'areaCode': '330624',
        'areaName': '新昌县'
      }, {
        'areaCode': '330681',
        'areaName': '诸暨市'
      }, {
        'areaCode': '330683',
        'areaName': '嵊州市'
      }]
    }, {
      'cityCode': '330700',
      'cityName': '金华市',
      'mallAreaList': [{
        'areaCode': '330702',
        'areaName': '婺城区'
      }, {
        'areaCode': '330703',
        'areaName': '金东区'
      }, {
        'areaCode': '330723',
        'areaName': '武义县'
      }, {
        'areaCode': '330726',
        'areaName': '浦江县'
      }, {
        'areaCode': '330727',
        'areaName': '磐安县'
      }, {
        'areaCode': '330781',
        'areaName': '兰溪市'
      }, {
        'areaCode': '330782',
        'areaName': '义乌市'
      }, {
        'areaCode': '330783',
        'areaName': '东阳市'
      }, {
        'areaCode': '330784',
        'areaName': '永康市'
      }]
    }, {
      'cityCode': '330800',
      'cityName': '衢州市',
      'mallAreaList': [{
        'areaCode': '330802',
        'areaName': '柯城区'
      }, {
        'areaCode': '330803',
        'areaName': '衢江区'
      }, {
        'areaCode': '330822',
        'areaName': '常山县'
      }, {
        'areaCode': '330824',
        'areaName': '开化县'
      }, {
        'areaCode': '330825',
        'areaName': '龙游县'
      }, {
        'areaCode': '330881',
        'areaName': '江山市'
      }]
    }, {
      'cityCode': '330900',
      'cityName': '舟山市',
      'mallAreaList': [{
        'areaCode': '330902',
        'areaName': '定海区'
      }, {
        'areaCode': '330903',
        'areaName': '普陀区'
      }, {
        'areaCode': '330921',
        'areaName': '岱山县'
      }, {
        'areaCode': '330922',
        'areaName': '嵊泗县'
      }]
    }, {
      'cityCode': '331000',
      'cityName': '台州市',
      'mallAreaList': [{
        'areaCode': '331002',
        'areaName': '椒江区'
      }, {
        'areaCode': '331003',
        'areaName': '黄岩区'
      }, {
        'areaCode': '331004',
        'areaName': '路桥区'
      }, {
        'areaCode': '331021',
        'areaName': '玉环县'
      }, {
        'areaCode': '331022',
        'areaName': '三门县'
      }, {
        'areaCode': '331023',
        'areaName': '天台县'
      }, {
        'areaCode': '331024',
        'areaName': '仙居县'
      }, {
        'areaCode': '331081',
        'areaName': '温岭市'
      }, {
        'areaCode': '331082',
        'areaName': '临海市'
      }]
    }, {
      'cityCode': '331100',
      'cityName': '丽水市',
      'mallAreaList': [{
        'areaCode': '331102',
        'areaName': '莲都区'
      }, {
        'areaCode': '331121',
        'areaName': '青田县'
      }, {
        'areaCode': '331122',
        'areaName': '缙云县'
      }, {
        'areaCode': '331123',
        'areaName': '遂昌县'
      }, {
        'areaCode': '331124',
        'areaName': '松阳县'
      }, {
        'areaCode': '331125',
        'areaName': '云和县'
      }, {
        'areaCode': '331126',
        'areaName': '庆元县'
      }, {
        'areaCode': '331127',
        'areaName': '景宁畲族自治县'
      }, {
        'areaCode': '331181',
        'areaName': '龙泉市'
      }]
    }]
  }, {
    'provinceCode': '340000',
    'provinceName': '安徽省',
    'mallCityList': [{
      'cityCode': '340100',
      'cityName': '合肥市',
      'mallAreaList': [{
        'areaCode': '340102',
        'areaName': '瑶海区'
      }, {
        'areaCode': '340103',
        'areaName': '庐阳区'
      }, {
        'areaCode': '340104',
        'areaName': '蜀山区'
      }, {
        'areaCode': '340111',
        'areaName': '包河区'
      }, {
        'areaCode': '340121',
        'areaName': '长丰县'
      }, {
        'areaCode': '340122',
        'areaName': '肥东县'
      }, {
        'areaCode': '340123',
        'areaName': '肥西县'
      }, {
        'areaCode': '340124',
        'areaName': '庐江县'
      }, {
        'areaCode': '340181',
        'areaName': '巢湖市'
      }]
    }, {
      'cityCode': '340200',
      'cityName': '芜湖市',
      'mallAreaList': [{
        'areaCode': '340202',
        'areaName': '镜湖区'
      }, {
        'areaCode': '340203',
        'areaName': '弋江区'
      }, {
        'areaCode': '340207',
        'areaName': '鸠江区'
      }, {
        'areaCode': '340208',
        'areaName': '三山区'
      }, {
        'areaCode': '340221',
        'areaName': '芜湖县'
      }, {
        'areaCode': '340222',
        'areaName': '繁昌县'
      }, {
        'areaCode': '340223',
        'areaName': '南陵县'
      }, {
        'areaCode': '340225',
        'areaName': '无为县'
      }]
    }, {
      'cityCode': '340300',
      'cityName': '蚌埠市',
      'mallAreaList': [{
        'areaCode': '340302',
        'areaName': '龙子湖区'
      }, {
        'areaCode': '340303',
        'areaName': '蚌山区'
      }, {
        'areaCode': '340304',
        'areaName': '禹会区'
      }, {
        'areaCode': '340311',
        'areaName': '淮上区'
      }, {
        'areaCode': '340321',
        'areaName': '怀远县'
      }, {
        'areaCode': '340322',
        'areaName': '五河县'
      }, {
        'areaCode': '340323',
        'areaName': '固镇县'
      }]
    }, {
      'cityCode': '340400',
      'cityName': '淮南市',
      'mallAreaList': [{
        'areaCode': '340402',
        'areaName': '大通区'
      }, {
        'areaCode': '340403',
        'areaName': '田家庵区'
      }, {
        'areaCode': '340404',
        'areaName': '谢家集区'
      }, {
        'areaCode': '340405',
        'areaName': '八公山区'
      }, {
        'areaCode': '340406',
        'areaName': '潘集区'
      }, {
        'areaCode': '340421',
        'areaName': '凤台县'
      }]
    }, {
      'cityCode': '340500',
      'cityName': '马鞍山市',
      'mallAreaList': [{
        'areaCode': '340503',
        'areaName': '花山区'
      }, {
        'areaCode': '340504',
        'areaName': '雨山区'
      }, {
        'areaCode': '340506',
        'areaName': '博望区'
      }, {
        'areaCode': '340521',
        'areaName': '当涂县'
      }, {
        'areaCode': '340522',
        'areaName': '含山县'
      }, {
        'areaCode': '340523',
        'areaName': '和县'
      }]
    }, {
      'cityCode': '340600',
      'cityName': '淮北市',
      'mallAreaList': [{
        'areaCode': '340602',
        'areaName': '杜集区'
      }, {
        'areaCode': '340603',
        'areaName': '相山区'
      }, {
        'areaCode': '340604',
        'areaName': '烈山区'
      }, {
        'areaCode': '340621',
        'areaName': '濉溪县'
      }]
    }, {
      'cityCode': '340700',
      'cityName': '铜陵市',
      'mallAreaList': [{
        'areaCode': '340702',
        'areaName': '铜官山区'
      }, {
        'areaCode': '340703',
        'areaName': '狮子山区'
      }, {
        'areaCode': '340711',
        'areaName': '郊区'
      }, {
        'areaCode': '340721',
        'areaName': '铜陵县'
      }]
    }, {
      'cityCode': '340800',
      'cityName': '安庆市',
      'mallAreaList': [{
        'areaCode': '340802',
        'areaName': '迎江区'
      }, {
        'areaCode': '340803',
        'areaName': '大观区'
      }, {
        'areaCode': '340811',
        'areaName': '宜秀区'
      }, {
        'areaCode': '340822',
        'areaName': '怀宁县'
      }, {
        'areaCode': '340823',
        'areaName': '枞阳县'
      }, {
        'areaCode': '340824',
        'areaName': '潜山县'
      }, {
        'areaCode': '340825',
        'areaName': '太湖县'
      }, {
        'areaCode': '340826',
        'areaName': '宿松县'
      }, {
        'areaCode': '340827',
        'areaName': '望江县'
      }, {
        'areaCode': '340828',
        'areaName': '岳西县'
      }, {
        'areaCode': '340881',
        'areaName': '桐城市'
      }]
    }, {
      'cityCode': '341000',
      'cityName': '黄山市',
      'mallAreaList': [{
        'areaCode': '341002',
        'areaName': '屯溪区'
      }, {
        'areaCode': '341003',
        'areaName': '黄山区'
      }, {
        'areaCode': '341004',
        'areaName': '徽州区'
      }, {
        'areaCode': '341021',
        'areaName': '歙县'
      }, {
        'areaCode': '341022',
        'areaName': '休宁县'
      }, {
        'areaCode': '341023',
        'areaName': '黟县'
      }, {
        'areaCode': '341024',
        'areaName': '祁门县'
      }]
    }, {
      'cityCode': '341100',
      'cityName': '滁州市',
      'mallAreaList': [{
        'areaCode': '341102',
        'areaName': '琅琊区'
      }, {
        'areaCode': '341103',
        'areaName': '南谯区'
      }, {
        'areaCode': '341122',
        'areaName': '来安县'
      }, {
        'areaCode': '341124',
        'areaName': '全椒县'
      }, {
        'areaCode': '341125',
        'areaName': '定远县'
      }, {
        'areaCode': '341126',
        'areaName': '凤阳县'
      }, {
        'areaCode': '341181',
        'areaName': '天长市'
      }, {
        'areaCode': '341182',
        'areaName': '明光市'
      }]
    }, {
      'cityCode': '341200',
      'cityName': '阜阳市',
      'mallAreaList': [{
        'areaCode': '341202',
        'areaName': '颍州区'
      }, {
        'areaCode': '341203',
        'areaName': '颍东区'
      }, {
        'areaCode': '341204',
        'areaName': '颍泉区'
      }, {
        'areaCode': '341221',
        'areaName': '临泉县'
      }, {
        'areaCode': '341222',
        'areaName': '太和县'
      }, {
        'areaCode': '341225',
        'areaName': '阜南县'
      }, {
        'areaCode': '341226',
        'areaName': '颍上县'
      }, {
        'areaCode': '341282',
        'areaName': '界首市'
      }]
    }, {
      'cityCode': '341300',
      'cityName': '宿州市',
      'mallAreaList': [{
        'areaCode': '341302',
        'areaName': '埇桥区'
      }, {
        'areaCode': '341321',
        'areaName': '砀山县'
      }, {
        'areaCode': '341322',
        'areaName': '萧县'
      }, {
        'areaCode': '341323',
        'areaName': '灵璧县'
      }, {
        'areaCode': '341324',
        'areaName': '泗县'
      }]
    }, {
      'cityCode': '341400',
      'cityName': '巢湖市',
      'mallAreaList': [{
        'areaCode': '341400',
        'areaName': '巢湖市区'
      }]
    }, {
      'cityCode': '341500',
      'cityName': '六安市',
      'mallAreaList': [{
        'areaCode': '341502',
        'areaName': '金安区'
      }, {
        'areaCode': '341503',
        'areaName': '裕安区'
      }, {
        'areaCode': '341521',
        'areaName': '寿县'
      }, {
        'areaCode': '341522',
        'areaName': '霍邱县'
      }, {
        'areaCode': '341523',
        'areaName': '舒城县'
      }, {
        'areaCode': '341524',
        'areaName': '金寨县'
      }, {
        'areaCode': '341525',
        'areaName': '霍山县'
      }]
    }, {
      'cityCode': '341600',
      'cityName': '亳州市',
      'mallAreaList': [{
        'areaCode': '341602',
        'areaName': '谯城区'
      }, {
        'areaCode': '341621',
        'areaName': '涡阳县'
      }, {
        'areaCode': '341622',
        'areaName': '蒙城县'
      }, {
        'areaCode': '341623',
        'areaName': '利辛县'
      }]
    }, {
      'cityCode': '341700',
      'cityName': '池州市',
      'mallAreaList': [{
        'areaCode': '341702',
        'areaName': '贵池区'
      }, {
        'areaCode': '341721',
        'areaName': '东至县'
      }, {
        'areaCode': '341722',
        'areaName': '石台县'
      }, {
        'areaCode': '341723',
        'areaName': '青阳县'
      }]
    }, {
      'cityCode': '341800',
      'cityName': '宣城市',
      'mallAreaList': [{
        'areaCode': '341802',
        'areaName': '宣州区'
      }, {
        'areaCode': '341821',
        'areaName': '郎溪县'
      }, {
        'areaCode': '341822',
        'areaName': '广德县'
      }, {
        'areaCode': '341823',
        'areaName': '泾县'
      }, {
        'areaCode': '341824',
        'areaName': '绩溪县'
      }, {
        'areaCode': '341825',
        'areaName': '旌德县'
      }, {
        'areaCode': '341881',
        'areaName': '宁国市'
      }]
    }]
  }, {
    'provinceCode': '350000',
    'provinceName': '福建省',
    'mallCityList': [{
      'cityCode': '350100',
      'cityName': '福州市',
      'mallAreaList': [{
        'areaCode': '350102',
        'areaName': '鼓楼区'
      }, {
        'areaCode': '350103',
        'areaName': '台江区'
      }, {
        'areaCode': '350104',
        'areaName': '仓山区'
      }, {
        'areaCode': '350105',
        'areaName': '马尾区'
      }, {
        'areaCode': '350111',
        'areaName': '晋安区'
      }, {
        'areaCode': '350121',
        'areaName': '闽侯县'
      }, {
        'areaCode': '350122',
        'areaName': '连江县'
      }, {
        'areaCode': '350123',
        'areaName': '罗源县'
      }, {
        'areaCode': '350124',
        'areaName': '闽清县'
      }, {
        'areaCode': '350125',
        'areaName': '永泰县'
      }, {
        'areaCode': '350128',
        'areaName': '平潭县'
      }, {
        'areaCode': '350181',
        'areaName': '福清市'
      }, {
        'areaCode': '350182',
        'areaName': '长乐市'
      }]
    }, {
      'cityCode': '350200',
      'cityName': '厦门市',
      'mallAreaList': [{
        'areaCode': '350203',
        'areaName': '思明区'
      }, {
        'areaCode': '350205',
        'areaName': '海沧区'
      }, {
        'areaCode': '350206',
        'areaName': '湖里区'
      }, {
        'areaCode': '350211',
        'areaName': '集美区'
      }, {
        'areaCode': '350212',
        'areaName': '同安区'
      }, {
        'areaCode': '350213',
        'areaName': '翔安区'
      }]
    }, {
      'cityCode': '350300',
      'cityName': '莆田市',
      'mallAreaList': [{
        'areaCode': '350302',
        'areaName': '城厢区'
      }, {
        'areaCode': '350303',
        'areaName': '涵江区'
      }, {
        'areaCode': '350304',
        'areaName': '荔城区'
      }, {
        'areaCode': '350305',
        'areaName': '秀屿区'
      }, {
        'areaCode': '350322',
        'areaName': '仙游县'
      }]
    }, {
      'cityCode': '350400',
      'cityName': '三明市',
      'mallAreaList': [{
        'areaCode': '350402',
        'areaName': '梅列区'
      }, {
        'areaCode': '350403',
        'areaName': '三元区'
      }, {
        'areaCode': '350421',
        'areaName': '明溪县'
      }, {
        'areaCode': '350423',
        'areaName': '清流县'
      }, {
        'areaCode': '350424',
        'areaName': '宁化县'
      }, {
        'areaCode': '350425',
        'areaName': '大田县'
      }, {
        'areaCode': '350426',
        'areaName': '尤溪县'
      }, {
        'areaCode': '350427',
        'areaName': '沙县'
      }, {
        'areaCode': '350428',
        'areaName': '将乐县'
      }, {
        'areaCode': '350429',
        'areaName': '泰宁县'
      }, {
        'areaCode': '350430',
        'areaName': '建宁县'
      }, {
        'areaCode': '350481',
        'areaName': '永安市'
      }]
    }, {
      'cityCode': '350500',
      'cityName': '泉州市',
      'mallAreaList': [{
        'areaCode': '350502',
        'areaName': '鲤城区'
      }, {
        'areaCode': '350503',
        'areaName': '丰泽区'
      }, {
        'areaCode': '350504',
        'areaName': '洛江区'
      }, {
        'areaCode': '350505',
        'areaName': '泉港区'
      }, {
        'areaCode': '350521',
        'areaName': '惠安县'
      }, {
        'areaCode': '350524',
        'areaName': '安溪县'
      }, {
        'areaCode': '350525',
        'areaName': '永春县'
      }, {
        'areaCode': '350526',
        'areaName': '德化县'
      }, {
        'areaCode': '350527',
        'areaName': '金门县'
      }, {
        'areaCode': '350581',
        'areaName': '石狮市'
      }, {
        'areaCode': '350582',
        'areaName': '晋江市'
      }, {
        'areaCode': '350583',
        'areaName': '南安市'
      }]
    }, {
      'cityCode': '350600',
      'cityName': '漳州市',
      'mallAreaList': [{
        'areaCode': '350602',
        'areaName': '芗城区'
      }, {
        'areaCode': '350603',
        'areaName': '龙文区'
      }, {
        'areaCode': '350622',
        'areaName': '云霄县'
      }, {
        'areaCode': '350623',
        'areaName': '漳浦县'
      }, {
        'areaCode': '350624',
        'areaName': '诏安县'
      }, {
        'areaCode': '350625',
        'areaName': '长泰县'
      }, {
        'areaCode': '350626',
        'areaName': '东山县'
      }, {
        'areaCode': '350627',
        'areaName': '南靖县'
      }, {
        'areaCode': '350628',
        'areaName': '平和县'
      }, {
        'areaCode': '350629',
        'areaName': '华安县'
      }, {
        'areaCode': '350681',
        'areaName': '龙海市'
      }]
    }, {
      'cityCode': '350700',
      'cityName': '南平市',
      'mallAreaList': [{
        'areaCode': '350702',
        'areaName': '延平区'
      }, {
        'areaCode': '350721',
        'areaName': '顺昌县'
      }, {
        'areaCode': '350722',
        'areaName': '浦城县'
      }, {
        'areaCode': '350723',
        'areaName': '光泽县'
      }, {
        'areaCode': '350724',
        'areaName': '松溪县'
      }, {
        'areaCode': '350725',
        'areaName': '政和县'
      }, {
        'areaCode': '350781',
        'areaName': '邵武市'
      }, {
        'areaCode': '350782',
        'areaName': '武夷山市'
      }, {
        'areaCode': '350783',
        'areaName': '建瓯市'
      }, {
        'areaCode': '350784',
        'areaName': '建阳市'
      }]
    }, {
      'cityCode': '350800',
      'cityName': '龙岩市',
      'mallAreaList': [{
        'areaCode': '350802',
        'areaName': '新罗区'
      }, {
        'areaCode': '350821',
        'areaName': '长汀县'
      }, {
        'areaCode': '350822',
        'areaName': '永定县'
      }, {
        'areaCode': '350823',
        'areaName': '上杭县'
      }, {
        'areaCode': '350824',
        'areaName': '武平县'
      }, {
        'areaCode': '350825',
        'areaName': '连城县'
      }, {
        'areaCode': '350881',
        'areaName': '漳平市'
      }]
    }, {
      'cityCode': '350900',
      'cityName': '宁德市',
      'mallAreaList': [{
        'areaCode': '350902',
        'areaName': '蕉城区'
      }, {
        'areaCode': '350921',
        'areaName': '霞浦县'
      }, {
        'areaCode': '350922',
        'areaName': '古田县'
      }, {
        'areaCode': '350923',
        'areaName': '屏南县'
      }, {
        'areaCode': '350924',
        'areaName': '寿宁县'
      }, {
        'areaCode': '350925',
        'areaName': '周宁县'
      }, {
        'areaCode': '350926',
        'areaName': '柘荣县'
      }, {
        'areaCode': '350981',
        'areaName': '福安市'
      }, {
        'areaCode': '350982',
        'areaName': '福鼎市'
      }]
    }]
  }, {
    'provinceCode': '360000',
    'provinceName': '江西省',
    'mallCityList': [{
      'cityCode': '360100',
      'cityName': '南昌市',
      'mallAreaList': [{
        'areaCode': '360102',
        'areaName': '东湖区'
      }, {
        'areaCode': '360103',
        'areaName': '西湖区'
      }, {
        'areaCode': '360104',
        'areaName': '青云谱区'
      }, {
        'areaCode': '360105',
        'areaName': '湾里区'
      }, {
        'areaCode': '360111',
        'areaName': '青山湖区'
      }, {
        'areaCode': '360121',
        'areaName': '南昌县'
      }, {
        'areaCode': '360122',
        'areaName': '新建县'
      }, {
        'areaCode': '360123',
        'areaName': '安义县'
      }, {
        'areaCode': '360124',
        'areaName': '进贤县'
      }]
    }, {
      'cityCode': '360200',
      'cityName': '景德镇市',
      'mallAreaList': [{
        'areaCode': '360202',
        'areaName': '昌江区'
      }, {
        'areaCode': '360203',
        'areaName': '珠山区'
      }, {
        'areaCode': '360222',
        'areaName': '浮梁县'
      }, {
        'areaCode': '360281',
        'areaName': '乐平市'
      }]
    }, {
      'cityCode': '360300',
      'cityName': '萍乡市',
      'mallAreaList': [{
        'areaCode': '360302',
        'areaName': '安源区'
      }, {
        'areaCode': '360313',
        'areaName': '湘东区'
      }, {
        'areaCode': '360321',
        'areaName': '莲花县'
      }, {
        'areaCode': '360322',
        'areaName': '上栗县'
      }, {
        'areaCode': '360323',
        'areaName': '芦溪县'
      }]
    }, {
      'cityCode': '360400',
      'cityName': '九江市',
      'mallAreaList': [{
        'areaCode': '360402',
        'areaName': '庐山区'
      }, {
        'areaCode': '360403',
        'areaName': '浔阳区'
      }, {
        'areaCode': '360421',
        'areaName': '九江县'
      }, {
        'areaCode': '360423',
        'areaName': '武宁县'
      }, {
        'areaCode': '360424',
        'areaName': '修水县'
      }, {
        'areaCode': '360425',
        'areaName': '永修县'
      }, {
        'areaCode': '360426',
        'areaName': '德安县'
      }, {
        'areaCode': '360427',
        'areaName': '星子县'
      }, {
        'areaCode': '360428',
        'areaName': '都昌县'
      }, {
        'areaCode': '360429',
        'areaName': '湖口县'
      }, {
        'areaCode': '360430',
        'areaName': '彭泽县'
      }, {
        'areaCode': '360481',
        'areaName': '瑞昌市'
      }, {
        'areaCode': '360482',
        'areaName': '共青城市'
      }]
    }, {
      'cityCode': '360500',
      'cityName': '新余市',
      'mallAreaList': [{
        'areaCode': '360502',
        'areaName': '渝水区'
      }, {
        'areaCode': '360521',
        'areaName': '分宜县'
      }]
    }, {
      'cityCode': '360600',
      'cityName': '鹰潭市',
      'mallAreaList': [{
        'areaCode': '360602',
        'areaName': '月湖区'
      }, {
        'areaCode': '360622',
        'areaName': '余江县'
      }, {
        'areaCode': '360681',
        'areaName': '贵溪市'
      }]
    }, {
      'cityCode': '360700',
      'cityName': '赣州市',
      'mallAreaList': [{
        'areaCode': '360702',
        'areaName': '章贡区'
      }, {
        'areaCode': '360703',
        'areaName': '南康区'
      }, {
        'areaCode': '360721',
        'areaName': '赣县'
      }, {
        'areaCode': '360722',
        'areaName': '信丰县'
      }, {
        'areaCode': '360723',
        'areaName': '大余县'
      }, {
        'areaCode': '360724',
        'areaName': '上犹县'
      }, {
        'areaCode': '360725',
        'areaName': '崇义县'
      }, {
        'areaCode': '360726',
        'areaName': '安远县'
      }, {
        'areaCode': '360727',
        'areaName': '龙南县'
      }, {
        'areaCode': '360728',
        'areaName': '定南县'
      }, {
        'areaCode': '360729',
        'areaName': '全南县'
      }, {
        'areaCode': '360730',
        'areaName': '宁都县'
      }, {
        'areaCode': '360731',
        'areaName': '于都县'
      }, {
        'areaCode': '360732',
        'areaName': '兴国县'
      }, {
        'areaCode': '360733',
        'areaName': '会昌县'
      }, {
        'areaCode': '360734',
        'areaName': '寻乌县'
      }, {
        'areaCode': '360735',
        'areaName': '石城县'
      }, {
        'areaCode': '360781',
        'areaName': '瑞金市'
      }]
    }, {
      'cityCode': '360800',
      'cityName': '吉安市',
      'mallAreaList': [{
        'areaCode': '360802',
        'areaName': '吉州区'
      }, {
        'areaCode': '360803',
        'areaName': '青原区'
      }, {
        'areaCode': '360821',
        'areaName': '吉安县'
      }, {
        'areaCode': '360822',
        'areaName': '吉水县'
      }, {
        'areaCode': '360823',
        'areaName': '峡江县'
      }, {
        'areaCode': '360824',
        'areaName': '新干县'
      }, {
        'areaCode': '360825',
        'areaName': '永丰县'
      }, {
        'areaCode': '360826',
        'areaName': '泰和县'
      }, {
        'areaCode': '360827',
        'areaName': '遂川县'
      }, {
        'areaCode': '360828',
        'areaName': '万安县'
      }, {
        'areaCode': '360829',
        'areaName': '安福县'
      }, {
        'areaCode': '360830',
        'areaName': '永新县'
      }, {
        'areaCode': '360881',
        'areaName': '井冈山市'
      }]
    }, {
      'cityCode': '360900',
      'cityName': '宜春市',
      'mallAreaList': [{
        'areaCode': '360902',
        'areaName': '袁州区'
      }, {
        'areaCode': '360921',
        'areaName': '奉新县'
      }, {
        'areaCode': '360922',
        'areaName': '万载县'
      }, {
        'areaCode': '360923',
        'areaName': '上高县'
      }, {
        'areaCode': '360924',
        'areaName': '宜丰县'
      }, {
        'areaCode': '360925',
        'areaName': '靖安县'
      }, {
        'areaCode': '360926',
        'areaName': '铜鼓县'
      }, {
        'areaCode': '360981',
        'areaName': '丰城市'
      }, {
        'areaCode': '360982',
        'areaName': '樟树市'
      }, {
        'areaCode': '360983',
        'areaName': '高安市'
      }]
    }, {
      'cityCode': '361000',
      'cityName': '抚州市',
      'mallAreaList': [{
        'areaCode': '361002',
        'areaName': '临川区'
      }, {
        'areaCode': '361021',
        'areaName': '南城县'
      }, {
        'areaCode': '361022',
        'areaName': '黎川县'
      }, {
        'areaCode': '361023',
        'areaName': '南丰县'
      }, {
        'areaCode': '361024',
        'areaName': '崇仁县'
      }, {
        'areaCode': '361025',
        'areaName': '乐安县'
      }, {
        'areaCode': '361026',
        'areaName': '宜黄县'
      }, {
        'areaCode': '361027',
        'areaName': '金溪县'
      }, {
        'areaCode': '361028',
        'areaName': '资溪县'
      }, {
        'areaCode': '361029',
        'areaName': '东乡县'
      }, {
        'areaCode': '361030',
        'areaName': '广昌县'
      }]
    }, {
      'cityCode': '361100',
      'cityName': '上饶市',
      'mallAreaList': [{
        'areaCode': '361102',
        'areaName': '信州区'
      }, {
        'areaCode': '361121',
        'areaName': '上饶县'
      }, {
        'areaCode': '361122',
        'areaName': '广丰县'
      }, {
        'areaCode': '361123',
        'areaName': '玉山县'
      }, {
        'areaCode': '361124',
        'areaName': '铅山县'
      }, {
        'areaCode': '361125',
        'areaName': '横峰县'
      }, {
        'areaCode': '361126',
        'areaName': '弋阳县'
      }, {
        'areaCode': '361127',
        'areaName': '余干县'
      }, {
        'areaCode': '361128',
        'areaName': '鄱阳县'
      }, {
        'areaCode': '361129',
        'areaName': '万年县'
      }, {
        'areaCode': '361130',
        'areaName': '婺源县'
      }, {
        'areaCode': '361181',
        'areaName': '德兴市'
      }]
    }]
  }, {
    'provinceCode': '370000',
    'provinceName': '山东省',
    'mallCityList': [{
      'cityCode': '370100',
      'cityName': '济南市',
      'mallAreaList': [{
        'areaCode': '370102',
        'areaName': '历下区'
      }, {
        'areaCode': '370103',
        'areaName': '市中区'
      }, {
        'areaCode': '370104',
        'areaName': '槐荫区'
      }, {
        'areaCode': '370105',
        'areaName': '天桥区'
      }, {
        'areaCode': '370112',
        'areaName': '历城区'
      }, {
        'areaCode': '370113',
        'areaName': '长清区'
      }, {
        'areaCode': '370124',
        'areaName': '平阴县'
      }, {
        'areaCode': '370125',
        'areaName': '济阳县'
      }, {
        'areaCode': '370126',
        'areaName': '商河县'
      }, {
        'areaCode': '370181',
        'areaName': '章丘市'
      }]
    }, {
      'cityCode': '370200',
      'cityName': '青岛市',
      'mallAreaList': [{
        'areaCode': '370202',
        'areaName': '市南区'
      }, {
        'areaCode': '370203',
        'areaName': '市北区'
      }, {
        'areaCode': '370211',
        'areaName': '黄岛区'
      }, {
        'areaCode': '370212',
        'areaName': '崂山区'
      }, {
        'areaCode': '370213',
        'areaName': '李沧区'
      }, {
        'areaCode': '370214',
        'areaName': '城阳区'
      }, {
        'areaCode': '370281',
        'areaName': '胶州市'
      }, {
        'areaCode': '370282',
        'areaName': '即墨市'
      }, {
        'areaCode': '370283',
        'areaName': '平度市'
      }, {
        'areaCode': '370285',
        'areaName': '莱西市'
      }]
    }, {
      'cityCode': '370300',
      'cityName': '淄博市',
      'mallAreaList': [{
        'areaCode': '370302',
        'areaName': '淄川区'
      }, {
        'areaCode': '370303',
        'areaName': '张店区'
      }, {
        'areaCode': '370304',
        'areaName': '博山区'
      }, {
        'areaCode': '370305',
        'areaName': '临淄区'
      }, {
        'areaCode': '370306',
        'areaName': '周村区'
      }, {
        'areaCode': '370321',
        'areaName': '桓台县'
      }, {
        'areaCode': '370322',
        'areaName': '高青县'
      }, {
        'areaCode': '370323',
        'areaName': '沂源县'
      }]
    }, {
      'cityCode': '370400',
      'cityName': '枣庄市',
      'mallAreaList': [{
        'areaCode': '370402',
        'areaName': '市中区'
      }, {
        'areaCode': '370403',
        'areaName': '薛城区'
      }, {
        'areaCode': '370404',
        'areaName': '峄城区'
      }, {
        'areaCode': '370405',
        'areaName': '台儿庄区'
      }, {
        'areaCode': '370406',
        'areaName': '山亭区'
      }, {
        'areaCode': '370481',
        'areaName': '滕州市'
      }]
    }, {
      'cityCode': '370500',
      'cityName': '东营市',
      'mallAreaList': [{
        'areaCode': '370502',
        'areaName': '东营区'
      }, {
        'areaCode': '370503',
        'areaName': '河口区'
      }, {
        'areaCode': '370521',
        'areaName': '垦利县'
      }, {
        'areaCode': '370522',
        'areaName': '利津县'
      }, {
        'areaCode': '370523',
        'areaName': '广饶县'
      }]
    }, {
      'cityCode': '370600',
      'cityName': '烟台市',
      'mallAreaList': [{
        'areaCode': '370602',
        'areaName': '芝罘区'
      }, {
        'areaCode': '370611',
        'areaName': '福山区'
      }, {
        'areaCode': '370612',
        'areaName': '牟平区'
      }, {
        'areaCode': '370613',
        'areaName': '莱山区'
      }, {
        'areaCode': '370634',
        'areaName': '长岛县'
      }, {
        'areaCode': '370681',
        'areaName': '龙口市'
      }, {
        'areaCode': '370682',
        'areaName': '莱阳市'
      }, {
        'areaCode': '370683',
        'areaName': '莱州市'
      }, {
        'areaCode': '370684',
        'areaName': '蓬莱市'
      }, {
        'areaCode': '370685',
        'areaName': '招远市'
      }, {
        'areaCode': '370686',
        'areaName': '栖霞市'
      }, {
        'areaCode': '370687',
        'areaName': '海阳市'
      }]
    }, {
      'cityCode': '370700',
      'cityName': '潍坊市',
      'mallAreaList': [{
        'areaCode': '370702',
        'areaName': '潍城区'
      }, {
        'areaCode': '370703',
        'areaName': '寒亭区'
      }, {
        'areaCode': '370704',
        'areaName': '坊子区'
      }, {
        'areaCode': '370705',
        'areaName': '奎文区'
      }, {
        'areaCode': '370724',
        'areaName': '临朐县'
      }, {
        'areaCode': '370725',
        'areaName': '昌乐县'
      }, {
        'areaCode': '370781',
        'areaName': '青州市'
      }, {
        'areaCode': '370782',
        'areaName': '诸城市'
      }, {
        'areaCode': '370783',
        'areaName': '寿光市'
      }, {
        'areaCode': '370784',
        'areaName': '安丘市'
      }, {
        'areaCode': '370785',
        'areaName': '高密市'
      }, {
        'areaCode': '370786',
        'areaName': '昌邑市'
      }]
    }, {
      'cityCode': '370800',
      'cityName': '济宁市',
      'mallAreaList': [{
        'areaCode': '370802',
        'areaName': '市中区'
      }, {
        'areaCode': '370811',
        'areaName': '任城区'
      }, {
        'areaCode': '370812',
        'areaName': '兖州区'
      }, {
        'areaCode': '370826',
        'areaName': '微山县'
      }, {
        'areaCode': '370827',
        'areaName': '鱼台县'
      }, {
        'areaCode': '370828',
        'areaName': '金乡县'
      }, {
        'areaCode': '370829',
        'areaName': '嘉祥县'
      }, {
        'areaCode': '370830',
        'areaName': '汶上县'
      }, {
        'areaCode': '370831',
        'areaName': '泗水县'
      }, {
        'areaCode': '370832',
        'areaName': '梁山县'
      }, {
        'areaCode': '370881',
        'areaName': '曲阜市'
      }, {
        'areaCode': '370883',
        'areaName': '邹城市'
      }]
    }, {
      'cityCode': '370900',
      'cityName': '泰安市',
      'mallAreaList': [{
        'areaCode': '370902',
        'areaName': '泰山区'
      }, {
        'areaCode': '370911',
        'areaName': '岱岳区'
      }, {
        'areaCode': '370921',
        'areaName': '宁阳县'
      }, {
        'areaCode': '370923',
        'areaName': '东平县'
      }, {
        'areaCode': '370982',
        'areaName': '新泰市'
      }, {
        'areaCode': '370983',
        'areaName': '肥城市'
      }]
    }, {
      'cityCode': '371000',
      'cityName': '威海市',
      'mallAreaList': [{
        'areaCode': '371002',
        'areaName': '环翠区'
      }, {
        'areaCode': '371003',
        'areaName': '文登区'
      }, {
        'areaCode': '371082',
        'areaName': '荣成市'
      }, {
        'areaCode': '371083',
        'areaName': '乳山市'
      }]
    }, {
      'cityCode': '371100',
      'cityName': '日照市',
      'mallAreaList': [{
        'areaCode': '371102',
        'areaName': '东港区'
      }, {
        'areaCode': '371103',
        'areaName': '岚山区'
      }, {
        'areaCode': '371121',
        'areaName': '五莲县'
      }, {
        'areaCode': '371122',
        'areaName': '莒县'
      }]
    }, {
      'cityCode': '371200',
      'cityName': '莱芜市',
      'mallAreaList': [{
        'areaCode': '371202',
        'areaName': '莱城区'
      }, {
        'areaCode': '371203',
        'areaName': '钢城区'
      }]
    }, {
      'cityCode': '371300',
      'cityName': '临沂市',
      'mallAreaList': [{
        'areaCode': '371302',
        'areaName': '兰山区'
      }, {
        'areaCode': '371311',
        'areaName': '罗庄区'
      }, {
        'areaCode': '371312',
        'areaName': '河东区'
      }, {
        'areaCode': '371321',
        'areaName': '沂南县'
      }, {
        'areaCode': '371322',
        'areaName': '郯城县'
      }, {
        'areaCode': '371323',
        'areaName': '沂水县'
      }, {
        'areaCode': '371324',
        'areaName': '兰陵县'
      }, {
        'areaCode': '371325',
        'areaName': '费县'
      }, {
        'areaCode': '371326',
        'areaName': '平邑县'
      }, {
        'areaCode': '371327',
        'areaName': '莒南县'
      }, {
        'areaCode': '371328',
        'areaName': '蒙阴县'
      }, {
        'areaCode': '371329',
        'areaName': '临沭县'
      }]
    }, {
      'cityCode': '371400',
      'cityName': '德州市',
      'mallAreaList': [{
        'areaCode': '371402',
        'areaName': '德城区'
      }, {
        'areaCode': '371403',
        'areaName': '陵城区'
      }, {
        'areaCode': '371422',
        'areaName': '宁津县'
      }, {
        'areaCode': '371423',
        'areaName': '庆云县'
      }, {
        'areaCode': '371424',
        'areaName': '临邑县'
      }, {
        'areaCode': '371425',
        'areaName': '齐河县'
      }, {
        'areaCode': '371426',
        'areaName': '平原县'
      }, {
        'areaCode': '371427',
        'areaName': '夏津县'
      }, {
        'areaCode': '371428',
        'areaName': '武城县'
      }, {
        'areaCode': '371481',
        'areaName': '乐陵市'
      }, {
        'areaCode': '371482',
        'areaName': '禹城市'
      }]
    }, {
      'cityCode': '371500',
      'cityName': '聊城市',
      'mallAreaList': [{
        'areaCode': '371502',
        'areaName': '东昌府区'
      }, {
        'areaCode': '371521',
        'areaName': '阳谷县'
      }, {
        'areaCode': '371522',
        'areaName': '莘县'
      }, {
        'areaCode': '371523',
        'areaName': '茌平县'
      }, {
        'areaCode': '371524',
        'areaName': '东阿县'
      }, {
        'areaCode': '371525',
        'areaName': '冠县'
      }, {
        'areaCode': '371526',
        'areaName': '高唐县'
      }, {
        'areaCode': '371581',
        'areaName': '临清市'
      }]
    }, {
      'cityCode': '371600',
      'cityName': '滨州市',
      'mallAreaList': [{
        'areaCode': '371602',
        'areaName': '滨城区'
      }, {
        'areaCode': '371603',
        'areaName': '沾化区'
      }, {
        'areaCode': '371621',
        'areaName': '惠民县'
      }, {
        'areaCode': '371622',
        'areaName': '阳信县'
      }, {
        'areaCode': '371623',
        'areaName': '无棣县'
      }, {
        'areaCode': '371625',
        'areaName': '博兴县'
      }, {
        'areaCode': '371626',
        'areaName': '邹平县'
      }]
    }, {
      'cityCode': '371700',
      'cityName': '菏泽市',
      'mallAreaList': [{
        'areaCode': '371702',
        'areaName': '牡丹区'
      }, {
        'areaCode': '371721',
        'areaName': '曹县'
      }, {
        'areaCode': '371722',
        'areaName': '单县'
      }, {
        'areaCode': '371723',
        'areaName': '成武县'
      }, {
        'areaCode': '371724',
        'areaName': '巨野县'
      }, {
        'areaCode': '371725',
        'areaName': '郓城县'
      }, {
        'areaCode': '371726',
        'areaName': '鄄城县'
      }, {
        'areaCode': '371727',
        'areaName': '定陶县'
      }, {
        'areaCode': '371728',
        'areaName': '东明县'
      }]
    }]
  }, {
    'provinceCode': '410000',
    'provinceName': '河南省',
    'mallCityList': [{
      'cityCode': '410100',
      'cityName': '郑州市',
      'mallAreaList': [{
        'areaCode': '410102',
        'areaName': '中原区'
      }, {
        'areaCode': '410103',
        'areaName': '二七区'
      }, {
        'areaCode': '410104',
        'areaName': '管城回族区'
      }, {
        'areaCode': '410105',
        'areaName': '金水区'
      }, {
        'areaCode': '410106',
        'areaName': '上街区'
      }, {
        'areaCode': '410108',
        'areaName': '惠济区'
      }, {
        'areaCode': '410122',
        'areaName': '中牟县'
      }, {
        'areaCode': '410181',
        'areaName': '巩义市'
      }, {
        'areaCode': '410182',
        'areaName': '荥阳市'
      }, {
        'areaCode': '410183',
        'areaName': '新密市'
      }, {
        'areaCode': '410184',
        'areaName': '新郑市'
      }, {
        'areaCode': '410185',
        'areaName': '登封市'
      }]
    }, {
      'cityCode': '410200',
      'cityName': '开封市',
      'mallAreaList': [{
        'areaCode': '410202',
        'areaName': '龙亭区'
      }, {
        'areaCode': '410203',
        'areaName': '顺河回族区'
      }, {
        'areaCode': '410204',
        'areaName': '鼓楼区'
      }, {
        'areaCode': '410205',
        'areaName': '禹王台区'
      }, {
        'areaCode': '410211',
        'areaName': '金明区'
      }, {
        'areaCode': '410221',
        'areaName': '杞县'
      }, {
        'areaCode': '410222',
        'areaName': '通许县'
      }, {
        'areaCode': '410223',
        'areaName': '尉氏县'
      }, {
        'areaCode': '410224',
        'areaName': '开封县'
      }, {
        'areaCode': '410225',
        'areaName': '兰考县'
      }]
    }, {
      'cityCode': '410300',
      'cityName': '洛阳市',
      'mallAreaList': [{
        'areaCode': '410302',
        'areaName': '老城区'
      }, {
        'areaCode': '410303',
        'areaName': '西工区'
      }, {
        'areaCode': '410304',
        'areaName': '瀍河回族区'
      }, {
        'areaCode': '410305',
        'areaName': '涧西区'
      }, {
        'areaCode': '410306',
        'areaName': '吉利区'
      }, {
        'areaCode': '410311',
        'areaName': '洛龙区'
      }, {
        'areaCode': '410322',
        'areaName': '孟津县'
      }, {
        'areaCode': '410323',
        'areaName': '新安县'
      }, {
        'areaCode': '410324',
        'areaName': '栾川县'
      }, {
        'areaCode': '410325',
        'areaName': '嵩县'
      }, {
        'areaCode': '410326',
        'areaName': '汝阳县'
      }, {
        'areaCode': '410327',
        'areaName': '宜阳县'
      }, {
        'areaCode': '410328',
        'areaName': '洛宁县'
      }, {
        'areaCode': '410329',
        'areaName': '伊川县'
      }, {
        'areaCode': '410381',
        'areaName': '偃师市'
      }]
    }, {
      'cityCode': '410400',
      'cityName': '平顶山市',
      'mallAreaList': [{
        'areaCode': '410402',
        'areaName': '新华区'
      }, {
        'areaCode': '410403',
        'areaName': '卫东区'
      }, {
        'areaCode': '410404',
        'areaName': '石龙区'
      }, {
        'areaCode': '410411',
        'areaName': '湛河区'
      }, {
        'areaCode': '410421',
        'areaName': '宝丰县'
      }, {
        'areaCode': '410422',
        'areaName': '叶县'
      }, {
        'areaCode': '410423',
        'areaName': '鲁山县'
      }, {
        'areaCode': '410425',
        'areaName': '郏县'
      }, {
        'areaCode': '410481',
        'areaName': '舞钢市'
      }, {
        'areaCode': '410482',
        'areaName': '汝州市'
      }]
    }, {
      'cityCode': '410500',
      'cityName': '安阳市',
      'mallAreaList': [{
        'areaCode': '410502',
        'areaName': '文峰区'
      }, {
        'areaCode': '410503',
        'areaName': '北关区'
      }, {
        'areaCode': '410505',
        'areaName': '殷都区'
      }, {
        'areaCode': '410506',
        'areaName': '龙安区'
      }, {
        'areaCode': '410522',
        'areaName': '安阳县'
      }, {
        'areaCode': '410523',
        'areaName': '汤阴县'
      }, {
        'areaCode': '410526',
        'areaName': '滑县'
      }, {
        'areaCode': '410527',
        'areaName': '内黄县'
      }, {
        'areaCode': '410581',
        'areaName': '林州市'
      }]
    }, {
      'cityCode': '410600',
      'cityName': '鹤壁市',
      'mallAreaList': [{
        'areaCode': '410602',
        'areaName': '鹤山区'
      }, {
        'areaCode': '410603',
        'areaName': '山城区'
      }, {
        'areaCode': '410611',
        'areaName': '淇滨区'
      }, {
        'areaCode': '410621',
        'areaName': '浚县'
      }, {
        'areaCode': '410622',
        'areaName': '淇县'
      }]
    }, {
      'cityCode': '410700',
      'cityName': '新乡市',
      'mallAreaList': [{
        'areaCode': '410702',
        'areaName': '红旗区'
      }, {
        'areaCode': '410703',
        'areaName': '卫滨区'
      }, {
        'areaCode': '410704',
        'areaName': '凤泉区'
      }, {
        'areaCode': '410711',
        'areaName': '牧野区'
      }, {
        'areaCode': '410721',
        'areaName': '新乡县'
      }, {
        'areaCode': '410724',
        'areaName': '获嘉县'
      }, {
        'areaCode': '410725',
        'areaName': '原阳县'
      }, {
        'areaCode': '410726',
        'areaName': '延津县'
      }, {
        'areaCode': '410727',
        'areaName': '封丘县'
      }, {
        'areaCode': '410728',
        'areaName': '长垣县'
      }, {
        'areaCode': '410781',
        'areaName': '卫辉市'
      }, {
        'areaCode': '410782',
        'areaName': '辉县市'
      }]
    }, {
      'cityCode': '410800',
      'cityName': '焦作市',
      'mallAreaList': [{
        'areaCode': '410802',
        'areaName': '解放区'
      }, {
        'areaCode': '410803',
        'areaName': '中站区'
      }, {
        'areaCode': '410804',
        'areaName': '马村区'
      }, {
        'areaCode': '410811',
        'areaName': '山阳区'
      }, {
        'areaCode': '410821',
        'areaName': '修武县'
      }, {
        'areaCode': '410822',
        'areaName': '博爱县'
      }, {
        'areaCode': '410823',
        'areaName': '武陟县'
      }, {
        'areaCode': '410825',
        'areaName': '温县'
      }, {
        'areaCode': '410882',
        'areaName': '沁阳市'
      }, {
        'areaCode': '410883',
        'areaName': '孟州市'
      }]
    }, {
      'cityCode': '410900',
      'cityName': '濮阳市',
      'mallAreaList': [{
        'areaCode': '410902',
        'areaName': '华龙区'
      }, {
        'areaCode': '410922',
        'areaName': '清丰县'
      }, {
        'areaCode': '410923',
        'areaName': '南乐县'
      }, {
        'areaCode': '410926',
        'areaName': '范县'
      }, {
        'areaCode': '410927',
        'areaName': '台前县'
      }, {
        'areaCode': '410928',
        'areaName': '濮阳县'
      }]
    }, {
      'cityCode': '411000',
      'cityName': '许昌市',
      'mallAreaList': [{
        'areaCode': '411002',
        'areaName': '魏都区'
      }, {
        'areaCode': '411023',
        'areaName': '许昌县'
      }, {
        'areaCode': '411024',
        'areaName': '鄢陵县'
      }, {
        'areaCode': '411025',
        'areaName': '襄城县'
      }, {
        'areaCode': '411081',
        'areaName': '禹州市'
      }, {
        'areaCode': '411082',
        'areaName': '长葛市'
      }]
    }, {
      'cityCode': '411100',
      'cityName': '漯河市',
      'mallAreaList': [{
        'areaCode': '411102',
        'areaName': '源汇区'
      }, {
        'areaCode': '411103',
        'areaName': '郾城区'
      }, {
        'areaCode': '411104',
        'areaName': '召陵区'
      }, {
        'areaCode': '411121',
        'areaName': '舞阳县'
      }, {
        'areaCode': '411122',
        'areaName': '临颍县'
      }]
    }, {
      'cityCode': '411200',
      'cityName': '三门峡市',
      'mallAreaList': [{
        'areaCode': '411202',
        'areaName': '湖滨区'
      }, {
        'areaCode': '411221',
        'areaName': '渑池县'
      }, {
        'areaCode': '411222',
        'areaName': '陕县'
      }, {
        'areaCode': '411224',
        'areaName': '卢氏县'
      }, {
        'areaCode': '411281',
        'areaName': '义马市'
      }, {
        'areaCode': '411282',
        'areaName': '灵宝市'
      }]
    }, {
      'cityCode': '411300',
      'cityName': '南阳市',
      'mallAreaList': [{
        'areaCode': '411302',
        'areaName': '宛城区'
      }, {
        'areaCode': '411303',
        'areaName': '卧龙区'
      }, {
        'areaCode': '411321',
        'areaName': '南召县'
      }, {
        'areaCode': '411322',
        'areaName': '方城县'
      }, {
        'areaCode': '411323',
        'areaName': '西峡县'
      }, {
        'areaCode': '411324',
        'areaName': '镇平县'
      }, {
        'areaCode': '411325',
        'areaName': '内乡县'
      }, {
        'areaCode': '411326',
        'areaName': '淅川县'
      }, {
        'areaCode': '411327',
        'areaName': '社旗县'
      }, {
        'areaCode': '411328',
        'areaName': '唐河县'
      }, {
        'areaCode': '411329',
        'areaName': '新野县'
      }, {
        'areaCode': '411330',
        'areaName': '桐柏县'
      }, {
        'areaCode': '411381',
        'areaName': '邓州市'
      }]
    }, {
      'cityCode': '411400',
      'cityName': '商丘市',
      'mallAreaList': [{
        'areaCode': '411402',
        'areaName': '梁园区'
      }, {
        'areaCode': '411403',
        'areaName': '睢阳区'
      }, {
        'areaCode': '411421',
        'areaName': '民权县'
      }, {
        'areaCode': '411422',
        'areaName': '睢县'
      }, {
        'areaCode': '411423',
        'areaName': '宁陵县'
      }, {
        'areaCode': '411424',
        'areaName': '柘城县'
      }, {
        'areaCode': '411425',
        'areaName': '虞城县'
      }, {
        'areaCode': '411426',
        'areaName': '夏邑县'
      }, {
        'areaCode': '411481',
        'areaName': '永城市'
      }]
    }, {
      'cityCode': '411500',
      'cityName': '信阳市',
      'mallAreaList': [{
        'areaCode': '411502',
        'areaName': '浉河区'
      }, {
        'areaCode': '411503',
        'areaName': '平桥区'
      }, {
        'areaCode': '411521',
        'areaName': '罗山县'
      }, {
        'areaCode': '411522',
        'areaName': '光山县'
      }, {
        'areaCode': '411523',
        'areaName': '新县'
      }, {
        'areaCode': '411524',
        'areaName': '商城县'
      }, {
        'areaCode': '411525',
        'areaName': '固始县'
      }, {
        'areaCode': '411526',
        'areaName': '潢川县'
      }, {
        'areaCode': '411527',
        'areaName': '淮滨县'
      }, {
        'areaCode': '411528',
        'areaName': '息县'
      }]
    }, {
      'cityCode': '411600',
      'cityName': '周口市',
      'mallAreaList': [{
        'areaCode': '411602',
        'areaName': '川汇区'
      }, {
        'areaCode': '411621',
        'areaName': '扶沟县'
      }, {
        'areaCode': '411622',
        'areaName': '西华县'
      }, {
        'areaCode': '411623',
        'areaName': '商水县'
      }, {
        'areaCode': '411624',
        'areaName': '沈丘县'
      }, {
        'areaCode': '411625',
        'areaName': '郸城县'
      }, {
        'areaCode': '411626',
        'areaName': '淮阳县'
      }, {
        'areaCode': '411627',
        'areaName': '太康县'
      }, {
        'areaCode': '411628',
        'areaName': '鹿邑县'
      }, {
        'areaCode': '411681',
        'areaName': '项城市'
      }]
    }, {
      'cityCode': '411700',
      'cityName': '驻马店市',
      'mallAreaList': [{
        'areaCode': '411702',
        'areaName': '驿城区'
      }, {
        'areaCode': '411721',
        'areaName': '西平县'
      }, {
        'areaCode': '411722',
        'areaName': '上蔡县'
      }, {
        'areaCode': '411723',
        'areaName': '平舆县'
      }, {
        'areaCode': '411724',
        'areaName': '正阳县'
      }, {
        'areaCode': '411725',
        'areaName': '确山县'
      }, {
        'areaCode': '411726',
        'areaName': '泌阳县'
      }, {
        'areaCode': '411727',
        'areaName': '汝南县'
      }, {
        'areaCode': '411728',
        'areaName': '遂平县'
      }, {
        'areaCode': '411729',
        'areaName': '新蔡县'
      }]
    }, {
      'cityCode': '419000',
      'cityName': '河南省直辖县级行政区划',
      'mallAreaList': [{
        'areaCode': '419001',
        'areaName': '济源市'
      }]
    }]
  }, {
    'provinceCode': '420000',
    'provinceName': '湖北省',
    'mallCityList': [{
      'cityCode': '420080',
      'cityName': '襄阳市',
      'mallAreaList': [{
        'areaCode': '420081',
        'areaName': '襄州区'
      }]
    }, {
      'cityCode': '420100',
      'cityName': '武汉市',
      'mallAreaList': [{
        'areaCode': '420102',
        'areaName': '江岸区'
      }, {
        'areaCode': '420103',
        'areaName': '江汉区'
      }, {
        'areaCode': '420104',
        'areaName': '硚口区'
      }, {
        'areaCode': '420105',
        'areaName': '汉阳区'
      }, {
        'areaCode': '420106',
        'areaName': '武昌区'
      }, {
        'areaCode': '420107',
        'areaName': '青山区'
      }, {
        'areaCode': '420111',
        'areaName': '洪山区'
      }, {
        'areaCode': '420112',
        'areaName': '东西湖区'
      }, {
        'areaCode': '420113',
        'areaName': '汉南区'
      }, {
        'areaCode': '420114',
        'areaName': '蔡甸区'
      }, {
        'areaCode': '420115',
        'areaName': '江夏区'
      }, {
        'areaCode': '420116',
        'areaName': '黄陂区'
      }, {
        'areaCode': '420117',
        'areaName': '新洲区'
      }]
    }, {
      'cityCode': '420200',
      'cityName': '黄石市',
      'mallAreaList': [{
        'areaCode': '420202',
        'areaName': '黄石港区'
      }, {
        'areaCode': '420203',
        'areaName': '西塞山区'
      }, {
        'areaCode': '420204',
        'areaName': '下陆区'
      }, {
        'areaCode': '420205',
        'areaName': '铁山区'
      }, {
        'areaCode': '420222',
        'areaName': '阳新县'
      }, {
        'areaCode': '420281',
        'areaName': '大冶市'
      }]
    }, {
      'cityCode': '420300',
      'cityName': '十堰市',
      'mallAreaList': [{
        'areaCode': '420302',
        'areaName': '茅箭区'
      }, {
        'areaCode': '420303',
        'areaName': '张湾区'
      }, {
        'areaCode': '420304',
        'areaName': '郧阳区'
      }, {
        'areaCode': '420322',
        'areaName': '郧西县'
      }, {
        'areaCode': '420323',
        'areaName': '竹山县'
      }, {
        'areaCode': '420324',
        'areaName': '竹溪县'
      }, {
        'areaCode': '420325',
        'areaName': '房县'
      }, {
        'areaCode': '420381',
        'areaName': '丹江口市'
      }]
    }, {
      'cityCode': '420500',
      'cityName': '宜昌市',
      'mallAreaList': [{
        'areaCode': '420502',
        'areaName': '西陵区'
      }, {
        'areaCode': '420503',
        'areaName': '伍家岗区'
      }, {
        'areaCode': '420504',
        'areaName': '点军区'
      }, {
        'areaCode': '420505',
        'areaName': '猇亭区'
      }, {
        'areaCode': '420506',
        'areaName': '夷陵区'
      }, {
        'areaCode': '420525',
        'areaName': '远安县'
      }, {
        'areaCode': '420526',
        'areaName': '兴山县'
      }, {
        'areaCode': '420527',
        'areaName': '秭归县'
      }, {
        'areaCode': '420528',
        'areaName': '长阳土家族自治县'
      }, {
        'areaCode': '420529',
        'areaName': '五峰土家族自治县'
      }, {
        'areaCode': '420581',
        'areaName': '宜都市'
      }, {
        'areaCode': '420582',
        'areaName': '当阳市'
      }, {
        'areaCode': '420583',
        'areaName': '枝江市'
      }]
    }, {
      'cityCode': '420600',
      'cityName': '襄樊市',
      'mallAreaList': [{
        'areaCode': '420602',
        'areaName': '襄城区'
      }, {
        'areaCode': '420606',
        'areaName': '樊城区'
      }, {
        'areaCode': '420607',
        'areaName': '襄州区'
      }, {
        'areaCode': '420624',
        'areaName': '南漳县'
      }, {
        'areaCode': '420625',
        'areaName': '谷城县'
      }, {
        'areaCode': '420626',
        'areaName': '保康县'
      }, {
        'areaCode': '420682',
        'areaName': '老河口市'
      }, {
        'areaCode': '420683',
        'areaName': '枣阳市'
      }, {
        'areaCode': '420684',
        'areaName': '宜城市'
      }]
    }, {
      'cityCode': '420700',
      'cityName': '鄂州市',
      'mallAreaList': [{
        'areaCode': '420702',
        'areaName': '梁子湖区'
      }, {
        'areaCode': '420703',
        'areaName': '华容区'
      }, {
        'areaCode': '420704',
        'areaName': '鄂城区'
      }]
    }, {
      'cityCode': '420800',
      'cityName': '荆门市',
      'mallAreaList': [{
        'areaCode': '420802',
        'areaName': '东宝区'
      }, {
        'areaCode': '420804',
        'areaName': '掇刀区'
      }, {
        'areaCode': '420821',
        'areaName': '京山县'
      }, {
        'areaCode': '420822',
        'areaName': '沙洋县'
      }, {
        'areaCode': '420881',
        'areaName': '钟祥市'
      }]
    }, {
      'cityCode': '420900',
      'cityName': '孝感市',
      'mallAreaList': [{
        'areaCode': '420902',
        'areaName': '孝南区'
      }, {
        'areaCode': '420921',
        'areaName': '孝昌县'
      }, {
        'areaCode': '420922',
        'areaName': '大悟县'
      }, {
        'areaCode': '420923',
        'areaName': '云梦县'
      }, {
        'areaCode': '420981',
        'areaName': '应城市'
      }, {
        'areaCode': '420982',
        'areaName': '安陆市'
      }, {
        'areaCode': '420984',
        'areaName': '汉川市'
      }]
    }, {
      'cityCode': '421000',
      'cityName': '荆州市',
      'mallAreaList': [{
        'areaCode': '421002',
        'areaName': '沙市区'
      }, {
        'areaCode': '421003',
        'areaName': '荆州区'
      }, {
        'areaCode': '421022',
        'areaName': '公安县'
      }, {
        'areaCode': '421023',
        'areaName': '监利县'
      }, {
        'areaCode': '421024',
        'areaName': '江陵县'
      }, {
        'areaCode': '421081',
        'areaName': '石首市'
      }, {
        'areaCode': '421083',
        'areaName': '洪湖市'
      }, {
        'areaCode': '421087',
        'areaName': '松滋市'
      }]
    }, {
      'cityCode': '421100',
      'cityName': '黄冈市',
      'mallAreaList': [{
        'areaCode': '421102',
        'areaName': '黄州区'
      }, {
        'areaCode': '421121',
        'areaName': '团风县'
      }, {
        'areaCode': '421122',
        'areaName': '红安县'
      }, {
        'areaCode': '421123',
        'areaName': '罗田县'
      }, {
        'areaCode': '421124',
        'areaName': '英山县'
      }, {
        'areaCode': '421125',
        'areaName': '浠水县'
      }, {
        'areaCode': '421126',
        'areaName': '蕲春县'
      }, {
        'areaCode': '421127',
        'areaName': '黄梅县'
      }, {
        'areaCode': '421181',
        'areaName': '麻城市'
      }, {
        'areaCode': '421182',
        'areaName': '武穴市'
      }]
    }, {
      'cityCode': '421200',
      'cityName': '咸宁市',
      'mallAreaList': [{
        'areaCode': '421202',
        'areaName': '咸安区'
      }, {
        'areaCode': '421221',
        'areaName': '嘉鱼县'
      }, {
        'areaCode': '421222',
        'areaName': '通城县'
      }, {
        'areaCode': '421223',
        'areaName': '崇阳县'
      }, {
        'areaCode': '421224',
        'areaName': '通山县'
      }, {
        'areaCode': '421281',
        'areaName': '赤壁市'
      }]
    }, {
      'cityCode': '421300',
      'cityName': '随州市',
      'mallAreaList': [{
        'areaCode': '421303',
        'areaName': '曾都区'
      }, {
        'areaCode': '421321',
        'areaName': '随县'
      }, {
        'areaCode': '421381',
        'areaName': '广水市'
      }]
    }, {
      'cityCode': '422800',
      'cityName': '恩施土家族苗族自治州',
      'mallAreaList': [{
        'areaCode': '422801',
        'areaName': '恩施市'
      }, {
        'areaCode': '422802',
        'areaName': '利川市'
      }, {
        'areaCode': '422822',
        'areaName': '建始县'
      }, {
        'areaCode': '422823',
        'areaName': '巴东县'
      }, {
        'areaCode': '422825',
        'areaName': '宣恩县'
      }, {
        'areaCode': '422826',
        'areaName': '咸丰县'
      }, {
        'areaCode': '422827',
        'areaName': '来凤县'
      }, {
        'areaCode': '422828',
        'areaName': '鹤峰县'
      }]
    }, {
      'cityCode': '429000',
      'cityName': '省直辖行政单位',
      'mallAreaList': [{
        'areaCode': '429004',
        'areaName': '仙桃市'
      }, {
        'areaCode': '429005',
        'areaName': '潜江市'
      }, {
        'areaCode': '429006',
        'areaName': '天门市'
      }, {
        'areaCode': '429021',
        'areaName': '神农架林区'
      }]
    }]
  }, {
    'provinceCode': '430000',
    'provinceName': '湖南省',
    'mallCityList': [{
      'cityCode': '430100',
      'cityName': '长沙市',
      'mallAreaList': [{
        'areaCode': '430102',
        'areaName': '芙蓉区'
      }, {
        'areaCode': '430103',
        'areaName': '天心区'
      }, {
        'areaCode': '430104',
        'areaName': '岳麓区'
      }, {
        'areaCode': '430105',
        'areaName': '开福区'
      }, {
        'areaCode': '430111',
        'areaName': '雨花区'
      }, {
        'areaCode': '430112',
        'areaName': '望城区'
      }, {
        'areaCode': '430121',
        'areaName': '长沙县'
      }, {
        'areaCode': '430124',
        'areaName': '宁乡县'
      }, {
        'areaCode': '430181',
        'areaName': '浏阳市'
      }]
    }, {
      'cityCode': '430200',
      'cityName': '株洲市',
      'mallAreaList': [{
        'areaCode': '430202',
        'areaName': '荷塘区'
      }, {
        'areaCode': '430203',
        'areaName': '芦淞区'
      }, {
        'areaCode': '430204',
        'areaName': '石峰区'
      }, {
        'areaCode': '430211',
        'areaName': '天元区'
      }, {
        'areaCode': '430221',
        'areaName': '株洲县'
      }, {
        'areaCode': '430223',
        'areaName': '攸县'
      }, {
        'areaCode': '430224',
        'areaName': '茶陵县'
      }, {
        'areaCode': '430225',
        'areaName': '炎陵县'
      }, {
        'areaCode': '430281',
        'areaName': '醴陵市'
      }]
    }, {
      'cityCode': '430300',
      'cityName': '湘潭市',
      'mallAreaList': [{
        'areaCode': '430302',
        'areaName': '雨湖区'
      }, {
        'areaCode': '430304',
        'areaName': '岳塘区'
      }, {
        'areaCode': '430321',
        'areaName': '湘潭县'
      }, {
        'areaCode': '430381',
        'areaName': '湘乡市'
      }, {
        'areaCode': '430382',
        'areaName': '韶山市'
      }]
    }, {
      'cityCode': '430400',
      'cityName': '衡阳市',
      'mallAreaList': [{
        'areaCode': '430405',
        'areaName': '珠晖区'
      }, {
        'areaCode': '430406',
        'areaName': '雁峰区'
      }, {
        'areaCode': '430407',
        'areaName': '石鼓区'
      }, {
        'areaCode': '430408',
        'areaName': '蒸湘区'
      }, {
        'areaCode': '430412',
        'areaName': '南岳区'
      }, {
        'areaCode': '430421',
        'areaName': '衡阳县'
      }, {
        'areaCode': '430422',
        'areaName': '衡南县'
      }, {
        'areaCode': '430423',
        'areaName': '衡山县'
      }, {
        'areaCode': '430424',
        'areaName': '衡东县'
      }, {
        'areaCode': '430426',
        'areaName': '祁东县'
      }, {
        'areaCode': '430481',
        'areaName': '耒阳市'
      }, {
        'areaCode': '430482',
        'areaName': '常宁市'
      }]
    }, {
      'cityCode': '430500',
      'cityName': '邵阳市',
      'mallAreaList': [{
        'areaCode': '430502',
        'areaName': '双清区'
      }, {
        'areaCode': '430503',
        'areaName': '大祥区'
      }, {
        'areaCode': '430511',
        'areaName': '北塔区'
      }, {
        'areaCode': '430521',
        'areaName': '邵东县'
      }, {
        'areaCode': '430522',
        'areaName': '新邵县'
      }, {
        'areaCode': '430523',
        'areaName': '邵阳县'
      }, {
        'areaCode': '430524',
        'areaName': '隆回县'
      }, {
        'areaCode': '430525',
        'areaName': '洞口县'
      }, {
        'areaCode': '430527',
        'areaName': '绥宁县'
      }, {
        'areaCode': '430528',
        'areaName': '新宁县'
      }, {
        'areaCode': '430529',
        'areaName': '城步苗族自治县'
      }, {
        'areaCode': '430581',
        'areaName': '武冈市'
      }]
    }, {
      'cityCode': '430600',
      'cityName': '岳阳市',
      'mallAreaList': [{
        'areaCode': '430602',
        'areaName': '岳阳楼区'
      }, {
        'areaCode': '430603',
        'areaName': '云溪区'
      }, {
        'areaCode': '430611',
        'areaName': '君山区'
      }, {
        'areaCode': '430621',
        'areaName': '岳阳县'
      }, {
        'areaCode': '430623',
        'areaName': '华容县'
      }, {
        'areaCode': '430624',
        'areaName': '湘阴县'
      }, {
        'areaCode': '430626',
        'areaName': '平江县'
      }, {
        'areaCode': '430681',
        'areaName': '汨罗市'
      }, {
        'areaCode': '430682',
        'areaName': '临湘市'
      }]
    }, {
      'cityCode': '430700',
      'cityName': '常德市',
      'mallAreaList': [{
        'areaCode': '430702',
        'areaName': '武陵区'
      }, {
        'areaCode': '430703',
        'areaName': '鼎城区'
      }, {
        'areaCode': '430721',
        'areaName': '安乡县'
      }, {
        'areaCode': '430722',
        'areaName': '汉寿县'
      }, {
        'areaCode': '430723',
        'areaName': '澧县'
      }, {
        'areaCode': '430724',
        'areaName': '临澧县'
      }, {
        'areaCode': '430725',
        'areaName': '桃源县'
      }, {
        'areaCode': '430726',
        'areaName': '石门县'
      }, {
        'areaCode': '430781',
        'areaName': '津市市'
      }]
    }, {
      'cityCode': '430800',
      'cityName': '张家界市',
      'mallAreaList': [{
        'areaCode': '430802',
        'areaName': '永定区'
      }, {
        'areaCode': '430811',
        'areaName': '武陵源区'
      }, {
        'areaCode': '430821',
        'areaName': '慈利县'
      }, {
        'areaCode': '430822',
        'areaName': '桑植县'
      }]
    }, {
      'cityCode': '430900',
      'cityName': '益阳市',
      'mallAreaList': [{
        'areaCode': '430902',
        'areaName': '资阳区'
      }, {
        'areaCode': '430903',
        'areaName': '赫山区'
      }, {
        'areaCode': '430921',
        'areaName': '南县'
      }, {
        'areaCode': '430922',
        'areaName': '桃江县'
      }, {
        'areaCode': '430923',
        'areaName': '安化县'
      }, {
        'areaCode': '430981',
        'areaName': '沅江市'
      }]
    }, {
      'cityCode': '431000',
      'cityName': '郴州市',
      'mallAreaList': [{
        'areaCode': '431002',
        'areaName': '北湖区'
      }, {
        'areaCode': '431003',
        'areaName': '苏仙区'
      }, {
        'areaCode': '431021',
        'areaName': '桂阳县'
      }, {
        'areaCode': '431022',
        'areaName': '宜章县'
      }, {
        'areaCode': '431023',
        'areaName': '永兴县'
      }, {
        'areaCode': '431024',
        'areaName': '嘉禾县'
      }, {
        'areaCode': '431025',
        'areaName': '临武县'
      }, {
        'areaCode': '431026',
        'areaName': '汝城县'
      }, {
        'areaCode': '431027',
        'areaName': '桂东县'
      }, {
        'areaCode': '431028',
        'areaName': '安仁县'
      }, {
        'areaCode': '431081',
        'areaName': '资兴市'
      }]
    }, {
      'cityCode': '431100',
      'cityName': '永州市',
      'mallAreaList': [{
        'areaCode': '431102',
        'areaName': '零陵区'
      }, {
        'areaCode': '431103',
        'areaName': '冷水滩区'
      }, {
        'areaCode': '431121',
        'areaName': '祁阳县'
      }, {
        'areaCode': '431122',
        'areaName': '东安县'
      }, {
        'areaCode': '431123',
        'areaName': '双牌县'
      }, {
        'areaCode': '431124',
        'areaName': '道县'
      }, {
        'areaCode': '431125',
        'areaName': '江永县'
      }, {
        'areaCode': '431126',
        'areaName': '宁远县'
      }, {
        'areaCode': '431127',
        'areaName': '蓝山县'
      }, {
        'areaCode': '431128',
        'areaName': '新田县'
      }, {
        'areaCode': '431129',
        'areaName': '江华瑶族自治县'
      }]
    }, {
      'cityCode': '431200',
      'cityName': '怀化市',
      'mallAreaList': [{
        'areaCode': '431202',
        'areaName': '鹤城区'
      }, {
        'areaCode': '431221',
        'areaName': '中方县'
      }, {
        'areaCode': '431222',
        'areaName': '沅陵县'
      }, {
        'areaCode': '431223',
        'areaName': '辰溪县'
      }, {
        'areaCode': '431224',
        'areaName': '溆浦县'
      }, {
        'areaCode': '431225',
        'areaName': '会�������县'
      }, {
        'areaCode': '431226',
        'areaName': '麻阳苗族自治县'
      }, {
        'areaCode': '431227',
        'areaName': '新晃侗族自治县'
      }, {
        'areaCode': '431228',
        'areaName': '芷江侗族自治县'
      }, {
        'areaCode': '431229',
        'areaName': '靖州苗族侗族自治县'
      }, {
        'areaCode': '431230',
        'areaName': '通道侗族自治县'
      }, {
        'areaCode': '431281',
        'areaName': '洪江市'
      }]
    }, {
      'cityCode': '431300',
      'cityName': '娄底市',
      'mallAreaList': [{
        'areaCode': '431302',
        'areaName': '娄星区'
      }, {
        'areaCode': '431321',
        'areaName': '双峰县'
      }, {
        'areaCode': '431322',
        'areaName': '新化县'
      }, {
        'areaCode': '431381',
        'areaName': '冷水江市'
      }, {
        'areaCode': '431382',
        'areaName': '涟源市'
      }]
    }, {
      'cityCode': '433100',
      'cityName': '湘西土家族苗族自治州',
      'mallAreaList': [{
        'areaCode': '433101',
        'areaName': '吉首市'
      }, {
        'areaCode': '433122',
        'areaName': '泸溪县'
      }, {
        'areaCode': '433123',
        'areaName': '凤凰县'
      }, {
        'areaCode': '433124',
        'areaName': '花垣县'
      }, {
        'areaCode': '433125',
        'areaName': '保靖县'
      }, {
        'areaCode': '433126',
        'areaName': '古丈县'
      }, {
        'areaCode': '433127',
        'areaName': '永顺县'
      }, {
        'areaCode': '433130',
        'areaName': '龙山县'
      }]
    }]
  }, {
    'provinceCode': '440000',
    'provinceName': '广东省',
    'mallCityList': [{
      'cityCode': '440100',
      'cityName': '广州市',
      'mallAreaList': [{
        'areaCode': '440103',
        'areaName': '荔湾区'
      }, {
        'areaCode': '440104',
        'areaName': '越秀区'
      }, {
        'areaCode': '440105',
        'areaName': '海珠区'
      }, {
        'areaCode': '440106',
        'areaName': '天河区'
      }, {
        'areaCode': '440111',
        'areaName': '白云区'
      }, {
        'areaCode': '440112',
        'areaName': '黄埔区'
      }, {
        'areaCode': '440113',
        'areaName': '番禺区'
      }, {
        'areaCode': '440114',
        'areaName': '花都区'
      }, {
        'areaCode': '440115',
        'areaName': '南沙区'
      }, {
        'areaCode': '440116',
        'areaName': '萝岗区'
      }, {
        'areaCode': '440117',
        'areaName': '从化区'
      }, {
        'areaCode': '440118',
        'areaName': '增城区'
      }]
    }, {
      'cityCode': '440200',
      'cityName': '韶关市',
      'mallAreaList': [{
        'areaCode': '440203',
        'areaName': '武江区'
      }, {
        'areaCode': '440204',
        'areaName': '浈江区'
      }, {
        'areaCode': '440205',
        'areaName': '曲江区'
      }, {
        'areaCode': '440222',
        'areaName': '始兴县'
      }, {
        'areaCode': '440224',
        'areaName': '仁化县'
      }, {
        'areaCode': '440229',
        'areaName': '翁源县'
      }, {
        'areaCode': '440232',
        'areaName': '乳源瑶族自治县'
      }, {
        'areaCode': '440233',
        'areaName': '新丰县'
      }, {
        'areaCode': '440281',
        'areaName': '乐昌市'
      }, {
        'areaCode': '440282',
        'areaName': '南雄市'
      }]
    }, {
      'cityCode': '440300',
      'cityName': '深圳市',
      'mallAreaList': [{
        'areaCode': '440303',
        'areaName': '罗湖区'
      }, {
        'areaCode': '440304',
        'areaName': '福田区'
      }, {
        'areaCode': '440305',
        'areaName': '南山区'
      }, {
        'areaCode': '440306',
        'areaName': '宝安区'
      }, {
        'areaCode': '440307',
        'areaName': '龙岗区'
      }, {
        'areaCode': '440308',
        'areaName': '盐田区'
      }, {
        'areaCode': '440309',
        'areaName': '龙华区'
      }]
    }, {
      'cityCode': '440400',
      'cityName': '珠海市',
      'mallAreaList': [{
        'areaCode': '440402',
        'areaName': '香洲区'
      }, {
        'areaCode': '440403',
        'areaName': '斗门区'
      }, {
        'areaCode': '440404',
        'areaName': '金湾区'
      }]
    }, {
      'cityCode': '440500',
      'cityName': '汕头市',
      'mallAreaList': [{
        'areaCode': '440507',
        'areaName': '龙湖区'
      }, {
        'areaCode': '440511',
        'areaName': '金平区'
      }, {
        'areaCode': '440512',
        'areaName': '濠江区'
      }, {
        'areaCode': '440513',
        'areaName': '潮阳区'
      }, {
        'areaCode': '440514',
        'areaName': '潮南区'
      }, {
        'areaCode': '440515',
        'areaName': '澄海区'
      }, {
        'areaCode': '440523',
        'areaName': '南澳县'
      }]
    }, {
      'cityCode': '440600',
      'cityName': '佛山市',
      'mallAreaList': [{
        'areaCode': '440604',
        'areaName': '禅城区'
      }, {
        'areaCode': '440605',
        'areaName': '南海区'
      }, {
        'areaCode': '440606',
        'areaName': '顺德区'
      }, {
        'areaCode': '440607',
        'areaName': '三水区'
      }, {
        'areaCode': '440608',
        'areaName': '高明区'
      }]
    }, {
      'cityCode': '440700',
      'cityName': '江门市',
      'mallAreaList': [{
        'areaCode': '440703',
        'areaName': '蓬江区'
      }, {
        'areaCode': '440704',
        'areaName': '江海区'
      }, {
        'areaCode': '440705',
        'areaName': '新会区'
      }, {
        'areaCode': '440781',
        'areaName': '台山市'
      }, {
        'areaCode': '440783',
        'areaName': '开平市'
      }, {
        'areaCode': '440784',
        'areaName': '鹤山市'
      }, {
        'areaCode': '440785',
        'areaName': '恩平市'
      }]
    }, {
      'cityCode': '440800',
      'cityName': '湛江市',
      'mallAreaList': [{
        'areaCode': '440802',
        'areaName': '赤坎区'
      }, {
        'areaCode': '440803',
        'areaName': '霞山区'
      }, {
        'areaCode': '440804',
        'areaName': '坡头区'
      }, {
        'areaCode': '440811',
        'areaName': '麻章区'
      }, {
        'areaCode': '440823',
        'areaName': '遂溪县'
      }, {
        'areaCode': '440825',
        'areaName': '徐闻县'
      }, {
        'areaCode': '440881',
        'areaName': '廉江市'
      }, {
        'areaCode': '440882',
        'areaName': '雷州市'
      }, {
        'areaCode': '440883',
        'areaName': '吴川市'
      }]
    }, {
      'cityCode': '440900',
      'cityName': '茂名市',
      'mallAreaList': [{
        'areaCode': '440902',
        'areaName': '茂南区'
      }, {
        'areaCode': '440904',
        'areaName': '电白区'
      }, {
        'areaCode': '440981',
        'areaName': '高州市'
      }, {
        'areaCode': '440982',
        'areaName': '化州市'
      }, {
        'areaCode': '440983',
        'areaName': '信宜市'
      }]
    }, {
      'cityCode': '441200',
      'cityName': '肇庆市',
      'mallAreaList': [{
        'areaCode': '441202',
        'areaName': '端州区'
      }, {
        'areaCode': '441203',
        'areaName': '鼎湖区'
      }, {
        'areaCode': '441223',
        'areaName': '广宁县'
      }, {
        'areaCode': '441224',
        'areaName': '怀集县'
      }, {
        'areaCode': '441225',
        'areaName': '封开县'
      }, {
        'areaCode': '441226',
        'areaName': '德庆县'
      }, {
        'areaCode': '441283',
        'areaName': '高要市'
      }, {
        'areaCode': '441284',
        'areaName': '四会市'
      }]
    }, {
      'cityCode': '441300',
      'cityName': '惠州市',
      'mallAreaList': [{
        'areaCode': '441302',
        'areaName': '惠城区'
      }, {
        'areaCode': '441303',
        'areaName': '惠阳区'
      }, {
        'areaCode': '441322',
        'areaName': '博罗县'
      }, {
        'areaCode': '441323',
        'areaName': '惠东县'
      }, {
        'areaCode': '441324',
        'areaName': '龙门县'
      }]
    }, {
      'cityCode': '441400',
      'cityName': '梅州市',
      'mallAreaList': [{
        'areaCode': '441402',
        'areaName': '梅江区'
      }, {
        'areaCode': '441403',
        'areaName': '梅县区'
      }, {
        'areaCode': '441422',
        'areaName': '大埔县'
      }, {
        'areaCode': '441423',
        'areaName': '丰顺县'
      }, {
        'areaCode': '441424',
        'areaName': '五华县'
      }, {
        'areaCode': '441426',
        'areaName': '平远县'
      }, {
        'areaCode': '441427',
        'areaName': '蕉岭县'
      }, {
        'areaCode': '441481',
        'areaName': '兴宁市'
      }]
    }, {
      'cityCode': '441500',
      'cityName': '汕尾市',
      'mallAreaList': [{
        'areaCode': '441502',
        'areaName': '城区'
      }, {
        'areaCode': '441521',
        'areaName': '海丰县'
      }, {
        'areaCode': '441523',
        'areaName': '陆河县'
      }, {
        'areaCode': '441581',
        'areaName': '陆丰市'
      }]
    }, {
      'cityCode': '441600',
      'cityName': '河源市',
      'mallAreaList': [{
        'areaCode': '441602',
        'areaName': '源城区'
      }, {
        'areaCode': '441621',
        'areaName': '紫金县'
      }, {
        'areaCode': '441622',
        'areaName': '龙川县'
      }, {
        'areaCode': '441623',
        'areaName': '连平县'
      }, {
        'areaCode': '441624',
        'areaName': '和平县'
      }, {
        'areaCode': '441625',
        'areaName': '东源县'
      }]
    }, {
      'cityCode': '441700',
      'cityName': '阳江市',
      'mallAreaList': [{
        'areaCode': '441702',
        'areaName': '江城区'
      }, {
        'areaCode': '441721',
        'areaName': '阳西县'
      }, {
        'areaCode': '441723',
        'areaName': '阳东县'
      }, {
        'areaCode': '441781',
        'areaName': '阳春市'
      }]
    }, {
      'cityCode': '441800',
      'cityName': '清远市',
      'mallAreaList': [{
        'areaCode': '441802',
        'areaName': '清城区'
      }, {
        'areaCode': '441803',
        'areaName': '清新区'
      }, {
        'areaCode': '441821',
        'areaName': '佛冈县'
      }, {
        'areaCode': '441823',
        'areaName': '阳山县'
      }, {
        'areaCode': '441825',
        'areaName': '连山壮族瑶族自治县'
      }, {
        'areaCode': '441826',
        'areaName': '连南瑶族自治县'
      }, {
        'areaCode': '441881',
        'areaName': '英德市'
      }, {
        'areaCode': '441882',
        'areaName': '连州市'
      }]
    }, {
      'cityCode': '441900',
      'cityName': '东莞市',
      'mallAreaList': [{
        'areaCode': '441900',
        'areaName': '寮步镇'
      }, {
        'areaCode': '441901',
        'areaName': '莞城区'
      }, {
        'areaCode': '441902',
        'areaName': '常平镇'
      }, {
        'areaCode': '441902',
        'areaName': '万江区'
      }, {
        'areaCode': '441902',
        'areaName': '塘厦镇'
      }, {
        'areaCode': '441902',
        'areaName': '东城区'
      }, {
        'areaCode': '441902',
        'areaName': '虎门镇'
      }]
    }, {
      'cityCode': '442000',
      'cityName': '中山市',
      'mallAreaList': [{
        'areaCode': '442000',
        'areaName': '东区街道'
      }, {
        'areaCode': '442022',
        'areaName': '西区街道'
      }, {
        'areaCode': '442021',
        'areaName': '南区街道'
      }, {
        'areaCode': '442001',
        'areaName': '石岐区街道'
      }, {
        'areaCode': '442002',
        'areaName': '小榄镇'
      }, {
        'areaCode': '442003',
        'areaName': '沙溪镇'
      }, {
        'areaCode': '442004',
        'areaName': '火炬开发区'
      }, {
        'areaCode': '442005',
        'areaName': '横栏镇'
      }, {
        'areaCode': '442006',
        'areaName': '三乡镇'
      }, {
        'areaCode': '442007',
        'areaName': '东凤镇'
      }, {
        'areaCode': '442008',
        'areaName': '大涌镇'
      }, {
        'areaCode': '442009',
        'areaName': '五桂山街道'
      }, {
        'areaCode': '442010',
        'areaName': '港口镇'
      }, {
        'areaCode': '442011',
        'areaName': '板芙镇'
      }, {
        'areaCode': '442012',
        'areaName': '神湾镇'
      }, {
        'areaCode': '442013',
        'areaName': '阜沙镇'
      }, {
        'areaCode': '442014',
        'areaName': '黄圃镇'
      }, {
        'areaCode': '442015',
        'areaName': '南头镇'
      }, {
        'areaCode': '442016',
        'areaName': '三角镇'
      }, {
        'areaCode': '442017',
        'areaName': '民众镇'
      }, {
        'areaCode': '442018',
        'areaName': '南朗镇'
      }, {
        'areaCode': '442019',
        'areaName': '坦洲镇'
      }, {
        'areaCode': '442020',
        'areaName': '东升镇'
      }]
    }, {
      'cityCode': '445100',
      'cityName': '潮州市',
      'mallAreaList': [{
        'areaCode': '445102',
        'areaName': '湘桥区'
      }, {
        'areaCode': '445103',
        'areaName': '潮安区'
      }, {
        'areaCode': '445122',
        'areaName': '饶平县'
      }]
    }, {
      'cityCode': '445200',
      'cityName': '揭阳市',
      'mallAreaList': [{
        'areaCode': '445202',
        'areaName': '榕城区'
      }, {
        'areaCode': '445203',
        'areaName': '揭东区'
      }, {
        'areaCode': '445222',
        'areaName': '揭西县'
      }, {
        'areaCode': '445224',
        'areaName': '惠来县'
      }, {
        'areaCode': '445281',
        'areaName': '普宁市'
      }]
    }, {
      'cityCode': '445300',
      'cityName': '云浮市',
      'mallAreaList': [{
        'areaCode': '445302',
        'areaName': '云城区'
      }, {
        'areaCode': '445303',
        'areaName': '云安区'
      }, {
        'areaCode': '445321',
        'areaName': '新兴县'
      }, {
        'areaCode': '445322',
        'areaName': '郁南县'
      }, {
        'areaCode': '445381',
        'areaName': '罗定市'
      }]
    }]
  }, {
    'provinceCode': '450000',
    'provinceName': '广西壮族自治区',
    'mallCityList': [{
      'cityCode': '450100',
      'cityName': '南宁市',
      'mallAreaList': [{
        'areaCode': '450102',
        'areaName': '兴宁区'
      }, {
        'areaCode': '450103',
        'areaName': '青秀区'
      }, {
        'areaCode': '450105',
        'areaName': '江南区'
      }, {
        'areaCode': '450107',
        'areaName': '西乡塘区'
      }, {
        'areaCode': '450108',
        'areaName': '良庆区'
      }, {
        'areaCode': '450109',
        'areaName': '邕宁区'
      }, {
        'areaCode': '450122',
        'areaName': '武鸣县'
      }, {
        'areaCode': '450123',
        'areaName': '隆安县'
      }, {
        'areaCode': '450124',
        'areaName': '马山县'
      }, {
        'areaCode': '450125',
        'areaName': '上林县'
      }, {
        'areaCode': '450126',
        'areaName': '宾阳县'
      }, {
        'areaCode': '450127',
        'areaName': '横县'
      }]
    }, {
      'cityCode': '450200',
      'cityName': '柳州市',
      'mallAreaList': [{
        'areaCode': '450202',
        'areaName': '城中区'
      }, {
        'areaCode': '450203',
        'areaName': '鱼峰区'
      }, {
        'areaCode': '450204',
        'areaName': '柳南区'
      }, {
        'areaCode': '450205',
        'areaName': '柳北区'
      }, {
        'areaCode': '450221',
        'areaName': '柳江县'
      }, {
        'areaCode': '450222',
        'areaName': '柳城县'
      }, {
        'areaCode': '450223',
        'areaName': '鹿寨县'
      }, {
        'areaCode': '450224',
        'areaName': '融安县'
      }, {
        'areaCode': '450225',
        'areaName': '融水苗族自治县'
      }, {
        'areaCode': '450226',
        'areaName': '三江侗族自治县'
      }]
    }, {
      'cityCode': '450300',
      'cityName': '桂林市',
      'mallAreaList': [{
        'areaCode': '450302',
        'areaName': '秀峰区'
      }, {
        'areaCode': '450303',
        'areaName': '叠彩区'
      }, {
        'areaCode': '450304',
        'areaName': '象山区'
      }, {
        'areaCode': '450305',
        'areaName': '七星区'
      }, {
        'areaCode': '450311',
        'areaName': '雁山区'
      }, {
        'areaCode': '450312',
        'areaName': '临桂区'
      }, {
        'areaCode': '450321',
        'areaName': '阳朔县'
      }, {
        'areaCode': '450323',
        'areaName': '灵川县'
      }, {
        'areaCode': '450324',
        'areaName': '全州县'
      }, {
        'areaCode': '450325',
        'areaName': '兴安县'
      }, {
        'areaCode': '450326',
        'areaName': '永福县'
      }, {
        'areaCode': '450327',
        'areaName': '灌阳县'
      }, {
        'areaCode': '450328',
        'areaName': '龙胜各族自治县'
      }, {
        'areaCode': '450329',
        'areaName': '资源县'
      }, {
        'areaCode': '450330',
        'areaName': '平乐县'
      }, {
        'areaCode': '450331',
        'areaName': '荔浦县'
      }, {
        'areaCode': '450332',
        'areaName': '恭城瑶族自治县'
      }]
    }, {
      'cityCode': '450400',
      'cityName': '梧州市',
      'mallAreaList': [{
        'areaCode': '450403',
        'areaName': '万秀区'
      }, {
        'areaCode': '450405',
        'areaName': '长洲区'
      }, {
        'areaCode': '450406',
        'areaName': '龙圩区'
      }, {
        'areaCode': '450421',
        'areaName': '苍梧县'
      }, {
        'areaCode': '450422',
        'areaName': '藤县'
      }, {
        'areaCode': '450423',
        'areaName': '蒙山县'
      }, {
        'areaCode': '450481',
        'areaName': '岑溪市'
      }]
    }, {
      'cityCode': '450500',
      'cityName': '北海市',
      'mallAreaList': [{
        'areaCode': '450502',
        'areaName': '海城区'
      }, {
        'areaCode': '450503',
        'areaName': '银海区'
      }, {
        'areaCode': '450512',
        'areaName': '铁山港区'
      }, {
        'areaCode': '450521',
        'areaName': '合浦县'
      }]
    }, {
      'cityCode': '450600',
      'cityName': '防城港市',
      'mallAreaList': [{
        'areaCode': '450602',
        'areaName': '港口区'
      }, {
        'areaCode': '450603',
        'areaName': '防城区'
      }, {
        'areaCode': '450621',
        'areaName': '上思县'
      }, {
        'areaCode': '450681',
        'areaName': '东兴市'
      }]
    }, {
      'cityCode': '450700',
      'cityName': '钦州市',
      'mallAreaList': [{
        'areaCode': '450702',
        'areaName': '钦南区'
      }, {
        'areaCode': '450703',
        'areaName': '钦北区'
      }, {
        'areaCode': '450721',
        'areaName': '灵山县'
      }, {
        'areaCode': '450722',
        'areaName': '浦北县'
      }]
    }, {
      'cityCode': '450800',
      'cityName': '贵港市',
      'mallAreaList': [{
        'areaCode': '450802',
        'areaName': '港北区'
      }, {
        'areaCode': '450803',
        'areaName': '港南区'
      }, {
        'areaCode': '450804',
        'areaName': '覃塘区'
      }, {
        'areaCode': '450821',
        'areaName': '平南县'
      }, {
        'areaCode': '450881',
        'areaName': '桂平市'
      }]
    }, {
      'cityCode': '450900',
      'cityName': '玉林市',
      'mallAreaList': [{
        'areaCode': '450902',
        'areaName': '玉州区'
      }, {
        'areaCode': '450903',
        'areaName': '福绵区'
      }, {
        'areaCode': '450921',
        'areaName': '容县'
      }, {
        'areaCode': '450922',
        'areaName': '陆川县'
      }, {
        'areaCode': '450923',
        'areaName': '博白县'
      }, {
        'areaCode': '450924',
        'areaName': '兴业县'
      }, {
        'areaCode': '450981',
        'areaName': '北流市'
      }]
    }, {
      'cityCode': '451000',
      'cityName': '百色市',
      'mallAreaList': [{
        'areaCode': '451002',
        'areaName': '右江区'
      }, {
        'areaCode': '451021',
        'areaName': '田阳县'
      }, {
        'areaCode': '451022',
        'areaName': '田东县'
      }, {
        'areaCode': '451023',
        'areaName': '平果县'
      }, {
        'areaCode': '451024',
        'areaName': '德保县'
      }, {
        'areaCode': '451025',
        'areaName': '靖西县'
      }, {
        'areaCode': '451026',
        'areaName': '那坡县'
      }, {
        'areaCode': '451027',
        'areaName': '凌云县'
      }, {
        'areaCode': '451028',
        'areaName': '乐业县'
      }, {
        'areaCode': '451029',
        'areaName': '田林县'
      }, {
        'areaCode': '451030',
        'areaName': '西林县'
      }, {
        'areaCode': '451031',
        'areaName': '隆林各族自治县'
      }]
    }, {
      'cityCode': '451100',
      'cityName': '贺州市',
      'mallAreaList': [{
        'areaCode': '451102',
        'areaName': '八步区'
      }, {
        'areaCode': '451121',
        'areaName': '昭平县'
      }, {
        'areaCode': '451122',
        'areaName': '钟山县'
      }, {
        'areaCode': '451123',
        'areaName': '富川瑶族自治县'
      }]
    }, {
      'cityCode': '451200',
      'cityName': '河池市',
      'mallAreaList': [{
        'areaCode': '451202',
        'areaName': '金城江区'
      }, {
        'areaCode': '451221',
        'areaName': '南丹县'
      }, {
        'areaCode': '451222',
        'areaName': '天峨县'
      }, {
        'areaCode': '451223',
        'areaName': '凤山县'
      }, {
        'areaCode': '451224',
        'areaName': '东兰县'
      }, {
        'areaCode': '451225',
        'areaName': '罗城仫佬族自治县'
      }, {
        'areaCode': '451226',
        'areaName': '环江毛南族自治县'
      }, {
        'areaCode': '451227',
        'areaName': '巴马瑶族自治县'
      }, {
        'areaCode': '451228',
        'areaName': '都安瑶族自治县'
      }, {
        'areaCode': '451229',
        'areaName': '大化瑶族自治县'
      }, {
        'areaCode': '451281',
        'areaName': '宜州市'
      }]
    }, {
      'cityCode': '451300',
      'cityName': '来宾市',
      'mallAreaList': [{
        'areaCode': '451302',
        'areaName': '兴宾区'
      }, {
        'areaCode': '451321',
        'areaName': '忻城县'
      }, {
        'areaCode': '451322',
        'areaName': '象州县'
      }, {
        'areaCode': '451323',
        'areaName': '武宣县'
      }, {
        'areaCode': '451324',
        'areaName': '金秀瑶族自治县'
      }, {
        'areaCode': '451381',
        'areaName': '合山市'
      }]
    }, {
      'cityCode': '451400',
      'cityName': '崇左市',
      'mallAreaList': [{
        'areaCode': '451402',
        'areaName': '江州区'
      }, {
        'areaCode': '451421',
        'areaName': '扶绥县'
      }, {
        'areaCode': '451422',
        'areaName': '宁明县'
      }, {
        'areaCode': '451423',
        'areaName': '龙州县'
      }, {
        'areaCode': '451424',
        'areaName': '大新县'
      }, {
        'areaCode': '451425',
        'areaName': '天等县'
      }, {
        'areaCode': '451481',
        'areaName': '凭祥市'
      }]
    }]
  }, {
    'provinceCode': '460000',
    'provinceName': '海南省',
    'mallCityList': [{
      'cityCode': '460100',
      'cityName': '海口市',
      'mallAreaList': [{
        'areaCode': '460105',
        'areaName': '秀英区'
      }, {
        'areaCode': '460106',
        'areaName': '龙华区'
      }, {
        'areaCode': '460107',
        'areaName': '琼山区'
      }, {
        'areaCode': '460108',
        'areaName': '美兰区'
      }]
    }, {
      'cityCode': '460200',
      'cityName': '三亚市',
      'mallAreaList': [{
        'areaCode': '460202',
        'areaName': '海棠区'
      }, {
        'areaCode': '460203',
        'areaName': '吉阳区'
      }, {
        'areaCode': '460204',
        'areaName': '天涯区'
      }, {
        'areaCode': '460205',
        'areaName': '崖州区'
      }]
    }, {
      'cityCode': '469000',
      'cityName': '省直辖县级行政单位',
      'mallAreaList': [{
        'areaCode': '469001',
        'areaName': '五指山市'
      }, {
        'areaCode': '469002',
        'areaName': '琼海市'
      }, {
        'areaCode': '469003',
        'areaName': '儋州市'
      }, {
        'areaCode': '469005',
        'areaName': '文昌市'
      }, {
        'areaCode': '469006',
        'areaName': '万宁市'
      }, {
        'areaCode': '469007',
        'areaName': '东方市'
      }, {
        'areaCode': '469021',
        'areaName': '定安县'
      }, {
        'areaCode': '469022',
        'areaName': '屯昌县'
      }, {
        'areaCode': '469023',
        'areaName': '澄迈县'
      }, {
        'areaCode': '469024',
        'areaName': '临高县'
      }, {
        'areaCode': '469025',
        'areaName': '白沙黎族自治县'
      }, {
        'areaCode': '469026',
        'areaName': '昌江黎族自治县'
      }, {
        'areaCode': '469027',
        'areaName': '乐东黎族自治县'
      }, {
        'areaCode': '469028',
        'areaName': '陵水黎族自治县'
      }, {
        'areaCode': '469029',
        'areaName': '保亭黎族苗族自治县'
      }, {
        'areaCode': '469030',
        'areaName': '琼中黎族苗族自治县'
      }]
    }]
  }, {
    'provinceCode': '500000',
    'provinceName': '重庆市',
    'mallCityList': [{
      'cityCode': '500100',
      'cityName': '重庆市',
      'mallAreaList': [{
        'areaCode': '500101',
        'areaName': '万州区'
      }, {
        'areaCode': '500102',
        'areaName': '涪陵区'
      }, {
        'areaCode': '500103',
        'areaName': '渝中区'
      }, {
        'areaCode': '500104',
        'areaName': '大渡口区'
      }, {
        'areaCode': '500105',
        'areaName': '江北区'
      }, {
        'areaCode': '500106',
        'areaName': '沙坪坝区'
      }, {
        'areaCode': '500107',
        'areaName': '九龙坡区'
      }, {
        'areaCode': '500108',
        'areaName': '南岸区'
      }, {
        'areaCode': '500109',
        'areaName': '北碚区'
      }, {
        'areaCode': '500110',
        'areaName': '綦江区'
      }, {
        'areaCode': '500111',
        'areaName': '大足区'
      }, {
        'areaCode': '500112',
        'areaName': '渝北区'
      }, {
        'areaCode': '500113',
        'areaName': '巴南区'
      }, {
        'areaCode': '500114',
        'areaName': '黔江区'
      }, {
        'areaCode': '500115',
        'areaName': '长寿区'
      }, {
        'areaCode': '500116',
        'areaName': '江津区'
      }, {
        'areaCode': '500117',
        'areaName': '合川区'
      }, {
        'areaCode': '500118',
        'areaName': '永川区'
      }, {
        'areaCode': '500119',
        'areaName': '南川区'
      }, {
        'areaCode': '500120',
        'areaName': '璧山区'
      }, {
        'areaCode': '500151',
        'areaName': '铜梁区'
      }]
    }, {
      'cityCode': '500200',
      'cityName': '重庆市辖县',
      'mallAreaList': [{
        'areaCode': '500223',
        'areaName': '潼南县'
      }, {
        'areaCode': '500226',
        'areaName': '荣昌县'
      }, {
        'areaCode': '500228',
        'areaName': '梁平县'
      }, {
        'areaCode': '500229',
        'areaName': '城口县'
      }, {
        'areaCode': '500230',
        'areaName': '丰都县'
      }, {
        'areaCode': '500231',
        'areaName': '垫江县'
      }, {
        'areaCode': '500232',
        'areaName': '武隆县'
      }, {
        'areaCode': '500233',
        'areaName': '忠县'
      }, {
        'areaCode': '500234',
        'areaName': '开县'
      }, {
        'areaCode': '500235',
        'areaName': '云阳县'
      }, {
        'areaCode': '500236',
        'areaName': '奉节县'
      }, {
        'areaCode': '500237',
        'areaName': '巫山县'
      }, {
        'areaCode': '500238',
        'areaName': '巫溪县'
      }, {
        'areaCode': '500240',
        'areaName': '石柱土家族自治县'
      }, {
        'areaCode': '500241',
        'areaName': '秀山土家族苗族自治县'
      }, {
        'areaCode': '500242',
        'areaName': '酉阳土家族苗族自治县'
      }, {
        'areaCode': '500243',
        'areaName': '彭水苗族土家族自治县'
      }]
    }]
  }, {
    'provinceCode': '510000',
    'provinceName': '四川省',
    'mallCityList': [{
      'cityCode': '510100',
      'cityName': '成都市',
      'mallAreaList': [{
        'areaCode': '510104',
        'areaName': '锦江区'
      }, {
        'areaCode': '510105',
        'areaName': '青羊区'
      }, {
        'areaCode': '510106',
        'areaName': '金牛区'
      }, {
        'areaCode': '510107',
        'areaName': '武侯区'
      }, {
        'areaCode': '510108',
        'areaName': '成华区'
      }, {
        'areaCode': '510112',
        'areaName': '龙泉驿区'
      }, {
        'areaCode': '510113',
        'areaName': '青白江区'
      }, {
        'areaCode': '510114',
        'areaName': '新都区'
      }, {
        'areaCode': '510115',
        'areaName': '温江区'
      }, {
        'areaCode': '510121',
        'areaName': '金堂县'
      }, {
        'areaCode': '510122',
        'areaName': '双流县'
      }, {
        'areaCode': '510124',
        'areaName': '郫县'
      }, {
        'areaCode': '510129',
        'areaName': '大邑县'
      }, {
        'areaCode': '510131',
        'areaName': '蒲江县'
      }, {
        'areaCode': '510132',
        'areaName': '新津县'
      }, {
        'areaCode': '510181',
        'areaName': '都江堰市'
      }, {
        'areaCode': '510182',
        'areaName': '彭州市'
      }, {
        'areaCode': '510183',
        'areaName': '邛崃市'
      }, {
        'areaCode': '510184',
        'areaName': '崇州市'
      }, {
        'areaCode': '510185',
        'areaName': '双流区'
      }]
    }, {
      'cityCode': '510300',
      'cityName': '自贡市',
      'mallAreaList': [{
        'areaCode': '510302',
        'areaName': '自流井区'
      }, {
        'areaCode': '510303',
        'areaName': '贡井区'
      }, {
        'areaCode': '510304',
        'areaName': '大安区'
      }, {
        'areaCode': '510311',
        'areaName': '沿滩区'
      }, {
        'areaCode': '510321',
        'areaName': '荣县'
      }, {
        'areaCode': '510322',
        'areaName': '富顺县'
      }]
    }, {
      'cityCode': '510400',
      'cityName': '攀枝花市',
      'mallAreaList': [{
        'areaCode': '510402',
        'areaName': '东区'
      }, {
        'areaCode': '510403',
        'areaName': '西区'
      }, {
        'areaCode': '510411',
        'areaName': '仁和区'
      }, {
        'areaCode': '510421',
        'areaName': '米易县'
      }, {
        'areaCode': '510422',
        'areaName': '盐边县'
      }]
    }, {
      'cityCode': '510500',
      'cityName': '泸州市',
      'mallAreaList': [{
        'areaCode': '510502',
        'areaName': '江阳区'
      }, {
        'areaCode': '510503',
        'areaName': '纳溪区'
      }, {
        'areaCode': '510504',
        'areaName': '龙马潭区'
      }, {
        'areaCode': '510521',
        'areaName': '泸县'
      }, {
        'areaCode': '510522',
        'areaName': '合江县'
      }, {
        'areaCode': '510524',
        'areaName': '叙永县'
      }, {
        'areaCode': '510525',
        'areaName': '古蔺县'
      }]
    }, {
      'cityCode': '510600',
      'cityName': '德阳市',
      'mallAreaList': [{
        'areaCode': '510603',
        'areaName': '旌阳区'
      }, {
        'areaCode': '510623',
        'areaName': '中江县'
      }, {
        'areaCode': '510626',
        'areaName': '罗江县'
      }, {
        'areaCode': '510681',
        'areaName': '广汉市'
      }, {
        'areaCode': '510682',
        'areaName': '什邡市'
      }, {
        'areaCode': '510683',
        'areaName': '绵竹市'
      }]
    }, {
      'cityCode': '510700',
      'cityName': '绵阳市',
      'mallAreaList': [{
        'areaCode': '510703',
        'areaName': '涪城区'
      }, {
        'areaCode': '510704',
        'areaName': '游仙区'
      }, {
        'areaCode': '510722',
        'areaName': '三台县'
      }, {
        'areaCode': '510723',
        'areaName': '盐亭县'
      }, {
        'areaCode': '510724',
        'areaName': '安县'
      }, {
        'areaCode': '510725',
        'areaName': '梓潼县'
      }, {
        'areaCode': '510726',
        'areaName': '北川羌族自治县'
      }, {
        'areaCode': '510727',
        'areaName': '平武县'
      }, {
        'areaCode': '510781',
        'areaName': '江油市'
      }]
    }, {
      'cityCode': '510800',
      'cityName': '广元市',
      'mallAreaList': [{
        'areaCode': '510802',
        'areaName': '利州区'
      }, {
        'areaCode': '510811',
        'areaName': '昭化区'
      }, {
        'areaCode': '510812',
        'areaName': '朝天区'
      }, {
        'areaCode': '510821',
        'areaName': '旺苍县'
      }, {
        'areaCode': '510822',
        'areaName': '青川县'
      }, {
        'areaCode': '510823',
        'areaName': '剑阁县'
      }, {
        'areaCode': '510824',
        'areaName': '苍溪县'
      }]
    }, {
      'cityCode': '510900',
      'cityName': '遂宁市',
      'mallAreaList': [{
        'areaCode': '510903',
        'areaName': '船山区'
      }, {
        'areaCode': '510904',
        'areaName': '安居区'
      }, {
        'areaCode': '510921',
        'areaName': '蓬溪县'
      }, {
        'areaCode': '510922',
        'areaName': '射洪县'
      }, {
        'areaCode': '510923',
        'areaName': '大英县'
      }]
    }, {
      'cityCode': '511000',
      'cityName': '内江市',
      'mallAreaList': [{
        'areaCode': '511002',
        'areaName': '市中区'
      }, {
        'areaCode': '511011',
        'areaName': '东兴区'
      }, {
        'areaCode': '511024',
        'areaName': '威远县'
      }, {
        'areaCode': '511025',
        'areaName': '资中县'
      }, {
        'areaCode': '511028',
        'areaName': '隆昌县'
      }]
    }, {
      'cityCode': '511100',
      'cityName': '乐山市',
      'mallAreaList': [{
        'areaCode': '511102',
        'areaName': '市中区'
      }, {
        'areaCode': '511111',
        'areaName': '沙湾区'
      }, {
        'areaCode': '511112',
        'areaName': '五通桥区'
      }, {
        'areaCode': '511113',
        'areaName': '金口河区'
      }, {
        'areaCode': '511123',
        'areaName': '犍为县'
      }, {
        'areaCode': '511124',
        'areaName': '井研县'
      }, {
        'areaCode': '511126',
        'areaName': '夹江县'
      }, {
        'areaCode': '511129',
        'areaName': '沐川县'
      }, {
        'areaCode': '511132',
        'areaName': '峨边彝族自治县'
      }, {
        'areaCode': '511133',
        'areaName': '马边彝族自治县'
      }, {
        'areaCode': '511181',
        'areaName': '峨眉山市'
      }]
    }, {
      'cityCode': '511300',
      'cityName': '南充市',
      'mallAreaList': [{
        'areaCode': '511302',
        'areaName': '顺庆区'
      }, {
        'areaCode': '511303',
        'areaName': '高坪区'
      }, {
        'areaCode': '511304',
        'areaName': '嘉陵区'
      }, {
        'areaCode': '511321',
        'areaName': '南部县'
      }, {
        'areaCode': '511322',
        'areaName': '营山县'
      }, {
        'areaCode': '511323',
        'areaName': '蓬安县'
      }, {
        'areaCode': '511324',
        'areaName': '仪陇县'
      }, {
        'areaCode': '511325',
        'areaName': '西充县'
      }, {
        'areaCode': '511381',
        'areaName': '阆中市'
      }]
    }, {
      'cityCode': '511400',
      'cityName': '眉山市',
      'mallAreaList': [{
        'areaCode': '511402',
        'areaName': '东坡区'
      }, {
        'areaCode': '511421',
        'areaName': '仁寿县'
      }, {
        'areaCode': '511422',
        'areaName': '彭山县'
      }, {
        'areaCode': '511423',
        'areaName': '洪雅县'
      }, {
        'areaCode': '511424',
        'areaName': '丹棱县'
      }, {
        'areaCode': '511425',
        'areaName': '青神县'
      }]
    }, {
      'cityCode': '511500',
      'cityName': '宜宾市',
      'mallAreaList': [{
        'areaCode': '511502',
        'areaName': '翠屏区'
      }, {
        'areaCode': '511503',
        'areaName': '南溪区'
      }, {
        'areaCode': '511521',
        'areaName': '宜宾县'
      }, {
        'areaCode': '511523',
        'areaName': '江安县'
      }, {
        'areaCode': '511524',
        'areaName': '长宁县'
      }, {
        'areaCode': '511525',
        'areaName': '高县'
      }, {
        'areaCode': '511526',
        'areaName': '珙县'
      }, {
        'areaCode': '511527',
        'areaName': '筠连县'
      }, {
        'areaCode': '511528',
        'areaName': '兴文县'
      }, {
        'areaCode': '511529',
        'areaName': '屏山县'
      }]
    }, {
      'cityCode': '511600',
      'cityName': '广安市',
      'mallAreaList': [{
        'areaCode': '511602',
        'areaName': '广安区'
      }, {
        'areaCode': '511603',
        'areaName': '前锋区'
      }, {
        'areaCode': '511621',
        'areaName': '岳池县'
      }, {
        'areaCode': '511622',
        'areaName': '武胜县'
      }, {
        'areaCode': '511623',
        'areaName': '邻水县'
      }, {
        'areaCode': '511681',
        'areaName': '华蓥市'
      }]
    }, {
      'cityCode': '511700',
      'cityName': '达州市',
      'mallAreaList': [{
        'areaCode': '511702',
        'areaName': '通川区'
      }, {
        'areaCode': '511703',
        'areaName': '达川区'
      }, {
        'areaCode': '511722',
        'areaName': '宣汉县'
      }, {
        'areaCode': '511723',
        'areaName': '开江县'
      }, {
        'areaCode': '511724',
        'areaName': '大竹县'
      }, {
        'areaCode': '511725',
        'areaName': '渠县'
      }, {
        'areaCode': '511781',
        'areaName': '万源市'
      }]
    }, {
      'cityCode': '511800',
      'cityName': '雅安市',
      'mallAreaList': [{
        'areaCode': '511802',
        'areaName': '雨城区'
      }, {
        'areaCode': '511803',
        'areaName': '名山区'
      }, {
        'areaCode': '511822',
        'areaName': '荥经县'
      }, {
        'areaCode': '511823',
        'areaName': '汉源县'
      }, {
        'areaCode': '511824',
        'areaName': '石棉县'
      }, {
        'areaCode': '511825',
        'areaName': '天全县'
      }, {
        'areaCode': '511826',
        'areaName': '芦山县'
      }, {
        'areaCode': '511827',
        'areaName': '宝兴县'
      }]
    }, {
      'cityCode': '511900',
      'cityName': '巴中市',
      'mallAreaList': [{
        'areaCode': '511902',
        'areaName': '巴州区'
      }, {
        'areaCode': '511903',
        'areaName': '恩阳区'
      }, {
        'areaCode': '511921',
        'areaName': '通江县'
      }, {
        'areaCode': '511922',
        'areaName': '南江县'
      }, {
        'areaCode': '511923',
        'areaName': '平昌县'
      }]
    }, {
      'cityCode': '512000',
      'cityName': '资阳市',
      'mallAreaList': [{
        'areaCode': '512002',
        'areaName': '雁江区'
      }, {
        'areaCode': '512021',
        'areaName': '安岳县'
      }, {
        'areaCode': '512022',
        'areaName': '乐至县'
      }, {
        'areaCode': '512081',
        'areaName': '简阳市'
      }]
    }, {
      'cityCode': '513200',
      'cityName': '阿坝藏族羌族自治州',
      'mallAreaList': [{
        'areaCode': '513221',
        'areaName': '汶川县'
      }, {
        'areaCode': '513222',
        'areaName': '理县'
      }, {
        'areaCode': '513223',
        'areaName': '茂县'
      }, {
        'areaCode': '513224',
        'areaName': '松潘县'
      }, {
        'areaCode': '513225',
        'areaName': '九寨沟县'
      }, {
        'areaCode': '513226',
        'areaName': '金川县'
      }, {
        'areaCode': '513227',
        'areaName': '小金县'
      }, {
        'areaCode': '513228',
        'areaName': '黑水县'
      }, {
        'areaCode': '513229',
        'areaName': '马尔康县'
      }, {
        'areaCode': '513230',
        'areaName': '壤塘县'
      }, {
        'areaCode': '513231',
        'areaName': '阿坝县'
      }, {
        'areaCode': '513232',
        'areaName': '若尔盖县'
      }, {
        'areaCode': '513233',
        'areaName': '红原县'
      }]
    }, {
      'cityCode': '513300',
      'cityName': '甘孜藏族自治州',
      'mallAreaList': [{
        'areaCode': '513321',
        'areaName': '康定县'
      }, {
        'areaCode': '513322',
        'areaName': '泸定县'
      }, {
        'areaCode': '513323',
        'areaName': '丹巴县'
      }, {
        'areaCode': '513324',
        'areaName': '九龙县'
      }, {
        'areaCode': '513325',
        'areaName': '雅江县'
      }, {
        'areaCode': '513326',
        'areaName': '道孚县'
      }, {
        'areaCode': '513327',
        'areaName': '炉霍县'
      }, {
        'areaCode': '513328',
        'areaName': '甘孜县'
      }, {
        'areaCode': '513329',
        'areaName': '新龙县'
      }, {
        'areaCode': '513330',
        'areaName': '德格县'
      }, {
        'areaCode': '513331',
        'areaName': '白玉县'
      }, {
        'areaCode': '513332',
        'areaName': '石渠县'
      }, {
        'areaCode': '513333',
        'areaName': '色达县'
      }, {
        'areaCode': '513334',
        'areaName': '理塘县'
      }, {
        'areaCode': '513335',
        'areaName': '巴塘县'
      }, {
        'areaCode': '513336',
        'areaName': '乡城县'
      }, {
        'areaCode': '513337',
        'areaName': '稻城县'
      }, {
        'areaCode': '513338',
        'areaName': '得荣县'
      }]
    }, {
      'cityCode': '513400',
      'cityName': '凉山彝族自治州',
      'mallAreaList': [{
        'areaCode': '513401',
        'areaName': '西昌市'
      }, {
        'areaCode': '513422',
        'areaName': '木里藏族自治县'
      }, {
        'areaCode': '513423',
        'areaName': '盐源县'
      }, {
        'areaCode': '513424',
        'areaName': '德昌县'
      }, {
        'areaCode': '513425',
        'areaName': '会理县'
      }, {
        'areaCode': '513426',
        'areaName': '会东县'
      }, {
        'areaCode': '513427',
        'areaName': '宁南县'
      }, {
        'areaCode': '513428',
        'areaName': '普格县'
      }, {
        'areaCode': '513429',
        'areaName': '布拖县'
      }, {
        'areaCode': '513430',
        'areaName': '金阳县'
      }, {
        'areaCode': '513431',
        'areaName': '昭觉县'
      }, {
        'areaCode': '513432',
        'areaName': '喜德县'
      }, {
        'areaCode': '513433',
        'areaName': '冕宁县'
      }, {
        'areaCode': '513434',
        'areaName': '越西县'
      }, {
        'areaCode': '513435',
        'areaName': '甘洛县'
      }, {
        'areaCode': '513436',
        'areaName': '美姑县'
      }, {
        'areaCode': '513437',
        'areaName': '雷波县'
      }]
    }]
  }, {
    'provinceCode': '520000',
    'provinceName': '贵州省',
    'mallCityList': [{
      'cityCode': '520100',
      'cityName': '贵阳市',
      'mallAreaList': [{
        'areaCode': '520102',
        'areaName': '南明区'
      }, {
        'areaCode': '520103',
        'areaName': '云岩区'
      }, {
        'areaCode': '520111',
        'areaName': '花溪区'
      }, {
        'areaCode': '520112',
        'areaName': '乌当区'
      }, {
        'areaCode': '520113',
        'areaName': '白云区'
      }, {
        'areaCode': '520115',
        'areaName': '观山湖区'
      }, {
        'areaCode': '520121',
        'areaName': '开阳县'
      }, {
        'areaCode': '520122',
        'areaName': '息烽县'
      }, {
        'areaCode': '520123',
        'areaName': '修文县'
      }, {
        'areaCode': '520181',
        'areaName': '清镇市'
      }]
    }, {
      'cityCode': '520200',
      'cityName': '六盘水市',
      'mallAreaList': [{
        'areaCode': '520201',
        'areaName': '钟山区'
      }, {
        'areaCode': '520203',
        'areaName': '六枝特区'
      }, {
        'areaCode': '520221',
        'areaName': '水城县'
      }, {
        'areaCode': '520222',
        'areaName': '盘县'
      }]
    }, {
      'cityCode': '520300',
      'cityName': '遵义市',
      'mallAreaList': [{
        'areaCode': '520302',
        'areaName': '红花岗区'
      }, {
        'areaCode': '520303',
        'areaName': '汇川区'
      }, {
        'areaCode': '520321',
        'areaName': '遵义县'
      }, {
        'areaCode': '520322',
        'areaName': '桐梓县'
      }, {
        'areaCode': '520323',
        'areaName': '绥阳县'
      }, {
        'areaCode': '520324',
        'areaName': '正安县'
      }, {
        'areaCode': '520325',
        'areaName': '道真仡佬族苗族自治县'
      }, {
        'areaCode': '520326',
        'areaName': '务川仡佬族苗族自治县'
      }, {
        'areaCode': '520327',
        'areaName': '凤冈县'
      }, {
        'areaCode': '520328',
        'areaName': '湄潭县'
      }, {
        'areaCode': '520329',
        'areaName': '余庆县'
      }, {
        'areaCode': '520330',
        'areaName': '习水县'
      }, {
        'areaCode': '520381',
        'areaName': '赤水市'
      }, {
        'areaCode': '520382',
        'areaName': '仁怀市'
      }]
    }, {
      'cityCode': '520400',
      'cityName': '安顺市',
      'mallAreaList': [{
        'areaCode': '520402',
        'areaName': '西秀区'
      }, {
        'areaCode': '520421',
        'areaName': '平坝县'
      }, {
        'areaCode': '520422',
        'areaName': '普定县'
      }, {
        'areaCode': '520423',
        'areaName': '镇宁布依族苗族自治县'
      }, {
        'areaCode': '520424',
        'areaName': '关岭布依族苗族自治县'
      }, {
        'areaCode': '520425',
        'areaName': '紫云苗族布依族自治县'
      }]
    }, {
      'cityCode': '522200',
      'cityName': '铜仁地区',
      'mallAreaList': [{
        'areaCode': '520602',
        'areaName': '碧江区'
      }, {
        'areaCode': '520603',
        'areaName': '万山区'
      }, {
        'areaCode': '520621',
        'areaName': '江口县'
      }, {
        'areaCode': '520622',
        'areaName': '玉屏侗族自治县'
      }, {
        'areaCode': '520623',
        'areaName': '石阡县'
      }, {
        'areaCode': '520624',
        'areaName': '思南县'
      }, {
        'areaCode': '520625',
        'areaName': '印江土家族苗族自治县'
      }, {
        'areaCode': '520626',
        'areaName': '德江县'
      }, {
        'areaCode': '520627',
        'areaName': '沿河土家族自治县'
      }, {
        'areaCode': '520628',
        'areaName': '松桃苗族自治县'
      }, {
        'areaCode': '522200',
        'areaName': '铜仁地区'
      }]
    }, {
      'cityCode': '522300',
      'cityName': '黔西南布依族苗族自治州',
      'mallAreaList': [{
        'areaCode': '522301',
        'areaName': '兴义市'
      }, {
        'areaCode': '522322',
        'areaName': '兴仁县'
      }, {
        'areaCode': '522323',
        'areaName': '普安县'
      }, {
        'areaCode': '522324',
        'areaName': '晴隆县'
      }, {
        'areaCode': '522325',
        'areaName': '贞丰县'
      }, {
        'areaCode': '522326',
        'areaName': '望谟县'
      }, {
        'areaCode': '522327',
        'areaName': '册亨县'
      }, {
        'areaCode': '522328',
        'areaName': '安龙县'
      }]
    }, {
      'cityCode': '522400',
      'cityName': '毕节地区',
      'mallAreaList': [{
        'areaCode': '520502',
        'areaName': '七星关区'
      }, {
        'areaCode': '520521',
        'areaName': '大方县'
      }, {
        'areaCode': '520522',
        'areaName': '黔西县'
      }, {
        'areaCode': '520523',
        'areaName': '金沙县'
      }, {
        'areaCode': '520524',
        'areaName': '织金县'
      }, {
        'areaCode': '520525',
        'areaName': '纳雍县'
      }, {
        'areaCode': '520526',
        'areaName': '威宁彝族回族苗族自治县'
      }, {
        'areaCode': '520527',
        'areaName': '赫章县'
      }, {
        'areaCode': '522400',
        'areaName': '毕节地区'
      }]
    }, {
      'cityCode': '522600',
      'cityName': '黔东南苗族侗族自治州',
      'mallAreaList': [{
        'areaCode': '522601',
        'areaName': '凯里市'
      }, {
        'areaCode': '522622',
        'areaName': '黄平县'
      }, {
        'areaCode': '522623',
        'areaName': '施秉县'
      }, {
        'areaCode': '522624',
        'areaName': '三穗县'
      }, {
        'areaCode': '522625',
        'areaName': '镇远县'
      }, {
        'areaCode': '522626',
        'areaName': '岑巩县'
      }, {
        'areaCode': '522627',
        'areaName': '天柱县'
      }, {
        'areaCode': '522628',
        'areaName': '锦屏县'
      }, {
        'areaCode': '522629',
        'areaName': '剑河县'
      }, {
        'areaCode': '522630',
        'areaName': '台江县'
      }, {
        'areaCode': '522631',
        'areaName': '黎平县'
      }, {
        'areaCode': '522632',
        'areaName': '榕江县'
      }, {
        'areaCode': '522633',
        'areaName': '从江县'
      }, {
        'areaCode': '522634',
        'areaName': '雷山县'
      }, {
        'areaCode': '522635',
        'areaName': '麻江县'
      }, {
        'areaCode': '522636',
        'areaName': '丹寨县'
      }]
    }, {
      'cityCode': '522700',
      'cityName': '黔南布依族苗族自治州',
      'mallAreaList': [{
        'areaCode': '522701',
        'areaName': '都匀市'
      }, {
        'areaCode': '522702',
        'areaName': '福泉市'
      }, {
        'areaCode': '522722',
        'areaName': '荔波县'
      }, {
        'areaCode': '522723',
        'areaName': '贵定县'
      }, {
        'areaCode': '522725',
        'areaName': '瓮安县'
      }, {
        'areaCode': '522726',
        'areaName': '独山县'
      }, {
        'areaCode': '522727',
        'areaName': '平塘县'
      }, {
        'areaCode': '522728',
        'areaName': '罗甸县'
      }, {
        'areaCode': '522729',
        'areaName': '长顺县'
      }, {
        'areaCode': '522730',
        'areaName': '龙里县'
      }, {
        'areaCode': '522731',
        'areaName': '惠水县'
      }, {
        'areaCode': '522732',
        'areaName': '三都水族自治县'
      }]
    }]
  }, {
    'provinceCode': '530000',
    'provinceName': '云南省',
    'mallCityList': [{
      'cityCode': '530100',
      'cityName': '昆明市',
      'mallAreaList': [{
        'areaCode': '530102',
        'areaName': '五华区'
      }, {
        'areaCode': '530103',
        'areaName': '盘龙区'
      }, {
        'areaCode': '530111',
        'areaName': '官渡区'
      }, {
        'areaCode': '530112',
        'areaName': '西山区'
      }, {
        'areaCode': '530113',
        'areaName': '东川区'
      }, {
        'areaCode': '530114',
        'areaName': '呈贡区'
      }, {
        'areaCode': '530122',
        'areaName': '晋宁县'
      }, {
        'areaCode': '530124',
        'areaName': '富民县'
      }, {
        'areaCode': '530125',
        'areaName': '宜良县'
      }, {
        'areaCode': '530126',
        'areaName': '石林彝族自治县'
      }, {
        'areaCode': '530127',
        'areaName': '嵩明县'
      }, {
        'areaCode': '530128',
        'areaName': '禄劝彝族苗族自治县'
      }, {
        'areaCode': '530129',
        'areaName': '寻甸回族彝族自治县'
      }, {
        'areaCode': '530181',
        'areaName': '安宁市'
      }]
    }, {
      'cityCode': '530300',
      'cityName': '曲靖市',
      'mallAreaList': [{
        'areaCode': '530302',
        'areaName': '麒麟区'
      }, {
        'areaCode': '530321',
        'areaName': '马龙县'
      }, {
        'areaCode': '530322',
        'areaName': '陆良县'
      }, {
        'areaCode': '530323',
        'areaName': '师宗县'
      }, {
        'areaCode': '530324',
        'areaName': '罗平县'
      }, {
        'areaCode': '530325',
        'areaName': '富源县'
      }, {
        'areaCode': '530326',
        'areaName': '会泽县'
      }, {
        'areaCode': '530328',
        'areaName': '沾益县'
      }, {
        'areaCode': '530381',
        'areaName': '宣威市'
      }]
    }, {
      'cityCode': '530400',
      'cityName': '玉溪市',
      'mallAreaList': [{
        'areaCode': '530402',
        'areaName': '红塔区'
      }, {
        'areaCode': '530421',
        'areaName': '江川县'
      }, {
        'areaCode': '530422',
        'areaName': '澄江县'
      }, {
        'areaCode': '530423',
        'areaName': '通海县'
      }, {
        'areaCode': '530424',
        'areaName': '华宁县'
      }, {
        'areaCode': '530425',
        'areaName': '易门县'
      }, {
        'areaCode': '530426',
        'areaName': '峨山彝族自治县'
      }, {
        'areaCode': '530427',
        'areaName': '新平彝族傣族自治县'
      }, {
        'areaCode': '530428',
        'areaName': '元江哈尼族彝族傣族自治县'
      }]
    }, {
      'cityCode': '530500',
      'cityName': '保山市',
      'mallAreaList': [{
        'areaCode': '530502',
        'areaName': '隆阳区'
      }, {
        'areaCode': '530521',
        'areaName': '施甸县'
      }, {
        'areaCode': '530522',
        'areaName': '腾冲县'
      }, {
        'areaCode': '530523',
        'areaName': '龙陵县'
      }, {
        'areaCode': '530524',
        'areaName': '昌宁县'
      }]
    }, {
      'cityCode': '530600',
      'cityName': '昭通市',
      'mallAreaList': [{
        'areaCode': '530602',
        'areaName': '昭阳区'
      }, {
        'areaCode': '530621',
        'areaName': '鲁甸县'
      }, {
        'areaCode': '530622',
        'areaName': '巧家县'
      }, {
        'areaCode': '530623',
        'areaName': '盐津县'
      }, {
        'areaCode': '530624',
        'areaName': '大关县'
      }, {
        'areaCode': '530625',
        'areaName': '永善县'
      }, {
        'areaCode': '530626',
        'areaName': '绥江县'
      }, {
        'areaCode': '530627',
        'areaName': '镇雄县'
      }, {
        'areaCode': '530628',
        'areaName': '彝良县'
      }, {
        'areaCode': '530629',
        'areaName': '威信县'
      }, {
        'areaCode': '530630',
        'areaName': '水富县'
      }]
    }, {
      'cityCode': '530700',
      'cityName': '丽江市',
      'mallAreaList': [{
        'areaCode': '530702',
        'areaName': '古城区'
      }, {
        'areaCode': '530721',
        'areaName': '玉龙纳西族自治县'
      }, {
        'areaCode': '530722',
        'areaName': '永胜县'
      }, {
        'areaCode': '530723',
        'areaName': '华坪县'
      }, {
        'areaCode': '530724',
        'areaName': '宁蒗彝族自治县'
      }]
    }, {
      'cityCode': '530800',
      'cityName': '思茅市',
      'mallAreaList': [{
        'areaCode': '530802',
        'areaName': '思茅区'
      }, {
        'areaCode': '530821',
        'areaName': '宁洱哈尼族彝族自治县'
      }, {
        'areaCode': '530822',
        'areaName': '墨江哈尼族自治县'
      }, {
        'areaCode': '530823',
        'areaName': '景东彝族自治县'
      }, {
        'areaCode': '530824',
        'areaName': '景谷傣族彝族自治县'
      }, {
        'areaCode': '530825',
        'areaName': '镇沅彝族哈尼族拉祜族自治县'
      }, {
        'areaCode': '530826',
        'areaName': '江城哈尼族彝族自治县'
      }, {
        'areaCode': '530827',
        'areaName': '孟连傣族拉祜族佤族自治县'
      }, {
        'areaCode': '530828',
        'areaName': '澜沧拉祜族自治县'
      }, {
        'areaCode': '530829',
        'areaName': '西盟佤族自治县'
      }]
    }, {
      'cityCode': '530900',
      'cityName': '临沧市',
      'mallAreaList': [{
        'areaCode': '530902',
        'areaName': '临翔区'
      }, {
        'areaCode': '530921',
        'areaName': '凤庆县'
      }, {
        'areaCode': '530922',
        'areaName': '云县'
      }, {
        'areaCode': '530923',
        'areaName': '永德县'
      }, {
        'areaCode': '530924',
        'areaName': '镇康县'
      }, {
        'areaCode': '530925',
        'areaName': '双江拉祜族佤族布朗族傣族自治县'
      }, {
        'areaCode': '530926',
        'areaName': '耿马傣族佤族自治县'
      }, {
        'areaCode': '530927',
        'areaName': '沧源佤族自治县'
      }]
    }, {
      'cityCode': '532300',
      'cityName': '楚雄彝族自治州',
      'mallAreaList': [{
        'areaCode': '532301',
        'areaName': '楚雄市'
      }, {
        'areaCode': '532322',
        'areaName': '双柏县'
      }, {
        'areaCode': '532323',
        'areaName': '牟定县'
      }, {
        'areaCode': '532324',
        'areaName': '南华县'
      }, {
        'areaCode': '532325',
        'areaName': '姚安县'
      }, {
        'areaCode': '532326',
        'areaName': '大姚县'
      }, {
        'areaCode': '532327',
        'areaName': '永仁县'
      }, {
        'areaCode': '532328',
        'areaName': '元谋县'
      }, {
        'areaCode': '532329',
        'areaName': '武定县'
      }, {
        'areaCode': '532331',
        'areaName': '禄丰县'
      }]
    }, {
      'cityCode': '532500',
      'cityName': '红河哈尼族彝族自治州',
      'mallAreaList': [{
        'areaCode': '532501',
        'areaName': '个旧市'
      }, {
        'areaCode': '532502',
        'areaName': '开远市'
      }, {
        'areaCode': '532503',
        'areaName': '蒙自市'
      }, {
        'areaCode': '532504',
        'areaName': '弥勒市'
      }, {
        'areaCode': '532523',
        'areaName': '屏边苗族自治县'
      }, {
        'areaCode': '532524',
        'areaName': '建水县'
      }, {
        'areaCode': '532525',
        'areaName': '石屏县'
      }, {
        'areaCode': '532527',
        'areaName': '泸西县'
      }, {
        'areaCode': '532528',
        'areaName': '元阳县'
      }, {
        'areaCode': '532529',
        'areaName': '红河县'
      }, {
        'areaCode': '532530',
        'areaName': '金平苗族瑶族傣族自治县'
      }, {
        'areaCode': '532531',
        'areaName': '绿春县'
      }, {
        'areaCode': '532532',
        'areaName': '河口瑶族自治县'
      }]
    }, {
      'cityCode': '532600',
      'cityName': '文山壮族苗族自治州',
      'mallAreaList': [{
        'areaCode': '532601',
        'areaName': '文山市'
      }, {
        'areaCode': '532622',
        'areaName': '砚山县'
      }, {
        'areaCode': '532623',
        'areaName': '西畴县'
      }, {
        'areaCode': '532624',
        'areaName': '麻栗坡县'
      }, {
        'areaCode': '532625',
        'areaName': '马关县'
      }, {
        'areaCode': '532626',
        'areaName': '丘北县'
      }, {
        'areaCode': '532627',
        'areaName': '广南县'
      }, {
        'areaCode': '532628',
        'areaName': '富宁县'
      }]
    }, {
      'cityCode': '532800',
      'cityName': '西双版纳傣族自治州',
      'mallAreaList': [{
        'areaCode': '532801',
        'areaName': '景洪市'
      }, {
        'areaCode': '532822',
        'areaName': '勐海县'
      }, {
        'areaCode': '532823',
        'areaName': '勐腊县'
      }]
    }, {
      'cityCode': '532900',
      'cityName': '大理白族自治州',
      'mallAreaList': [{
        'areaCode': '532901',
        'areaName': '大理市'
      }, {
        'areaCode': '532922',
        'areaName': '漾濞彝族自治县'
      }, {
        'areaCode': '532923',
        'areaName': '祥云县'
      }, {
        'areaCode': '532924',
        'areaName': '宾川县'
      }, {
        'areaCode': '532925',
        'areaName': '弥渡县'
      }, {
        'areaCode': '532926',
        'areaName': '南涧彝族自治县'
      }, {
        'areaCode': '532927',
        'areaName': '巍山彝族回族自治县'
      }, {
        'areaCode': '532928',
        'areaName': '永平县'
      }, {
        'areaCode': '532929',
        'areaName': '云龙县'
      }, {
        'areaCode': '532930',
        'areaName': '洱源县'
      }, {
        'areaCode': '532931',
        'areaName': '剑川县'
      }, {
        'areaCode': '532932',
        'areaName': '鹤庆县'
      }]
    }, {
      'cityCode': '533100',
      'cityName': '德宏傣族景颇族自治州',
      'mallAreaList': [{
        'areaCode': '533102',
        'areaName': '瑞丽市'
      }, {
        'areaCode': '533103',
        'areaName': '芒市'
      }, {
        'areaCode': '533122',
        'areaName': '梁河县'
      }, {
        'areaCode': '533123',
        'areaName': '盈江县'
      }, {
        'areaCode': '533124',
        'areaName': '陇川县'
      }]
    }, {
      'cityCode': '533300',
      'cityName': '怒江傈僳族自治州',
      'mallAreaList': [{
        'areaCode': '533321',
        'areaName': '泸水县'
      }, {
        'areaCode': '533323',
        'areaName': '福贡县'
      }, {
        'areaCode': '533324',
        'areaName': '贡山独龙族怒族自治县'
      }, {
        'areaCode': '533325',
        'areaName': '兰坪白族普米族自治县'
      }]
    }, {
      'cityCode': '533400',
      'cityName': '迪庆藏族自治州',
      'mallAreaList': [{
        'areaCode': '533421',
        'areaName': '香格里拉县'
      }, {
        'areaCode': '533422',
        'areaName': '德钦县'
      }, {
        'areaCode': '533423',
        'areaName': '维西傈僳族自治县'
      }]
    }]
  }, {
    'provinceCode': '540000',
    'provinceName': '西藏自治区',
    'mallCityList': [{
      'cityCode': '540100',
      'cityName': '拉萨市',
      'mallAreaList': [{
        'areaCode': '540102',
        'areaName': '城关区'
      }, {
        'areaCode': '540121',
        'areaName': '林周县'
      }, {
        'areaCode': '540122',
        'areaName': '当雄县'
      }, {
        'areaCode': '540123',
        'areaName': '尼木县'
      }, {
        'areaCode': '540124',
        'areaName': '曲水县'
      }, {
        'areaCode': '540125',
        'areaName': '堆龙德庆县'
      }, {
        'areaCode': '540126',
        'areaName': '达孜县'
      }, {
        'areaCode': '540127',
        'areaName': '墨竹工卡县'
      }]
    }, {
      'cityCode': '542100',
      'cityName': '昌都地区',
      'mallAreaList': [{
        'areaCode': '542121',
        'areaName': '昌都县'
      }, {
        'areaCode': '542122',
        'areaName': '江达县'
      }, {
        'areaCode': '542123',
        'areaName': '贡觉县'
      }, {
        'areaCode': '542124',
        'areaName': '类乌齐县'
      }, {
        'areaCode': '542125',
        'areaName': '丁青县'
      }, {
        'areaCode': '542126',
        'areaName': '察雅县'
      }, {
        'areaCode': '542127',
        'areaName': '八宿县'
      }, {
        'areaCode': '542128',
        'areaName': '左贡县'
      }, {
        'areaCode': '542129',
        'areaName': '芒康县'
      }, {
        'areaCode': '542132',
        'areaName': '洛隆县'
      }, {
        'areaCode': '542133',
        'areaName': '边坝县'
      }]
    }, {
      'cityCode': '542200',
      'cityName': '山南地区',
      'mallAreaList': [{
        'areaCode': '542221',
        'areaName': '乃东县'
      }, {
        'areaCode': '542222',
        'areaName': '扎囊县'
      }, {
        'areaCode': '542223',
        'areaName': '贡嘎县'
      }, {
        'areaCode': '542224',
        'areaName': '桑日县'
      }, {
        'areaCode': '542225',
        'areaName': '琼结县'
      }, {
        'areaCode': '542226',
        'areaName': '曲松县'
      }, {
        'areaCode': '542227',
        'areaName': '措美县'
      }, {
        'areaCode': '542228',
        'areaName': '洛扎县'
      }, {
        'areaCode': '542229',
        'areaName': '加查县'
      }, {
        'areaCode': '542231',
        'areaName': '隆子县'
      }, {
        'areaCode': '542232',
        'areaName': '错那县'
      }, {
        'areaCode': '542233',
        'areaName': '浪卡子县'
      }]
    }, {
      'cityCode': '542300',
      'cityName': '日喀则地区',
      'mallAreaList': [{
        'areaCode': '540202',
        'areaName': '桑珠孜区'
      }, {
        'areaCode': '540221',
        'areaName': '南木林县'
      }, {
        'areaCode': '540222',
        'areaName': '江孜县'
      }, {
        'areaCode': '540223',
        'areaName': '定日县'
      }, {
        'areaCode': '540224',
        'areaName': '萨迦县'
      }, {
        'areaCode': '540225',
        'areaName': '拉孜县'
      }, {
        'areaCode': '540226',
        'areaName': '昂仁县'
      }, {
        'areaCode': '540227',
        'areaName': '谢通门县'
      }, {
        'areaCode': '540228',
        'areaName': '白朗县'
      }, {
        'areaCode': '540229',
        'areaName': '仁布县'
      }, {
        'areaCode': '540230',
        'areaName': '康马县'
      }, {
        'areaCode': '540231',
        'areaName': '定结县'
      }, {
        'areaCode': '540232',
        'areaName': '仲巴县'
      }, {
        'areaCode': '540233',
        'areaName': '亚东县'
      }, {
        'areaCode': '540234',
        'areaName': '吉隆县'
      }, {
        'areaCode': '540235',
        'areaName': '聂拉木县'
      }, {
        'areaCode': '540236',
        'areaName': '萨嘎县'
      }, {
        'areaCode': '540237',
        'areaName': '岗巴县'
      }, {
        'areaCode': '542300',
        'areaName': '日喀则地区'
      }]
    }, {
      'cityCode': '542400',
      'cityName': '那曲地区',
      'mallAreaList': [{
        'areaCode': '542421',
        'areaName': '那曲县'
      }, {
        'areaCode': '542422',
        'areaName': '嘉黎县'
      }, {
        'areaCode': '542423',
        'areaName': '比如县'
      }, {
        'areaCode': '542424',
        'areaName': '聂荣县'
      }, {
        'areaCode': '542425',
        'areaName': '安多县'
      }, {
        'areaCode': '542426',
        'areaName': '申扎县'
      }, {
        'areaCode': '542427',
        'areaName': '索县'
      }, {
        'areaCode': '542428',
        'areaName': '班戈县'
      }, {
        'areaCode': '542429',
        'areaName': '巴青县'
      }, {
        'areaCode': '542430',
        'areaName': '尼玛县'
      }, {
        'areaCode': '542431',
        'areaName': '双湖县'
      }]
    }, {
      'cityCode': '542500',
      'cityName': '阿里地区',
      'mallAreaList': [{
        'areaCode': '542521',
        'areaName': '普兰县'
      }, {
        'areaCode': '542522',
        'areaName': '札达县'
      }, {
        'areaCode': '542523',
        'areaName': '噶尔县'
      }, {
        'areaCode': '542524',
        'areaName': '日土县'
      }, {
        'areaCode': '542525',
        'areaName': '革吉县'
      }, {
        'areaCode': '542526',
        'areaName': '改则县'
      }, {
        'areaCode': '542527',
        'areaName': '措勤县'
      }]
    }, {
      'cityCode': '542600',
      'cityName': '林芝地区',
      'mallAreaList': [{
        'areaCode': '542621',
        'areaName': '林芝县'
      }, {
        'areaCode': '542622',
        'areaName': '工布江达县'
      }, {
        'areaCode': '542623',
        'areaName': '米林县'
      }, {
        'areaCode': '542624',
        'areaName': '墨脱县'
      }, {
        'areaCode': '542625',
        'areaName': '波密县'
      }, {
        'areaCode': '542626',
        'areaName': '察隅县'
      }, {
        'areaCode': '542627',
        'areaName': '朗县'
      }]
    }]
  }, {
    'provinceCode': '610000',
    'provinceName': '陕西省',
    'mallCityList': [{
      'cityCode': '610100',
      'cityName': '西安市',
      'mallAreaList': [{
        'areaCode': '610102',
        'areaName': '新城区'
      }, {
        'areaCode': '610103',
        'areaName': '碑林区'
      }, {
        'areaCode': '610104',
        'areaName': '莲湖区'
      }, {
        'areaCode': '610111',
        'areaName': '灞桥区'
      }, {
        'areaCode': '610112',
        'areaName': '未央区'
      }, {
        'areaCode': '610113',
        'areaName': '雁塔区'
      }, {
        'areaCode': '610114',
        'areaName': '阎良区'
      }, {
        'areaCode': '610115',
        'areaName': '临潼区'
      }, {
        'areaCode': '610116',
        'areaName': '长安区'
      }, {
        'areaCode': '610122',
        'areaName': '蓝田县'
      }, {
        'areaCode': '610124',
        'areaName': '周至县'
      }, {
        'areaCode': '610125',
        'areaName': '户县'
      }, {
        'areaCode': '610126',
        'areaName': '高陵县'
      }, {
        'areaCode': '610127',
        'areaName': '鄂邑区'
      }]
    }, {
      'cityCode': '610200',
      'cityName': '铜川市',
      'mallAreaList': [{
        'areaCode': '610202',
        'areaName': '王益区'
      }, {
        'areaCode': '610203',
        'areaName': '印台区'
      }, {
        'areaCode': '610204',
        'areaName': '耀州区'
      }, {
        'areaCode': '610222',
        'areaName': '宜君县'
      }]
    }, {
      'cityCode': '610300',
      'cityName': '宝鸡市',
      'mallAreaList': [{
        'areaCode': '610302',
        'areaName': '渭滨区'
      }, {
        'areaCode': '610303',
        'areaName': '金台区'
      }, {
        'areaCode': '610304',
        'areaName': '陈仓区'
      }, {
        'areaCode': '610322',
        'areaName': '凤翔县'
      }, {
        'areaCode': '610323',
        'areaName': '岐山县'
      }, {
        'areaCode': '610324',
        'areaName': '扶风县'
      }, {
        'areaCode': '610326',
        'areaName': '眉县'
      }, {
        'areaCode': '610327',
        'areaName': '陇县'
      }, {
        'areaCode': '610328',
        'areaName': '千阳县'
      }, {
        'areaCode': '610329',
        'areaName': '麟游县'
      }, {
        'areaCode': '610330',
        'areaName': '凤县'
      }, {
        'areaCode': '610331',
        'areaName': '太白县'
      }]
    }, {
      'cityCode': '610400',
      'cityName': '咸阳市',
      'mallAreaList': [{
        'areaCode': '610402',
        'areaName': '秦都区'
      }, {
        'areaCode': '610403',
        'areaName': '杨陵区'
      }, {
        'areaCode': '610404',
        'areaName': '渭城区'
      }, {
        'areaCode': '610422',
        'areaName': '三原县'
      }, {
        'areaCode': '610423',
        'areaName': '泾阳县'
      }, {
        'areaCode': '610424',
        'areaName': '乾县'
      }, {
        'areaCode': '610425',
        'areaName': '礼泉县'
      }, {
        'areaCode': '610426',
        'areaName': '永寿县'
      }, {
        'areaCode': '610427',
        'areaName': '彬县'
      }, {
        'areaCode': '610428',
        'areaName': '长武县'
      }, {
        'areaCode': '610429',
        'areaName': '旬邑县'
      }, {
        'areaCode': '610430',
        'areaName': '淳化县'
      }, {
        'areaCode': '610431',
        'areaName': '武功县'
      }, {
        'areaCode': '610481',
        'areaName': '兴平市'
      }]
    }, {
      'cityCode': '610500',
      'cityName': '渭南市',
      'mallAreaList': [{
        'areaCode': '610502',
        'areaName': '临渭区'
      }, {
        'areaCode': '610521',
        'areaName': '华县'
      }, {
        'areaCode': '610522',
        'areaName': '潼关���'
      }, {
        'areaCode': '610523',
        'areaName': '大荔县'
      }, {
        'areaCode': '610524',
        'areaName': '合阳县'
      }, {
        'areaCode': '610525',
        'areaName': '澄城县'
      }, {
        'areaCode': '610526',
        'areaName': '蒲城县'
      }, {
        'areaCode': '610527',
        'areaName': '白水县'
      }, {
        'areaCode': '610528',
        'areaName': '富平县'
      }, {
        'areaCode': '610581',
        'areaName': '韩城市'
      }, {
        'areaCode': '610582',
        'areaName': '华阴市'
      }]
    }, {
      'cityCode': '610600',
      'cityName': '延安市',
      'mallAreaList': [{
        'areaCode': '610602',
        'areaName': '宝塔区'
      }, {
        'areaCode': '610621',
        'areaName': '延长县'
      }, {
        'areaCode': '610622',
        'areaName': '延川县'
      }, {
        'areaCode': '610623',
        'areaName': '子长县'
      }, {
        'areaCode': '610624',
        'areaName': '安塞县'
      }, {
        'areaCode': '610625',
        'areaName': '志丹县'
      }, {
        'areaCode': '610626',
        'areaName': '吴起县'
      }, {
        'areaCode': '610627',
        'areaName': '甘泉县'
      }, {
        'areaCode': '610628',
        'areaName': '富县'
      }, {
        'areaCode': '610629',
        'areaName': '洛川县'
      }, {
        'areaCode': '610630',
        'areaName': '宜川县'
      }, {
        'areaCode': '610631',
        'areaName': '黄龙县'
      }, {
        'areaCode': '610632',
        'areaName': '黄陵县'
      }]
    }, {
      'cityCode': '610700',
      'cityName': '汉中市',
      'mallAreaList': [{
        'areaCode': '610702',
        'areaName': '汉台区'
      }, {
        'areaCode': '610721',
        'areaName': '南郑县'
      }, {
        'areaCode': '610722',
        'areaName': '城固县'
      }, {
        'areaCode': '610723',
        'areaName': '洋县'
      }, {
        'areaCode': '610724',
        'areaName': '西乡县'
      }, {
        'areaCode': '610725',
        'areaName': '勉县'
      }, {
        'areaCode': '610726',
        'areaName': '宁强县'
      }, {
        'areaCode': '610727',
        'areaName': '略阳县'
      }, {
        'areaCode': '610728',
        'areaName': '镇巴县'
      }, {
        'areaCode': '610729',
        'areaName': '留坝县'
      }, {
        'areaCode': '610730',
        'areaName': '佛坪县'
      }]
    }, {
      'cityCode': '610800',
      'cityName': '榆林市',
      'mallAreaList': [{
        'areaCode': '610802',
        'areaName': '榆阳区'
      }, {
        'areaCode': '610821',
        'areaName': '神木县'
      }, {
        'areaCode': '610822',
        'areaName': '府谷县'
      }, {
        'areaCode': '610823',
        'areaName': '横山县'
      }, {
        'areaCode': '610824',
        'areaName': '靖边县'
      }, {
        'areaCode': '610825',
        'areaName': '定边县'
      }, {
        'areaCode': '610826',
        'areaName': '绥德县'
      }, {
        'areaCode': '610827',
        'areaName': '米脂县'
      }, {
        'areaCode': '610828',
        'areaName': '佳县'
      }, {
        'areaCode': '610829',
        'areaName': '吴堡县'
      }, {
        'areaCode': '610830',
        'areaName': '清涧县'
      }, {
        'areaCode': '610831',
        'areaName': '子洲县'
      }]
    }, {
      'cityCode': '610900',
      'cityName': '安康市',
      'mallAreaList': [{
        'areaCode': '610902',
        'areaName': '汉滨区'
      }, {
        'areaCode': '610921',
        'areaName': '汉阴县'
      }, {
        'areaCode': '610922',
        'areaName': '石泉县'
      }, {
        'areaCode': '610923',
        'areaName': '宁陕县'
      }, {
        'areaCode': '610924',
        'areaName': '紫阳县'
      }, {
        'areaCode': '610925',
        'areaName': '岚皋县'
      }, {
        'areaCode': '610926',
        'areaName': '平利县'
      }, {
        'areaCode': '610927',
        'areaName': '镇坪县'
      }, {
        'areaCode': '610928',
        'areaName': '旬阳县'
      }, {
        'areaCode': '610929',
        'areaName': '白河县'
      }]
    }, {
      'cityCode': '611000',
      'cityName': '商洛市',
      'mallAreaList': [{
        'areaCode': '611002',
        'areaName': '商州区'
      }, {
        'areaCode': '611021',
        'areaName': '洛南县'
      }, {
        'areaCode': '611022',
        'areaName': '丹凤县'
      }, {
        'areaCode': '611023',
        'areaName': '商南县'
      }, {
        'areaCode': '611024',
        'areaName': '山阳县'
      }, {
        'areaCode': '611025',
        'areaName': '镇安县'
      }, {
        'areaCode': '611026',
        'areaName': '柞水县'
      }]
    }]
  }, {
    'provinceCode': '620000',
    'provinceName': '甘肃省',
    'mallCityList': [{
      'cityCode': '620100',
      'cityName': '兰州市',
      'mallAreaList': [{
        'areaCode': '620102',
        'areaName': '城关区'
      }, {
        'areaCode': '620103',
        'areaName': '七里河区'
      }, {
        'areaCode': '620104',
        'areaName': '西固区'
      }, {
        'areaCode': '620105',
        'areaName': '安宁区'
      }, {
        'areaCode': '620111',
        'areaName': '红古区'
      }, {
        'areaCode': '620121',
        'areaName': '永登县'
      }, {
        'areaCode': '620122',
        'areaName': '皋兰县'
      }, {
        'areaCode': '620123',
        'areaName': '榆中县'
      }]
    }, {
      'cityCode': '620200',
      'cityName': '嘉峪关市',
      'mallAreaList': [{
        'areaCode': '620201',
        'areaName': '嘉峪关市辖区'
      }]
    }, {
      'cityCode': '620300',
      'cityName': '金昌市',
      'mallAreaList': [{
        'areaCode': '620302',
        'areaName': '金川区'
      }, {
        'areaCode': '620321',
        'areaName': '永昌县'
      }]
    }, {
      'cityCode': '620400',
      'cityName': '白银市',
      'mallAreaList': [{
        'areaCode': '620402',
        'areaName': '白银区'
      }, {
        'areaCode': '620403',
        'areaName': '平川区'
      }, {
        'areaCode': '620421',
        'areaName': '靖远县'
      }, {
        'areaCode': '620422',
        'areaName': '会宁县'
      }, {
        'areaCode': '620423',
        'areaName': '景泰县'
      }]
    }, {
      'cityCode': '620500',
      'cityName': '天水市',
      'mallAreaList': [{
        'areaCode': '620502',
        'areaName': '秦州区'
      }, {
        'areaCode': '620503',
        'areaName': '麦积区'
      }, {
        'areaCode': '620521',
        'areaName': '清水县'
      }, {
        'areaCode': '620522',
        'areaName': '秦安县'
      }, {
        'areaCode': '620523',
        'areaName': '甘谷县'
      }, {
        'areaCode': '620524',
        'areaName': '武山县'
      }, {
        'areaCode': '620525',
        'areaName': '张家川回族自治县'
      }]
    }, {
      'cityCode': '620600',
      'cityName': '武威市',
      'mallAreaList': [{
        'areaCode': '620602',
        'areaName': '凉州区'
      }, {
        'areaCode': '620621',
        'areaName': '民勤县'
      }, {
        'areaCode': '620622',
        'areaName': '古浪县'
      }, {
        'areaCode': '620623',
        'areaName': '天祝藏族自治县'
      }]
    }, {
      'cityCode': '620700',
      'cityName': '张掖市',
      'mallAreaList': [{
        'areaCode': '620702',
        'areaName': '甘州区'
      }, {
        'areaCode': '620721',
        'areaName': '肃南裕固族自治县'
      }, {
        'areaCode': '620722',
        'areaName': '民乐县'
      }, {
        'areaCode': '620723',
        'areaName': '临泽县'
      }, {
        'areaCode': '620724',
        'areaName': '高台县'
      }, {
        'areaCode': '620725',
        'areaName': '山丹县'
      }]
    }, {
      'cityCode': '620800',
      'cityName': '平凉市',
      'mallAreaList': [{
        'areaCode': '620802',
        'areaName': '崆峒区'
      }, {
        'areaCode': '620821',
        'areaName': '泾川县'
      }, {
        'areaCode': '620822',
        'areaName': '灵台县'
      }, {
        'areaCode': '620823',
        'areaName': '崇信县'
      }, {
        'areaCode': '620824',
        'areaName': '华亭县'
      }, {
        'areaCode': '620825',
        'areaName': '庄浪县'
      }, {
        'areaCode': '620826',
        'areaName': '静宁县'
      }]
    }, {
      'cityCode': '620900',
      'cityName': '酒泉市',
      'mallAreaList': [{
        'areaCode': '620902',
        'areaName': '肃州区'
      }, {
        'areaCode': '620921',
        'areaName': '金塔县'
      }, {
        'areaCode': '620922',
        'areaName': '瓜州县'
      }, {
        'areaCode': '620923',
        'areaName': '肃北蒙古族自治县'
      }, {
        'areaCode': '620924',
        'areaName': '阿克塞哈萨克族自治县'
      }, {
        'areaCode': '620981',
        'areaName': '玉门市'
      }, {
        'areaCode': '620982',
        'areaName': '敦煌市'
      }]
    }, {
      'cityCode': '621000',
      'cityName': '庆阳市',
      'mallAreaList': [{
        'areaCode': '621002',
        'areaName': '西峰区'
      }, {
        'areaCode': '621021',
        'areaName': '庆城县'
      }, {
        'areaCode': '621022',
        'areaName': '环县'
      }, {
        'areaCode': '621023',
        'areaName': '华池县'
      }, {
        'areaCode': '621024',
        'areaName': '合水县'
      }, {
        'areaCode': '621025',
        'areaName': '正宁县'
      }, {
        'areaCode': '621026',
        'areaName': '宁县'
      }, {
        'areaCode': '621027',
        'areaName': '镇原县'
      }]
    }, {
      'cityCode': '621100',
      'cityName': '定西市',
      'mallAreaList': [{
        'areaCode': '621102',
        'areaName': '安定区'
      }, {
        'areaCode': '621121',
        'areaName': '通渭县'
      }, {
        'areaCode': '621122',
        'areaName': '陇西县'
      }, {
        'areaCode': '621123',
        'areaName': '渭源县'
      }, {
        'areaCode': '621124',
        'areaName': '临洮县'
      }, {
        'areaCode': '621125',
        'areaName': '漳县'
      }, {
        'areaCode': '621126',
        'areaName': '岷县'
      }]
    }, {
      'cityCode': '621200',
      'cityName': '陇南市',
      'mallAreaList': [{
        'areaCode': '621202',
        'areaName': '武都区'
      }, {
        'areaCode': '621221',
        'areaName': '成县'
      }, {
        'areaCode': '621222',
        'areaName': '文县'
      }, {
        'areaCode': '621223',
        'areaName': '宕昌县'
      }, {
        'areaCode': '621224',
        'areaName': '康县'
      }, {
        'areaCode': '621225',
        'areaName': '西和县'
      }, {
        'areaCode': '621226',
        'areaName': '礼县'
      }, {
        'areaCode': '621227',
        'areaName': '徽县'
      }, {
        'areaCode': '621228',
        'areaName': '两当县'
      }]
    }, {
      'cityCode': '622900',
      'cityName': '临夏回族自治州',
      'mallAreaList': [{
        'areaCode': '622901',
        'areaName': '临夏市'
      }, {
        'areaCode': '622921',
        'areaName': '临夏县'
      }, {
        'areaCode': '622922',
        'areaName': '康乐县'
      }, {
        'areaCode': '622923',
        'areaName': '永靖县'
      }, {
        'areaCode': '622924',
        'areaName': '广河县'
      }, {
        'areaCode': '622925',
        'areaName': '和政县'
      }, {
        'areaCode': '622926',
        'areaName': '东乡族自治县'
      }, {
        'areaCode': '622927',
        'areaName': '积石山保安族东乡族撒拉族自治县'
      }]
    }, {
      'cityCode': '623000',
      'cityName': '甘南藏族自治州',
      'mallAreaList': [{
        'areaCode': '623001',
        'areaName': '合作市'
      }, {
        'areaCode': '623021',
        'areaName': '临潭县'
      }, {
        'areaCode': '623022',
        'areaName': '卓尼县'
      }, {
        'areaCode': '623023',
        'areaName': '舟曲县'
      }, {
        'areaCode': '623024',
        'areaName': '迭部县'
      }, {
        'areaCode': '623025',
        'areaName': '玛曲县'
      }, {
        'areaCode': '623026',
        'areaName': '碌曲县'
      }, {
        'areaCode': '623027',
        'areaName': '夏河县'
      }]
    }]
  }, {
    'provinceCode': '630000',
    'provinceName': '青海省',
    'mallCityList': [{
      'cityCode': '630100',
      'cityName': '西宁市',
      'mallAreaList': [{
        'areaCode': '630102',
        'areaName': '城东区'
      }, {
        'areaCode': '630103',
        'areaName': '城中区'
      }, {
        'areaCode': '630104',
        'areaName': '城西区'
      }, {
        'areaCode': '630105',
        'areaName': '城北区'
      }, {
        'areaCode': '630121',
        'areaName': '大通回族土族自治县'
      }, {
        'areaCode': '630122',
        'areaName': '湟中县'
      }, {
        'areaCode': '630123',
        'areaName': '湟源县'
      }]
    }, {
      'cityCode': '632100',
      'cityName': '海东地区',
      'mallAreaList': [{
        'areaCode': '632100',
        'areaName': '海东地区'
      }, {
        'areaCode': '632121',
        'areaName': '平安县'
      }, {
        'areaCode': '632122',
        'areaName': '民和回族土族自治县'
      }, {
        'areaCode': '632123',
        'areaName': '乐都县'
      }, {
        'areaCode': '632126',
        'areaName': '互助土族自治县'
      }, {
        'areaCode': '632127',
        'areaName': '化隆回族自治县'
      }, {
        'areaCode': '632128',
        'areaName': '循化撒拉族自治县'
      }]
    }, {
      'cityCode': '632200',
      'cityName': '海北藏族自治州',
      'mallAreaList': [{
        'areaCode': '632221',
        'areaName': '门源回族自治县'
      }, {
        'areaCode': '632222',
        'areaName': '祁连县'
      }, {
        'areaCode': '632223',
        'areaName': '海晏县'
      }, {
        'areaCode': '632224',
        'areaName': '刚察县'
      }]
    }, {
      'cityCode': '632300',
      'cityName': '黄南藏族自治州',
      'mallAreaList': [{
        'areaCode': '632321',
        'areaName': '同仁县'
      }, {
        'areaCode': '632322',
        'areaName': '尖扎县'
      }, {
        'areaCode': '632323',
        'areaName': '泽库县'
      }, {
        'areaCode': '632324',
        'areaName': '河南蒙古族自治县'
      }]
    }, {
      'cityCode': '632500',
      'cityName': '海南藏族自治州',
      'mallAreaList': [{
        'areaCode': '632521',
        'areaName': '共和县'
      }, {
        'areaCode': '632522',
        'areaName': '同德县'
      }, {
        'areaCode': '632523',
        'areaName': '贵德县'
      }, {
        'areaCode': '632524',
        'areaName': '兴海县'
      }, {
        'areaCode': '632525',
        'areaName': '贵南县'
      }]
    }, {
      'cityCode': '632600',
      'cityName': '果洛藏族自治州',
      'mallAreaList': [{
        'areaCode': '632621',
        'areaName': '玛沁县'
      }, {
        'areaCode': '632622',
        'areaName': '班玛县'
      }, {
        'areaCode': '632623',
        'areaName': '甘德县'
      }, {
        'areaCode': '632624',
        'areaName': '达日县'
      }, {
        'areaCode': '632625',
        'areaName': '久治县'
      }, {
        'areaCode': '632626',
        'areaName': '玛多县'
      }]
    }, {
      'cityCode': '632700',
      'cityName': '玉树藏族自治州',
      'mallAreaList': [{
        'areaCode': '632701',
        'areaName': '玉树市'
      }, {
        'areaCode': '632722',
        'areaName': '杂多县'
      }, {
        'areaCode': '632723',
        'areaName': '称多县'
      }, {
        'areaCode': '632724',
        'areaName': '治多县'
      }, {
        'areaCode': '632725',
        'areaName': '囊谦县'
      }, {
        'areaCode': '632726',
        'areaName': '曲麻莱县'
      }]
    }, {
      'cityCode': '632800',
      'cityName': '海西蒙古族藏族自治州',
      'mallAreaList': [{
        'areaCode': '632801',
        'areaName': '格尔木市'
      }, {
        'areaCode': '632802',
        'areaName': '德令哈市'
      }, {
        'areaCode': '632821',
        'areaName': '乌兰县'
      }, {
        'areaCode': '632822',
        'areaName': '都兰县'
      }, {
        'areaCode': '632823',
        'areaName': '天峻县'
      }]
    }]
  }, {
    'provinceCode': '640000',
    'provinceName': '宁夏回族自治区',
    'mallCityList': [{
      'cityCode': '640100',
      'cityName': '银川市',
      'mallAreaList': [{
        'areaCode': '640104',
        'areaName': '兴庆区'
      }, {
        'areaCode': '640105',
        'areaName': '西夏区'
      }, {
        'areaCode': '640106',
        'areaName': '金凤区'
      }, {
        'areaCode': '640121',
        'areaName': '永宁县'
      }, {
        'areaCode': '640122',
        'areaName': '贺兰县'
      }, {
        'areaCode': '640181',
        'areaName': '灵武市'
      }]
    }, {
      'cityCode': '640200',
      'cityName': '石嘴山市',
      'mallAreaList': [{
        'areaCode': '640202',
        'areaName': '大武口区'
      }, {
        'areaCode': '640205',
        'areaName': '惠农区'
      }, {
        'areaCode': '640221',
        'areaName': '平罗县'
      }]
    }, {
      'cityCode': '640300',
      'cityName': '吴忠市',
      'mallAreaList': [{
        'areaCode': '640302',
        'areaName': '利通区'
      }, {
        'areaCode': '640303',
        'areaName': '红寺堡区'
      }, {
        'areaCode': '640323',
        'areaName': '盐池县'
      }, {
        'areaCode': '640324',
        'areaName': '同心县'
      }, {
        'areaCode': '640381',
        'areaName': '青铜峡市'
      }]
    }, {
      'cityCode': '640400',
      'cityName': '固原市',
      'mallAreaList': [{
        'areaCode': '640402',
        'areaName': '原州区'
      }, {
        'areaCode': '640422',
        'areaName': '西吉县'
      }, {
        'areaCode': '640423',
        'areaName': '隆德县'
      }, {
        'areaCode': '640424',
        'areaName': '泾源县'
      }, {
        'areaCode': '640425',
        'areaName': '彭阳县'
      }]
    }, {
      'cityCode': '640500',
      'cityName': '中卫市',
      'mallAreaList': [{
        'areaCode': '640502',
        'areaName': '沙坡头区'
      }, {
        'areaCode': '640521',
        'areaName': '中宁县'
      }, {
        'areaCode': '640522',
        'areaName': '海原县'
      }]
    }]
  }, {
    'provinceCode': '650000',
    'provinceName': '新疆维吾尔自治区',
    'mallCityList': [{
      'cityCode': '650100',
      'cityName': '乌鲁木齐市',
      'mallAreaList': [{
        'areaCode': '650102',
        'areaName': '天山区'
      }, {
        'areaCode': '650103',
        'areaName': '沙依巴克区'
      }, {
        'areaCode': '650104',
        'areaName': '新市区'
      }, {
        'areaCode': '650105',
        'areaName': '水磨沟区'
      }, {
        'areaCode': '650106',
        'areaName': '头屯河区'
      }, {
        'areaCode': '650107',
        'areaName': '达坂城区'
      }, {
        'areaCode': '650109',
        'areaName': '米东区'
      }, {
        'areaCode': '650121',
        'areaName': '乌鲁木齐县'
      }]
    }, {
      'cityCode': '650200',
      'cityName': '克拉玛依市',
      'mallAreaList': [{
        'areaCode': '650202',
        'areaName': '独山子区'
      }, {
        'areaCode': '650203',
        'areaName': '克拉玛依区'
      }, {
        'areaCode': '650204',
        'areaName': '白碱滩区'
      }, {
        'areaCode': '650205',
        'areaName': '乌尔禾区'
      }]
    }, {
      'cityCode': '652100',
      'cityName': '吐鲁番地区',
      'mallAreaList': [{
        'areaCode': '652101',
        'areaName': '吐鲁番市'
      }, {
        'areaCode': '652122',
        'areaName': '鄯善县'
      }, {
        'areaCode': '652123',
        'areaName': '托克逊县'
      }]
    }, {
      'cityCode': '652200',
      'cityName': '哈密地区',
      'mallAreaList': [{
        'areaCode': '652201',
        'areaName': '哈密市'
      }, {
        'areaCode': '652222',
        'areaName': '巴里坤哈萨克自治县'
      }, {
        'areaCode': '652223',
        'areaName': '伊吾县'
      }]
    }, {
      'cityCode': '652300',
      'cityName': '昌吉回族自治州',
      'mallAreaList': [{
        'areaCode': '652301',
        'areaName': '昌吉市'
      }, {
        'areaCode': '652302',
        'areaName': '阜康市'
      }, {
        'areaCode': '652323',
        'areaName': '呼图壁县'
      }, {
        'areaCode': '652324',
        'areaName': '玛纳斯县'
      }, {
        'areaCode': '652325',
        'areaName': '奇台县'
      }, {
        'areaCode': '652327',
        'areaName': '吉木萨尔县'
      }, {
        'areaCode': '652328',
        'areaName': '木垒哈萨克自治县'
      }]
    }, {
      'cityCode': '652700',
      'cityName': '博尔塔拉蒙古自治州',
      'mallAreaList': [{
        'areaCode': '652701',
        'areaName': '博乐市'
      }, {
        'areaCode': '652702',
        'areaName': '阿拉山口市'
      }, {
        'areaCode': '652722',
        'areaName': '精河县'
      }, {
        'areaCode': '652723',
        'areaName': '温泉县'
      }]
    }, {
      'cityCode': '652800',
      'cityName': '巴音郭楞蒙古自治州',
      'mallAreaList': [{
        'areaCode': '652801',
        'areaName': '库尔勒市'
      }, {
        'areaCode': '652822',
        'areaName': '轮台县'
      }, {
        'areaCode': '652823',
        'areaName': '尉犁县'
      }, {
        'areaCode': '652824',
        'areaName': '若羌县'
      }, {
        'areaCode': '652825',
        'areaName': '且末县'
      }, {
        'areaCode': '652826',
        'areaName': '焉耆回族自治县'
      }, {
        'areaCode': '652827',
        'areaName': '和静县'
      }, {
        'areaCode': '652828',
        'areaName': '和硕县'
      }, {
        'areaCode': '652829',
        'areaName': '博湖县'
      }]
    }, {
      'cityCode': '652900',
      'cityName': '阿克苏地区',
      'mallAreaList': [{
        'areaCode': '652901',
        'areaName': '阿克苏市'
      }, {
        'areaCode': '652922',
        'areaName': '温宿县'
      }, {
        'areaCode': '652923',
        'areaName': '库车县'
      }, {
        'areaCode': '652924',
        'areaName': '沙雅县'
      }, {
        'areaCode': '652925',
        'areaName': '新和县'
      }, {
        'areaCode': '652926',
        'areaName': '拜城县'
      }, {
        'areaCode': '652927',
        'areaName': '乌什县'
      }, {
        'areaCode': '652928',
        'areaName': '阿瓦提县'
      }, {
        'areaCode': '652929',
        'areaName': '柯坪县'
      }]
    }, {
      'cityCode': '653000',
      'cityName': '克孜勒苏柯尔克孜自治州',
      'mallAreaList': [{
        'areaCode': '653001',
        'areaName': '阿图什市'
      }, {
        'areaCode': '653022',
        'areaName': '阿克陶县'
      }, {
        'areaCode': '653023',
        'areaName': '阿合奇县'
      }, {
        'areaCode': '653024',
        'areaName': '乌恰县'
      }]
    }, {
      'cityCode': '653100',
      'cityName': '喀什地区',
      'mallAreaList': [{
        'areaCode': '653101',
        'areaName': '喀什市'
      }, {
        'areaCode': '653121',
        'areaName': '疏附县'
      }, {
        'areaCode': '653122',
        'areaName': '疏勒县'
      }, {
        'areaCode': '653123',
        'areaName': '英吉沙县'
      }, {
        'areaCode': '653124',
        'areaName': '泽普县'
      }, {
        'areaCode': '653125',
        'areaName': '莎车县'
      }, {
        'areaCode': '653126',
        'areaName': '叶城县'
      }, {
        'areaCode': '653127',
        'areaName': '麦盖提县'
      }, {
        'areaCode': '653128',
        'areaName': '岳普湖县'
      }, {
        'areaCode': '653129',
        'areaName': '伽师县'
      }, {
        'areaCode': '653130',
        'areaName': '巴楚县'
      }, {
        'areaCode': '653131',
        'areaName': '塔什库尔干塔吉克自治县'
      }]
    }, {
      'cityCode': '653200',
      'cityName': '和田地区',
      'mallAreaList': [{
        'areaCode': '653201',
        'areaName': '和田市'
      }, {
        'areaCode': '653221',
        'areaName': '和田县'
      }, {
        'areaCode': '653222',
        'areaName': '墨玉县'
      }, {
        'areaCode': '653223',
        'areaName': '皮山县'
      }, {
        'areaCode': '653224',
        'areaName': '洛浦县'
      }, {
        'areaCode': '653225',
        'areaName': '策勒县'
      }, {
        'areaCode': '653226',
        'areaName': '于田县'
      }, {
        'areaCode': '653227',
        'areaName': '民丰县'
      }]
    }, {
      'cityCode': '654000',
      'cityName': '伊犁哈萨克自治州',
      'mallAreaList': [{
        'areaCode': '654002',
        'areaName': '伊宁市'
      }, {
        'areaCode': '654003',
        'areaName': '奎屯市'
      }, {
        'areaCode': '654021',
        'areaName': '伊宁县'
      }, {
        'areaCode': '654022',
        'areaName': '察布查尔锡伯自治县'
      }, {
        'areaCode': '654023',
        'areaName': '霍城县'
      }, {
        'areaCode': '654024',
        'areaName': '巩留县'
      }, {
        'areaCode': '654025',
        'areaName': '新源县'
      }, {
        'areaCode': '654026',
        'areaName': '昭苏县'
      }, {
        'areaCode': '654027',
        'areaName': '特克斯县'
      }, {
        'areaCode': '654028',
        'areaName': '尼勒克县'
      }]
    }, {
      'cityCode': '654200',
      'cityName': '塔城地区',
      'mallAreaList': [{
        'areaCode': '654201',
        'areaName': '塔城市'
      }, {
        'areaCode': '654202',
        'areaName': '乌苏市'
      }, {
        'areaCode': '654221',
        'areaName': '额敏县'
      }, {
        'areaCode': '654223',
        'areaName': '沙湾县'
      }, {
        'areaCode': '654224',
        'areaName': '托里县'
      }, {
        'areaCode': '654225',
        'areaName': '裕民县'
      }, {
        'areaCode': '654226',
        'areaName': '和布克赛尔蒙古自治县'
      }]
    }, {
      'cityCode': '654300',
      'cityName': '阿勒泰地区',
      'mallAreaList': [{
        'areaCode': '654301',
        'areaName': '阿勒泰市'
      }, {
        'areaCode': '654321',
        'areaName': '布尔津县'
      }, {
        'areaCode': '654322',
        'areaName': '富蕴县'
      }, {
        'areaCode': '654323',
        'areaName': '福海县'
      }, {
        'areaCode': '654324',
        'areaName': '哈巴河县'
      }, {
        'areaCode': '654325',
        'areaName': '青河县'
      }, {
        'areaCode': '654326',
        'areaName': '吉木乃县'
      }]
    }, {
      'cityCode': '659000',
      'cityName': '省直辖行政单位',
      'mallAreaList': [{
        'areaCode': '659001',
        'areaName': '石河子市'
      }, {
        'areaCode': '659002',
        'areaName': '阿拉尔市'
      }, {
        'areaCode': '659003',
        'areaName': '图木舒克市'
      }]
    }]
  }]
};