//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  components: {},
  data: function data() {
    return {
      config: {
        day: 0,
        serverRealTime: '2021-12-17 10:10:10',
        serverBalanceTime: '2021-12-17 10:10:10'
      }
    };
  },
  methods: {}
};