var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-input",
    {
      staticClass: "input-with-select",
      staticStyle: { width: "300px" },
      attrs: { size: "mini", placeholder: _vm.placeholder },
      model: {
        value: _vm.value,
        callback: function($$v) {
          _vm.value = $$v
        },
        expression: "value"
      }
    },
    [
      _c(
        "el-select",
        {
          style: { width: _vm.selectWidth + "px" },
          attrs: { slot: "prepend", placeholder: "请选择" },
          slot: "prepend",
          model: {
            value: _vm.key,
            callback: function($$v) {
              _vm.key = $$v
            },
            expression: "key"
          }
        },
        _vm._l(_vm.field, function(item) {
          return _c("el-option", {
            attrs: { label: item.desc, value: item.key }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }