//
//
//
//
export default {
  props: {
    type: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    field: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      value: ''
    };
  },
  methods: {
    where: function where() {
      var where = {};

      if (this.value === '') {
        return where;
      }

      for (var i in this.field) {
        var item = this.field[i];
        where[item.key] = this.value;
      }

      return where;
    }
  }
};