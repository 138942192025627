var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-link",
    {
      attrs: { size: "mini", type: "primary" },
      on: {
        click: function($event) {
          return _vm.onClickLink(_vm.prop, _vm.model)
        }
      }
    },
    [_vm._v(_vm._s(_vm.model[_vm.prop]))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }