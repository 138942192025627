import "core-js/modules/es.array.index-of";
import "core-js/modules/es.number.constructor";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.parse-int";
import "core-js/modules/es.regexp.to-string";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tempAdd from '../tempAdd/index';
import tempMulAdd from '../tempMulAdd/index';
import tempDetail from '../tempDetail/index';
import tableText from './components/tableText';
import tableAvatar from './components/tableAvatar';
import tableIcon from './components/tableIcon';
import tableImage from './components/tableImage';
import tableImages from './components/tableImages';
import tableLink from './components/tableLink';
import tableSwitch from './components/tableSwitch';
import tableVideo from './components/tableVideo';
import tableSelect from './components/tableSelect';
import tableTextarea from './components/tableTextarea';
import tableRate from './components/tableRate';
import tableRadio from './components/tableRadio';
import tableAudio from './components/tableAudio';
import tableExcelSelect from './components/tableExcelSelect';
import tableCustom from './components/tableCustom';
import user from '@/store/modules/d2admin/modules/user';
export default {
  components: {
    tempAdd: tempAdd,
    tempDetail: tempDetail,
    tempMulAdd: tempMulAdd,
    tableText: tableText,
    tableAvatar: tableAvatar,
    tableIcon: tableIcon,
    tableImage: tableImage,
    tableImages: tableImages,
    tableLink: tableLink,
    tableSwitch: tableSwitch,
    tableVideo: tableVideo,
    tableSelect: tableSelect,
    tableTextarea: tableTextarea,
    tableRate: tableRate,
    tableRadio: tableRadio,
    tableAudio: tableAudio,
    tableExcelSelect: tableExcelSelect,
    tableCustom: tableCustom
  },
  props: {
    size: {
      type: String,
      default: 'mini'
    },
    title: {
      type: String,
      default: ''
    },
    maxHeight: {
      type: Number,
      default: null
    },
    opButtonWidth: {
      type: Number,
      default: null
    },
    tableModel: {
      type: Array,
      required: true
    },
    tableSwitchProps: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    tableSelectProps: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    tableCascaderProps: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    tableRadioProps: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    tableGroupProps: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    remoteFunc: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    rowButton: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    headerButton: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    onAddShow: {
      type: Function,
      default: function _default() {
        return null;
      }
    },
    showSelection: {
      type: Boolean,
      default: false
    },
    //是否显示多选
    showSummary: {
      type: Boolean,
      default: false
    },
    showPagination: {
      type: Boolean,
      default: true
    },
    //是否显示分页
    stripe: {
      type: Boolean,
      default: false
    },
    //斑马纹
    isGroup: {
      type: Boolean,
      default: false
    },
    //是否分组显示
    border: {
      type: Boolean,
      default: true
    },
    //是否显示边框
    defaultExpandAll: {
      type: Boolean,
      default: undefined
    },
    //是否全展开
    treeProps: {
      type: Object,
      default: function _default() {
        return undefined;
      }
    },
    //多级属性
    rowKey: {
      type: String,
      default: undefined
    },
    //row-key
    onSummary: {
      type: Function
    },
    onSwitchChange: {
      type: Function
    },
    onClickRowButton: {
      type: Function
    },
    onClickHeaderButton: {
      type: Function
    },
    onClickLink: {
      type: Function
    },
    onRowButtonShow: {
      type: Function
    },
    //行按钮是否显示
    reqTableData: {
      type: Function,
      required: true
    },
    reqAdd: {
      type: Function
    },
    reqDel: {
      type: Function
    },
    openShow: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      tableData: [],
      showList: {},
      reloadTimer: 'reloadTimer',
      pagination: {
        page: 1,
        limit: this.$tempModel.pagination.limit,
        total: 0,
        sizes: this.$tempModel.pagination.sizes
      },
      multipleSelection: []
    };
  },
  computed: {
    rowButtonWidth: function rowButtonWidth() {
      var width = 0;

      for (var i = 0; i < this.rowButton.length; i++) {
        var item = this.rowButton[i];

        if (!this.powerOf(item.id)) {
          continue;
        }

        width = width + item.title.length * 15;
        width += 50;
      }

      return width;
    },
    canAdd: function canAdd() {
      for (var i in this.rowButton) {
        if (this.rowButton[i].tag === 'edit') {
          return true;
        }
      }

      for (var _i in this.headerButton) {
        if (this.headerButton[_i].tag === 'add') {
          return true;
        }
      }

      return false;
    },
    canDetail: function canDetail() {
      for (var i in this.rowButton) {
        if (this.rowButton[i].tag === 'detail') {
          return true;
        }
      }

      return false;
    }
  },
  created: function created() {
    var tmp = {};

    for (var i in this.tableModel) {
      tmp[this.tableModel[i].prop] = true;
    }

    this.showList = tmp;
  },
  beforeUpdate: function beforeUpdate() {
    if (!this.loaded) {
      this.reloadData();
      this.loaded = true;
    }
  },
  mounted: function mounted() {
    var _this = this;

    for (var key in this.tableSelectProps) {
      var item = this.tableSelectProps[key];

      if (item.remote) {
        if (this.remoteFunc.hasOwnProperty(item.remoteFunc)) {
          this.remoteFunc[item.remoteFunc](key, null, function (tag, data) {
            _this.tableSelectProps[tag].data = data;
          });
        }
      }
    }
  },
  methods: {
    powerOf: function powerOf(tid) {
      if (!tid || tid === '') {
        return true;
      }

      var userRules = user.state.info.rules_ids;

      if (userRules.indexOf(parseInt(tid)) >= 0) {
        return true;
      }

      return false;
    },
    clickResetShow: function clickResetShow() {
      for (var key in this.showList) {
        this.showList[key] = true;
      }
    },
    curComponent: function curComponent(type) {
      if (this.$tempModel.showType.isText(type)) {
        return 'tableText';
      } else if (this.$tempModel.showType.isAvatar(type)) {
        return 'tableAvatar';
      } else if (this.$tempModel.showType.isIcon(type)) {
        return 'tableIcon';
      } else if (this.$tempModel.showType.isSelect(type)) {
        return 'tableSelect';
      } else if (this.$tempModel.showType.isImage(type)) {
        return 'tableImage';
      } else if (this.$tempModel.showType.isImages(type)) {
        return 'tableImages';
      } else if (this.$tempModel.showType.isLink(type)) {
        return 'tableLink';
      } else if (this.$tempModel.showType.isSwitch(type)) {
        return 'tableSwitch';
      } else if (this.$tempModel.showType.isVideo(type)) {
        return 'tableVideo';
      } else if (this.$tempModel.showType.isTextarea(type)) {
        return 'tableTextarea';
      } else if (this.$tempModel.showType.isRate(type)) {
        return 'tableRate';
      } else if (this.$tempModel.showType.isRadio(type)) {
        return 'tableRadio';
      } else if (this.$tempModel.showType.isAudio(type)) {
        return 'tableAudio';
      } else if (this.$tempModel.showType.isCustom(type)) {
        return 'tableCustom';
      }
    },
    _rowButtonShow: function _rowButtonShow(btnIndex, row) {
      if (this.onRowButtonShow) {
        return this.onRowButtonShow(btnIndex, row);
      }

      return true;
    },
    callRemoteFunc: function callRemoteFunc(prop) {
      var _this2 = this;

      var item = this.tableSelectProps[prop];

      if (item && item.remote) {
        if (this.remoteFunc.hasOwnProperty(item.remoteFunc)) {
          this.remoteFunc[item.remoteFunc](prop, null, function (tag, data) {
            _this2.tableSelectProps[tag].data = data;
          });
        }
      }
    },
    switchDisable: function switchDisable() {
      //编辑按钮有没有权限，有权限switch按钮就有权限
      for (var i in this.rowButton) {
        var item = this.rowButton[i];

        if (item.tag === this.$tempModel.buttonTag.edit) {
          if (this.powerOf(item.id)) {
            return false;
          }
        }
      }

      return true;
    },
    selectValue: function selectValue(prop, value) {
      if (!this.tableSelectProps.hasOwnProperty(prop)) {
        return '';
      }

      for (var i in this.tableSelectProps[prop].data) {
        if (this.tableSelectProps[prop].data[i].key == value) {
          return this.tableSelectProps[prop].data[i].value;
        }
      }

      return '';
    },
    splitTableData: function splitTableData(data) {
      for (var i in data) {
        var item = data[i];

        for (var field in item) {
          if (Object.prototype.toString.call(item[field]) === '[object Object]') {
            for (var key in item[field]) {
              if (Object.prototype.toString.call(item[field][key]) === '[object Object]') {
                for (var subKey in item[field][key]) {
                  if (Object.prototype.toString.call(item[field][key][subKey]) === '[object Object]') {
                    //3层字典不做处理
                    var nField = field + '.' + key + '.' + subKey;
                    item[nField] = JSON.stringify(item[field][key][subKey]);
                  } else {
                    var _nField = field + '.' + key + '.' + subKey;

                    item[_nField] = item[field][key][subKey];
                  }
                }
              } else {
                var _nField2 = field + '.' + key;

                item[_nField2] = item[field][key];
              }
            }
          }
        }
      }
    },
    reloadData: function reloadData() {
      var _this3 = this;

      new Promise(function (resolve) {
        var params = {};

        if (_this3.showPagination) {
          params = {
            page: _this3.pagination.page,
            limit: _this3.pagination.limit
          };
        }

        _this3.reqTableData(params, resolve);
      }).then(function (_ref) {
        var total = _ref.total,
            data = _ref.data,
            _ref$isSplit = _ref.isSplit,
            isSplit = _ref$isSplit === void 0 ? true : _ref$isSplit;
        _this3.pagination.total = total;

        if (isSplit) {
          _this3.splitTableData(data);
        }

        _this3.tableData = data;
      });
    },
    exportExcelCustom: function exportExcelCustom(finish) {
      var _this4 = this;

      this.$refs['excel'].show(this.title, this.tableModel, function (ret, tag, model) {
        if (ret) {
          var columns = [];

          for (var i in _this4.tableModel) {
            if (_this4.tableModel[i].excel === 1) {
              columns.push(_this4.tableModel[i]);
            }
          }

          var cols = [];

          for (var _i2 in columns) {
            for (var j in model) {
              if (columns[_i2].prop == model[j].prop) {
                cols.push(columns[_i2]);
                break;
              }
            }
          }

          _this4.getExportData(tag, function (data) {
            _this4.splitTableData(data);

            for (var _i3 in columns) {
              var item = columns[_i3];

              if (item.type === _this4.$tempModel.showType.select) {
                var selectData = _this4.tableSelectProps[item.prop].data;

                for (var k in data) {
                  var value = data[k][item.prop];

                  for (var _j in selectData) {
                    if (selectData[_j].key == value) {
                      data[k][item.prop] = selectData[_j].value;
                    }
                  }
                }
              }
            }

            finish(true, tag, cols, data);
          });
        } else {
          finish(false);
        }
      });
    },
    getExportData: function getExportData(isAll, finish) {
      var _this5 = this;

      if (isAll === 0) {
        //当前页导出
        var data = this.tableData;
        finish(data);
      } else {
        new Promise(function (resolve) {
          _this5.reqTableData({
            page: 1,
            limit: 1000000
          }, resolve);
        }).then(function (_ref2) {
          var total = _ref2.total,
              data = _ref2.data;
          finish(data);
        });
      }
    },
    exportExcel: function exportExcel(title) {
      var _this6 = this;

      if (!title || title === '') {
        title = this.title;
      }

      this.$g.fun.startLoading();
      this.exportExcelCustom(function (ret, isAll, columns, data) {
        if (ret) {
          if (_this6.treeProps) {
            //多级
            if (data.length > 0) {
              data = _this6.$tempModel.treeToList(data, 'children', function (parent, item) {});
            }
          }

          _this6.$export.excel({
            columns: columns,
            data: data,
            title: title
          }).then(function () {
            _this6.$g.fun.endLoading();
          });
        } else {
          _this6.$g.fun.endLoading();
        }
      });
    },
    _onSwitchChange: function _onSwitchChange(val, prop, row) {
      if (this.onSwitchChange()) {
        this.onSwitchChange(val, prop, row);
      }
    },
    _onClickRowButton: function _onClickRowButton(index, row) {
      var _this7 = this;

      var tag = this.rowButton[index].tag;

      if (tag === this.$tempModel.buttonTag.edit) {
        this.$refs['add'].show(true, row);
      } else if (tag === this.$tempModel.buttonTag.rowAdd) {
        this.$refs['add'].show(false, row);
      } else if (tag === this.$tempModel.buttonTag.detail) {
        this.$refs['detail'].show(row);
      } else if (tag === this.$tempModel.buttonTag.del) {
        this.$confirm('确定要删除当前数据么？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          if (_this7.reqDel) {
            new Promise(function (resolve) {
              _this7.reqDel([row], resolve);
            }).then(function (error) {
              if (!error) {
                _this7.$message({
                  type: 'success',
                  message: '删除成功!'
                });

                _this7.reloadData();
              }
            });
          }
        }).catch(function () {});
      }

      if (this.onClickRowButton) {
        this.onClickRowButton(index, row);
      }
    },
    _onClickHeaderButton: function _onClickHeaderButton(index) {
      var _this8 = this;

      if (this.onClickHeaderButton) {
        this.onClickHeaderButton(index, this.multipleSelection);
      }

      var tag = this.headerButton[index].tag;

      if (tag === this.$tempModel.buttonTag.add) {
        this.$refs['add'].show(false);
      } else if (tag === this.$tempModel.buttonTag.mulDel) {
        var ms = this.multipleSelection;

        if (ms.length <= 0) {
          this.$alert('请选择要删除的数据', '提示', {
            confirmButtonText: '确定'
          });
          return;
        }

        this.$confirm('确定要删除选中的数据么？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          if (_this8.reqDel) {
            new Promise(function (resolve) {
              _this8.reqDel(ms, resolve);
            }).then(function () {
              _this8.$message({
                type: 'success',
                message: '删除成功!'
              });

              _this8.reloadData();
            });
          }
        }).catch(function () {});
      }
    },
    _activeValue: function _activeValue(prop) {
      if (this.tableSwitchProps.hasOwnProperty(prop)) {
        return this.tableSwitchProps[prop].activeValue;
      } else {
        return 1;
      }
    },
    _inactiveValue: function _inactiveValue(prop) {
      if (this.tableSwitchProps.hasOwnProperty(prop)) {
        return this.tableSwitchProps[prop].inactiveValue;
      } else {
        return 0;
      }
    },
    _onSelectionChange: function _onSelectionChange(val) {
      this.multipleSelection = val;
    },
    _onPanSizeChange: function _onPanSizeChange(val) {
      this.pagination.limit = val;
      this.reloadData();
    },
    _onPanCurrentChange: function _onPanCurrentChange(val) {
      this.pagination.page = val;
      this.reloadData();
    },
    _onAddShow: function _onAddShow(isEdit, params, row) {
      if (this.onAddShow) {
        this.onAddShow(isEdit, params, row);
      }
    },
    _onReqAdd: function _onReqAdd(isEdit, params, row, resolveAdd) {
      var _this9 = this;

      new Promise(function (resolve) {
        _this9.reqAdd(isEdit, params, row, resolve);
      }).then(function (error) {
        if (!error) {
          _this9.reloadData();
        }

        resolveAdd(error);
      });
    },
    _onClickLink: function _onClickLink(prop, value) {
      if (this.onClickLink) {
        this.onClickLink(prop, value);
      }
    },
    _relationData: function _relationData(id) {
      //关联条件的数据(谁关联指向了id)
      for (var key in this.tableSelectProps) {
        if (this.tableSelectProps[key].id == id) {
          return this.tableSelectProps[key];
        }
      }

      for (var _key in this.tableSelectProps) {
        if (this.tableSelectProps[_key].id == id) {
          return this.tableSelectProps[_key];
        }
      }

      for (var i = 0; i < this.searchData.length; i++) {
        var section = this.searchData[i];

        if (section.relationId == id) {
          return this.parseWhere(section.id);
        }
      }

      return null;
    }
  }
};