var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    { ref: "config.", attrs: { size: "mini", model: _vm.config } },
    [
      _c(
        "el-form-item",
        { attrs: { label: "编号生成规则" } },
        [
          _c(
            "el-input",
            {
              staticClass: "formItemInput",
              model: {
                value: _vm.config.numberPrefix,
                callback: function($$v) {
                  _vm.$set(_vm.config, "numberPrefix", $$v)
                },
                expression: "config.numberPrefix"
              }
            },
            [_c("template", { slot: "prepend" }, [_vm._v("前缀")])],
            2
          ),
          _c(
            "el-input",
            {
              staticClass: "formItemInput",
              model: {
                value: _vm.config.numberDigit,
                callback: function($$v) {
                  _vm.$set(_vm.config, "numberDigit", $$v)
                },
                expression: "config.numberDigit"
              }
            },
            [_c("template", { slot: "prepend" }, [_vm._v("位数")])],
            2
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "默认密码设置" } },
        [
          _c(
            "el-input",
            {
              staticClass: "formItemInput",
              model: {
                value: _vm.config.pwd1,
                callback: function($$v) {
                  _vm.$set(_vm.config, "pwd1", $$v)
                },
                expression: "config.pwd1"
              }
            },
            [_c("template", { slot: "prepend" }, [_vm._v("一级密码")])],
            2
          ),
          _c(
            "el-input",
            {
              staticClass: "formItemInput",
              model: {
                value: _vm.config.pwd2,
                callback: function($$v) {
                  _vm.$set(_vm.config, "pwd2", $$v)
                },
                expression: "config.pwd2"
              }
            },
            [_c("template", { slot: "prepend" }, [_vm._v("二级密码")])],
            2
          ),
          _c("el-tag", { attrs: { type: "danger", size: "mini" } }, [
            _vm._v("密码未填写状态下，未设置状态下注册默认为编号")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }