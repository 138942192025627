import "core-js/modules/es.array.concat";
//
//
//
//
//
//
//
//
//
//
import { last, get } from 'lodash';
export default {
  data: function data() {
    return {
      isActive: false,
      path: ''
    };
  },
  computed: {
    show: function show() {
      return process.env.VUE_APP_SCOURCE_LINK === 'TRUE';
    }
  },
  watch: {
    $route: {
      handler: function handler(to) {
        this.path = get(last(to.matched), 'components.default.__source');
      },
      immediate: true
    }
  },
  mounted: function mounted() {
    var _this = this;

    // 一秒后显示按钮
    setTimeout(function () {
      _this.isActive = true;
    }, 500);
  },
  methods: {
    // 点击按钮的时候跳转到源代码
    handleClick: function handleClick() {
      this.$open("".concat(process.env.VUE_APP_REPO, "/blob/master/").concat(this.path));
    }
  }
};