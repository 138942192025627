import "core-js/modules/es.array.map";
import "core-js/modules/es.string.search";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tempTable from "@/components/rw/tempTable/index";
import tempSearch from "@/components/rw/tempSearch/index";
import tempForm from "@/components/rw/tempForm/index";
import tempChart from "@/components/rw/tempChart/index";
export default {
  components: {
    tempTable: tempTable,
    tempSearch: tempSearch,
    tempForm: tempForm,
    tempChart: tempChart
  },
  data: function data() {
    var _this = this;

    return {
      tableModel_1000: {
        "type": "tableTemplate",
        "id": "id_1000",
        "del": false,
        "tableModel": [{
          "label": "备份文件名",
          "show": 1,
          "add": 1,
          "detail": 1,
          "width": null,
          "type": "text",
          "desc": "",
          "groupId": "",
          "rules": [],
          "prop": "file_name"
        }, {
          "label": "备份时间",
          "show": 1,
          "add": 1,
          "detail": 1,
          "width": null,
          "type": "text",
          "desc": "",
          "groupId": "",
          "rules": [],
          "prop": "add_time"
        }, {
          "label": "备份大小",
          "show": 1,
          "add": 1,
          "detail": 1,
          "width": null,
          "type": "text",
          "desc": "",
          "groupId": "",
          "rules": [],
          "prop": "file_size"
        }],
        "tableSwitchProps": {},
        "tableSelectProps": {},
        "tableCascaderProps": {},
        "tableFileProps": {},
        "rowButton": [{
          "title": "下载",
          "tag": "detail",
          "id": "",
          "type": null
        }, {
          "title": "恢复",
          "type": "danger",
          "tag": "edit",
          "id": ""
        }],
        "stripe": true,
        "border": true,
        "showPagination": true,
        "showSelection": true,
        "showSummary": false,
        "title": "数据库备份",
        "tableName": "",
        "isGroup": false,
        "isTree": false,
        "headerButton": [{
          "tag": "default",
          "type": null,
          "title": "备份数据库",
          "id": "",
          "icon": ""
        }, {
          "tag": "mul-del",
          "type": "danger",
          "title": "删除选中的备份",
          "id": "",
          "icon": ""
        }],
        "tableAutocompleteProps": {},
        "tableRadioProps": {}
      },
      remoteFunc: {}
    };
  },
  mounted: function mounted() {
    this.table = this.$refs.ref_1000;
  },
  methods: {
    reqTableData: function reqTableData(params, resolve) {
      var where = {};

      if (this.search) {
        where = this.search.parseWhere();
        params.where = where;
      }

      resolve({
        total: 0,
        data: []
      });
    },
    reqAdd: function reqAdd(isEdit, params, row, resolve) {//if (isEdit){
      //  params.id = row.id
      //}
      //xxxAdd(params).then(async res=>{
      //  resolve()
      //})
    },
    reqDel: function reqDel(params, resolve) {
      var ids = params.map(function (item) {
        return item.id;
      }); //xxxDel({id:ids}).then(async res=>{
      //  resolve()
      //})
    },
    onClickLink: function onClickLink(prop, row) {//点击列表link类型回调
    },
    onClickRowButton: function onClickRowButton(btnIndex, row) {//点击列表按钮回调
    },
    onClickHeaderButton: function onClickHeaderButton(btnIndex, row) {//点击列表头部按钮回调
    },
    onSummary: function onSummary(param) {//列表尾部合计回调
    },
    onSwitchChange: function onSwitchChange(val, prop, row) {//列表中switch变化事件
    },
    onClickSearchOperate: function onClickSearchOperate(btnIndex, btnData) {
      //条件菜单按钮点击事件
      if (btnData.tag === this.$tempModel.buttonTag.search) {
        this.table.reloadData();
      } else if (btnData.tag === this.$tempModel.buttonTag.export) {
        this.table.exportExcel();
      } else if (btnData.tag === this.$tempModel.buttonTag.import) {
        var header = btnData.header;
        var results = btnData.results;
      }
    },
    onConditionChange: function onConditionChange(tag, index) {
      this.table.reloadData();
    },
    onRowButtonShow: function onRowButtonShow(btnIndex, row) {
      return true;
    }
  }
};