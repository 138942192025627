var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-tooltip",
    {
      attrs: {
        effect: "dark",
        content: _vm.tooltipContent,
        placement: "bottom"
      }
    },
    [
      _c(
        "el-button",
        {
          staticClass: "d2-ml-0 d2-mr btn-text can-hover",
          attrs: { type: "text" },
          on: { click: _vm.handleClick }
        },
        [
          _vm.logLength > 0
            ? _c(
                "el-badge",
                {
                  attrs: {
                    max: 99,
                    value: _vm.logLengthError,
                    "is-dot": _vm.logLengthError === 0
                  }
                },
                [
                  _c("d2-icon", {
                    staticStyle: { "font-size": "20px" },
                    attrs: {
                      name: _vm.logLengthError === 0 ? "dot-circle-o" : "bug"
                    }
                  })
                ],
                1
              )
            : _c("d2-icon", {
                staticStyle: { "font-size": "20px" },
                attrs: { name: "dot-circle-o" }
              })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }