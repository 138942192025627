import "core-js/modules/es.array.map";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.search";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tempTable from "@/components/rw/tempTable/index";
import tempSearch from "@/components/rw/tempSearch/index";
import tempForm from "@/components/rw/tempForm/index";
import tempChart from "@/components/rw/tempChart/index";
import { tradeFlow } from '../../../../api/apis';
export default {
  components: {
    tempTable: tempTable,
    tempSearch: tempSearch,
    tempForm: tempForm,
    tempChart: tempChart
  },
  data: function data() {
    var _this = this;

    return {
      searchModel_1000: {
        "del": false,
        "id": "id_1000",
        "operateData": [{
          "icon": "el-icon-search",
          "tag": "search",
          "title": "搜索",
          "type": "primary"
        }, {
          "icon": "el-icon-position",
          "tag": "export",
          "title": "导出",
          "type": "primary"
        }],
        "searchData": [{
          "children": [{
            "field": [],
            "title": "全部",
            "type": "button"
          }, {
            "field": [{
              "key": "source_type",
              "value": "1"
            }],
            "title": "订单",
            "type": "button"
          }, {
            "field": [{
              "key": "source_type",
              "value": "2"
            }],
            "title": "转账",
            "type": "button"
          }, {
            "field": [{
              "key": "source_type",
              "value": "3"
            }],
            "title": "后台补扣款",
            "total": 0,
            "type": "button"
          }, {
            "field": [{
              "key": "source_type",
              "value": "4"
            }],
            "title": "奖金发放",
            "total": 0,
            "type": "button"
          }, {
            "field": [{
              "key": "source_type",
              "value": "5"
            }],
            "title": "撤单返款",
            "total": 0,
            "type": "button"
          }, {
            "field": [{
              "key": "source_type",
              "value": "6"
            }],
            "title": "提现",
            "total": 0,
            "type": "button"
          }, {
            "field": [{
              "key": "source_type",
              "value": "7"
            }],
            "title": "奖金撤销",
            "total": 0,
            "type": "button"
          }, {
            "field": [{
              "key": "source_type",
              "value": "8"
            }],
            "title": "培训费",
            "total": 0,
            "type": "button"
          }, {
            "field": [{
              "key": "source_type",
              "value": "9"
            }],
            "title": "复销积分",
            "total": 0,
            "type": "button"
          }, {
            "field": [{
              "key": "source_type",
              "value": "10"
            }],
            "title": "汇款审核",
            "total": 0,
            "type": "button"
          }, {
            "field": [{
              "key": "source_type",
              "value": "12"
            }],
            "title": "云仓交易",
            "total": 0,
            "type": "button"
          }, {
            "field": [{
              "key": "source_type",
              "value": "13"
            }],
            "title": "三方商城",
            "total": 0,
            "type": "button"
          }, {
            "field": [{
              "key": "source_type",
              "value": "14"
            }],
            "title": "店仓交易 ",
            "total": 0,
            "type": "button"
          }],
          "id": 2,
          "relation": false,
          "relationId": "",
          "remote": false,
          "remoteType": 0,
          "select": 0,
          "title": "来源",
          "type": 0,
          "remoteFunc": []
        }, {
          "children": [{
            "field": [],
            "title": "全部",
            "type": "button"
          }, {
            "field": [{
              "key": "direction",
              "value": "1"
            }],
            "title": "出账",
            "type": "button"
          }, {
            "field": [{
              "key": "direction",
              "value": "0"
            }],
            "title": "入账",
            "type": "button"
          }],
          "id": 1,
          "relation": false,
          "relationId": "",
          "remote": false,
          "remoteType": 0,
          "select": 0,
          "title": "出入账",
          "type": 0,
          "remoteFunc": []
        }, {
          "children": [{
            "field": [],
            "title": "全部",
            "type": "button"
          }, {
            "field": [],
            "title": "今天",
            "type": "button"
          }, {
            "field": [],
            "title": "昨天",
            "type": "button"
          }, {
            "field": [],
            "title": "最近7天",
            "type": "button"
          }, {
            "field": [],
            "title": "本月",
            "type": "button"
          }, {
            "field": [],
            "title": "本年",
            "type": "button"
          }, {
            "field": [],
            "title": "自定义",
            "type": "custom-datetime-range"
          }],
          "id": 0,
          "relation": false,
          "relationId": "",
          "remote": true,
          "remoteType": 1,
          "select": 0,
          "title": "发生日期",
          "type": 1,
          "remoteFunc": ["modelConditionFunc_0"]
        }, {
          "children": [{
            "field": [{
              "desc": "操作账户编号",
              "key": "number",
              "value": ""
            }, {
              "desc": "变动账户编号",
              "key": "happen_number",
              "value": ""
            }, {
              "desc": "顶点编号",
              "key": "top_number",
              "value": ""
            }],
            "operator": [],
            "placeholder": "请输入",
            "type": "input-complex"
          }],
          "id": 4,
          "relation": false,
          "relationId": "",
          "remote": false,
          "remoteType": 0,
          "select": 0,
          "title": "按条件",
          "type": 2,
          "remoteFunc": []
        }, {
          "children": [{
            "field": [],
            "title": "全部",
            "type": "button"
          }, {
            "field": [{
              "key": "money_type",
              "value": "1"
            }],
            "title": "消费账户",
            "type": "button"
          }, {
            "field": [{
              "key": "money_type",
              "value": "2"
            }],
            "title": "奖金账户",
            "type": "button"
          }, {
            "field": [{
              "key": "money_type",
              "value": "4"
            }],
            "title": "复销账户",
            "total": 0,
            "type": "button"
          }, {
            "field": [{
              "key": "money_type",
              "value": "5"
            }],
            "title": "云仓账户",
            "total": 0,
            "type": "button"
          }],
          "id": 5,
          "relation": false,
          "relationId": "",
          "remote": false,
          "remoteType": 0,
          "select": 0,
          "title": "账户类型",
          "type": 0,
          "remoteFunc": []
        }],
        "type": "searchTemplate"
      },
      tableModel_1001: {
        "border": true,
        "del": false,
        "headerButton": [],
        "id": "id_1001",
        "isGroup": false,
        "isTree": false,
        "rowButton": [],
        "showPagination": true,
        "showSelection": false,
        "stripe": false,
        "tableAutocompleteProps": {},
        "tableCascaderProps": {},
        "tableGroupProps": [],
        "tableModel": [{
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "ID",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": 80,
          "prop": "id"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "期数",
          "rules": [],
          "show": 0,
          "type": "text",
          "width": "80",
          "prop": "expect_num"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "出入账",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "select",
          "width": "100",
          "prop": "direction"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "变动账户",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "120",
          "prop": "happen_number"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "操作账户",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "100",
          "prop": "number"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "交易前余额",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "100",
          "prop": "before_money"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "金额",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "100",
          "prop": "happen_money"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "当前余额",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "100",
          "prop": "balance_money"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "账户类型",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "select",
          "width": "120",
          "prop": "money_type"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "来源",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "select",
          "width": "120",
          "prop": "source_type"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "备注",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "200",
          "prop": "remark"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "时间",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "150",
          "prop": "add_time"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "是否后台操作",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "select",
          "width": "100",
          "prop": "is_op"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "ip",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "120",
          "prop": "ip"
        }],
        "tableName": "t_money_flowing",
        "tableRadioProps": {},
        "tableSelectProps": {
          "direction": {
            "data": [{
              "key": 0,
              "value": "入账"
            }, {
              "key": 1,
              "value": "出账"
            }],
            "id": 1,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": false
          },
          "money_type": {
            "data": [{
              "key": 1,
              "value": "消费账户"
            }, {
              "key": 2,
              "value": "奖金账户"
            }, {
              "key": 4,
              "value": "复消账户"
            }, {
              "key": 5,
              "value": "云仓账户"
            }, {
              "key": 6,
              "value": "店体账户"
            }],
            "id": 2,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": false
          },
          "source_type": {
            "data": [{
              "key": 1,
              "value": "订单"
            }, {
              "key": 2,
              "value": "转账"
            }, {
              "key": 3,
              "value": "后台补扣款"
            }, {
              "key": 4,
              "value": "奖金发放"
            }, {
              "key": 5,
              "value": "撤单返款"
            }, {
              "key": 6,
              "value": "提现"
            }, {
              "key": 7,
              "value": "奖金撤销"
            }, {
              "key": 8,
              "value": "培训费"
            }, {
              "key": 9,
              "value": "复销积分"
            }, {
              "key": 10,
              "value": "汇款审核"
            }, {
              "key": 12,
              "value": "云仓交易"
            }, {
              "key": 13,
              "value": "三方商城"
            }, {
              "key": 14,
              "value": "店仓交易"
            }],
            "id": 3,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": false
          },
          "is_op": {
            "data": [{
              "key": 0,
              "value": "否"
            }, {
              "key": 1,
              "value": "是"
            }],
            "id": 4,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": false
          }
        },
        "tableSwitchProps": {},
        "title": "账号流水",
        "type": "tableTemplate"
      },
      remoteFunc: {
        modelConditionFunc_0: function modelConditionFunc_0(tag, relationData, resolve) {
          //筛选条件数据
          //[[{key:"type",value:"1"}]]
          var data = [];
          data = [[{
            key: "add_time",
            value: []
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 0, true)
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 1, true)
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 7, true)
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 30, true)
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 365, true)
          }], [{
            key: "add_time",
            value: []
          }]];
          resolve(tag, data);
        }
      }
    };
  },
  mounted: function mounted() {
    this.table = this.$refs.ref_1001;
    this.search = this.$refs.ref_1000;
  },
  methods: {
    reqTableData: function reqTableData(params, resolve) {
      var where = {};

      if (this.search) {
        where = this.search.parseWhere();
        params.where = where;
      }

      tradeFlow(params).then(function _callee(res) {
        return regeneratorRuntime.async(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                resolve({
                  total: res.count,
                  data: res.rows
                });

              case 1:
              case "end":
                return _context.stop();
            }
          }
        });
      });
    },
    reqAdd: function reqAdd(isEdit, params, row, resolve) {//if (isEdit){
      //  params.id = row.id
      //}
      //xxxAdd(params).then(async res=>{
      //  resolve()
      //})
    },
    reqDel: function reqDel(params, resolve) {
      var ids = params.map(function (item) {
        return item.id;
      }); //xxxDel({id:ids}).then(async res=>{
      //  resolve()
      //})
    },
    onClickLink: function onClickLink(prop, row) {//点击列表link类型回调
    },
    onClickRowButton: function onClickRowButton(btnIndex, row) {//点击列表按钮回调
    },
    onClickHeaderButton: function onClickHeaderButton(btnIndex, row) {//点击列表头部按钮回调
    },
    onSummary: function onSummary(param) {//列表尾部合计回调
    },
    onSwitchChange: function onSwitchChange(val, prop, row) {//列表中switch变化事件
    },
    onClickSearchOperate: function onClickSearchOperate(btnIndex, btnData) {
      //条件菜单按钮点击事件
      if (btnData.tag === this.$tempModel.buttonTag.search) {
        this.table.reloadData();
      } else if (btnData.tag === this.$tempModel.buttonTag.export) {
        this.table.exportExcel();
      } else if (btnData.tag === this.$tempModel.buttonTag.import) {
        var header = btnData.header;
        var results = btnData.results;
      }
    },
    onConditionChange: function onConditionChange(tag, index) {
      this.table.reloadData();
    }
  }
};