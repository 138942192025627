var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-card",
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { model: _vm.form, size: "mini", "label-position": "top" }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "计算类型" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.bonusMode,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "bonusMode", $$v)
                        },
                        expression: "form.bonusMode"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("比率")]),
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("金额")])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "是否紧缩(紧缩后会跳过等级低于代理级别的会员)"
                  }
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.isTighten,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "isTighten", $$v)
                        },
                        expression: "form.isTighten"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("否")]),
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "对应极差奖（必填）" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { size: "mini" },
                      model: {
                        value: _vm.form.bonusTag,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "bonusTag", $$v)
                        },
                        expression: "form.bonusTag"
                      }
                    },
                    _vm._l(_vm.bonusData, function(item) {
                      return _c("el-option", {
                        key: item.tag,
                        attrs: { label: item.name, value: item.tag }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "因素设置(请选择可获得奖金的会员等级)" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { size: "mini", multiple: "" },
                      model: {
                        value: _vm.form.selectGrade,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "selectGrade", $$v)
                        },
                        expression: "form.selectGrade"
                      }
                    },
                    _vm._l(_vm.gradeData, function(item) {
                      return _c("el-option", {
                        key: item.tag,
                        attrs: { label: item.name, value: item.tag }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-card",
        { staticStyle: { "margin-top": "10px" } },
        [
          _c(
            "el-row",
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "danger" },
                  on: { click: _vm.createFactor }
                },
                [_vm._v("生成奖励配置")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "success" },
                  on: { click: _vm.clearFactorData }
                },
                [_vm._v("重置")]
              )
            ],
            1
          ),
          _vm._l(_vm.form.factorList, function(item, index) {
            return _c(
              "el-card",
              {
                staticStyle: { "margin-top": "10px" },
                attrs: { shadow: "never" }
              },
              [
                _c(
                  "div",
                  { attrs: { slot: "header" }, slot: "header" },
                  [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-size": "13px",
                          "margin-right": "10px"
                        }
                      },
                      [_vm._v(_vm._s(item.gradeName))]
                    ),
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "300px" },
                        attrs: {
                          size: "mini",
                          multiple: "",
                          placeholder: "请选择出奖金级别，必须大于获奖者等级"
                        },
                        model: {
                          value: item.giveGrade,
                          callback: function($$v) {
                            _vm.$set(item, "giveGrade", $$v)
                          },
                          expression: "item.giveGrade"
                        }
                      },
                      _vm._l(_vm.giveGrade, function(item) {
                        return _c("el-option", {
                          key: item.tag,
                          attrs: { label: item.name, value: item.tag }
                        })
                      }),
                      1
                    ),
                    _c(
                      "el-input",
                      {
                        staticStyle: { width: "230px", "margin-left": "10px" },
                        attrs: { size: "mini", placeholder: "不填不限制" },
                        model: {
                          value: item.giveMaxMoney,
                          callback: function($$v) {
                            _vm.$set(item, "giveMaxMoney", $$v)
                          },
                          expression: "item.giveMaxMoney"
                        }
                      },
                      [
                        _c("template", { slot: "prepend" }, [
                          _vm._v("扣除最大金额")
                        ])
                      ],
                      2
                    ),
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "180px", "margin-left": "10px" },
                        attrs: {
                          size: "mini",
                          multiple: "",
                          placeholder: "请选择特殊报单人等级"
                        },
                        model: {
                          value: item.specialGrade,
                          callback: function($$v) {
                            _vm.$set(item, "specialGrade", $$v)
                          },
                          expression: "item.specialGrade"
                        }
                      },
                      _vm._l(_vm.giveGrade, function(item) {
                        return _c("el-option", {
                          key: item.tag,
                          attrs: { label: item.name, value: item.tag }
                        })
                      }),
                      1
                    ),
                    _c(
                      "el-tag",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { type: "danger", size: "mini" }
                      },
                      [_vm._v("特殊报单人无需获得极差奖也会让上级获得平级奖")]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "factorList" },
                  _vm._l(item.rateList, function(factor, subIndex) {
                    return _c(
                      "div",
                      { staticClass: "factorItem" },
                      [
                        _c("span", { staticStyle: { "font-size": "13px" } }, [
                          _vm._v(_vm._s("第" + (subIndex + 1) + "层"))
                        ]),
                        _c("el-input-number", {
                          attrs: { size: "mini", min: 0 },
                          model: {
                            value: factor.rate,
                            callback: function($$v) {
                              _vm.$set(factor, "rate", $$v)
                            },
                            expression: "factor.rate"
                          }
                        }),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: {
                              click: function($event) {
                                return _vm.clickDelLayer(index, subIndex)
                              }
                            }
                          },
                          [_vm._v("删除")]
                        )
                      ],
                      1
                    )
                  }),
                  0
                ),
                _c(
                  "el-button",
                  {
                    attrs: { size: "mini", type: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.clickAddLayer(index)
                      }
                    }
                  },
                  [_vm._v("添加层")]
                )
              ],
              1
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }