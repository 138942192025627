import "core-js/modules/es.array.map";
import "core-js/modules/es.function.name";
import "core-js/modules/es.number.to-fixed";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.parse-float";
import "core-js/modules/es.parse-int";
import "core-js/modules/es.string.search";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tempTable from "@/components/rw/tempTable/index";
import tempSearch from "@/components/rw/tempSearch/index";
import tempForm from "@/components/rw/tempForm/index";
import tempChart from "@/components/rw/tempChart/index";
import { configBonusList, configGradeList, configLevelList, memberBonusSource } from '../../../../api/apis';
export default {
  name: 'memberBonusLog',
  components: {
    tempTable: tempTable,
    tempSearch: tempSearch,
    tempForm: tempForm,
    tempChart: tempChart
  },
  data: function data() {
    var _this = this;

    return {
      searchModel_1000: {
        "del": false,
        "id": "id_1000",
        "isCollapse": false,
        "operateData": [{
          "icon": "el-icon-search",
          "tag": "search",
          "title": "搜索",
          "type": "primary"
        }, {
          "icon": "el-icon-position",
          "tag": "export",
          "title": "导出",
          "type": "primary"
        }],
        "searchData": [{
          "children": [{
            "field": [{
              "desc": "奖励会员编号",
              "key": "to_number",
              "value": ""
            }],
            "operator": ["gte", "lte", "gt", "lt", "eq", "ne", "like", "notLike", "in", "between"],
            "placeholder": "请输入",
            "type": "input-complex"
          }, {
            "field": [{
              "desc": "来源会员编号",
              "key": "from_number"
            }],
            "operator": [],
            "placeholder": "请输入",
            "type": "input-complex"
          }, {
            "field": [{
              "desc": "按订单",
              "key": "order_id"
            }],
            "operator": [],
            "placeholder": "请输入",
            "type": "input-complex"
          }, {
            "field": [{
              "desc": "按期数",
              "key": "expect_num"
            }],
            "operator": [],
            "placeholder": "请输入",
            "type": "input-complex"
          }],
          "id": 0,
          "relation": false,
          "relationId": "",
          "remote": false,
          "remoteType": 0,
          "select": 0,
          "title": "按条件",
          "type": 2,
          "remoteFunc": []
        }, {
          "children": [{
            "field": [],
            "title": "全部",
            "type": "button"
          }],
          "id": 3,
          "relation": false,
          "relationId": "",
          "remote": true,
          "remoteType": 0,
          "select": 0,
          "title": "按奖金类型",
          "type": 0,
          "remoteFunc": ["modelFunc_3"]
        }, {
          "children": [{
            "field": [],
            "title": "全部",
            "type": "button"
          }, {
            "field": [],
            "title": "今天",
            "type": "button"
          }, {
            "field": [],
            "title": "昨天",
            "type": "button"
          }, {
            "field": [],
            "title": "最近7天",
            "type": "button"
          }, {
            "field": [],
            "title": "本月",
            "type": "button"
          }, {
            "field": [],
            "title": "本年",
            "type": "button"
          }, {
            "field": [],
            "title": "自定义",
            "type": "custom-datetime-range"
          }],
          "id": 4,
          "relation": false,
          "relationId": "",
          "remote": true,
          "remoteType": 1,
          "select": 0,
          "title": "按发放日期",
          "type": 1,
          "remoteFunc": ["modelConditionFunc_4"]
        }, {
          "title": "是否发放",
          "select": 0,
          "type": 0,
          "id": 5,
          "relation": false,
          "relationId": "",
          "remote": false,
          "remoteType": 0,
          "children": [{
            "type": "button",
            "title": "全部",
            "field": []
          }, {
            "type": "button",
            "title": "是",
            "field": [{
              "key": "status",
              "value": "1"
            }]
          }, {
            "type": "button",
            "title": "否",
            "field": [{
              "key": "status",
              "value": "0"
            }]
          }],
          "remoteFunc": []
        }],
        "type": "searchTemplate"
      },
      tableModel_1001: {
        "border": true,
        "del": false,
        "headerButton": [],
        "id": "id_1001",
        "isGroup": false,
        "isTree": false,
        "rowButton": [],
        "showPagination": true,
        "showSelection": false,
        "showSummary": true,
        "stripe": false,
        "tableAutocompleteProps": {},
        "tableCascaderProps": {},
        "tableFileProps": {},
        "tableModel": [{
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "订单ID",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": 160,
          "prop": "order_id"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "期数",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "expect_num"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "类型",
          "rules": [],
          "show": 1,
          "type": "select",
          "width": "80",
          "prop": "bonus_tag"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "来源会员",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "90",
          "prop": "from_number"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "奖励会员",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "90",
          "prop": "to_number"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "来源姓名",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "from_number_ex.real_name"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "奖励姓名",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "to_number_ex.real_name"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "来源等级",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "select",
          "width": "100",
          "prop": "from_grade"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "奖励等级",
          "rules": [],
          "show": 1,
          "type": "select",
          "width": "100",
          "prop": "to_grade"
        }, {
          "add": 0,
          "desc": "",
          "detail": 0,
          "excel": 0,
          "groupId": "",
          "label": "奖励层级",
          "rules": [],
          "show": 0,
          "type": "text",
          "width": "80",
          "prop": "to_layer"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "来源资格",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "select",
          "width": 80,
          "prop": "from_level"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "奖励资格",
          "rules": [],
          "show": 1,
          "type": "select",
          "width": "80",
          "prop": "to_level"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "奖金额",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "bonus"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "订单PV",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "order_pv"
        }, {
          "add": 0,
          "detail": 0,
          "excel": 0,
          "groupId": "",
          "label": "费率",
          "rowAddField": "",
          "rules": [],
          "show": 0,
          "type": "text",
          "width": 80,
          "prop": "bonus_rate"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "计算方式",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "150",
          "prop": "compute_mode"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "备注",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": 80,
          "prop": "remark"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "发生时间",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "150",
          "prop": "add_time"
        }, {
          "add": 0,
          "desc": "",
          "detail": 0,
          "groupId": "",
          "label": "管理税",
          "rules": [],
          "show": 0,
          "type": "text",
          "width": "80",
          "prop": "tax_manage"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "扣复销",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "tax_fx"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "发放奖金",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "real_bonus"
        }, {
          "label": "是否发放",
          "show": 1,
          "add": 1,
          "detail": 1,
          "width": "80",
          "type": "select",
          "desc": "",
          "groupId": "",
          "rules": [],
          "excel": 1,
          "prop": "status"
        }],
        "tableName": "t_bonus_source",
        "tableRadioProps": {},
        "tableSelectProps": {
          "bonus_tag": {
            "data": [{
              "key": null,
              "value": ""
            }],
            "id": 5,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": true,
            "remoteFunc": "tableFunc_select_bonus_tag"
          },
          "from_grade": {
            "data": [{
              "key": null,
              "value": ""
            }],
            "id": 6,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": true,
            "remoteFunc": "tableFunc_select_from_grade"
          },
          "to_grade": {
            "data": [{
              "key": null,
              "value": ""
            }],
            "id": 7,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": true,
            "remoteFunc": "tableFunc_select_to_grade"
          },
          "from_level": {
            "data": [{
              "key": null,
              "value": ""
            }],
            "id": 8,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": true,
            "remoteFunc": "tableFunc_select_from_level"
          },
          "to_level": {
            "data": [{
              "key": null,
              "value": ""
            }],
            "id": 9,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": true,
            "remoteFunc": "tableFunc_select_to_level"
          },
          "status": {
            "multiple": 0,
            "data": [{
              "key": 0,
              "value": "否"
            }, {
              "key": 1,
              "value": "是"
            }],
            "remote": false,
            "id": 10,
            "relation": false,
            "relationProps": []
          }
        },
        "tableSwitchProps": {},
        "title": "",
        "type": "tableTemplate"
      },
      remoteFunc: {
        modelConditionFunc_4: function modelConditionFunc_4(tag, relationData, resolve) {
          //筛选条件数据
          //[[{key:"type",value:"1"}]]
          var data = [];
          data = [[{
            key: "add_time",
            value: []
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 0, true)
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 1, true)
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 7, true)
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 30, true)
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 365, true)
          }], [{
            key: "add_time",
            value: []
          }]];
          resolve(tag, data);
        },
        modelFunc_3: function modelFunc_3(tag, relationData, resolve) {
          //筛选项模型数组
          configBonusList({}).then(function _callee(res) {
            var data, i, item;
            return regeneratorRuntime.async(function _callee$(_context) {
              while (1) {
                switch (_context.prev = _context.next) {
                  case 0:
                    data = [{
                      type: _this.$tempModel.searchType.button,
                      title: "全部",
                      field: [{
                        key: "",
                        value: ""
                      }]
                    }];

                    for (i in res.rows) {
                      item = res.rows[i];
                      data.push({
                        type: _this.$tempModel.searchType.button,
                        title: item.name,
                        field: [{
                          key: 'bonus_tag',
                          value: item.tag
                        }]
                      });
                    }

                    resolve(tag, data);

                  case 3:
                  case "end":
                    return _context.stop();
                }
              }
            });
          });
        },
        tableFunc_select_bonus_tag: function tableFunc_select_bonus_tag(tag, relationData, resolve) {
          //筛选项模型数组
          configBonusList({}).then(function _callee2(res) {
            var data, i, item;
            return regeneratorRuntime.async(function _callee2$(_context2) {
              while (1) {
                switch (_context2.prev = _context2.next) {
                  case 0:
                    data = [];

                    for (i in res.rows) {
                      item = res.rows[i];
                      data.push({
                        key: item.tag,
                        value: item.name
                      });
                    }

                    resolve(tag, data);

                  case 3:
                  case "end":
                    return _context2.stop();
                }
              }
            });
          });
        },
        tableFunc_select_from_grade: function tableFunc_select_from_grade(tag, relationData, resolve) {
          //筛选项模型数组
          configGradeList({}).then(function _callee3(res) {
            var data, i, item;
            return regeneratorRuntime.async(function _callee3$(_context3) {
              while (1) {
                switch (_context3.prev = _context3.next) {
                  case 0:
                    data = [];

                    for (i in res.rows) {
                      item = res.rows[i];
                      data.push({
                        key: item.tag,
                        value: item.name
                      });
                    }

                    resolve(tag, data);

                  case 3:
                  case "end":
                    return _context3.stop();
                }
              }
            });
          });
        },
        tableFunc_select_to_grade: function tableFunc_select_to_grade(tag, relationData, resolve) {
          //筛选项模型数组
          configGradeList({}).then(function _callee4(res) {
            var data, i, item;
            return regeneratorRuntime.async(function _callee4$(_context4) {
              while (1) {
                switch (_context4.prev = _context4.next) {
                  case 0:
                    data = [];

                    for (i in res.rows) {
                      item = res.rows[i];
                      data.push({
                        key: item.tag,
                        value: item.name
                      });
                    }

                    resolve(tag, data);

                  case 3:
                  case "end":
                    return _context4.stop();
                }
              }
            });
          });
        },
        tableFunc_select_from_level: function tableFunc_select_from_level(tag, relationData, resolve) {
          //筛选项模型数组
          configLevelList({}).then(function _callee5(res) {
            var data, i, item;
            return regeneratorRuntime.async(function _callee5$(_context5) {
              while (1) {
                switch (_context5.prev = _context5.next) {
                  case 0:
                    data = [];

                    for (i in res.rows) {
                      item = res.rows[i];
                      data.push({
                        key: item.tag,
                        value: item.name
                      });
                    }

                    resolve(tag, data);

                  case 3:
                  case "end":
                    return _context5.stop();
                }
              }
            });
          });
        },
        tableFunc_select_to_level: function tableFunc_select_to_level(tag, relationData, resolve) {
          //筛选项模型数组
          configLevelList({}).then(function _callee6(res) {
            var data, i, item;
            return regeneratorRuntime.async(function _callee6$(_context6) {
              while (1) {
                switch (_context6.prev = _context6.next) {
                  case 0:
                    data = [];

                    for (i in res.rows) {
                      item = res.rows[i];
                      data.push({
                        key: item.tag,
                        value: item.name
                      });
                    }

                    resolve(tag, data);

                  case 3:
                  case "end":
                    return _context6.stop();
                }
              }
            });
          });
        }
      }
    };
  },
  mounted: function mounted() {
    this.table = this.$refs.ref_1001;
    this.search = this.$refs.ref_1000;
  },
  methods: {
    reqTableData: function reqTableData(params, resolve) {
      var where = {};

      if (this.search) {
        where = this.search.parseWhere();
        params.where = where;
      }

      if (params.where.hasOwnProperty('expect_num')) {
        params.where.expect_num = parseInt(params.where.expect_num);
      }

      memberBonusSource(params).then(function _callee7(res) {
        var i;
        return regeneratorRuntime.async(function _callee7$(_context7) {
          while (1) {
            switch (_context7.prev = _context7.next) {
              case 0:
                for (i in res.rows) {
                  res.rows[i].real_bonus = parseFloat(res.rows[i].bonus) - parseFloat(res.rows[i].tax_manage) - parseFloat(res.rows[i].tax_fx);
                  res.rows[i].real_bonus = res.rows[i].real_bonus.toFixed(2);
                }

                resolve({
                  total: res.count,
                  data: res.rows
                });

              case 2:
              case "end":
                return _context7.stop();
            }
          }
        });
      });
    },
    reqAdd: function reqAdd(isEdit, params, row, resolve) {//if (isEdit){
      //  params.id = row.id
      //}
      //xxxAdd(params).then(async res=>{
      //  resolve()
      //})
    },
    reqDel: function reqDel(params, resolve) {
      var ids = params.map(function (item) {
        return item.id;
      }); //xxxDel({id:ids}).then(async res=>{
      //  resolve()
      //})e
    },
    onClickLink: function onClickLink(prop, row) {//点击列表link类型回调
    },
    onClickRowButton: function onClickRowButton(btnIndex, row) {//点击列表按钮回调
    },
    onClickHeaderButton: function onClickHeaderButton(btnIndex, row) {//点击列表头部按钮回调
    },
    onSummary: function onSummary(param) {//列表尾部合计回调
    },
    onSwitchChange: function onSwitchChange(val, prop, row) {//列表中switch变化事件
    },
    onClickSearchOperate: function onClickSearchOperate(btnIndex, btnData) {
      //条件菜单按钮点击事件
      if (btnData.tag === this.$tempModel.buttonTag.search) {
        this.table.reloadData();
      } else if (btnData.tag === this.$tempModel.buttonTag.export) {
        this.table.exportExcel();
      } else if (btnData.tag === this.$tempModel.buttonTag.import) {
        var header = btnData.header;
        var results = btnData.results;
      }
    },
    onConditionChange: function onConditionChange(tag, index) {
      this.table.reloadData();
    },
    onRowButtonShow: function onRowButtonShow(btnIndex, row) {
      return true;
    }
  }
};