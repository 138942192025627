//
//
//
export default {
  props: {
    model: {
      type: Object,
      required: true
    },
    prop: {
      type: String,
      required: true
    },
    radioProps: {
      type: Object,
      required: true
    }
  },
  methods: {
    selectValue: function selectValue(prop, value) {
      if (!this.radioProps.hasOwnProperty(prop)) {
        return '';
      }

      for (var i in this.radioProps[prop].data) {
        if (this.radioProps[prop].data[i].key == value) {
          return this.radioProps[prop].data[i].value;
        }
      }

      return '';
    }
  }
};