import "core-js/modules/es.object.to-string";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { trainConfig } from '@api/apis';
export default {
  data: function data() {
    return {
      dialogVisible: false,
      active_id: '',
      activeList: []
    };
  },
  mounted: function mounted() {
    var _this = this;

    trainConfig({
      page: 1,
      limit: 1000000
    }).then(function _callee(res) {
      return regeneratorRuntime.async(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _this.activeList = res.rows;

            case 1:
            case "end":
              return _context.stop();
          }
        }
      });
    });
  },
  methods: {
    show: function show(finish) {
      this.dialogVisible = true;
      this.finish = finish;
      this.labels = [];
    },
    clickSubmit: function clickSubmit() {
      if (this.active_id == '') {
        this.$alert('请选择一个活动', '提示', {
          confirmButtonText: '确定'
        });
        return;
      }

      this.dialogVisible = false;

      if (this.finish) {
        this.finish(this.active_id);
      }
    }
  }
};