import "core-js/modules/es.array.splice";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    conditions: {
      type: Object
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      data: [],
      agentData: [{
        tag: 1,
        name: '区代'
      }, {
        tag: 2,
        name: '市代'
      }, {
        tag: 3,
        name: '省代'
      }],
      form: {
        orderTypes: [1, 2, 3],
        order: 0,
        bonus: 0
      }
    };
  },
  computed: {},
  mounted: function mounted() {
    if (this.conditions) {
      this.data = this.conditions.data;

      for (var key in this.form) {
        this.form[key] = this.conditions[key];
      }
    }
  },
  methods: {
    clickDelete: function clickDelete(index, row) {
      var tmp = this.$tempModel.deepCopy(this.data);
      tmp.splice(index, 1);
      this.data = tmp;
    },
    clickAddCond: function clickAddCond() {
      this.data.push({
        grade: '',
        rate: 0
      });
    },
    clickAddAllCond: function clickAddAllCond() {
      for (var i = 0; i < this.agentData.length; i++) {
        var item = this.agentData[i];
        this.data.push({
          grade: item.tag,
          rate: 0
        });
      }
    },
    getParams: function getParams() {
      var params = this.form;
      params.data = this.data;
      return this.$tempModel.deepCopy(params);
    }
  }
};