import "core-js/modules/es.symbol";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.index-of";
import "core-js/modules/es.array.sort";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.get-own-property-descriptor";
import "core-js/modules/es.object.get-own-property-descriptors";
import "core-js/modules/es.object.keys";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.parse-int";
import "core-js/modules/web.dom-collections.for-each";
import _defineProperty from "/Users/suifeng/Desktop/projects/2022/sdSdm/sdSdmAdmin/node_modules/@babel/runtime/helpers/esm/defineProperty";
import "regenerator-runtime/runtime";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { goodsAttrList, goodsAttrValueList, goodsSpecsList, goodsSpecsHandle } from '@/api/apis';
export default {
  data: function data() {
    return {
      dialogVisible: false,
      selectAttr: [],
      attrList: [],
      selectAttrData: [],
      attrValueDic: {},
      attrDic: {},
      valueDic: {},
      kv: {},
      tableData: []
    };
  },
  mounted: function mounted() {
    var _this = this;

    goodsAttrList({
      page: 1,
      limit: 10000
    }).then(function _callee(res) {
      var i, item;
      return regeneratorRuntime.async(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _this.attrList = res.rows;

              for (i in res.rows) {
                item = res.rows[i];
                _this.attrDic[item.id] = item;
              }

            case 2:
            case "end":
              return _context.stop();
          }
        }
      });
    });
    goodsAttrValueList({
      page: 1,
      limit: 10000
    }).then(function _callee2(res) {
      var i, item;
      return regeneratorRuntime.async(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              for (i in res.rows) {
                item = res.rows[i];
                _this.valueDic[item.id] = item;

                if (!_this.attrValueDic.hasOwnProperty(item.attribute_id)) {
                  _this.attrValueDic[item.attribute_id] = [];
                }

                _this.attrValueDic[item.attribute_id].push(item);
              }

            case 1:
            case "end":
              return _context2.stop();
          }
        }
      });
    });
  },
  methods: {
    show: function show(row, finish) {
      var _this2 = this;

      this.dialogVisible = true;
      this.finish = finish;
      this.goodsId = row.id; // selectAttr:[],
      // selectAttrData:[],
      // kv:{},
      // tableData:[]

      var dic = {};
      goodsSpecsList({
        goods_id: row.id
      }).then(function _callee3(res) {
        var i, item, specs, key, tmp, kv, _key, attrId, value, _i, attributes, _i2, _item, ary, j, attrValue, skus, _i3;

        return regeneratorRuntime.async(function _callee3$(_context3) {
          while (1) {
            switch (_context3.prev = _context3.next) {
              case 0:
                for (i in res) {
                  item = res[i];
                  specs = JSON.parse(item.goods_specs);

                  for (key in specs) {
                    if (!dic.hasOwnProperty(key)) {
                      dic[key] = [];
                    }

                    if (dic[key].indexOf(specs[key]) < 0) {
                      dic[key].push(specs[key]);
                    }
                  }
                }

                tmp = [];
                kv = {};

                for (_key in dic) {
                  attrId = _this2.findAttrId(_key);

                  _this2.selectAttr.push(parseInt(attrId));

                  value = [];

                  for (_i in dic[_key]) {
                    value.push(_this2.findAttrValueId(dic[_key][_i]));
                  }

                  kv[attrId] = value;
                  tmp.push({
                    attrId: attrId,
                    name: _key,
                    value: value,
                    data: _this2.attrValueDic[attrId]
                  });
                }

                _this2.selectAttrData = tmp;
                _this2.kv = kv;
                attributes = {};

                for (_i2 in _this2.selectAttrData) {
                  _item = _this2.selectAttrData[_i2];
                  ary = [];

                  for (j in _item.value) {
                    attrValue = _this2.valueDic[_item.value[j]];
                    ary.push(attrValue.attribute_value);
                  }

                  attributes[_item.name] = ary;
                }

                skus = _this2.generateSKUs(attributes);

                for (_i3 in skus) {
                  skus[_i3]['price'] = res[_i3]['goods_price'];
                  skus[_i3]['pv'] = res[_i3]['goods_price'];
                  skus[_i3]['stock'] = res[_i3]['goods_stock'];
                }

                _this2.tableData = skus;
                console.log("this.tableData", _this2.tableData);

              case 12:
              case "end":
                return _context3.stop();
            }
          }
        });
      });
    },
    findAttrId: function findAttrId(name) {
      for (var i in this.attrList) {
        if (this.attrList[i].attribute_name === name) {
          return parseInt(this.attrList[i].id);
        }
      }

      return null;
    },
    findAttrValueId: function findAttrValueId(name) {
      for (var key in this.valueDic) {
        if (this.valueDic[key].attribute_value === name) {
          return parseInt(this.valueDic[key].id);
        }
      }

      return null;
    },
    attrChange: function attrChange(options) {
      options.sort(function (a, b) {
        return b - a;
      });
      var tmp = [];

      for (var i in options) {
        var attrId = options[i];
        var attr = this.attrDic[attrId];
        var value = [];

        if (this.kv.hasOwnProperty(attrId)) {
          value = this.kv[attrId];
        }

        tmp.push({
          attrId: attrId,
          name: attr.attribute_name,
          value: value,
          data: this.attrValueDic[attr.id]
        });
      }

      this.selectAttrData = tmp;
    },
    attrValueChange: function attrValueChange(attrId, value) {
      value.sort(function (a, b) {
        return b - a;
      });
      this.kv[attrId] = value; // const attributes = {
      //   color: ['红色', '蓝色'],
      //   size: ['XL', 'L'],
      //   style: ['简约', '时尚']
      // };

      var attributes = {};

      for (var i in this.selectAttrData) {
        var item = this.selectAttrData[i];
        var ary = [];

        for (var j in item.value) {
          var attrValue = this.valueDic[item.value[j]];
          ary.push(attrValue.attribute_value);
        }

        attributes[item.name] = ary;
      }

      var skus = this.generateSKUs(attributes);

      for (var _i4 in skus) {
        skus[_i4]['price'] = 0;
        skus[_i4]['pv'] = 0;
        skus[_i4]['stock'] = 999999;
      }

      this.tableData = skus;
    },
    generateSKUs: function generateSKUs(attributes) {
      var skus = [];
      var attributeKeys = Object.keys(attributes); // 递归生成SKU组合

      function generateCombinations(index, combination) {
        if (index === attributeKeys.length) {
          skus.push(combination);
          return;
        }

        var currentAttribute = attributeKeys[index];
        var options = attributes[currentAttribute];

        for (var i = 0; i < options.length; i++) {
          var newCombination = _objectSpread({}, combination, _defineProperty({}, currentAttribute, options[i]));

          generateCombinations(index + 1, newCombination);
        }
      }

      generateCombinations(0, {});
      return skus;
    },
    clickSubmit: function clickSubmit() {
      var _this3 = this;

      var params = {};
      params.goods_id = this.goodsId;
      var data = [];
      var table = this.$tempModel.deepCopy(this.tableData);

      for (var i in table) {
        var dic = {};
        var item = table[i];
        delete item.price;
        delete item.pv;
        delete item.stock;
        var specs = {};

        for (var key in item) {
          if (['price', 'pv', 'stock'].indexOf(key) >= 0) {
            continue;
          }

          specs[key] = item[key];
        }

        dic.goods_specs = JSON.stringify(specs);
        dic.goods_price = this.tableData[i].price;
        dic.goods_stock = this.tableData[i].stock;
        dic.goods_pv = this.tableData[i].pv;
        data.push(dic);
      }

      params.data = data;
      console.log('params', params);
      goodsSpecsHandle(params).then(function _callee4(res) {
        return regeneratorRuntime.async(function _callee4$(_context4) {
          while (1) {
            switch (_context4.prev = _context4.next) {
              case 0:
                _this3.dialogVisible = false;

                _this3.finish();

                _this3.$alert('保存成功', '提示', {
                  confirmButtonText: '确定'
                });

              case 3:
              case "end":
                return _context4.stop();
            }
          }
        });
      });
    }
  }
};