var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "商品属性",
        visible: _vm.dialogVisible,
        width: "70%",
        "append-to-body": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "show-close": false
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            "max-height": "50vh",
            "overflow-y": "auto",
            padding: "15px 30px",
            "background-color": "#f6f6f6"
          }
        },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { "label-width": "100px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "商品属性" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      on: { change: _vm.attrChange },
                      model: {
                        value: _vm.selectAttr,
                        callback: function($$v) {
                          _vm.selectAttr = $$v
                        },
                        expression: "selectAttr"
                      }
                    },
                    _vm._l(_vm.attrList, function(item) {
                      return _c(
                        "el-checkbox",
                        { attrs: { label: item.id, name: "type" } },
                        [_vm._v(_vm._s(item.attribute_name))]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._l(_vm.selectAttrData, function(item) {
                return _c(
                  "el-form-item",
                  { attrs: { label: item.name } },
                  [
                    _c(
                      "el-checkbox-group",
                      {
                        on: {
                          change: function(value) {
                            return _vm.attrValueChange(item.attrId, value)
                          }
                        },
                        model: {
                          value: item.value,
                          callback: function($$v) {
                            _vm.$set(item, "value", $$v)
                          },
                          expression: "item.value"
                        }
                      },
                      _vm._l(item.data, function(subItem) {
                        return _c(
                          "el-checkbox",
                          { attrs: { label: subItem.id, name: "value" } },
                          [_vm._v(_vm._s(subItem.attribute_value))]
                        )
                      }),
                      1
                    )
                  ],
                  1
                )
              })
            ],
            2
          ),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableData, border: "", size: "mini" }
            },
            [
              _vm._l(_vm.selectAttrData, function(item, index) {
                return _c("el-table-column", {
                  attrs: { label: item.name, prop: item.name }
                })
              }),
              _c("el-table-column", {
                attrs: { label: "价格" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-input", {
                          attrs: { size: "mini" },
                          model: {
                            value: scope.row.price,
                            callback: function($$v) {
                              _vm.$set(scope.row, "price", $$v)
                            },
                            expression: "scope.row.price"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "PV" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-input", {
                          attrs: { size: "mini" },
                          model: {
                            value: scope.row.pv,
                            callback: function($$v) {
                              _vm.$set(scope.row, "pv", $$v)
                            },
                            expression: "scope.row.pv"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "库存" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-input", {
                          attrs: { size: "mini" },
                          model: {
                            value: scope.row.stock,
                            callback: function($$v) {
                              _vm.$set(scope.row, "stock", $$v)
                            },
                            expression: "scope.row.stock"
                          }
                        })
                      ]
                    }
                  }
                ])
              })
            ],
            2
          )
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.dialogVisible = false
                }
              }
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.clickSubmit } },
            [_vm._v("提交")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }