import "core-js/modules/es.array.join";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { areaApplyHandle, areaApplyAllList } from '@api/apis';
export default {
  data: function data() {
    return {
      dialogVisible: false,
      form: {
        user_number: '',
        area: [],
        create_at: '',
        status: 1,
        remark: '手动添加'
      },
      isShowInfo: true,
      props: {
        lazy: true,
        checkStrictly: true,
        filterable: true,
        lazyLoad: function lazyLoad(node, resolve) {
          var level = node.level;
          console.log('node', node);
          areaApplyAllList({
            parent: level === 0 ? 0 : node.data.value
          }).then(function _callee(res) {
            var ary, i, item;
            return regeneratorRuntime.async(function _callee$(_context) {
              while (1) {
                switch (_context.prev = _context.next) {
                  case 0:
                    ary = [];

                    for (i in res) {
                      item = res[i];
                      ary.push({
                        value: item.id,
                        label: item.name,
                        disabled: item.lock == 0 ? false : true,
                        leaf: level >= 2
                      });
                    }

                    resolve(ary);

                  case 3:
                  case "end":
                    return _context.stop();
                }
              }
            });
          });
        }
      }
    };
  },
  mounted: function mounted() {},
  methods: {
    show: function show(finish) {
      this.dialogVisible = true;
      this.finish = finish;
      this.labels = [];
    },
    clickSubmit: function clickSubmit() {
      var _this = this;

      if (this.form.user_number === '') {
        this.$message({
          message: '请输入会员编号',
          type: 'error'
        });
        return;
      }

      this.$g.fun.startLoading();
      var params = this.$tempModel.deepCopy(this.form);
      params.area_id = params.area[params.area.length - 1];
      params.add_time = params.create_at;
      params.area_name = this.labels[this.labels.length - 1];
      params.area_full_name = this.labels.join('');

      if (params.area.length === 1) {
        params.area_type = 3;
      } else if (params.area.length === 2) {
        params.area_type = 2;
      } else if (params.area.length === 3) {
        params.area_type = 1;
      }

      areaApplyHandle(params).then(function _callee2(res) {
        return regeneratorRuntime.async(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                _this.$message({
                  message: '添加成功',
                  type: 'success'
                });

                _this.dialogVisible = false;

                if (_this.finish) {
                  _this.finish();
                }

                _this.$g.fun.endLoading();

              case 4:
              case "end":
                return _context2.stop();
            }
          }
        });
      }).catch(function () {
        _this.$g.fun.endLoading();
      });
    },
    areaChange: function areaChange(arr, index) {
      this.labels = this.$refs.cascaderRef.getCheckedNodes()[0].pathLabels;
      console.log(this.labels);
    }
  }
};