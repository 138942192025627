var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    {
      staticStyle: { width: "100%" },
      attrs: { data: _vm.data, size: "mini", "show-summary": "" }
    },
    _vm._l(_vm.tableProps, function(item) {
      return _c("el-table-column", {
        attrs: { prop: item.prop, label: item.label }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }