//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import personalConfig from './personalConfig';
import regConfig from './regConfig';
import homeConfig from './homeConfig';
export default {
  name: 'webConfig',
  components: {
    personalConfig: personalConfig,
    regConfig: regConfig,
    homeConfig: homeConfig
  },
  data: function data() {
    return {
      phoneUrl: '',
      activeName: '0'
    };
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {}
};