import "core-js/modules/es.object.to-string";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { configGradeList, productPriceAdd } from '../../../../api/apis';
export default {
  data: function data() {
    return {
      dialogVisible: false,
      gradeList: []
    };
  },
  mounted: function mounted() {
    var _this = this;

    configGradeList({}).then(function _callee(res) {
      var i;
      return regeneratorRuntime.async(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _this.gradeList = res.rows;

              for (i in _this.gradeList) {
                _this.gradeList[i].price = 0;
                _this.gradeList[i].pv = 0;
              }

            case 2:
            case "end":
              return _context.stop();
          }
        }
      });
    });
  },
  methods: {
    show: function show(product, finish) {
      var _this2 = this;

      this.dialogVisible = true;
      this.product = product;
      this.finish = finish;

      if (this.product.t_product_prices.length > 0) {
        this.$nextTick(function () {
          var tmp = _this2.gradeList;

          for (var i in tmp) {
            for (var j in _this2.product.t_product_prices) {
              if (_this2.product.t_product_prices[j].grade_tag === tmp[i].tag) {
                tmp[i].price = _this2.product.t_product_prices[j].price;
                tmp[i].pv = _this2.product.t_product_prices[j].pv;
              }
            }
          }

          _this2.gradeList = _this2.$tempModel.deepCopy(tmp);
        });
      } else {
        var tmp = this.gradeList;

        for (var i in tmp) {
          tmp[i].price = 0;
          tmp[i].pv = 0;
        }

        this.gradeList = this.$tempModel.deepCopy(tmp);
      }
    },
    clickBatch: function clickBatch() {
      var tmp = this.$tempModel.deepCopy(this.gradeList);

      for (var i in tmp) {
        tmp[i].price = this.product.price;
        tmp[i].pv = this.product.pv;
      }

      this.gradeList = tmp;
    },
    clickSubmit: function clickSubmit() {
      var _this3 = this;

      var data = [];

      for (var i in this.gradeList) {
        var item = this.gradeList[i];
        data.push({
          grade_tag: item.tag,
          price: item.price,
          pv: item.pv
        });
      }

      var params = {
        product_id: this.product.id,
        data: data
      };
      this.$g.fun.startLoading();
      productPriceAdd(params).then(function _callee2(res) {
        return regeneratorRuntime.async(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                _this3.$message({
                  message: '设置成功',
                  type: 'success'
                });

                _this3.$g.fun.endLoading();

                _this3.dialogVisible = false;

                _this3.finish();

              case 4:
              case "end":
                return _context2.stop();
            }
          }
        });
      });
    }
  }
};