import "core-js/modules/es.array.map";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.search";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tempTable from "@/components/rw/tempTable/index";
import tempSearch from "@/components/rw/tempSearch/index";
import tempForm from "@/components/rw/tempForm/index";
import tempChart from "@/components/rw/tempChart/index";
import { configGradeList, expectNumList, sendBonusLog } from '../../../../api/apis';
export default {
  components: {
    tempTable: tempTable,
    tempSearch: tempSearch,
    tempForm: tempForm,
    tempChart: tempChart
  },
  data: function data() {
    var _this = this;

    return {
      searchModel_1000: {
        "type": "searchTemplate",
        "id": "id_1000",
        "del": false,
        "searchData": [{
          "title": "按条件",
          "select": 0,
          "type": 2,
          "id": 1,
          "relation": false,
          "relationId": "",
          "remote": false,
          "remoteType": 0,
          "children": [{
            "type": "input-mul-complex",
            "placeholder": "请输入",
            "field": [{
              "key": "expect_num",
              "value": "",
              "desc": "发放期数"
            }]
          }, {
            "type": "input-mul-complex",
            "placeholder": "请输入",
            "field": [{
              "key": "curr_send_bonus",
              "desc": "发放奖金"
            }]
          }, {
            "type": "input-complex",
            "placeholder": "请输入",
            "field": [{
              "key": "number",
              "desc": "会员编号"
            }, {
              "key": "direct",
              "value": "",
              "desc": "顶点编号"
            }]
          }],
          "remoteFunc": []
        }],
        "operateData": [{
          "title": "搜索",
          "type": "primary",
          "tag": "search",
          "icon": "el-icon-search"
        }, {
          "title": "导出",
          "type": "primary",
          "tag": "export",
          "icon": "el-icon-position"
        }]
      },
      tableModel_1001: {
        "type": "tableTemplate",
        "id": "id_1001",
        "del": false,
        "tableModel": [{
          "label": "ID",
          "show": 1,
          "add": 1,
          "detail": 1,
          "excel": 1,
          "width": "80",
          "type": "text",
          "rowAddField": "",
          "groupId": "",
          "rules": [],
          "prop": "id"
        }, {
          "label": "期数",
          "show": 1,
          "add": 1,
          "detail": 1,
          "excel": 1,
          "width": 80,
          "type": "text",
          "rowAddField": "",
          "groupId": "",
          "rules": [],
          "prop": "expect_num"
        }, {
          "label": "会员编号",
          "show": 1,
          "add": 1,
          "detail": 1,
          "excel": 1,
          "width": "100",
          "type": "text",
          "rowAddField": "",
          "groupId": "",
          "rules": [],
          "prop": "number"
        }, {
          "label": "推荐人",
          "show": 1,
          "add": 1,
          "detail": 1,
          "excel": 1,
          "width": "100",
          "type": "text",
          "rowAddField": "",
          "groupId": "",
          "rules": [],
          "prop": "direct"
        }, {
          "label": "会员等级",
          "show": 0,
          "add": 1,
          "detail": 1,
          "excel": 1,
          "width": "100",
          "type": "text",
          "rowAddField": "",
          "groupId": "",
          "rules": [],
          "prop": "level"
        }, {
          "label": "当前等级",
          "show": 1,
          "add": 1,
          "detail": 1,
          "excel": 1,
          "width": "100",
          "type": "select",
          "rowAddField": "",
          "groupId": "",
          "rules": [],
          "prop": "grade"
        }, {
          "label": "累计获得奖金",
          "show": 1,
          "add": 1,
          "detail": 1,
          "excel": 1,
          "width": "100",
          "type": "text",
          "rowAddField": "",
          "groupId": "",
          "rules": [],
          "prop": "total_all_bonus"
        }, {
          "label": "累计实际发放奖金",
          "show": 1,
          "add": 1,
          "detail": 1,
          "excel": 1,
          "width": "120",
          "type": "text",
          "rowAddField": "",
          "groupId": "",
          "rules": [],
          "prop": "total_send_bonus"
        }, {
          "label": "累计扣税",
          "show": 1,
          "add": 1,
          "detail": 1,
          "excel": 1,
          "width": "100",
          "type": "text",
          "rowAddField": "",
          "groupId": "",
          "rules": [],
          "prop": "total_deduct_tax"
        }, {
          "label": "消费账户",
          "show": 1,
          "add": 1,
          "detail": 1,
          "excel": 1,
          "width": "100",
          "type": "text",
          "rowAddField": "",
          "groupId": "",
          "rules": [],
          "prop": "money1"
        }, {
          "label": "奖金账户",
          "show": 1,
          "add": 1,
          "detail": 1,
          "excel": 1,
          "width": "100",
          "type": "text",
          "rowAddField": "",
          "groupId": "",
          "rules": [],
          "prop": "money2"
        }, {
          "label": "充值中心",
          "show": 1,
          "add": 1,
          "detail": 1,
          "excel": 1,
          "width": "100",
          "type": "text",
          "rowAddField": "",
          "groupId": "",
          "rules": [],
          "prop": "money3"
        }, {
          "label": "销售佣金",
          "show": 1,
          "add": 1,
          "detail": 1,
          "excel": 1,
          "width": "100",
          "type": "text",
          "rowAddField": "",
          "groupId": "",
          "rules": [],
          "prop": "bonus0"
        }, {
          "label": "超额提成",
          "show": 1,
          "add": 1,
          "detail": 1,
          "excel": 1,
          "width": "100",
          "type": "text",
          "rowAddField": "",
          "groupId": "",
          "rules": [],
          "prop": "bonus1"
        }, {
          "label": "加权分红",
          "show": 1,
          "add": 1,
          "detail": 1,
          "excel": 1,
          "width": "100",
          "type": "text",
          "rowAddField": "",
          "groupId": "",
          "rules": [],
          "prop": "bonus2"
        }],
        "tableSwitchProps": {},
        "tableSelectProps": {
          "grade": {
            "multiple": 0,
            "data": [{
              "key": null,
              "value": ""
            }],
            "remote": true,
            "id": 1,
            "relation": false,
            "relationProps": [],
            "remoteFunc": "tableFunc_select_grade"
          }
        },
        "tableCascaderProps": {},
        "tableFileProps": {},
        "rowButton": [{
          "tag": "detail",
          "type": null,
          "title": "详情",
          "id": ""
        }],
        "stripe": false,
        "border": true,
        "showPagination": false,
        "showSelection": false,
        "showSummary": false,
        "title": "",
        "tableName": "t_balance",
        "isGroup": false,
        "isTree": false,
        "headerButton": [],
        "tableRadioProps": {}
      },
      remoteFunc: {
        tableFunc_select_grade: function tableFunc_select_grade(tag, relationData, resolve) {
          //筛选项模型数组
          expectNumList({
            page: 1,
            limit: 1
          }).then(function _callee2(res) {
            var curExpectNum;
            return regeneratorRuntime.async(function _callee2$(_context2) {
              while (1) {
                switch (_context2.prev = _context2.next) {
                  case 0:
                    curExpectNum = res[0].expect_num;
                    configGradeList({
                      where: {
                        expect_num: curExpectNum
                      },
                      page: 1,
                      limit: 1000
                    }).then(function _callee(res) {
                      var data, i, item;
                      return regeneratorRuntime.async(function _callee$(_context) {
                        while (1) {
                          switch (_context.prev = _context.next) {
                            case 0:
                              data = [];
                              data.push({
                                key: -1,
                                value: '无等级'
                              });

                              for (i in res.rows) {
                                item = res.rows[i];
                                data.push({
                                  key: item.tag,
                                  value: item.name
                                });
                              }

                              _this.gradeList = data;
                              resolve(tag, data);

                            case 5:
                            case "end":
                              return _context.stop();
                          }
                        }
                      });
                    });

                  case 2:
                  case "end":
                    return _context2.stop();
                }
              }
            });
          });
        }
      }
    };
  },
  mounted: function mounted() {
    this.table = this.$refs.ref_1001;
    this.search = this.$refs.ref_1000;
  },
  methods: {
    reqTableData: function reqTableData(params, resolve) {
      var where = {};

      if (this.search) {
        where = this.search.parseWhere();
        params.where = where;
      }

      sendBonusLog(params).then(function _callee3(res) {
        var i;
        return regeneratorRuntime.async(function _callee3$(_context3) {
          while (1) {
            switch (_context3.prev = _context3.next) {
              case 0:
                for (i in res) {
                  if (res[i].grade === null) {
                    res[i].grade = -1;
                  }
                }

                resolve({
                  total: res.count,
                  data: res.rows
                });

              case 2:
              case "end":
                return _context3.stop();
            }
          }
        });
      });
    },
    reqAdd: function reqAdd(isEdit, params, row, resolve) {//if (isEdit){
      //  params.id = row.id
      //}
      //xxxAdd(params).then(async res=>{
      //  resolve()
      //})
    },
    reqDel: function reqDel(params, resolve) {
      var ids = params.map(function (item) {
        return item.id;
      }); //xxxDel({id:ids}).then(async res=>{
      //  resolve()
      //})
    },
    onClickLink: function onClickLink(prop, row) {//点击列表link类型回调
    },
    onClickRowButton: function onClickRowButton(btnIndex, row) {//点击列表按钮回调
    },
    onClickHeaderButton: function onClickHeaderButton(btnIndex, row) {//点击列表头部按钮回调
    },
    onSummary: function onSummary(param) {//列表尾部合计回调
    },
    onSwitchChange: function onSwitchChange(val, prop, row) {//列表中switch变化事件
    },
    onClickSearchOperate: function onClickSearchOperate(btnIndex, btnData) {
      //条件菜单按钮点击事件
      if (btnData.tag === this.$tempModel.buttonTag.search) {
        this.table.reloadData();
      } else if (btnData.tag === this.$tempModel.buttonTag.export) {
        this.table.exportExcel();
      } else if (btnData.tag === this.$tempModel.buttonTag.import) {
        var header = btnData.header;
        var results = btnData.results;
      }
    },
    onConditionChange: function onConditionChange(tag, index) {
      this.table.reloadData();
    }
  }
};