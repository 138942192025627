//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    model: {
      type: Object,
      required: true
    },
    prop: {
      type: String,
      required: true
    }
  },
  data: function data() {
    return {
      dialogVisible: false
    };
  },
  methods: {
    clickVideo: function clickVideo() {
      this.dialogVisible = true;
    }
  }
};