//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    type: {
      type: String,
      required: true
    },
    field: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  mounted: function mounted() {
    console.log('aaaaaaa', this.type, this.field);
  },
  data: function data() {
    return {
      startTime: '',
      endTime: ''
    };
  },
  methods: {
    where: function where() {
      var where = {};

      for (var i in this.field) {
        var item = this.field[i];

        if (this.startTime !== '' || this.endTime !== '') {
          where[item.key] = [this.startTime, this.endTime];
        }
      }

      return where;
    }
  }
};