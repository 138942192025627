import "core-js/modules/es.object.to-string";
import "core-js/modules/es.parse-int";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { systemSwitchAdd, systemSwitchList } from '../../../../api/apis';
export default {
  data: function data() {
    return {
      form: {
        tagOrder: 0,
        tagOrderDesc: '',
        tagWeb: 0,
        tagWebDesc: '',
        tagOpenReg: 0,
        tagOpenRegDesc: '',
        startDate: '',
        startDateDesc: '',
        endDate: '',
        endDateDesc: ''
      }
    };
  },
  mounted: function mounted() {
    var _this = this;

    systemSwitchList().then(function _callee(res) {
      var i, item;
      return regeneratorRuntime.async(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              for (i in res) {
                item = res[i];

                if (item.id === 2) {
                  _this.form.tagOrder = item.value;
                  _this.form.tagOrderDesc = item.desc;
                } else if (item.id === 3) {
                  _this.form.tagWeb = parseInt(item.value);
                  _this.form.tagWebDesc = item.desc;
                } else if (item.id === 4) {
                  _this.form.tagOpenReg = item.value;
                  _this.form.tagOpenRegDesc = item.desc;
                } else if (item.id === 5) {
                  _this.form.startDate = item.value;
                  _this.form.startDateDesc = item.desc;
                } else if (item.id === 6) {
                  _this.form.endDate = item.value;
                  _this.form.endDateDesc = item.desc;
                }
              }

            case 1:
            case "end":
              return _context.stop();
          }
        }
      });
    });
  },
  methods: {
    clickSubmit: function clickSubmit() {
      var _this2 = this;

      if (this.form.tagWeb === 2) {
        if (!this.form.startDate || !this.form.endDate || this.form.startDate === '' || this.form.endDate === '') {
          this.$message({
            message: '请输入网站关闭开始日结和结束日期',
            type: 'error'
          });
          return;
        }

        var oDate1 = new Date(this.form.startDate);
        var oDate2 = new Date(this.form.endDate);

        if (oDate1 >= oDate2) {
          this.$message({
            message: '结束日期不能小于开始日期',
            type: 'error'
          });
          return;
        }
      }

      console.log(this.form);
      var params = {
        data: [{
          id: 2,
          value: this.form.tagOrder
        }, {
          id: 3,
          value: this.form.tagWeb
        }, {
          id: 4,
          value: this.form.tagOpenReg
        }, {
          id: 5,
          value: this.form.startDate
        }, {
          id: 6,
          value: this.form.endDate
        }]
      };
      systemSwitchAdd(params).then(function _callee2(res) {
        return regeneratorRuntime.async(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                _this2.$message({
                  message: '保存成功',
                  type: 'success'
                });

              case 1:
              case "end":
                return _context2.stop();
            }
          }
        });
      });
    }
  }
};