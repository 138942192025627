import "core-js/modules/es.array.index-of";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.replace";
import "regenerator-runtime/runtime";
import Vue from 'vue';
import VueRouter from 'vue-router'; // 进度条

import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import store from '@/store/index';
import util from '@/libs/util.js'; // 路由数据

import routes from './routes';
import setting from '@/setting';

var _import = require('@/libs/util.import.' + process.env.NODE_ENV); // fix vue-router NavigationDuplicated


var VueRouterPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return VueRouterPush.call(this, location).catch(function (err) {
    return err;
  });
};

var VueRouterReplace = VueRouter.prototype.replace;

VueRouter.prototype.replace = function replace(location) {
  return VueRouterReplace.call(this, location).catch(function (err) {
    return err;
  });
};

Vue.use(VueRouter); // 导出路由 在 main.js 里使用

var router = new VueRouter({
  routes: routes
});
import fetchroutes from '@/router/fetchroutes';
import routerSwitch from '@/router/routerSwitch'; //标记是否已经拉取权限信息

/**
 * 路由拦截
 * 权限验证
 */

router.beforeEach(function _callee(to, from, next) {
  return regeneratorRuntime.async(function _callee$(_context) {
    while (1) {
      switch (_context.prev = _context.next) {
        case 0:
          _context.next = 2;
          return regeneratorRuntime.awrap(store.dispatch('d2admin/page/isLoaded'));

        case 2:
          _context.next = 4;
          return regeneratorRuntime.awrap(store.dispatch('d2admin/size/isLoaded'));

        case 4:
          // 进度条
          NProgress.start(); // 关闭搜索面板

          store.commit('d2admin/search/set', false);

          if (!(setting.baseRoute.indexOf(to.path) >= 0)) {
            _context.next = 9;
            break;
          }

          //过滤掉
          next();
          return _context.abrupt("return");

        case 9:
          if (routerSwitch.isFetchPermissionInfo) {
            _context.next = 23;
            break;
          }

          _context.prev = 10;
          _context.next = 13;
          return regeneratorRuntime.awrap(fetchroutes.fetchPermissionInfo());

        case 13:
          routerSwitch.isFetchPermissionInfo = true;
          next(to.path, true); // console.log('跳转routerSwitch.isFetchPermissionInfo',routerSwitch.isFetchPermissionInfo,to,from)
          // next()

          _context.next = 21;
          break;

        case 17:
          _context.prev = 17;
          _context.t0 = _context["catch"](10);
          // console.log('异常routerSwitch.isFetchPermissionInfo',routerSwitch.isFetchPermissionInfo,to,from)
          util.cookies.remove('token');
          next({
            name: 'login'
          });

        case 21:
          _context.next = 24;
          break;

        case 23:
          next();

        case 24:
        case "end":
          return _context.stop();
      }
    }
  }, null, null, [[10, 17]]);
});
router.afterEach(function (to) {
  // 进度条
  NProgress.done(); // 多页控制 打开新的页面

  store.dispatch('d2admin/page/open', to); // 更改标题

  util.title(to.meta.title);
});
export default router;