import "core-js/modules/es.array.sort";
import "core-js/modules/es.array.splice";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.parse-int";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { systemWebConfig, systemWebConfigAdd } from '../../../../api/apis';
import tempAdd from '@/components/rw/tempAdd/index';
import Sortable from "sortablejs";
import phoneView from './phoneView';
import setting from '../../../../setting';
export default {
  name: 'regConfig',
  components: {
    phoneView: phoneView,
    tempAdd: tempAdd
  },
  data: function data() {
    return {
      styleTag: 0,
      phoneUrl: setting.appApi.previewUrl + '/pages/public/previewReg',
      tableData: [],
      tableModel_1000: {
        "border": true,
        "del": false,
        "headerButton": [{
          "id": "",
          "tag": "add",
          "title": "添加",
          "type": "primary"
        }],
        "id": "id_1000",
        "isGroup": false,
        "isTree": false,
        "rowButton": [{
          "id": "",
          "tag": "detail",
          "title": "详情"
        }, {
          "id": "",
          "tag": "edit",
          "title": "编辑",
          "type": "primary"
        }, {
          "id": "",
          "tag": "del",
          "title": "删除",
          "type": "danger"
        }],
        "showPagination": false,
        "showSelection": false,
        "showSummary": false,
        "stripe": false,
        "tableAutocompleteProps": {},
        "tableCascaderProps": {},
        "tableFileProps": {},
        "tableModel": [{
          "add": 1,
          "desc": "唯一",
          "detail": 1,
          "groupId": "",
          "label": "KEY",
          "required": 1,
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "key"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "groupId": "",
          "label": "字段名称",
          "required": 1,
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "name"
        }, {
          "add": 1,
          "defaultValue": 0,
          "desc": "",
          "detail": 1,
          "groupId": "",
          "label": "注册可见项",
          "rules": [],
          "show": 1,
          "type": "select",
          "width": null,
          "prop": "regShow"
        }, {
          "add": 1,
          "defaultValue": 0,
          "desc": "",
          "detail": 1,
          "groupId": "",
          "label": "注册必选项",
          "rules": [],
          "show": 1,
          "type": "select",
          "width": null,
          "prop": "regRequire"
        }, {
          "add": 1,
          "defaultValue": 0,
          "desc": "",
          "detail": 1,
          "groupId": "",
          "label": "前台可查看项",
          "rules": [],
          "show": 1,
          "type": "select",
          "width": null,
          "prop": "webShow"
        }, {
          "add": 1,
          "defaultValue": 0,
          "desc": "",
          "detail": 1,
          "groupId": "",
          "label": "前台可修改项",
          "rules": [],
          "show": 1,
          "type": "select",
          "width": null,
          "prop": "webEdit"
        }, {
          "add": 1,
          "defaultValue": 0,
          "desc": "",
          "detail": 1,
          "groupId": "",
          "label": "唯一",
          "rules": [],
          "show": 1,
          "type": "select",
          "width": null,
          "prop": "unique"
        }, {
          "add": 1,
          "defaultValue": 0,
          "desc": "",
          "detail": 1,
          "groupId": "",
          "label": "真实性",
          "rules": [],
          "show": 1,
          "type": "select",
          "width": null,
          "prop": "verify"
        }, {
          "label": "排序",
          "show": 1,
          "add": 1,
          "detail": 1,
          "width": null,
          "type": "text",
          "desc": "数值越小在前面",
          "groupId": "",
          "rules": [{
            "name": "integer"
          }],
          "prop": "sort"
        }],
        "tableName": "",
        "tableRadioProps": {},
        "tableSelectProps": {
          "regShow": {
            "data": [{
              "key": 0,
              "value": "否"
            }, {
              "key": 1,
              "value": "是"
            }],
            "id": 0,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": false
          },
          "regRequire": {
            "data": [{
              "key": 0,
              "value": "否"
            }, {
              "key": 1,
              "value": "是"
            }],
            "id": 1,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": false
          },
          "webShow": {
            "data": [{
              "key": 0,
              "value": "否"
            }, {
              "key": 1,
              "value": "是"
            }],
            "id": 2,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": false
          },
          "webEdit": {
            "data": [{
              "key": 0,
              "value": "否"
            }, {
              "key": 1,
              "value": "是"
            }],
            "id": 3,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": false
          },
          "unique": {
            "data": [{
              "key": 0,
              "value": "不限"
            }, {
              "key": 1,
              "value": "1"
            }, {
              "key": 2,
              "value": "2"
            }, {
              "key": 3,
              "value": "3"
            }, {
              "key": 4,
              "value": "4"
            }, {
              "key": 5,
              "value": "5"
            }, {
              "key": 6,
              "value": "6"
            }, {
              "key": 7,
              "value": "7"
            }, {
              "key": 8,
              "value": "8"
            }, {
              "key": 9,
              "value": "9"
            }, {
              "key": 10,
              "value": "10"
            }],
            "id": 4,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": false
          },
          "verify": {
            "data": [{
              "key": 0,
              "value": "不需要验证"
            }, {
              "key": 1,
              "value": "需要验证"
            }],
            "id": 5,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": false
          }
        },
        "tableSwitchProps": {},
        "title": "",
        "type": "tableTemplate"
      }
    };
  },
  watch: {
    tableData: {
      deep: true,
      handler: function handler(val) {
        this.reloadData();
      }
    }
  },
  computed: {
    uniqueList: function uniqueList() {
      var ret = [{
        label: '不限',
        value: 0
      }];

      for (var i = 1; i < 20; i++) {
        ret.push({
          label: i,
          value: i
        });
      }

      return ret;
    }
  },
  mounted: function mounted() {
    var _this2 = this;

    var params = {
      filed: ['regForm']
    };
    this.initTableSort();
    systemWebConfig(params).then(function _callee(res) {
      return regeneratorRuntime.async(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _this2.tableData = res[0].value;
              setTimeout(function () {
                _this2.reloadData();
              }, 2000);

            case 2:
            case "end":
              return _context.stop();
          }
        }
      });
    });
  },
  methods: {
    reloadData: function reloadData() {
      this.$refs.phone.reloadPhone({
        styleTag: this.styleTag,
        data: this.tableData
      });
    },
    initTableSort: function initTableSort() {
      var tbody = document.querySelector('.tableData .el-table__body-wrapper tbody');

      var _this = this;

      new Sortable(tbody, {
        onEnd: function onEnd(_ref) {
          var newIndex = _ref.newIndex,
              oldIndex = _ref.oldIndex;

          var currRow = _this.tableData.splice(oldIndex, 1)[0];

          _this.tableData.splice(newIndex, 0, currRow);

          var tmp = _this.tableData;

          for (var i in tmp) {
            tmp[i].sort = parseInt(i);
          }

          _this.tableData = tmp;
        }
      });
    },
    updateTableData: function updateTableData(data) {
      data.sort(function (a, b) {
        return b.sort === a.sort ? 0 : a.sort > b.sort ? 1 : -1;
      });
      this.tableData = data;
    },
    onReqAdd: function onReqAdd(isEdit, params, row, resolveAdd) {
      if (isEdit) {
        var tmp = this.$tempModel.deepCopy(this.tableData);

        for (var i in tmp) {
          if (tmp[i].key === params.key) {
            tmp[i] = params;
            break;
          }
        }

        this.updateTableData(tmp);
      } else {
        var _tmp = this.$tempModel.deepCopy(this.tableData);

        for (var _i in this.tableData) {
          if (_tmp.key === params.key) {
            this.$message.error('KEY已存在');
            resolveAdd(true);
            return;
          }
        }

        _tmp.push(params);

        this.updateTableData(_tmp);
      }

      resolveAdd();
    },
    clickAdd: function clickAdd() {
      this.$refs['add'].show(false, null);
    },
    clickEdit: function clickEdit(row) {
      this.$refs['add'].show(true, row);
    },
    clickDel: function clickDel(row) {
      var tmp = this.$tempModel.deepCopy(this.tableData);

      for (var i in tmp) {
        if (tmp[i].key === row.key) {
          tmp.splice(parseInt(i), 1);
          this.updateTableData(tmp);
          break;
        }
      }
    },
    clickSubmit: function clickSubmit() {
      var _this3 = this;

      var params = [{
        id: 'regForm',
        value: JSON.stringify(this.tableData)
      }];
      systemWebConfigAdd(params).then(function _callee2(res) {
        return regeneratorRuntime.async(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                _this3.$message({
                  message: '保存成功',
                  type: 'success'
                });

              case 1:
              case "end":
                return _context2.stop();
            }
          }
        });
      });
    }
  }
};