import "core-js/modules/es.string.replace";
export default {
  beforeRouteEnter: function beforeRouteEnter(to, from, next) {
    next(function (instance) {
      return instance.$router.replace(from.fullPath);
    });
  },
  render: function render(h) {
    return h();
  }
};