var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "参数设置",
        visible: _vm.dialogVisible,
        width: "600px",
        "append-to-body": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "destroy-on-close": true,
        "show-close": false
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            "max-height": "50vh",
            "overflow-y": "auto",
            padding: "15px 30px",
            "background-color": "#f6f6f6"
          }
        },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.form, "label-width": "120px" } },
            _vm._l(_vm.data, function(item) {
              return _c(
                "el-form-item",
                { attrs: { label: item.title } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 16 } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: item.placeholder },
                        model: {
                          value: _vm.form[item.key],
                          callback: function($$v) {
                            _vm.$set(_vm.form, item.key, $$v)
                          },
                          expression: "form[item.key]"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: {
                        "flex-direction": "row",
                        display: "flex",
                        "justify-content": "flex-end"
                      },
                      attrs: { span: 8 }
                    },
                    [_c("span", [_vm._v(_vm._s(item.remark))])]
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.dialogVisible = false
                }
              }
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.clickSubmit } },
            [_vm._v("提交")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }