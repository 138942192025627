//
//
//
//
//
//
//
//
//
//
//
import baseConfig from './baseConfig';
export default {
  name: 'webConfig',
  components: {
    baseConfig: baseConfig
  },
  data: function data() {
    return {};
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {}
};