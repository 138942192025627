import "core-js/modules/es.object.to-string";
import "regenerator-runtime/runtime";
// Element
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'; // flex 布局库

import 'flex.css'; // 组件

import '@/components'; // svg 图标

import '@/assets/svg-icons'; // 国际化

import _i18n from '@/i18n.js'; // 功能插件

import pluginError from '@/plugin/error';
import pluginLog from '@/plugin/log';
import pluginOpen from '@/plugin/open';
import pluginGlobal from '@/plugin/global';
import pluginExport from '@d2-projects/vue-table-export';
import pluginImport from '@d2-projects/vue-table-import';
import VCharts from 'v-charts';
export default {
  install: function install(Vue, options) {
    return regeneratorRuntime.async(function install$(_context) {
      while (1) {
        switch (_context.prev = _context.next) {
          case 0:
            // 设置为 false 以阻止 vue 在启动时生成生产提示
            // https://cn.vuejs.org/v2/api/#productionTip
            Vue.config.productionTip = false; // 当前环境

            Vue.prototype.$env = process.env.NODE_ENV; // 当前的 baseUrl

            Vue.prototype.$baseUrl = process.env.BASE_URL; // 当前版本

            Vue.prototype.$version = process.env.VUE_APP_VERSION; // 构建时间

            Vue.prototype.$buildTime = process.env.VUE_APP_BUILD_TIME; // Element

            Vue.use(ElementUI, {
              i18n: function i18n(key, value) {
                return _i18n.t(key, value);
              }
            }); // 插件

            Vue.use(pluginError);
            Vue.use(pluginLog);
            Vue.use(pluginOpen);
            Vue.use(pluginGlobal);
            Vue.use(pluginExport);
            Vue.use(pluginImport);
            Vue.use(VCharts);

          case 13:
          case "end":
            return _context.stop();
        }
      }
    });
  }
};