var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-card",
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { model: _vm.form, size: "mini", "label-position": "top" }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "生效的订单类型" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.form.orderTypes,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "orderTypes", $$v)
                        },
                        expression: "form.orderTypes"
                      }
                    },
                    [
                      _c("el-checkbox", { attrs: { label: 1, name: "type" } }, [
                        _vm._v("注册单")
                      ]),
                      _c("el-checkbox", { attrs: { label: 2, name: "type" } }, [
                        _vm._v("复销单")
                      ]),
                      _c("el-checkbox", { attrs: { label: 3, name: "type" } }, [
                        _vm._v("升级单")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否加权" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { size: "mini" },
                      model: {
                        value: _vm.form.isWeight,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "isWeight", $$v)
                        },
                        expression: "form.isWeight"
                      }
                    },
                    [
                      _c("el-option", { attrs: { label: "否", value: 0 } }),
                      _c("el-option", { attrs: { label: "是", value: 1 } })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "平分方式" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "400px" },
                      attrs: { size: "mini" },
                      model: {
                        value: _vm.form.weightType,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "weightType", $$v)
                        },
                        expression: "form.weightType"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: {
                          label: "按团队业绩在等级总业绩中占比",
                          value: 0
                        }
                      }),
                      _c("el-option", {
                        attrs: { label: "按团队业绩在总业绩占比", value: 1 }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.form.weightType === 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "分红占比" } },
                    [
                      _c("el-input-number", {
                        attrs: { size: "mini", min: 0, max: 100 },
                        model: {
                          value: _vm.form.weightRate,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "weightRate", $$v)
                          },
                          expression: "form.weightRate"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c("el-tag", [_vm._v(_vm._s(_vm.prompt))])
        ],
        1
      ),
      _c(
        "el-card",
        { staticStyle: { "margin-top": "10px" } },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.form.weightType === 0 ? "等级占比配置" : "会员达标条件"
                )
              )
            ])
          ]),
          _vm.form.weightType === 0
            ? _c(
                "div",
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { data: _vm.data }
                    },
                    [
                      _c("el-table-column", {
                        attrs: { label: "会员级别", width: "180" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        size: "mini",
                                        placeholder: "请选择会员级别"
                                      },
                                      model: {
                                        value: scope.row.grade,
                                        callback: function($$v) {
                                          _vm.$set(scope.row, "grade", $$v)
                                        },
                                        expression: "scope.row.grade"
                                      }
                                    },
                                    _vm._l(_vm.gradeData, function(item) {
                                      return _c("el-option", {
                                        key: item.tag,
                                        attrs: {
                                          label: item.name,
                                          value: item.tag
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3958874499
                        )
                      }),
                      _c("el-table-column", {
                        attrs: { label: "提成比例", width: "180" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-row",
                                    [
                                      _c("el-input-number", {
                                        staticStyle: { width: "80%" },
                                        attrs: {
                                          size: "mini",
                                          min: 0,
                                          max: 100
                                        },
                                        model: {
                                          value: scope.row.rate,
                                          callback: function($$v) {
                                            _vm.$set(scope.row, "rate", $$v)
                                          },
                                          expression: "scope.row.rate"
                                        }
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: { "margin-left": "10px" }
                                        },
                                        [_vm._v("%")]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3485434468
                        )
                      }),
                      _c("el-table-column", {
                        attrs: { label: "操作" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini", type: "danger" },
                                      on: {
                                        click: function($event) {
                                          return _vm.clickDelete(
                                            scope.$index,
                                            scope.row
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("删除 ")]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1431131302
                        )
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { width: "100px", "margin-top": "20px" },
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.clickAddCond }
                        },
                        [_vm._v("添加等级 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticStyle: { width: "100px", "margin-top": "20px" },
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.clickAddAllCond }
                        },
                        [_vm._v("添加所有等级 ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _c("condition-creator", {
                ref: "creator",
                attrs: {
                  "grade-list": _vm.gradeData,
                  "level-list": _vm.levelData
                }
              })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }