import "core-js/modules/es.array.map";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.search";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tempTable from "@/components/rw/tempTable/index";
import tempSearch from "@/components/rw/tempSearch/index";
import tempForm from "@/components/rw/tempForm/index";
import tempChart from "@/components/rw/tempChart/index";
import { configBonusAdd, configBonusList, configGradeList, expectNumList } from '../../../../api/apis';
export default {
  components: {
    tempTable: tempTable,
    tempSearch: tempSearch,
    tempForm: tempForm,
    tempChart: tempChart
  },
  data: function data() {
    var _this = this;

    return {
      tableModel_1001: {
        "border": true,
        "del": false,
        "headerButton": [],
        "id": "id_1001",
        "isGroup": false,
        "isTree": false,
        "rowButton": [],
        "showPagination": false,
        "showSelection": false,
        "stripe": true,
        "tableAutocompleteProps": {},
        "tableCascaderProps": {},
        "tableFileProps": {},
        "tableModel": [{
          "add": 1,
          "desc": "",
          "detail": 1,
          "groupId": "",
          "label": "唯一标识",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "tag"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "是否启用",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "select",
          "width": "100",
          "prop": "status"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "奖金名称",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": 160,
          "prop": "name"
        }, {
          "add": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "结周期",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "select",
          "width": 80,
          "prop": "period"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "是否需要审核",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "select",
          "width": "100",
          "prop": "is_examine"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "审核周期",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "select",
          "width": "100",
          "prop": "exa_period"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "备注",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "300",
          "prop": "remark"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "更新日期",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "150",
          "prop": "update_time"
        }],
        "tableName": "t_bonus_config",
        "tableRadioProps": {},
        "tableSelectProps": {
          "exa_period": {
            "data": [{
              "key": 0,
              "value": "日结"
            }, {
              "key": 1,
              "value": "周结"
            }, {
              "key": 2,
              "value": "月结"
            }],
            "id": 0,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": false
          },
          "is_examine": {
            "data": [{
              "key": 0,
              "value": "否"
            }, {
              "key": 1,
              "value": "是"
            }],
            "id": 2,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": false
          },
          "period": {
            "data": [{
              "key": 0,
              "value": "日结"
            }, {
              "key": 1,
              "value": "周结"
            }, {
              "key": 2,
              "value": "月结"
            }],
            "id": 3,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": false
          },
          "status": {
            "data": [{
              "key": 0,
              "value": "否"
            }, {
              "key": 1,
              "value": "是"
            }],
            "id": 4,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": false
          }
        },
        "tableSwitchProps": {},
        "title": "奖金配置",
        "type": "tableTemplate"
      },
      remoteFunc: {}
    };
  },
  mounted: function mounted() {
    this.table = this.$refs.ref_1001;
  },
  methods: {
    reqTableData: function reqTableData(params, resolve) {
      configBonusList({}).then(function _callee(res) {
        return regeneratorRuntime.async(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                resolve({
                  total: res.count,
                  data: res.rows
                });

              case 1:
              case "end":
                return _context.stop();
            }
          }
        });
      });
    },
    reqAdd: function reqAdd(isEdit, params, row, resolve) {
      if (isEdit) {
        params.id = row.id;
      }

      configBonusAdd(params).then(function _callee2(res) {
        return regeneratorRuntime.async(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                resolve();

              case 1:
              case "end":
                return _context2.stop();
            }
          }
        });
      });
    },
    reqDel: function reqDel(params, resolve) {
      var ids = params.map(function (item) {
        return item.id;
      }); //xxxDel({id:ids}).then(async res=>{
      //  resolve()
      //})
    },
    onClickLink: function onClickLink(prop, row) {//点击列表link类型回调
    },
    onClickRowButton: function onClickRowButton(btnIndex, row) {//点击列表按钮回调
    },
    onClickHeaderButton: function onClickHeaderButton(btnIndex, row) {//点击列表头部按钮回调
    },
    onSummary: function onSummary(param) {//列表尾部合计回调
    },
    onSwitchChange: function onSwitchChange(val, prop, row) {//列表中switch变化事件
    },
    onClickSearchOperate: function onClickSearchOperate(btnIndex, btnData) {
      //条件菜单按钮点击事件
      if (btnData.tag === this.$tempModel.buttonTag.search) {
        this.table.reloadData();
      } else if (btnData.tag === this.$tempModel.buttonTag.export) {
        this.table.exportExcel();
      } else if (btnData.tag === this.$tempModel.buttonTag.import) {
        var header = btnData.header;
        var results = btnData.results;
      }
    },
    onConditionChange: function onConditionChange(tag, index) {
      this.table.reloadData();
    }
  }
};