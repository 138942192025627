var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.isEdit ? _vm.title + "编辑" : _vm.title + "添加",
        visible: _vm.dialogVisible,
        width: _vm.width,
        "append-to-body": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "show-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            "max-height": "50vh",
            "overflow-y": "auto",
            padding: "15px 30px",
            "background-color": "#f6f6f6"
          }
        },
        [
          _c(
            "el-tabs",
            {
              model: {
                value: _vm.activeName,
                callback: function($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName"
              }
            },
            [
              _vm._l(_vm.formData, function(group) {
                return _c(
                  "el-tab-pane",
                  { attrs: { label: group.groupName, name: group.groupId } },
                  [
                    _c("temp-form", {
                      ref: "form" + group.groupId,
                      refInFor: true,
                      attrs: {
                        "remote-func": _vm.remoteFunc,
                        "form-model": group.groupData
                      }
                    })
                  ],
                  1
                )
              }),
              _vm._t("add_tab")
            ],
            2
          )
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.dialogVisible = false
                }
              }
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.clickSubmit }
            },
            [_vm._v(_vm._s(_vm.isEdit ? "保 存" : "添 加"))]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }