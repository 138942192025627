import "core-js/modules/es.array.map";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.search";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tempTable from "@/components/rw/tempTable/index";
import tempSearch from "@/components/rw/tempSearch/index";
import tempForm from "@/components/rw/tempForm/index";
import tempChart from "@/components/rw/tempChart/index";
import { goodsList, goodsDel, goodsHandle, goodsImport, goodsCatList } from '@/api/apis';
import attr from '@/views/pages/goods/goodsList/attr.vue';
export default {
  components: {
    tempTable: tempTable,
    tempSearch: tempSearch,
    tempForm: tempForm,
    tempChart: tempChart,
    attr: attr
  },
  data: function data() {
    var _this = this;

    return {
      activeNames: 'search',
      searchModel_1000: {
        "del": false,
        "id": "id_1000",
        "isCollapse": true,
        "operateData": [{
          "icon": "el-icon-search",
          "tag": "search",
          "title": "搜索",
          "type": "primary"
        }, {
          "icon": "el-icon-position",
          "tag": "export",
          "title": "导出",
          "type": "primary"
        }, {
          "tag": "import",
          "type": "success",
          "title": "导入商品",
          "id": "",
          "icon": ""
        }],
        "searchData": [{
          "children": [{
            "field": [{
              "desc": "类型一",
              "key": "type",
              "value": "0"
            }, {
              "desc": "类型二",
              "key": "type",
              "value": "1"
            }],
            "multiple": false,
            "type": "select"
          }],
          "id": 2,
          "relation": false,
          "relationId": "",
          "remote": true,
          "remoteType": 1,
          "select": 0,
          "title": "按分类",
          "type": 3,
          "remoteFunc": ["modelConditionFunc_2"]
        }, {
          "children": [{
            "field": [{
              "desc": "产品名称",
              "key": "store_name",
              "value": ""
            }, {
              "desc": "产品编码",
              "key": "code",
              "value": ""
            }],
            "operator": [],
            "placeholder": "请输入名称",
            "type": "input-complex"
          }, {
            "field": [{
              "desc": "产品库存",
              "key": "stock"
            }],
            "operator": [{
              "key": "gte",
              "value": ">="
            }, {
              "key": "lte",
              "value": "<="
            }, {
              "key": "gt",
              "value": ">"
            }, {
              "key": "lt",
              "value": "<"
            }, {
              "key": "eq",
              "value": "="
            }, {
              "key": "ne",
              "value": "!="
            }],
            "placeholder": "请输入",
            "type": "input-mul-complex"
          }],
          "id": 0,
          "relation": false,
          "relationId": "",
          "remote": false,
          "remoteType": 0,
          "select": 0,
          "title": "按条件",
          "type": 2,
          "remoteFunc": []
        }, {
          "children": [{
            "field": [],
            "title": "全部",
            "type": "button"
          }, {
            "field": [{
              "key": "is_show",
              "value": "1"
            }],
            "title": "销售中",
            "type": "button"
          }, {
            "field": [{
              "key": "is_show",
              "value": "0"
            }],
            "title": "未销售",
            "type": "button"
          }],
          "id": 3,
          "relation": false,
          "relationId": "",
          "remote": false,
          "remoteType": 0,
          "select": 0,
          "title": "是否销售",
          "type": 0,
          "remoteFunc": []
        }, {
          "children": [{
            "field": [],
            "title": "全部",
            "type": "button"
          }, {
            "field": [],
            "title": "今天",
            "type": "button"
          }, {
            "field": [],
            "title": "昨天",
            "type": "button"
          }, {
            "field": [],
            "title": "最近7天",
            "type": "button"
          }, {
            "field": [],
            "title": "本月",
            "type": "button"
          }, {
            "field": [],
            "title": "本年",
            "type": "button"
          }, {
            "field": [],
            "title": "自定义",
            "type": "custom-datetime-range"
          }],
          "id": 4,
          "relation": false,
          "relationId": "",
          "remote": true,
          "remoteType": 1,
          "select": 0,
          "title": "上架日期",
          "type": 1,
          "remoteFunc": ["modelConditionFunc_4"]
        }],
        "type": "searchTemplate"
      },
      tableModel_1001: {
        "border": true,
        "del": false,
        "headerButton": [{
          "id": "",
          "tag": "add",
          "title": "添加",
          "type": "primary"
        }, {
          "id": "",
          "tag": "default",
          "title": "批量上架",
          "type": "danger"
        }, {
          "id": "",
          "tag": "default",
          "title": "批量下架",
          "type": "danger"
        }],
        "id": "id_1001",
        "isGroup": false,
        "isTree": false,
        "rowButton": [{
          "id": "",
          "tag": "edit",
          "title": "编辑",
          "type": "primary"
        }, {
          "id": "",
          "tag": "del",
          "title": "删除",
          "type": "danger"
        }, {
          "id": "",
          "tag": "default",
          "title": "商品属性",
          "type": "success"
        }],
        "showPagination": true,
        "showSelection": true,
        "showSummary": false,
        "stripe": false,
        "tableAutocompleteProps": {},
        "tableCascaderProps": {},
        "tableFileProps": {},
        "tableGroupProps": [],
        "tableModel": [{
          "add": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "6",
          "label": "产品ID",
          "required": 0,
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "60",
          "prop": "id"
        }, {
          "add": 1,
          "desc": "多个以逗号分割",
          "detail": 1,
          "excel": 1,
          "groupId": "6",
          "label": "产品编号",
          "required": 1,
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "70",
          "prop": "code"
        }, {
          "add": 1,
          "defaultValue": null,
          "desc": "建议尺寸640x320 不超过256kb",
          "detail": 1,
          "excel": 1,
          "groupId": "7",
          "label": "产品主图",
          "required": 1,
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "image",
          "width": "100",
          "prop": "image",
          "file": {
            "checkSize": 0,
            "exData": {
              "type": "default"
            },
            "fileType": ".png,.jpg,.jpeg",
            "height": 320,
            "id": 9,
            "key": "image",
            "maxNum": 1,
            "maxSize": 256,
            "width": 640
          }
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "6",
          "label": "产品名称",
          "required": 1,
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "textarea",
          "width": "120",
          "prop": "store_name"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "6",
          "label": "产品简称",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "120",
          "prop": "shrink_name"
        }, {
          "add": 1,
          "desc": "最多64个字符",
          "detail": 1,
          "excel": 1,
          "groupId": "6",
          "label": "产品简介",
          "required": 0,
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "textarea",
          "width": "150",
          "prop": "store_info"
        }, {
          "add": 1,
          "defaultValue": null,
          "detail": 1,
          "excel": 1,
          "groupId": "6",
          "label": "产品分类",
          "required": 1,
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "select",
          "width": "100",
          "prop": "category_id"
        }, {
          "add": 1,
          "defaultValue": null,
          "desc": "无等级购买产品的价格",
          "detail": 1,
          "excel": 1,
          "groupId": "6",
          "label": "产品价格",
          "required": 1,
          "rowAddField": "",
          "rules": [{
            "name": "money"
          }],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "price"
        }, {
          "add": 1,
          "defaultValue": 0,
          "desc": "数值越大，优先展示",
          "detail": 1,
          "excel": 1,
          "groupId": "6",
          "label": "排序",
          "required": 0,
          "rowAddField": "",
          "rules": [{
            "name": "integer"
          }],
          "show": 1,
          "type": "text",
          "width": 80,
          "prop": "sort"
        }, {
          "add": 0,
          "defaultValue": null,
          "desc": "",
          "detail": 0,
          "excel": 0,
          "groupId": "6",
          "label": "产品PV",
          "required": 0,
          "rules": [{
            "name": "decimal"
          }],
          "show": 0,
          "type": "text",
          "width": "70",
          "prop": "pv"
        }, {
          "add": 1,
          "defaultValue": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "6",
          "label": "是否销售",
          "required": 1,
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "select",
          "width": "70",
          "prop": "is_show"
        }, {
          "add": 1,
          "defaultValue": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "6",
          "label": "产品销量",
          "required": 0,
          "rowAddField": "",
          "rules": [{
            "name": "integer"
          }],
          "show": 1,
          "type": "text",
          "width": 80,
          "prop": "sales"
        }, {
          "add": 1,
          "defaultValue": 999999,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "6",
          "label": "产品库存",
          "required": 1,
          "rowAddField": "",
          "rules": [{
            "name": "integer"
          }],
          "show": 1,
          "type": "text",
          "width": "100",
          "prop": "stock"
        }, {
          "add": 1,
          "desc": "建议尺寸640x320 不超过256kb 最多上传9个",
          "detail": 1,
          "excel": 1,
          "groupId": "17",
          "label": "产品描述",
          "required": 0,
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "images",
          "width": 160,
          "prop": "description",
          "file": {
            "checkSize": 0,
            "exData": {
              "type": "default"
            },
            "fileType": ".png,.jpg,.jpeg",
            "height": 320,
            "id": 11,
            "key": "description",
            "maxNum": 9,
            "maxSize": 256,
            "width": 640
          }
        }, {
          "add": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "6",
          "label": "创建时间",
          "required": 0,
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "150",
          "prop": "add_time"
        }],
        "tableName": "t_product",
        "tableRadioProps": {},
        "tableSelectProps": {
          "category_id": {
            "data": [{
              "key": null,
              "value": ""
            }],
            "id": 1,
            "multiple": 0,
            "relation": false,
            "relationId": "",
            "remote": true,
            "tableGroupProps": [],
            "remoteFunc": "tableFunc_select_category_id"
          },
          "is_show": {
            "data": [{
              "key": 0,
              "value": "否"
            }, {
              "key": 1,
              "value": "是"
            }],
            "id": 12,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": false
          }
        },
        "tableSwitchProps": {},
        "title": "产品",
        "type": "tableTemplate"
      },
      remoteFunc: {
        modelConditionFunc_2: function modelConditionFunc_2(tag, relationData, resolve) {
          //筛选条件数据
          var data = [];
          goodsCatList({
            page: 1,
            limit: 1000
          }).then(function _callee(res) {
            var i;
            return regeneratorRuntime.async(function _callee$(_context) {
              while (1) {
                switch (_context.prev = _context.next) {
                  case 0:
                    for (i in res) {
                      data.push({
                        key: 'category_id',
                        value: res[i].id,
                        desc: res[i].category_name
                      });
                    }

                    resolve(tag, [data]);

                  case 2:
                  case "end":
                    return _context.stop();
                }
              }
            });
          });
        },
        modelConditionFunc_4: function modelConditionFunc_4(tag, relationData, resolve) {
          //筛选条件数据
          //[[{key:"type",value:"1"}]]
          var data = [];
          data = [[{
            key: "add_time",
            value: []
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 0, true)
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 1, true)
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 7, true)
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 30, true)
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 365, true)
          }], [{
            key: "add_time",
            value: []
          }]];
          resolve(tag, data);
        },
        tableFunc_select_category_id: function tableFunc_select_category_id(tag, relationData, resolve) {
          //筛选项模型数组
          var data = [];
          goodsCatList({
            page: 1,
            limit: 1000
          }).then(function _callee2(res) {
            var i;
            return regeneratorRuntime.async(function _callee2$(_context2) {
              while (1) {
                switch (_context2.prev = _context2.next) {
                  case 0:
                    for (i in res) {
                      data.push({
                        key: res[i].id,
                        value: res[i].category_name
                      });
                    }

                    resolve(tag, data);

                  case 2:
                  case "end":
                    return _context2.stop();
                }
              }
            });
          }); //[{ key: "新品", value: "0" }]
        }
      }
    };
  },
  mounted: function mounted() {
    this.table = this.$refs.ref_1001;
    this.search = this.$refs.ref_1000;
  },
  methods: {
    reqTableData: function reqTableData(params, resolve) {
      var where = {};

      if (this.search) {
        where = this.search.parseWhere();
        params.where = where;
      }

      goodsList(params).then(function _callee3(res) {
        return regeneratorRuntime.async(function _callee3$(_context3) {
          while (1) {
            switch (_context3.prev = _context3.next) {
              case 0:
                resolve({
                  total: res.count,
                  data: res.rows
                });

              case 1:
              case "end":
                return _context3.stop();
            }
          }
        });
      });
    },
    reqAdd: function reqAdd(isEdit, params, row, resolve) {
      if (isEdit) {
        params.id = row.id;
      }

      goodsHandle(params).then(function _callee4(res) {
        return regeneratorRuntime.async(function _callee4$(_context4) {
          while (1) {
            switch (_context4.prev = _context4.next) {
              case 0:
                resolve();

              case 1:
              case "end":
                return _context4.stop();
            }
          }
        });
      });
    },
    reqDel: function reqDel(params, resolve) {
      var ids = params.map(function (item) {
        return item.id;
      });
      goodsDel({
        id: ids
      }).then(function _callee5(res) {
        return regeneratorRuntime.async(function _callee5$(_context5) {
          while (1) {
            switch (_context5.prev = _context5.next) {
              case 0:
                resolve();

              case 1:
              case "end":
                return _context5.stop();
            }
          }
        });
      });
    },
    onClickLink: function onClickLink(prop, row) {//点击列表link类型回调
    },
    onClickRowButton: function onClickRowButton(btnIndex, row) {
      var _this2 = this;

      //点击列表按钮回调
      if (btnIndex === 2) {
        this.$refs.attr.show(row, function () {
          _this2.table.reloadData();
        });
      }
    },
    onClickHeaderButton: function onClickHeaderButton(btnIndex, row) {//点击列表头部按钮回调
    },
    onSummary: function onSummary(param) {//列表尾部合计回调
    },
    onSwitchChange: function onSwitchChange(val, prop, row) {//列表中switch变化事件
    },
    onClickSearchOperate: function onClickSearchOperate(btnIndex, btnData) {
      var _this3 = this;

      //条件菜单按钮点击事件
      if (btnData.tag === this.$tempModel.buttonTag.search) {
        this.table.reloadData();
      } else if (btnData.tag === this.$tempModel.buttonTag.export) {
        this.table.exportExcel();
      } else if (btnData.tag === this.$tempModel.buttonTag.import) {
        var header = btnData.header;
        var results = btnData.results;
        var dicHeader = {};
        dicHeader['商品编码'] = 'code';
        dicHeader['商品名称'] = 'store_name';
        dicHeader['商品封面'] = 'image';
        dicHeader['轮播图'] = 'slider_image';
        dicHeader['商品简称'] = 'shrink_name';
        dicHeader['商品简述'] = 'store_info';
        dicHeader['商品描述'] = 'description';
        dicHeader['规格'] = 'unit_name';
        dicHeader['分类'] = 'category_id';
        dicHeader['展示价格'] = 'price';
        dicHeader['SKU'] = 'sku';
        dicHeader['库存'] = 'goods_stock';
        dicHeader['商品价格'] = 'goods_price';
        dicHeader['商品PV'] = 'goods_pv';

        for (var i in header) {
          var key = header[i];

          if (!dicHeader.hasOwnProperty(key)) {
            this.$alert('表格字段缺少', '提示', {
              confirmButtonText: '确定'
            });
            return;
          }
        }

        var data = [];

        for (var _i in results) {
          var item = results[_i];
          var dic = {};

          for (var _key in item) {
            // if (key == '轮播图'){
            //   dic[dicHeader[key]] = item[key].split(',')
            // }else{
            dic[dicHeader[_key]] = item[_key]; // }
          }

          data.push(dic);
        }

        this.$g.fun.startLoading('导入中');
        goodsImport({
          data: data
        }).then(function _callee6(res) {
          return regeneratorRuntime.async(function _callee6$(_context6) {
            while (1) {
              switch (_context6.prev = _context6.next) {
                case 0:
                  _this3.$g.fun.endLoading();

                  _this3.$alert('导入成功', '提示', {
                    confirmButtonText: '确定'
                  });

                  _this3.table.reloadData();

                case 3:
                case "end":
                  return _context6.stop();
              }
            }
          });
        });
      }
    },
    onConditionChange: function onConditionChange(tag, index) {
      this.table.reloadData();
    },
    onRowButtonShow: function onRowButtonShow(btnIndex, row) {
      return true;
    }
  }
};