var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("audio", {
    staticStyle: { height: "40px", width: "300px" },
    attrs: { src: _vm.model[_vm.prop], controls: "" }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }