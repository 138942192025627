import "core-js/modules/es.array.map";
import "core-js/modules/es.array.slice";
import "core-js/modules/es.object.to-string";
import "core-js/modules/web.dom-collections.iterator";
import Vue from 'vue';

var requireAll = function requireAll(requireContext) {
  return requireContext.keys().map(requireContext);
};

var req = require.context('./icons', false, /\.svg$/);

var iconMap = requireAll(req);
Vue.prototype.$IconSvg = iconMap.map(function (e) {
  return e.default.id.slice(3);
});