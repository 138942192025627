var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c("img", { attrs: { src: require("@/assets/img/404.gif") } }),
      _c("p", { staticClass: "page_title" }, [_vm._v("404 page not found")]),
      _c(
        "el-button",
        {
          staticClass: "d2-mt",
          on: {
            click: function($event) {
              return _vm.$router.replace({ path: "/" })
            }
          }
        },
        [_vm._v(" 返回首页 ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }