//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    label: {
      type: String,
      required: true
    },
    width: {
      required: true
    },
    model: {
      type: Object,
      required: true
    },
    prop: {
      type: String,
      required: true
    }
  }
};