//
//
//
//
//
//
//
//
import setting from '@/setting';
export default {
  components: {},
  data: function data() {
    return {
      title: setting.projectName
    };
  },
  created: function created() {},
  mounted: function mounted() {},
  beforeDestroy: function beforeDestroy() {},
  methods: {}
};