import "core-js/modules/es.object.to-string";
import "regenerator-runtime/runtime";
// 设置文件
import setting from '@/setting.js';
export default {
  namespaced: true,
  state: {
    // 是否开启页面过度动画
    active: setting.transition.active
  },
  actions: {
    /**
     * @description 设置开启状态
     * @param {Object} context
     * @param {Boolean} active 新的状态
     */
    set: function set(_ref, active) {
      var state = _ref.state,
          dispatch = _ref.dispatch;
      return new Promise(function _callee(resolve) {
        return regeneratorRuntime.async(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                // store 赋值
                state.active = active; // 持久化

                _context.next = 3;
                return regeneratorRuntime.awrap(dispatch('d2admin/db/set', {
                  dbName: 'sys',
                  path: 'transition.active',
                  value: state.active,
                  user: true
                }, {
                  root: true
                }));

              case 3:
                // end
                resolve();

              case 4:
              case "end":
                return _context.stop();
            }
          }
        });
      });
    },

    /**
     * 从数据库读取页面过渡动画设置
     * @param {Object} context
     */
    load: function load(_ref2) {
      var state = _ref2.state,
          dispatch = _ref2.dispatch;
      return new Promise(function _callee2(resolve) {
        return regeneratorRuntime.async(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                _context2.next = 2;
                return regeneratorRuntime.awrap(dispatch('d2admin/db/get', {
                  dbName: 'sys',
                  path: 'transition.active',
                  defaultValue: setting.transition.active,
                  user: true
                }, {
                  root: true
                }));

              case 2:
                state.active = _context2.sent;
                // end
                resolve();

              case 4:
              case "end":
                return _context2.stop();
            }
          }
        });
      });
    }
  }
};