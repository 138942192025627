var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.isAgree ? "同意退款" : "拒绝退款",
        visible: _vm.dialogVisible,
        width: "50%",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "show-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            "max-height": "50vh",
            "overflow-y": "auto",
            padding: "15px 30px",
            "background-color": "#f6f6f6"
          }
        },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.form, "label-width": "120px" } },
            [
              _c("el-form-item", {
                attrs: { label: "订单号" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-input", { attrs: { value: _vm.form.order_id } })
                      ]
                    }
                  }
                ])
              }),
              _c("el-form-item", {
                attrs: { label: "用户姓名" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-input", { attrs: { value: _vm.form.real_name } })
                      ]
                    }
                  }
                ])
              }),
              _c("el-form-item", {
                attrs: { label: "用户电话" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-input", {
                          attrs: { value: _vm.form.user_phone }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("el-form-item", {
                attrs: { label: _vm.isAgree ? "退款备注" : "拒绝原因" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-input", {
                          model: {
                            value: _vm.form.refund_reason,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "refund_reason", $$v)
                            },
                            expression: "form.refund_reason"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("el-form-item", {
                attrs: { label: "退款金额" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-input", {
                          model: {
                            value: _vm.form.pay_price,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "pay_price", $$v)
                            },
                            expression: "form.pay_price"
                          }
                        })
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c("el-button", { on: { click: _vm.clickCancel } }, [
            _vm._v(_vm._s("取消"))
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.clickSubmit } },
            [_vm._v(_vm._s("确定"))]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }