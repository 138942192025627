import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import "core-js/modules/web.dom-collections.iterator";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { areaList, checkMembers, gradeMulUpdate, memberMulUpdateRegion } from '../../../../api/apis';
import area from '@/plugin/area';
export default {
  props: {},
  data: function data() {
    return {
      dialogVisible: false,
      tableData: [],
      isWrong: 0,
      type: '0',
      options: []
    };
  },
  mounted: function mounted() {},
  methods: {
    show: function show(data, finish) {
      var _this = this;

      this.dialogVisible = true;
      this.tableData = data;
      this.finish = finish;
      this.isWrong = 0;
      this.isNumberWrong = false;
      var wrongData = [];
      areaList({}).then(function _callee(res) {
        return regeneratorRuntime.async(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                _this.options = res;

              case 1:
              case "end":
                return _context.stop();
            }
          }
        });
      });

      for (var i in data) {
        var city = data[i].direct_city;
        var district = data[i].direct_district;

        if (!this.isRegionRight(city, district)) {
          wrongData.push(data[i]);
          this.isWrong = 1;
        }

        for (var j in data) {
          if (i == j) {
            continue;
          }

          var city1 = data[j].direct_city;
          var district1 = data[j].direct_district;

          if (city == city1 && district == district1) {
            //存在相同的
            wrongData.push(data[i]);
            this.isWrong = 2;
          }
        }
      }

      if (wrongData.length > 0) {
        this.tableData = wrongData;
      } else {
        this.tableData = data;
        var numbers = [];

        for (var _i in data) {
          numbers.push(data[_i].phone + '');
        }

        var params = {
          number: numbers
        }; // params.where ={number: {op: "in", value: numbers}}

        checkMembers(params).then(function _callee2(res) {
          var tmp, _j, _i2, item;

          return regeneratorRuntime.async(function _callee2$(_context2) {
            while (1) {
              switch (_context2.prev = _context2.next) {
                case 0:
                  console.log('checkMembers', res);

                  if (!(res.length > 0)) {
                    _context2.next = 21;
                    break;
                  }

                  tmp = [];
                  _context2.t0 = regeneratorRuntime.keys(res);

                case 4:
                  if ((_context2.t1 = _context2.t0()).done) {
                    _context2.next = 17;
                    break;
                  }

                  _j = _context2.t1.value;
                  _context2.t2 = regeneratorRuntime.keys(data);

                case 7:
                  if ((_context2.t3 = _context2.t2()).done) {
                    _context2.next = 15;
                    break;
                  }

                  _i2 = _context2.t3.value;
                  item = data[_i2];

                  if (!(item.phone == res[_j])) {
                    _context2.next = 13;
                    break;
                  }

                  tmp.push(item);
                  return _context2.abrupt("break", 15);

                case 13:
                  _context2.next = 7;
                  break;

                case 15:
                  _context2.next = 4;
                  break;

                case 17:
                  _this.isWrong = 2;
                  _this.tableData = tmp;
                  _context2.next = 22;
                  break;

                case 21:
                  _this.tableData = data;

                case 22:
                case "end":
                  return _context2.stop();
              }
            }
          });
        });
      }
    },
    isRegionRight: function isRegionRight(city, district) {
      var areaList = this.options;

      for (var i in areaList) {
        var item = areaList[i];

        for (var j in item.children) {
          var cityItem = item.children[j];

          if (cityItem.name != city) {
            continue;
          }

          for (var k in cityItem.children) {
            var areaItem = cityItem.children[k];

            if (areaItem.name != district) {
              continue;
            }

            return true;
          }
        }
      }

      return false;
    },
    clickSubmit: function clickSubmit() {
      var _this2 = this;

      var data = this.tableData;

      if (this.type === '0') {
        var tmp = this.$tempModel.deepCopy(this.tableData);

        for (var i in tmp) {
          tmp[i].number = tmp[i].phone;
          delete tmp[i].phone;
        }

        data = tmp;
      }

      var params = {
        data: data
      };
      memberMulUpdateRegion(params).then(function _callee3(res) {
        return regeneratorRuntime.async(function _callee3$(_context3) {
          while (1) {
            switch (_context3.prev = _context3.next) {
              case 0:
                _this2.$message({
                  message: '设置成功',
                  type: 'success'
                });

                _this2.dialogVisible = false;

                _this2.finish();

              case 3:
              case "end":
                return _context3.stop();
            }
          }
        });
      });
    }
  }
};