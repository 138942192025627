import "core-js/modules/es.object.to-string";
import "regenerator-runtime/runtime";
import setting from '@/setting';
export default {
  namespaced: true,
  state: {
    // 用户信息
    info: {}
  },
  actions: {
    /**
     * @description 设置用户数据
     * @param {Object} context
     * @param {*} info info
     */
    set: function set(_ref, info) {
      var state = _ref.state,
          dispatch = _ref.dispatch;
      return new Promise(function _callee(resolve) {
        return regeneratorRuntime.async(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                // store 赋值
                state.info = info; // 持久化

                _context.next = 3;
                return regeneratorRuntime.awrap(dispatch('d2admin/db/set', {
                  dbName: 'sys',
                  path: 'user.info.' + setting.proId,
                  value: info,
                  user: true
                }, {
                  root: true
                }));

              case 3:
                // end
                resolve();

              case 4:
              case "end":
                return _context.stop();
            }
          }
        });
      });
    },

    /**
     * @description 从数据库取用户数据
     * @param {Object} context
     */
    load: function load(_ref2) {
      var state = _ref2.state,
          dispatch = _ref2.dispatch;
      return new Promise(function _callee2(resolve) {
        return regeneratorRuntime.async(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                _context2.next = 2;
                return regeneratorRuntime.awrap(dispatch('d2admin/db/get', {
                  dbName: 'sys',
                  path: 'user.info.' + setting.proId,
                  defaultValue: {},
                  user: true
                }, {
                  root: true
                }));

              case 2:
                state.info = _context2.sent;
                // end
                resolve();

              case 4:
              case "end":
                return _context2.stop();
            }
          }
        });
      });
    }
  }
};