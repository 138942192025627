import "core-js/modules/es.array.last-index-of";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.replace";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { areaApplyUpdate, areaApplyAllList } from '@api/apis';
export default {
  data: function data() {
    return {
      dialogVisible: false,
      isXz: false,
      //是否乡镇
      oldTownName: '',
      row: null,
      form: {
        number: '',
        area: [],
        curArea: '',
        townName: ''
      },
      isShowInfo: true,
      props: {
        lazy: true,
        checkStrictly: true,
        filterable: true,
        lazyLoad: function lazyLoad(node, resolve) {
          var level = node.level;
          console.log('node', node);
          areaApplyAllList({
            parent: level === 0 ? 0 : node.data.value
          }).then(function _callee(res) {
            var ary, i, item;
            return regeneratorRuntime.async(function _callee$(_context) {
              while (1) {
                switch (_context.prev = _context.next) {
                  case 0:
                    ary = [];

                    for (i in res) {
                      item = res[i];
                      ary.push({
                        value: item.id,
                        label: item.name,
                        disabled: item.lock == 0 ? false : true,
                        leaf: level >= 2
                      });
                    }

                    resolve(ary);

                  case 3:
                  case "end":
                    return _context.stop();
                }
              }
            });
          });
        }
      }
    };
  },
  mounted: function mounted() {},
  methods: {
    show: function show(row, finish) {
      var _this = this;

      this.row = row;
      this.applyId = row.id;
      this.dialogVisible = true;
      this.finish = finish;
      this.isXz = row.area_type == 0;
      this.form = {
        number: row.user_number,
        area: '',
        curArea: row.area_name + '(' + row.area_full_name + ')',
        townName: row.town_name
      };
      this.oldTownName = row.town_name;
      this.isShowInfo = false;
      setTimeout(function () {
        _this.isShowInfo = true;
      }, 0.3);
    },
    clickSubmit: function clickSubmit() {
      var _this2 = this;

      console.log('this.form', this.form);

      if (this.form.number === '') {
        this.$message({
          message: '请输入会员编号',
          type: 'error'
        });
        return;
      }

      this.$g.fun.startLoading();
      var params = {};

      if (this.isXz) {
        var name = this.row.area_full_name.replace(this.oldTownName, this.form.townName); // 找到最后一个匹配子字符串的位置

        var lastIndex = this.row.area_full_name.lastIndexOf(this.oldTownName); // 使用 substring() 截取字符串的不同部分，并拼接成新的字符串

        var newString = this.row.area_full_name.substring(0, lastIndex) + this.form.townName;
        params = {
          id: this.applyId,
          town_name: this.form.townName,
          area_full_name: newString
        };
      } else {
        params = {
          id: this.applyId,
          area_id: this.form.area[this.form.area.length - 1]
        };
      }

      console.log(params);
      areaApplyUpdate(params).then(function _callee2(res) {
        return regeneratorRuntime.async(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                _this2.$message({
                  message: '设置成功',
                  type: 'success'
                });

                _this2.dialogVisible = false;

                if (_this2.finish) {
                  _this2.finish();
                }

                _this2.$g.fun.endLoading();

              case 4:
              case "end":
                return _context2.stop();
            }
          }
        });
      }).catch(function () {
        _this2.$g.fun.endLoading();
      });
    }
  }
};