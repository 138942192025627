import "core-js/modules/es.array.join";
import "core-js/modules/es.object.to-string";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { activeMulUpdate, gradeMulUpdate, levelMulUpdate } from '../../../../api/apis';
export default {
  data: function data() {
    return {
      dialogVisible: false,
      data: [{
        key: 0,
        value: '否'
      }, {
        key: 1,
        value: '是'
      }],
      form: {
        numbers: '',
        status: 0
      }
    };
  },
  mounted: function mounted() {},
  methods: {
    show: function show(numbers, finish) {
      this.dialogVisible = true;
      this.form.numbers = numbers.join(',');
      this.numberAry = numbers;
      this.finish = finish;
    },
    clickSubmit: function clickSubmit() {
      var _this = this;

      activeMulUpdate({
        number: this.numberAry,
        status: this.form.status
      }).then(function _callee(res) {
        return regeneratorRuntime.async(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                _this.$message({
                  message: '批量设置成功',
                  type: 'success'
                });

                _this.dialogVisible = false;

                _this.finish();

              case 3:
              case "end":
                return _context.stop();
            }
          }
        });
      });
    }
  }
};