import "core-js/modules/es.array.map";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.search";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tempTable from "@/components/rw/tempTable/index";
import tempSearch from "@/components/rw/tempSearch/index";
import tempForm from "@/components/rw/tempForm/index";
import tempChart from "@/components/rw/tempChart/index";
import areaHandle from '../memberAreaApply/areaHandle.vue';
import { areaAdd, areaList, areaListByPid, userAreaDel, memberArea, auditPassArea, auditRejectArea } from '../../../../api/apis';
export default {
  components: {
    tempTable: tempTable,
    tempSearch: tempSearch,
    tempForm: tempForm,
    tempChart: tempChart,
    areaHandle: areaHandle
  },
  data: function data() {
    var _this = this;

    return {
      currentRow: {},
      isShowRejectReasonDialog: false,
      reject_reason: '',
      region: [],
      options: [],
      tableModel_1000: {
        "border": true,
        "del": false,
        "headerButton": [// {
          //   "id": "",
          //   "tag": "default",
          //   "title": "添加/编辑会员区域",
          //   "type": "primary"
          // }
        ],
        "id": "id_1000",
        "isGroup": false,
        "isTree": false,
        'rowButton': [{
          'id': '',
          'tag': 'default',
          'title': '通过',
          'type': 'primary'
        }, {
          'id': '',
          'tag': 'default',
          'title': '拒绝',
          'type': 'danger'
        }, {
          'id': '',
          'tag': 'del',
          'title': '删除',
          'type': 'danger'
        }],
        "showPagination": true,
        "showSelection": false,
        "showSummary": false,
        "stripe": false,
        "tableAutocompleteProps": {},
        "tableCascaderProps": {},
        "tableFileProps": {},
        "tableModel": [{
          "label": "ID",
          "show": 1,
          "add": 1,
          "detail": 1,
          "excel": 1,
          "width": "80",
          "type": "text",
          "rowAddField": "",
          "groupId": "",
          "rules": [],
          "prop": "id"
        }, {
          "label": "会员编号",
          "show": 1,
          "add": 1,
          "detail": 1,
          "excel": 1,
          "width": "80",
          "type": "text",
          "rowAddField": "",
          "groupId": "",
          "rules": [],
          "prop": "user_number"
        }, {
          "label": "区域类型",
          "show": 1,
          "add": 1,
          "detail": 1,
          "excel": 1,
          "width": "100",
          "type": "select",
          "rowAddField": "",
          "groupId": "",
          "rules": [],
          "prop": "area_type"
        }, {
          "label": "区域名称",
          "show": 1,
          "add": 1,
          "detail": 1,
          "excel": 1,
          "width": "100",
          "type": "text",
          "rowAddField": "",
          "groupId": "",
          "rules": [],
          "prop": "area_name"
        }, {
          "label": "姓名",
          "show": 1,
          "add": 1,
          "detail": 1,
          "excel": 1,
          "width": "100",
          "type": "text",
          "rowAddField": "",
          "groupId": "",
          "rules": [],
          "prop": "user_name"
        }, {
          "label": "性别",
          "show": 1,
          "add": 1,
          "detail": 1,
          "excel": 1,
          "width": "100",
          "type": "select",
          "rowAddField": "",
          "groupId": "",
          "rules": [],
          "prop": "sex"
        }, {
          "label": "身份证号",
          "show": 1,
          "add": 1,
          "detail": 1,
          "excel": 1,
          "width": 160,
          "type": "text",
          "rowAddField": "",
          "groupId": "",
          "rules": [],
          "prop": "idcard"
        }, {
          "label": "身份证复印件",
          "show": 1,
          "add": 1,
          "detail": 1,
          "width": "300",
          "type": "image",
          "desc": "",
          "groupId": "",
          "rules": [],
          "prop": "idcard_pic"
        }, {
          "label": "营业执照",
          "show": 1,
          "add": 1,
          "detail": 1,
          "width": "300",
          "type": "image",
          "desc": "",
          "groupId": "",
          "rules": [],
          "prop": "business_pic"
        }, {
          "label": "开户银行账号",
          "show": 1,
          "add": 1,
          "detail": 1,
          "excel": 1,
          "width": 160,
          "type": "text",
          "rowAddField": "",
          "groupId": "",
          "rules": [],
          "prop": "bank_account_no"
        }, {
          "label": "银行名称",
          "show": 1,
          "add": 1,
          "detail": 1,
          "excel": 1,
          "width": 160,
          "type": "text",
          "rowAddField": "",
          "groupId": "",
          "rules": [],
          "prop": "bank_name"
        }, {
          "label": "提交时间",
          "show": 1,
          "add": 1,
          "detail": 1,
          "excel": 1,
          "width": 160,
          "type": "text",
          "rowAddField": "",
          "groupId": "",
          "rules": [],
          "prop": "created_at"
        }, {
          "label": "状态",
          "show": 1,
          "add": 1,
          "detail": 1,
          "excel": 1,
          "width": 160,
          "type": "select",
          "rowAddField": "",
          "groupId": "",
          "rules": [],
          "prop": "status"
        }],
        "tableName": "t_agent",
        "tableRadioProps": {},
        "tableSelectProps": {
          "area_type": {
            "multiple": 0,
            "data": [{
              "key": 1,
              "value": "县级"
            }, {
              "key": 2,
              "value": "市级"
            }, {
              "key": 3,
              "value": "省级"
            }],
            "remote": false,
            "id": 0,
            "relation": false,
            "relationProps": []
          },
          "sex": {
            "multiple": 0,
            "data": [{
              "key": 1,
              "value": "男"
            }, {
              "key": 2,
              "value": "女"
            }],
            "remote": false,
            "id": 0,
            "relation": false,
            "relationProps": []
          },
          "status": {
            "multiple": 0,
            "data": [{
              "key": 0,
              "value": "待审核"
            }, {
              "key": 1,
              "value": "审核通过"
            }, {
              "key": 2,
              "value": "审核不通过"
            }, {
              "key": 3,
              "value": "已过期"
            }],
            "remote": false,
            "id": 0,
            "relation": false,
            "relationProps": []
          }
        },
        "tableSwitchProps": {},
        "title": "区域",
        "type": "tableTemplate"
      },
      searchModel_1001: {
        "type": "searchTemplate",
        "id": "id_1001",
        "del": false,
        "searchData": [{
          "title": "按条件",
          "select": 0,
          "type": 2,
          "id": 0,
          "relation": false,
          "relationId": "",
          "remote": false,
          "remoteType": 0,
          "children": [{
            "type": "input-complex",
            "placeholder": "请输入",
            "field": [{
              "key": "number",
              "value": "",
              "desc": "会员编号"
            }, {
              "key": "area_name",
              "value": "",
              "desc": "区域名称"
            }, {
              "key": "user_name",
              "value": "",
              "desc": "姓名"
            }],
            "operator": ["gte", "lte", "gt", "lt", "eq", "ne", "like", "notLike", "in", "between"]
          }],
          "remoteFunc": []
        }, // {
        //   "title": "添加日期",
        //   "select": 0,
        //   "type": 1,
        //   "id": 1,
        //   "relation": false,
        //   "relationId": "",
        //   "remote": true,
        //   "remoteType": 1,
        //   "children": [
        //     {
        //       "type": "button",
        //       "title": "全部",
        //       "field": []
        //     },
        //     {
        //       "type": "button",
        //       "title": "今天",
        //       "field": []
        //     },
        //     {
        //       "type": "button",
        //       "title": "昨天",
        //       "field": []
        //     },
        //     {
        //       "type": "button",
        //       "title": "最近7天",
        //       "field": []
        //     },
        //     {
        //       "type": "button",
        //       "title": "本月",
        //       "field": []
        //     },
        //     {
        //       "type": "button",
        //       "title": "本年",
        //       "field": []
        //     },
        //     {
        //       "type": "custom-datetime-range",
        //       "title": "自定义",
        //       "field": []
        //     }
        //   ],
        //   "remoteFunc": [
        //     "modelConditionFunc_1"
        //   ]
        // },
        {
          "title": "区域类型",
          "select": 0,
          "type": 3,
          "id": 2,
          "relation": false,
          "relationId": "",
          "remote": false,
          "remoteType": 1,
          "children": [{
            "type": "select",
            "multiple": false,
            "field": [{
              "key": "area_type",
              "value": "1",
              "desc": "县级"
            }, {
              "key": "area_type",
              "value": "2",
              "desc": "市级"
            }, {
              "key": "area_type",
              "value": "3",
              "desc": "省级"
            }]
          }] // "remoteFunc": [
          //   "modelConditionFunc_2"
          // ]

        }],
        "operateData": [{
          "title": "搜索",
          "type": "primary",
          "tag": "search",
          "icon": "el-icon-search"
        }, {
          "title": "导出",
          "type": "primary",
          "tag": "export",
          "icon": "el-icon-position"
        }],
        "isCollapse": false
      },
      remoteFunc: {
        modelConditionFunc_2: function modelConditionFunc_2(tag, relationData, resolve) {
          //筛选条件数据
          console.log('31231131312');
          areaList({
            layer: 1
          }).then(function _callee(res) {
            var data, i;
            return regeneratorRuntime.async(function _callee$(_context) {
              while (1) {
                switch (_context.prev = _context.next) {
                  case 0:
                    data = [];

                    for (i in res) {
                      data.push({
                        key: 'province',
                        value: res[i].name,
                        desc: res[i].name
                      });
                    }

                    console.log('datatata', data);
                    resolve(tag, [data]);

                  case 4:
                  case "end":
                    return _context.stop();
                }
              }
            });
          });
        },
        modelConditionFunc_3: function modelConditionFunc_3(tag, relationData, resolve) {
          //筛选条件数据
          areaList({
            layer: 2
          }).then(function _callee2(res) {
            var data, i, j;
            return regeneratorRuntime.async(function _callee2$(_context2) {
              while (1) {
                switch (_context2.prev = _context2.next) {
                  case 0:
                    data = [];

                    for (i in res) {
                      for (j in res[i].children) {
                        data.push({
                          key: 'city',
                          value: res[i].children[j].name,
                          desc: res[i].children[j].name
                        });
                      }
                    }

                    resolve(tag, [data]);

                  case 3:
                  case "end":
                    return _context2.stop();
                }
              }
            });
          });
        },
        modelConditionFunc_4: function modelConditionFunc_4(tag, relationData, resolve) {
          //筛选条件数据
          areaList({
            layer: 3
          }).then(function _callee3(res) {
            var data, i, j, k, item;
            return regeneratorRuntime.async(function _callee3$(_context3) {
              while (1) {
                switch (_context3.prev = _context3.next) {
                  case 0:
                    data = [];

                    for (i in res) {
                      for (j in res[i].children) {
                        for (k in res[i].children[j].children) {
                          item = res[i].children[j].children[k];
                          data.push({
                            key: 'district',
                            value: item.name,
                            desc: item.name
                          });
                        }
                      }
                    }

                    resolve(tag, [data]);

                  case 3:
                  case "end":
                    return _context3.stop();
                }
              }
            });
          });
        }
      }
    };
  },
  mounted: function mounted() {
    this.table = this.$refs.ref_1000;
    this.search = this.$refs.ref_1001;
  },
  methods: {
    reqTableData: function reqTableData(params, resolve) {
      var where = {};

      if (this.search) {
        where = this.search.parseWhere();
        params.where = where;
      }

      memberArea(params).then(function _callee4(res) {
        var i, mark, j, item;
        return regeneratorRuntime.async(function _callee4$(_context4) {
          while (1) {
            switch (_context4.prev = _context4.next) {
              case 0:
                for (i in res.rows) {
                  mark = '';

                  for (j in res.rows[i].t_agent_products) {
                    item = res.rows[i].t_agent_products[j].t_product;

                    if (item) {
                      mark += "<div>" + item.code + item.store_name + "</div>";
                    }
                  }

                  res.rows[i].product = mark;
                }

                resolve({
                  total: res.count,
                  data: res.rows
                });

              case 2:
              case "end":
                return _context4.stop();
            }
          }
        });
      });
    },
    reqAdd: function reqAdd(isEdit, params, row, resolve) {
      if (isEdit) {
        params.id = row.id;
      } else {
        params.pid = this.curCity;
        params.layer = 2;
      }

      areaAdd(params).then(function _callee5(res) {
        return regeneratorRuntime.async(function _callee5$(_context5) {
          while (1) {
            switch (_context5.prev = _context5.next) {
              case 0:
                resolve();

              case 1:
              case "end":
                return _context5.stop();
            }
          }
        });
      });
    },
    reqDel: function reqDel(params, resolve) {
      var ids = params.map(function (item) {
        return item.id;
      });
      userAreaDel({
        id: ids
      }).then(function _callee6(res) {
        return regeneratorRuntime.async(function _callee6$(_context6) {
          while (1) {
            switch (_context6.prev = _context6.next) {
              case 0:
                resolve();

              case 1:
              case "end":
                return _context6.stop();
            }
          }
        });
      });
    },
    onClickLink: function onClickLink(prop, row) {//点击列表link类型回调
    },
    doReject: function doReject() {
      var _this2 = this;

      if (!this.reject_reason || this.reject_reason.length == 0) {
        this.$message.error("请输入拒绝原因");
        return;
      }

      auditRejectArea({
        id: this.currentRow.id,
        reject_reason: this.reject_reason
      }).then(function _callee7(res) {
        return regeneratorRuntime.async(function _callee7$(_context7) {
          while (1) {
            switch (_context7.prev = _context7.next) {
              case 0:
                _this2.currentRow = {};
                _this2.isShowRejectReasonDialog = false;

                _this2.$message({
                  message: '操作成功',
                  type: 'success'
                });

                _this2.table.reloadData();

              case 4:
              case "end":
                return _context7.stop();
            }
          }
        });
      });
    },
    onClickRowButton: function onClickRowButton(btnIndex, row) {
      var _this3 = this;

      //点击列表按钮回调
      if (btnIndex === 0) {
        console.log("row", row);
        auditPassArea({
          id: row.id
        }).then(function _callee8(res) {
          return regeneratorRuntime.async(function _callee8$(_context8) {
            while (1) {
              switch (_context8.prev = _context8.next) {
                case 0:
                  _this3.$message({
                    message: '操作成功',
                    type: 'success'
                  });

                  _this3.table.reloadData();

                case 2:
                case "end":
                  return _context8.stop();
              }
            }
          });
        });
      } else if (btnIndex === 1) {
        this.isShowRejectReasonDialog = true;
        this.currentRow = row;
      }
    },
    onClickHeaderButton: function onClickHeaderButton(btnIndex, row) {
      var _this4 = this;

      //点击列表头部按钮回调
      this.$refs['areaHandle'].show(function () {
        _this4.table.reloadData();
      });
    },
    onSummary: function onSummary(param) {//列表尾部合计回调
    },
    onSwitchChange: function onSwitchChange(val, prop, row) {//列表中switch变化事件
    },
    onClickSearchOperate: function onClickSearchOperate(btnIndex, btnData) {
      //条件菜单按钮点击事件
      if (btnData.tag === this.$tempModel.buttonTag.search) {
        this.table.reloadData();
      } else if (btnData.tag === this.$tempModel.buttonTag.export) {
        this.table.exportExcel();
      } else if (btnData.tag === this.$tempModel.buttonTag.import) {
        var header = btnData.header;
        var results = btnData.results;
      }
    },
    onConditionChange: function onConditionChange(tag, index) {
      this.table.reloadData();
    },
    onRowButtonShow: function onRowButtonShow(btnIndex, row) {
      if (btnIndex == 0 || btnIndex == 1) {
        if (row.status == 0) {
          return true;
        } else {
          return false;
        }
      }

      return true;
    }
  }
};