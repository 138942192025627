var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-image",
    {
      staticClass: "item",
      staticStyle: { width: "40px", height: "40px" },
      attrs: {
        src: _vm.model[_vm.prop],
        fit: "contain",
        "preview-src-list": [_vm.model[_vm.prop]]
      }
    },
    [
      _c(
        "div",
        { staticClass: "image-slot", attrs: { slot: "error" }, slot: "error" },
        [_c("i", { staticClass: "el-icon-picture-outline" })]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }