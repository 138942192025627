import "core-js/modules/es.array.map";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.search";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tempTable from '@/components/rw/tempTable/index';
import tempSearch from '@/components/rw/tempSearch/index';
import tempForm from '@/components/rw/tempForm/index';
import tempChart from '@/components/rw/tempChart/index';
import { productAdd, productBatchShow, productDel, productList, stockCheck } from '../../../../api/apis';
export default {
  components: {
    tempTable: tempTable,
    tempSearch: tempSearch,
    tempForm: tempForm,
    tempChart: tempChart
  },
  data: function data() {
    var _this = this;

    return {
      activeNames: 'search',
      searchModel_1000: {
        'del': false,
        'id': 'id_1000',
        'isCollapse': true,
        'operateData': [{
          'icon': 'el-icon-search',
          'tag': 'search',
          'title': '搜索',
          'type': 'primary'
        }, {
          'icon': 'el-icon-position',
          'tag': 'export',
          'title': '导出',
          'type': 'primary'
        }],
        'searchData': [{
          'children': [{
            'field': [{
              'desc': '产品名称',
              'key': 'store_name',
              'value': ''
            }, {
              'desc': '产品编码',
              'key': 'code',
              'value': ''
            }],
            'placeholder': '请输入',
            'type': 'input-complex'
          }, {
            'field': [{
              'desc': '库存',
              'key': 'stock'
            }],
            'placeholder': '请输入',
            'type': 'input-mul-complex'
          }],
          'id': 0,
          'relation': false,
          'relationId': '',
          'remote': false,
          'remoteType': 0,
          'select': 0,
          'title': '按条件',
          'type': 2,
          'remoteFunc': []
        }, {
          'children': [{
            'field': [],
            'title': '全部',
            'type': 'button'
          }, {
            'field': [{
              'key': 'is_show',
              'value': '1'
            }],
            'title': '已上架',
            'type': 'button'
          }, {
            'field': [{
              'key': 'is_show',
              'value': '0'
            }],
            'title': '未上架',
            'type': 'button'
          }],
          'id': 1,
          'relation': false,
          'relationId': '',
          'remote': false,
          'remoteType': 0,
          'select': 0,
          'title': '上架状态',
          'type': 0,
          'remoteFunc': []
        }, {
          'children': [{
            'field': [],
            'title': '全部',
            'type': 'button'
          }, {
            'field': [{
              'key': 'is_gift',
              'value': '1'
            }],
            'title': '赠品',
            'type': 'button'
          }, {
            'field': [{
              'key': 'is_gift',
              'value': '0'
            }],
            'title': '非赠品',
            'type': 'button'
          }],
          'id': 2,
          'relation': false,
          'relationId': '',
          'remote': false,
          'remoteType': 0,
          'select': 0,
          'title': '是否赠品',
          'type': 0,
          'remoteFunc': []
        }],
        'type': 'searchTemplate'
      },
      tableModel_1001: {
        'border': true,
        'del': false,
        'headerButton': [{
          'id': '',
          'tag': 'add',
          'title': '添加',
          'type': 'primary'
        }, {
          'id': '',
          'tag': 'default',
          'title': '批量上架',
          'type': 'danger'
        }, {
          'id': '',
          'tag': 'default',
          'title': '批量下架',
          'type': 'danger'
        }, {
          "tag": "default",
          "type": null,
          "title": "销售数校准",
          "id": "",
          "icon": ""
        }],
        'id': 'id_1001',
        'isGroup': true,
        'isTree': false,
        'rowButton': [{
          'id': '',
          'tag': 'edit',
          'title': '编辑',
          'type': 'primary'
        }, {
          'id': '',
          'tag': 'del',
          'title': '删除',
          'type': 'danger'
        }],
        'showPagination': true,
        'showSelection': true,
        'showSummary': false,
        'stripe': false,
        'tableAutocompleteProps': {},
        'tableCascaderProps': {},
        'tableFileProps': {},
        'tableGroupProps': [{
          'id': 6,
          'name': '基础信息'
        }, {
          'id': 7,
          'name': '商品图片'
        }, {
          'id': 17,
          'name': '详细说明'
        }],
        'tableModel': [{
          'add': 0,
          'detail': 1,
          'excel': 1,
          'groupId': '6',
          'label': '产品ID',
          'required': 0,
          'rowAddField': '',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '60',
          'prop': 'id'
        }, {
          'add': 1,
          'defaultValue': null,
          'desc': '建议尺寸640x320 不超过256kb',
          'detail': 1,
          'excel': 1,
          'groupId': '7',
          'label': '产品主图',
          'required': 1,
          'rowAddField': '',
          'rules': [],
          'show': 1,
          'type': 'image',
          'width': '100',
          'prop': 'image',
          'file': {
            'checkSize': 0,
            'exData': {
              'type': 'default'
            },
            'fileType': '.png,.jpg,.jpeg',
            'height': 320,
            'id': 9,
            'key': 'image',
            'maxNum': 1,
            'maxSize': 256,
            'width': 640
          }
        }, {
          'add': 1,
          'defaultValue': 1,
          'detail': 1,
          'excel': 1,
          'groupId': '6',
          'label': '是否销售',
          'required': 1,
          'rowAddField': '',
          'rules': [],
          'show': 1,
          'type': 'select',
          'width': '70',
          'prop': 'is_show'
        }, {
          'add': 1,
          'desc': '多个以逗号分割',
          'detail': 1,
          'excel': 1,
          'groupId': '6',
          'label': '产品编号',
          'required': 1,
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '100',
          'prop': 'code'
        }, {
          'add': 1,
          'detail': 1,
          'excel': 1,
          'groupId': '6',
          'label': '产品名称',
          'required': 1,
          'rowAddField': '',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '120',
          'prop': 'store_name'
        }, {
          'add': 1,
          'desc': '',
          'detail': 1,
          'excel': 1,
          'groupId': '6',
          'label': '产品规格',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '100',
          'prop': 'unit_name'
        }, {
          'add': 1,
          'defaultValue': 0,
          'detail': 1,
          'excel': 1,
          'groupId': '6',
          'label': '产品价格',
          'required': 1,
          'rowAddField': '',
          'rules': [{
            'name': 'money'
          }],
          'show': 1,
          'type': 'text',
          'width': '80',
          'prop': 'price'
        }, {
          'add': 0,
          'desc': '',
          'detail': 1,
          'excel': 1,
          'groupId': '6',
          'label': '产品PV',
          'required': 0,
          'rules': [],
          'show': 0,
          'type': 'text',
          'width': null,
          'prop': 'pv'
        }, {
          'add': 1,
          'defaultValue': 0,
          'detail': 1,
          'excel': 1,
          'groupId': '6',
          'label': '产品销量',
          'required': 1,
          'rowAddField': '',
          'rules': [{
            'name': 'integer'
          }],
          'show': 1,
          'type': 'text',
          'width': '70',
          'prop': 'sales'
        }, {
          'add': 1,
          'defaultValue': 999999,
          'desc': '',
          'detail': 1,
          'excel': 1,
          'groupId': '6',
          'label': '产品库存',
          'required': 1,
          'rowAddField': '',
          'rules': [{
            'name': 'integer'
          }],
          'show': 1,
          'type': 'text',
          'width': '80',
          'prop': 'stock'
        }, {
          'add': 1,
          'desc': '最多64个字符',
          'detail': 1,
          'excel': 1,
          'groupId': '6',
          'label': '产品简介',
          'required': 0,
          'rowAddField': '',
          'rules': [],
          'show': 1,
          'type': 'textarea',
          'width': '100',
          'prop': 'store_info'
        }, {
          'add': 1,
          'desc': '',
          'detail': 1,
          'excel': 1,
          'groupId': '6',
          'label': '产品简称',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '100',
          'prop': 'shrink_name'
        }, {
          'add': 1,
          'defaultValue': 0,
          'desc': '',
          'detail': 1,
          'excel': 1,
          'groupId': '6',
          'label': '是否赠品',
          'required': 1,
          'rules': [],
          'show': 1,
          'type': 'select',
          'width': '80',
          'prop': 'is_gift'
        }, {
          'add': 1,
          'defaultValue': 1,
          'desc': '',
          'detail': 1,
          'groupId': '6',
          'label': '库存扣减',
          'required': 1,
          'rules': [],
          'show': 1,
          'type': 'select',
          'width': '80',
          'prop': 'is_stock'
        }, {
          'add': 1,
          'defaultValue': 0,
          'detail': 1,
          'excel': 1,
          'groupId': '6',
          'label': '排序',
          'required': 0,
          'rowAddField': '',
          'rules': [{
            'name': 'integer'
          }],
          'show': 1,
          'type': 'text',
          'width': 80,
          'prop': 'sort'
        }, {
          'add': 1,
          'desc': '建议尺寸640x320 不超过256kb 最多上传9个',
          'detail': 1,
          'excel': 1,
          'groupId': '17',
          'label': '产品描述',
          'required': 0,
          'rowAddField': '',
          'rules': [],
          'show': 1,
          'type': 'images',
          'width': '120',
          'prop': 'description',
          'file': {
            'checkSize': 0,
            'exData': {
              'type': 'default'
            },
            'fileType': '.png,.jpg,.jpeg',
            'height': 320,
            'id': 11,
            'key': 'description',
            'maxNum': 9,
            'maxSize': 256,
            'width': 640
          }
        }, {
          'add': 0,
          'detail': 1,
          'excel': 1,
          'groupId': '6',
          'label': '创建时间',
          'required': 0,
          'rowAddField': '',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': '150',
          'prop': 'add_time'
        }],
        'tableName': 't_product',
        'tableRadioProps': {},
        'tableSelectProps': {
          'is_show': {
            'data': [{
              'key': 0,
              'value': '否'
            }, {
              'key': 1,
              'value': '是'
            }],
            'id': 12,
            'multiple': 0,
            'relation': false,
            'relationProps': [],
            'remote': false
          },
          'product_type': {
            'data': [{
              'key': 1,
              'value': '注册'
            }, {
              'key': 2,
              'value': '重购'
            }, {
              'key': 3,
              'value': '升级'
            }],
            'id': 13,
            'multiple': 1,
            'relation': false,
            'relationProps': [],
            'remote': false
          },
          'is_gift': {
            'data': [{
              'key': 0,
              'value': '否'
            }, {
              'key': 1,
              'value': '是'
            }],
            'id': 19,
            'multiple': 0,
            'relation': false,
            'relationProps': [],
            'remote': false
          },
          'is_stock': {
            'data': [{
              'key': 0,
              'value': '否'
            }, {
              'key': 1,
              'value': '是'
            }],
            'id': 20,
            'multiple': 0,
            'relation': false,
            'relationProps': [],
            'remote': false
          }
        },
        'tableSwitchProps': {},
        'title': '产品',
        'type': 'tableTemplate'
      },
      remoteFunc: {}
    };
  },
  mounted: function mounted() {
    this.table = this.$refs.ref_1001;
    this.search = this.$refs.ref_1000;
  },
  methods: {
    reqTableData: function reqTableData(params, resolve) {
      var where = {};

      if (this.search) {
        where = this.search.parseWhere();
      }

      params.where = where;
      params.where.is_main = 0;
      productList(params).then(function _callee(res) {
        return regeneratorRuntime.async(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                resolve({
                  total: res.count,
                  data: res.rows
                });

              case 1:
              case "end":
                return _context.stop();
            }
          }
        });
      });
    },
    reqAdd: function reqAdd(isEdit, params, row, resolve) {
      if (isEdit) {
        params.id = row.id;
        params.is_main = 0;
      } else {
        params.is_main = 0;
      }

      productAdd(params).then(function _callee2(res) {
        return regeneratorRuntime.async(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                resolve();

              case 1:
              case "end":
                return _context2.stop();
            }
          }
        });
      });
    },
    reqDel: function reqDel(params, resolve) {
      var ids = params.map(function (item) {
        return item.id;
      });
      productDel({
        id: ids
      }).then(function _callee3(res) {
        return regeneratorRuntime.async(function _callee3$(_context3) {
          while (1) {
            switch (_context3.prev = _context3.next) {
              case 0:
                resolve();

              case 1:
              case "end":
                return _context3.stop();
            }
          }
        });
      });
    },
    onClickLink: function onClickLink(prop, row) {//点击列表link类型回调
    },
    onClickRowButton: function onClickRowButton(btnIndex, row) {//点击列表按钮回调
    },
    onClickHeaderButton: function onClickHeaderButton(btnIndex, row) {
      var _this2 = this;

      //点击列表头部按钮回调
      if (btnIndex === 1 || btnIndex === 2 || btnIndex === 3) {
        if (row.length <= 0) {
          this.$message({
            message: '请至少选择一个商品',
            type: 'warning'
          });
          return;
        }

        var ids = row.map(function (item) {
          return item.id;
        });

        if (btnIndex === 3) {
          stockCheck({
            id: ids
          }).then(function _callee4(res) {
            return regeneratorRuntime.async(function _callee4$(_context4) {
              while (1) {
                switch (_context4.prev = _context4.next) {
                  case 0:
                    _this2.$message({
                      message: '校准成功',
                      type: 'success'
                    });

                    _this2.table.reloadData();

                  case 2:
                  case "end":
                    return _context4.stop();
                }
              }
            });
          });
        } else {
          productBatchShow({
            id: ids,
            is_show: btnIndex === 1 ? 1 : 0
          }).then(function _callee5(res) {
            return regeneratorRuntime.async(function _callee5$(_context5) {
              while (1) {
                switch (_context5.prev = _context5.next) {
                  case 0:
                    _this2.$message({
                      message: '操作成功',
                      type: 'success'
                    });

                    _this2.table.reloadData();

                  case 2:
                  case "end":
                    return _context5.stop();
                }
              }
            });
          });
        }
      }
    },
    onSummary: function onSummary(param) {//列表尾部合计回调
    },
    onSwitchChange: function onSwitchChange(val, prop, row) {//列表中switch变化事件
    },
    onClickSearchOperate: function onClickSearchOperate(btnIndex, btnData) {
      //条件菜单按钮点击事件
      if (btnData.tag === this.$tempModel.buttonTag.search) {
        this.table.reloadData();
      } else if (btnData.tag === this.$tempModel.buttonTag.export) {
        this.table.exportExcel();
      } else if (btnData.tag === this.$tempModel.buttonTag.import) {
        var header = btnData.header;
        var results = btnData.results;
      }
    },
    onConditionChange: function onConditionChange(tag, index) {
      this.table.reloadData();
    }
  }
};