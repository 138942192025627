//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  data: function data() {
    return {
      dialogVisible: false,
      form: {},
      data: []
    };
  },
  mounted: function mounted() {},
  methods: {
    show: function show(data, finish) {
      var _this = this;

      this.data = data;
      this.dialogVisible = true;
      this.finish = finish;
      this.$nextTick(function () {
        _this.reloadForm();
      });
    },
    reloadForm: function reloadForm() {
      var tmp = {};

      for (var i in this.data) {
        tmp[this.data[i].key] = this.data[i].defaultValue;
      }

      this.form = tmp;
    },
    clickSubmit: function clickSubmit() {
      this.dialogVisible = false;

      if (this.finish) {
        this.finish(this.form);
      }
    }
  }
};