var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "el-card",
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c("span", [_vm._v("支付配置")])
          ]),
          _c("el-form", {
            ref: "form",
            attrs: { size: "mini", model: _vm.config }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }