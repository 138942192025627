import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.parse-int";
import "core-js/modules/es.string.search";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tempTable from "@/components/rw/tempTable/index";
import tempSearch from "@/components/rw/tempSearch/index";
import tempForm from "@/components/rw/tempForm/index";
import tempChart from "@/components/rw/tempChart/index";
import setting from '@/setting';
import { configGradeList, expectNumList, staticAllTotal, staticGrade, staticIncome, staticMemberAdd, staticPgv, staticProduct, staticTotal } from '../../../../api/apis';
export default {
  components: {
    tempTable: tempTable,
    tempSearch: tempSearch,
    tempForm: tempForm,
    tempChart: tempChart
  },
  data: function data() {
    var _this = this;

    return {
      expectList: [],
      curExpectNum: -1,
      title: setting.projectName,
      numberList: [{
        color: '#ff5c87',
        icon: 'el-icon-coin',
        value: '0',
        unit: '￥',
        title: '新增业绩'
      }, {
        color: '#759aff',
        icon: 'el-icon-pie-chart',
        value: '0',
        unit: '￥',
        title: '发放奖金'
      }, {
        color: '#f59821',
        icon: 'el-icon-user',
        value: '0',
        title: '新增会员'
      }, {
        color: '#55cb75',
        icon: 'el-icon-discount',
        value: '0',
        title: '新增订单'
      }],
      numberListTotal: [{
        color: '#ff5c87',
        icon: 'el-icon-coin',
        value: '0',
        unit: '￥',
        title: '累计业绩'
      }, {
        color: '#759aff',
        icon: 'el-icon-pie-chart',
        value: '0',
        unit: '￥',
        title: '累计发放奖金'
      }, {
        color: '#f59821',
        icon: 'el-icon-user',
        value: '0',
        title: '总会员数'
      }, {
        color: '#55cb75',
        icon: 'el-icon-discount',
        value: '0',
        title: '累计订单'
      }],
      staTotal: {
        curr_pov: 0,
        new_member: 0,
        order_count: 0,
        order_price: 0,
        order_type_sum: [0, 0, 0]
      },
      chartModel_1000: {
        "data": {
          "columns": ["dt", "count", "sum", "member"],
          "rows": [{
            "dt": "x0",
            "count": 592,
            "sum": 421,
            "member": 234
          }, {
            "dt": "x1",
            "count": 377,
            "sum": 840,
            "member": 805
          }, {
            "dt": "x2",
            "count": 229,
            "sum": 905,
            "member": 430
          }]
        },
        "grid": {
          "backgroundColor": "transparent",
          "borderColor": "#ccc",
          "borderWidth": 1,
          "bottom": 60,
          "height": "auto",
          "left": "10%",
          "right": "10%",
          "shadowBlur": 0,
          "shadowColor": "rgba(0, 0, 0, 0)",
          "shadowOffsetX": 0,
          "shadowOffsetY": 0,
          "show": false,
          "tooltip": {
            "show": true
          },
          "top": 60,
          "width": "auto"
        },
        "id": "id_1000",
        "remoteFunc": "chartModel_1000_data",
        "settings": {
          "area": true,
          "digit": 2,
          "itemStyle": {
            "borderColor": "#000",
            "borderType": "solid",
            "borderWidth": 0,
            "shadowBlur": 0,
            "shadowColor": "rgba(0, 0, 0, 0)",
            "shadowOffsetX": 0,
            "shadowOffsetY": 0
          },
          "labelMap": {
            "count": "订单总数",
            "sum": "销售金额",
            "member": "新增会员"
          },
          "showLine": [],
          "type": "line",
          "xAxisType": "category",
          "yAxisType": "normal"
        },
        "type": "chartTemplate",
        "remoteFuncName": "chartModel_1000_data"
      },
      chartModel_1001: {
        "data": {
          "columns": ["x", "y1"],
          "rows": [{
            "x": "x0",
            "y1": 124
          }, {
            "x": "x1",
            "y1": 898
          }, {
            "x": "x2",
            "y1": 419
          }]
        },
        "grid": {
          "backgroundColor": "transparent",
          "borderColor": "#ccc",
          "borderWidth": 1,
          "bottom": 60,
          "height": "auto",
          "left": "10%",
          "right": "10%",
          "shadowBlur": 0,
          "shadowColor": "rgba(0, 0, 0, 0)",
          "shadowOffsetX": 0,
          "shadowOffsetY": 0,
          "show": false,
          "tooltip": {
            "show": true
          },
          "top": 60,
          "width": "auto"
        },
        "id": "id_1001",
        "remoteFunc": "chartModel_1001_data",
        "settings": {
          "area": true,
          "digit": 2,
          "itemStyle": {
            "borderColor": "#000",
            "borderType": "solid",
            "borderWidth": 0,
            "shadowBlur": 0,
            "shadowColor": "rgba(0, 0, 0, 0)",
            "shadowOffsetX": 0,
            "shadowOffsetY": 0
          },
          "labelMap": {},
          "showLine": [],
          "type": "ring",
          "xAxisType": "category",
          "yAxisType": "normal"
        },
        "type": "chartTemplate",
        "remoteFuncName": "chartModel_1001_data"
      },
      tableModel_1002: {
        "border": true,
        "del": false,
        "headerButton": [],
        "id": "id_1002",
        "isGroup": false,
        "isTree": false,
        "rowButton": [],
        "showPagination": false,
        "showSelection": false,
        "showSummary": false,
        "stripe": false,
        "tableCascaderProps": {},
        "tableFileProps": {},
        "tableModel": [{
          "add": 1,
          "desc": "",
          "detail": 1,
          "groupId": "",
          "label": "NO.",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "index"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "groupId": "",
          "label": "产品名称",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "product_name"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "groupId": "",
          "label": "销售个数",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "quantity"
        }],
        "tableName": "",
        "tableRadioProps": {},
        "tableSelectProps": {},
        "tableSwitchProps": {},
        "title": "商品销售排行",
        "type": "tableTemplate"
      },
      tableModel_1003: {
        "border": true,
        "del": false,
        "headerButton": [],
        "id": "id_1003",
        "isGroup": false,
        "isTree": false,
        "rowButton": [],
        "showPagination": false,
        "showSelection": false,
        "showSummary": false,
        "stripe": false,
        "tableCascaderProps": {},
        "tableFileProps": {},
        "tableModel": [{
          "add": 1,
          "desc": "",
          "detail": 1,
          "groupId": "",
          "label": "NO.",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "index"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "groupId": "",
          "label": "会员编号",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "number"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "groupId": "",
          "label": "姓名",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "t_user.real_name"
        }, {
          "label": "等级",
          "show": 1,
          "add": 1,
          "detail": 1,
          "width": null,
          "type": "select",
          "desc": "",
          "groupId": "",
          "rules": [],
          "prop": "grade"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "groupId": "",
          "label": "金额",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "curr_pgv"
        }],
        "tableName": "",
        "tableRadioProps": {},
        "tableSelectProps": {
          "grade": {
            "multiple": 0,
            "data": [{
              "key": null,
              "value": ""
            }],
            "remote": true,
            "id": 0,
            "relation": false,
            "relationProps": [],
            "remoteFunc": "tableFunc_select_grade"
          }
        },
        "tableSwitchProps": {},
        "title": "小组销售业绩排行",
        "type": "tableTemplate"
      },
      tableModel_1004: {
        "border": true,
        "del": false,
        "headerButton": [],
        "id": "id_1004",
        "isGroup": false,
        "isTree": false,
        "rowButton": [],
        "showPagination": false,
        "showSelection": false,
        "showSummary": false,
        "stripe": false,
        "tableCascaderProps": {},
        "tableFileProps": {},
        "tableModel": [{
          "add": 1,
          "desc": "",
          "detail": 1,
          "groupId": "",
          "label": "NO.",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "index"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "groupId": "",
          "label": "等级名称",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "name"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "groupId": "",
          "label": "等级人数",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "count"
        }],
        "tableName": "",
        "tableRadioProps": {},
        "tableSelectProps": {
          "grade": {
            "data": [{
              "key": null,
              "value": ""
            }],
            "id": 0,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": true,
            "remoteFunc": "tableFunc_select_grade"
          }
        },
        "tableSwitchProps": {},
        "title": "各等级人数",
        "type": "tableTemplate"
      },
      remoteFunc: {
        chartModel_1000_data: function chartModel_1000_data(resolve) {
          //图表数据，第一列为x轴数据
          staticIncome({}).then(function _callee2(res) {
            var data;
            return regeneratorRuntime.async(function _callee2$(_context2) {
              while (1) {
                switch (_context2.prev = _context2.next) {
                  case 0:
                    data = res;
                    staticMemberAdd({}).then(function _callee(res) {
                      var i;
                      return regeneratorRuntime.async(function _callee$(_context) {
                        while (1) {
                          switch (_context.prev = _context.next) {
                            case 0:
                              for (i in res) {
                                data[i].member = res[i].count;
                              }

                              resolve(['dt', 'count', 'member', 'sum'], data);

                            case 2:
                            case "end":
                              return _context.stop();
                          }
                        }
                      });
                    });

                  case 2:
                  case "end":
                    return _context2.stop();
                }
              }
            });
          });
        },
        chartModel_1001_data: function chartModel_1001_data(resolve) {
          //图表数据，第一列为x轴数据
          //[
          //  { '日期': '1/1', '访问用户': 1393, '下单用户': 1093, '下单率': 0.32 },
          //]
          var data = [];
          data.push({
            x: '注册订单',
            y1: _this.staTotal.order_type_sum[1]
          }, {
            x: '重购订单',
            y1: _this.staTotal.order_type_sum[2]
          }, {
            x: '升级订单',
            y1: _this.staTotal.order_type_sum[3]
          });
          resolve(['x', 'y1'], data);
        },
        tableFunc_select_grade: function tableFunc_select_grade(tag, relationData, resolve) {
          //筛选项模型数组
          configGradeList({}).then(function _callee3(res) {
            var data, i, item;
            return regeneratorRuntime.async(function _callee3$(_context3) {
              while (1) {
                switch (_context3.prev = _context3.next) {
                  case 0:
                    data = [];

                    for (i in res.rows) {
                      item = res.rows[i];
                      data.push({
                        key: item.tag,
                        value: item.name
                      });
                    }

                    _this.gradeList = data;
                    resolve(tag, data);

                  case 4:
                  case "end":
                    return _context3.stop();
                }
              }
            });
          });
        }
      }
    };
  },
  created: function created() {},
  mounted: function mounted() {
    var _this2 = this;

    this.reqAll();
    staticAllTotal({}).then(function _callee4(res) {
      return regeneratorRuntime.async(function _callee4$(_context4) {
        while (1) {
          switch (_context4.prev = _context4.next) {
            case 0:
              _this2.numberListTotal[0].value = res.total_pv;
              _this2.numberListTotal[1].value = res.send_bonus;
              _this2.numberListTotal[2].value = res.number;
              _this2.numberListTotal[3].value = res.order;

            case 4:
            case "end":
              return _context4.stop();
          }
        }
      });
    });
  },
  beforeDestroy: function beforeDestroy() {},
  methods: {
    changeExpectNum: function changeExpectNum(value) {
      this.curExpectNum = value;
      this.reqStaticTotal();
    },
    reqAll: function reqAll() {
      var _this3 = this;

      expectNumList({
        page: 1,
        limit: this.$g.maxExpectNum
      }).then(function _callee5(res) {
        var data, i, item;
        return regeneratorRuntime.async(function _callee5$(_context5) {
          while (1) {
            switch (_context5.prev = _context5.next) {
              case 0:
                data = [];

                for (i = 0; i < res.length; i++) {
                  item = res[i];

                  if (i > 0) {
                    data.push({
                      key: item.expect_num,
                      value: '第' + item.expect_num + '期(' + item.add_time.substring(0, 10) + ')'
                    });
                  }
                }

                _this3.expectList = data;
                _this3.curExpectNum = _this3.expectList[0].key;

                _this3.reqStaticTotal();

              case 5:
              case "end":
                return _context5.stop();
            }
          }
        });
      });
    },
    reqStaticTotal: function reqStaticTotal() {
      var _this4 = this;

      staticTotal({
        expect_num: this.curExpectNum
      }).then(function _callee6(res) {
        return regeneratorRuntime.async(function _callee6$(_context6) {
          while (1) {
            switch (_context6.prev = _context6.next) {
              case 0:
                _this4.staTotal = res;
                _this4.numberList[0].value = res.total_pv;
                _this4.numberList[1].value = _this4.staTotal.curr_all_bonus;
                _this4.numberList[2].value = _this4.staTotal.new_member;
                _this4.numberList[3].value = _this4.staTotal.order_count;

                _this4.$refs['ref_1001'].reloadData();

              case 6:
              case "end":
                return _context6.stop();
            }
          }
        });
      });
      this.$refs['ref_1002'].reloadData();
      this.$refs['ref_1003'].reloadData();
      this.$refs['ref_1004'].reloadData();
    },
    reqTableData1: function reqTableData1(params, resolve) {
      if (!this.curExpectNum) {
        resolve({
          total: 0,
          data: []
        });
        return;
      }

      var where = {};

      if (this.search) {
        where = this.search.parseWhere();
        params.where = where;
      } // staticGrade({expect_num:this.curExpectNum}).then(async res=>{
      //   for (let i in res){
      //     if (res[i].grade == null){
      //       res[i].grade = -1
      //     }
      //     res[i].index = parseInt(i)+1
      //   }
      //   resolve({total:0,data:res})
      // })

    },
    reqTableData2: function reqTableData2(params, resolve) {
      if (!this.curExpectNum) {
        resolve({
          total: 0,
          data: []
        });
        return;
      }

      var where = {};

      if (this.search) {
        where = this.search.parseWhere();
        params.where = where;
      } // staticPgv({expect_num:this.curExpectNum}).then(async res=>{
      //   for (let i in res){
      //     res[i].index = parseInt(i)+1
      //   }
      //   resolve({total:0,data:res})
      // })

    },
    reqTableData3: function reqTableData3(params, resolve) {
      if (!this.curExpectNum) {
        resolve({
          total: 0,
          data: []
        });
        return;
      }

      var where = {};

      if (this.search) {
        where = this.search.parseWhere();
        params.where = where;
      }

      staticProduct({
        expect_num: this.curExpectNum
      }).then(function _callee7(res) {
        var i;
        return regeneratorRuntime.async(function _callee7$(_context7) {
          while (1) {
            switch (_context7.prev = _context7.next) {
              case 0:
                for (i in res) {
                  res[i].index = parseInt(i) + 1;
                }

                resolve({
                  total: 0,
                  data: res
                });

              case 2:
              case "end":
                return _context7.stop();
            }
          }
        });
      });
    }
  }
};