import "core-js/modules/es.array.splice";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import areaConditionItemCell from './areaConditionItemCell';
export default {
  components: {
    areaConditionItemCell: areaConditionItemCell
  },
  props: ['data', 'conditionList', 'parent', 'index', 'gradeList', 'levelList', 'period', 'type'],
  name: 'areaConditionGroupCell',
  methods: {
    isGroup: function isGroup(item) {
      if (item.hasOwnProperty('op')) {
        return true;
      }

      return false;
    },
    clickDelGroup: function clickDelGroup() {
      var ary = this.$tempModel.deepCopy(this.parent.data);
      ary.splice(this.index, 1);
      this.parent.data = ary;
    },
    clickAddCondition: function clickAddCondition() {
      var cond = this.$tempModel.deepCopy(this.conditionList[0]);
      this.data.data.push(cond);
    },
    onClickAddGroup: function onClickAddGroup(index, item) {
      var cond = this.$tempModel.deepCopy(this.conditionList[0]);
      var group = {
        op: 'and',
        data: [item, cond]
      };
      var ary = this.$tempModel.deepCopy(this.data.data);
      ary[index] = group;
      this.data.data = ary;
    },
    onClickDelItem: function onClickDelItem(index, item) {
      var ary = this.$tempModel.deepCopy(this.data.data);
      ary.splice(index, 1);
      this.data.data = ary;
    },
    onClickResetParams: function onClickResetParams(index, item) {
      var ary = this.$tempModel.deepCopy(this.data.data);
      var tmp = ary[index];

      for (var i in tmp.params) {
        tmp.params[i].value = '';
      }

      this.data.data = ary;
    },
    onCondChange: function onCondChange(index, toId) {
      var cond = this.$tempModel.deepCopy(this.condById(toId));
      var tmp = this.$tempModel.deepCopy(this.data.data);
      tmp[index] = cond;
      this.data.data = tmp;
    },
    condById: function condById(id) {
      for (var i in this.conditionList) {
        var item = this.conditionList[i];

        if (item.id == id) {
          return item;
        }
      }

      return [];
    }
  }
};