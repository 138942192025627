var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "el-card",
        [
          _c(
            "el-collapse",
            {
              model: {
                value: _vm.activeNames,
                callback: function($$v) {
                  _vm.activeNames = $$v
                },
                expression: "activeNames"
              }
            },
            [
              _c(
                "el-collapse-item",
                { attrs: { name: "search" } },
                [
                  _c("template", { slot: "title" }, [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-size": "16px",
                          "font-weight": "normal"
                        }
                      },
                      [_vm._v(_vm._s("搜索条件"))]
                    )
                  ]),
                  _c(
                    "temp-search",
                    _vm._b(
                      {
                        ref: "ref_1000",
                        attrs: {
                          "remote-func": _vm.remoteFunc,
                          "on-click-operate": _vm.onClickSearchOperate,
                          "on-condition-change": _vm.onConditionChange
                        }
                      },
                      "temp-search",
                      _vm.searchModel_1000,
                      false
                    ),
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "flex-direction": "row",
                            width: "100%",
                            "flex-wrap": "wrap"
                          },
                          attrs: { slot: "content_ex" },
                          slot: "content_ex"
                        },
                        _vm._l(_vm.productAttr, function(item) {
                          return _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-top": "15px",
                                width: "220px",
                                "padding-right": "20px"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    width: "200px",
                                    "font-size": "14px"
                                  }
                                },
                                [_c("span", [_vm._v(_vm._s(item.title))])]
                              ),
                              _c(
                                "div",
                                { staticStyle: { width: "200px" } },
                                [
                                  item.type == "input"
                                    ? [
                                        _c("el-input", {
                                          staticStyle: { width: "200px" },
                                          attrs: { size: "mini" },
                                          model: {
                                            value: item.value,
                                            callback: function($$v) {
                                              _vm.$set(item, "value", $$v)
                                            },
                                            expression: "item.value"
                                          }
                                        })
                                      ]
                                    : _vm._e(),
                                  item.type == "choose"
                                    ? [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "200px" },
                                            attrs: {
                                              size: "mini",
                                              placeholder: "请选择"
                                            },
                                            model: {
                                              value: item.value,
                                              callback: function($$v) {
                                                _vm.$set(item, "value", $$v)
                                              },
                                              expression: "item.value"
                                            }
                                          },
                                          _vm._l(item.values, function(item) {
                                            return _c("el-option", {
                                              key: item.desc,
                                              attrs: {
                                                label: item.desc,
                                                value: item.desc
                                              }
                                            })
                                          }),
                                          1
                                        )
                                      ]
                                    : _vm._e()
                                ],
                                2
                              )
                            ]
                          )
                        }),
                        0
                      )
                    ]
                  )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-card",
        { staticStyle: { "margin-top": "20px" } },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c("span", [_vm._v(_vm._s("订单列表"))])
          ]),
          _c(
            "temp-table",
            _vm._b(
              {
                key: _vm.componentKey,
                ref: "ref_1001",
                attrs: {
                  "remote-func": _vm.remoteFunc,
                  "req-table-data": _vm.reqTableData,
                  "req-add": _vm.reqAdd,
                  "req-del": _vm.reqDel,
                  "on-click-link": _vm.onClickLink,
                  "on-click-row-button": _vm.onClickRowButton,
                  "on-click-header-button": _vm.onClickHeaderButton,
                  "on-summary": _vm.onSummary,
                  "on-switch-change": _vm.onSwitchChange
                }
              },
              "temp-table",
              _vm.tableModel_1001,
              false
            )
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }