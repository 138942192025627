var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "el-tabs",
        { attrs: { type: "border-card" } },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "注册显示项" } },
            [_c("reg-config")],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "主页显示项" } },
            [_c("home-config")],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "个人中心显示项" } },
            [_c("personal-config")],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }