var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-card",
        { staticStyle: { "margin-top": "20px" } },
        [
          _c(
            "temp-table",
            _vm._b(
              {
                ref: "ref_1000",
                attrs: {
                  "remote-func": _vm.remoteFunc,
                  "req-table-data": _vm.reqTableData,
                  "req-add": _vm.reqAdd,
                  "req-del": _vm.reqDel,
                  "on-click-link": _vm.onClickLink,
                  "on-click-row-button": _vm.onClickRowButton,
                  "on-click-header-button": _vm.onClickHeaderButton,
                  "on-summary": _vm.onSummary,
                  "on-switch-change": _vm.onSwitchChange,
                  "on-row-button-show": _vm.onRowButtonShow
                }
              },
              "temp-table",
              _vm.tableModel_1000,
              false
            )
          )
        ],
        1
      ),
      _c("grade-cond", {
        key: _vm.reloadTimer1,
        ref: "cond",
        attrs: {
          "grade-list": _vm.config.gradeData,
          "level-list": _vm.config.levelData
        }
      }),
      _c("exa-cond", {
        key: _vm.reloadTimer2,
        ref: "exa",
        attrs: {
          "grade-list": _vm.config.gradeData,
          "level-list": _vm.config.levelData
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }