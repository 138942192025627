import "core-js/modules/es.array.splice";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    config: {
      type: Object
    }
  },
  data: function data() {
    return {
      mulRate: 0,
      dialogVisible: false
    };
  },
  mounted: function mounted() {},
  methods: {
    clickDelete: function clickDelete(index, row) {
      var tmp = this.$tempModel.deepCopy(this.config.baseData.manageTax);
      tmp.splice(index, 1);
      this.config.baseData.manageTax = tmp;
    },
    clickAddCond: function clickAddCond() {
      this.config.baseData.manageTax.push({
        bonus: '',
        rate: 0
      });
    },
    clickAddAllCond: function clickAddAllCond() {
      for (var i = 0; i < this.config.bonusData.length; i++) {
        var item = this.config.bonusData[i];
        this.config.baseData.manageTax.push({
          bonus: item.tag,
          rate: 0
        });
      }
    },
    clickMulRate: function clickMulRate() {
      var tmp = this.$tempModel.deepCopy(this.config.baseData.manageTax);

      for (var i in tmp) {
        var item = tmp[i];
        item.rate = this.mulRate;
      }

      this.config.baseData.manageTax = tmp;
    }
  }
};