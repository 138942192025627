var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-rate", {
    attrs: { colors: _vm.colors },
    model: {
      value: _vm.v,
      callback: function($$v) {
        _vm.v = $$v
      },
      expression: "v"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }