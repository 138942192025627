import "core-js/modules/es.object.to-string";
import _toConsumableArray from "/Users/suifeng/Desktop/projects/2022/sdSdm/sdSdmAdmin/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import "regenerator-runtime/runtime";
import client from 'webpack-theme-color-replacer/client';
import forElementUI from 'webpack-theme-color-replacer/forElementUI';
export default {
  namespaced: true,
  state: {
    // 颜色
    value: process.env.VUE_APP_ELEMENT_COLOR
  },
  actions: {
    /**
     * @description 设置颜色
     * @param {Object} context
     * @param {String} color 尺寸
     */
    set: function set(_ref, color) {
      var state = _ref.state,
          dispatch = _ref.dispatch,
          commit = _ref.commit;
      return new Promise(function _callee(resolve) {
        var old;
        return regeneratorRuntime.async(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                // 记录上个值
                old = state.value; // store 赋值

                state.value = color || process.env.VUE_APP_ELEMENT_COLOR; // 持久化

                _context.next = 4;
                return regeneratorRuntime.awrap(dispatch('d2admin/db/set', {
                  dbName: 'sys',
                  path: 'color.value',
                  value: state.value,
                  user: true
                }, {
                  root: true
                }));

              case 4:
                // 应用
                commit('apply', {
                  oldColor: old,
                  newColor: state.value
                }); // end

                resolve();

              case 6:
              case "end":
                return _context.stop();
            }
          }
        });
      });
    },

    /**
     * @description 从持久化数据读取颜色设置
     * @param {Object} context
     */
    load: function load(_ref2) {
      var state = _ref2.state,
          dispatch = _ref2.dispatch,
          commit = _ref2.commit;
      return new Promise(function _callee2(resolve) {
        var old;
        return regeneratorRuntime.async(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                // 记录上个值
                old = state.value; // store 赋值

                _context2.next = 3;
                return regeneratorRuntime.awrap(dispatch('d2admin/db/get', {
                  dbName: 'sys',
                  path: 'color.value',
                  defaultValue: process.env.VUE_APP_ELEMENT_COLOR,
                  user: true
                }, {
                  root: true
                }));

              case 3:
                state.value = _context2.sent;
                // 应用
                commit('apply', {
                  oldColor: old,
                  newColor: state.value
                }); // end

                resolve();

              case 6:
              case "end":
                return _context2.stop();
            }
          }
        });
      });
    }
  },
  mutations: {
    /**
     * @description 将 vuex 中的主题颜色设置应用到系统中
     * @param {Object} context
     * @param {Object} payload oldColor {String} 旧的颜色
     * @param {Object} payload newColor {String} 新颜色
     */
    apply: function apply(state, _ref3) {
      var oldColor = _ref3.oldColor,
          newColor = _ref3.newColor;
      var options = {
        oldColors: _toConsumableArray(forElementUI.getElementUISeries(oldColor)),
        newColors: _toConsumableArray(forElementUI.getElementUISeries(newColor))
      };
      client.changer.changeColor(options);
    }
  }
};