import "core-js/modules/es.object.to-string";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { memberShopFitHandle, areaApplyList, userList } from '../../../../api/apis';
export default {
  data: function data() {
    return {
      dialogVisible: false,
      desc1: '',
      desc2: '',
      form: {
        shop_no: '',
        user_number: '',
        bili: ''
      },
      rules: {
        shop_no: [{
          require: true,
          message: '请输入店号',
          trigger: 'blur'
        }],
        user_number: [{
          require: true,
          message: '请输入会员编号',
          trigger: 'blur'
        }],
        bili: [{
          require: true,
          validator: this.$tempModel.rules.money.validator,
          trigger: 'blur'
        }]
      }
    };
  },
  mounted: function mounted() {},
  methods: {
    show: function show(finish) {
      this.dialogVisible = true;
      this.finish = finish;
    },
    clickSubmit: function clickSubmit() {
      var _this = this;

      if (this.form.shop_no === "" || this.form.user_number === "" || this.form.bili === "") {
        this.$alert('请输入店号、会员编号、分配霹雳', '提示', {
          confirmButtonText: '确定'
        });
        return;
      }

      this.$g.fun.startLoading();
      memberShopFitHandle(this.form).then(function _callee(res) {
        return regeneratorRuntime.async(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                _this.$g.fun.endLoading();

                _this.finish();

                _this.$alert('添加成功', '提示', {
                  confirmButtonText: '确定'
                });

              case 3:
              case "end":
                return _context.stop();
            }
          }
        });
      }).finally(function () {
        _this.$g.fun.endLoading();
      });
    },
    onInputShopNo: function onInputShopNo() {
      var _this2 = this;

      if (this.form.shop_no.length === 8) {
        areaApplyList({
          page: 1,
          limit: 1,
          where: {
            shop_no: this.form.shop_no
          }
        }).then(function _callee2(res) {
          return regeneratorRuntime.async(function _callee2$(_context2) {
            while (1) {
              switch (_context2.prev = _context2.next) {
                case 0:
                  if (res.rows.length > 0) {
                    _this2.desc1 = res.rows[0].user_number + " " + res.rows[0].user_name;
                  }

                case 1:
                case "end":
                  return _context2.stop();
              }
            }
          });
        });
      } else {
        this.desc1 = "";
      }
    },
    onInputUserNumber: function onInputUserNumber() {
      var _this3 = this;

      if (this.form.user_number.length === 9) {
        userList({
          page: 1,
          limit: 1,
          where: {
            number: this.form.user_number
          }
        }).then(function _callee3(res) {
          return regeneratorRuntime.async(function _callee3$(_context3) {
            while (1) {
              switch (_context3.prev = _context3.next) {
                case 0:
                  if (res.rows.length > 0) {
                    _this3.desc2 = res.rows[0].real_name;
                  }

                case 1:
                case "end":
                  return _context3.stop();
              }
            }
          });
        });
      } else {
        this.desc2 = "";
      }
    }
  }
};