//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import goodsValue from '@/views/pages/goods/goodsAttribute/goodsValue.vue';
export default {
  components: {
    goodsValue: goodsValue
  },
  data: function data() {
    return {
      dialogVisible: false,
      goodsId: ''
    };
  },
  mounted: function mounted() {},
  methods: {
    show: function show(row, finish) {
      var _this = this;

      this.dialogVisible = true;
      this.finish = finish;
      this.goodsId = row.id;
      this.$nextTick(function () {
        _this.refs.value.reloadData();
      });
    },
    clickSubmit: function clickSubmit() {
      this.finish();
    }
  }
};