import "core-js/modules/es.array.map";
import "core-js/modules/es.string.search";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tempTable from "@/components/rw/tempTable/index";
import tempSearch from "@/components/rw/tempSearch/index";
import tempForm from "@/components/rw/tempForm/index";
import tempChart from "@/components/rw/tempChart/index";
import orderRefundConfirm from './orderRefundConfirm';
export default {
  components: {
    tempTable: tempTable,
    tempSearch: tempSearch,
    tempForm: tempForm,
    tempChart: tempChart,
    orderRefundConfirm: orderRefundConfirm
  },
  data: function data() {
    var _this = this;

    return {
      searchModel_1000: {
        "type": "searchTemplate",
        "id": "id_1000",
        "del": false,
        "searchData": [{
          "children": [{
            "field": [{
              "key": "",
              "value": ""
            }],
            "title": "全部",
            "type": "button"
          }, {
            "field": [{
              "key": "",
              "value": ""
            }],
            "title": "申请退款",
            "type": "button"
          }, {
            "field": [{
              "key": "",
              "value": ""
            }],
            "title": "退款中",
            "type": "button"
          }, {
            "field": [{
              "key": "",
              "value": ""
            }],
            "title": "退款完成",
            "total": 0,
            "type": "button"
          }],
          "id": 1,
          "relation": false,
          "relationId": "",
          "remote": false,
          "remoteType": 0,
          "select": 0,
          "title": "订单状态",
          "type": 0,
          "remoteFunc": []
        }, {
          "children": [{
            "field": [],
            "title": "全部",
            "type": "button"
          }, {
            "field": [],
            "title": "今天",
            "type": "button"
          }, {
            "field": [],
            "title": "昨天",
            "type": "button"
          }, {
            "field": [],
            "title": "最近7天",
            "type": "button"
          }, {
            "field": [],
            "title": "本月",
            "type": "button"
          }, {
            "field": [],
            "title": "本年",
            "type": "button"
          }, {
            "field": [],
            "title": "自定义",
            "type": "custom-datetime-range"
          }],
          "id": 3,
          "relation": false,
          "relationId": "",
          "remote": true,
          "remoteType": 1,
          "select": 0,
          "title": "订单日期",
          "type": 1,
          "remoteFunc": ["modelConditionFunc_3"]
        }, {
          "children": [{
            "field": [{
              "desc": "订单号",
              "key": "order_id",
              "value": ""
            }, {
              "desc": "会员编号",
              "key": "number",
              "value": ""
            }, {
              "desc": "顶点编号",
              "key": "direct",
              "value": ""
            }, {
              "desc": "收货人姓名",
              "key": "real_name",
              "value": ""
            }, {
              "desc": "收货人电话",
              "key": "user_phone",
              "value": ""
            }],
            "placeholder": "请输入",
            "type": "input-complex"
          }, {
            "field": [{
              "desc": "订单金额",
              "key": "total_price"
            }, {
              "desc": "产品数量",
              "key": "total_num",
              "value": ""
            }],
            "placeholder": "请输入",
            "type": "input-mul-complex"
          }],
          "id": 2,
          "relation": false,
          "relationId": "",
          "remote": false,
          "remoteType": 0,
          "select": 0,
          "title": "订单号",
          "type": 2,
          "remoteFunc": []
        }],
        "operateData": [{
          "icon": "el-icon-search",
          "tag": "search",
          "title": "搜索",
          "type": "primary"
        }, {
          "icon": "el-icon-position",
          "tag": "export",
          "title": "导出",
          "type": "primary"
        }]
      },
      tableModel_1001: {
        "type": "tableTemplate",
        "id": "id_1001",
        "del": false,
        "tableModel": [{
          "add": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "订单号",
          "required": 0,
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "150",
          "prop": "order_id"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "会员编号",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "number"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "收货人姓名",
          "required": 0,
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "100",
          "prop": "real_name"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "收货人电话",
          "required": 0,
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "100",
          "prop": "user_phone"
        }, {
          "add": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "订单总价",
          "required": 0,
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "total_price"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "订单类型",
          "rules": [],
          "show": 1,
          "type": "select",
          "width": "100",
          "prop": "order_type"
        }, {
          "add": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "订单状态",
          "required": 0,
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "select",
          "width": "100",
          "prop": "status"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "快递信息",
          "rules": [],
          "show": 1,
          "type": "textarea",
          "width": "300",
          "prop": "delivery_info"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "商品信息",
          "rules": [],
          "show": 1,
          "type": "textarea",
          "width": "200",
          "prop": "product_info"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "商品个数",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "total_num"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "省",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "address_province"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "市",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "address_city"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "区",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "address_district"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "收货地址",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "300",
          "prop": "address_detail"
        }, {
          "add": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "支付时间",
          "required": 0,
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "150",
          "prop": "pay_time"
        }, {
          "add": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "创建时间",
          "required": 0,
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "150",
          "prop": "add_time"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "备注",
          "required": 0,
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "120",
          "prop": "remark"
        }],
        "tableSwitchProps": {},
        "tableSelectProps": {
          "status": {
            "data": [{
              "key": -1,
              "value": "申请退款"
            }, {
              "key": -2,
              "value": "退货成功"
            }, {
              "key": 0,
              "value": "待发货"
            }, {
              "key": 1,
              "value": "待收货"
            }, {
              "key": 2,
              "value": "完成"
            }, {
              "key": 3,
              "value": "待评价"
            }],
            "id": 1,
            "multiple": 0,
            "relation": false,
            "relationId": "",
            "remote": false
          },
          "order_type": {
            "data": [{
              "key": 1,
              "value": "注册单"
            }, {
              "key": 2,
              "value": "重购单"
            }, {
              "key": 3,
              "value": "升级单"
            }],
            "id": 2,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": false
          }
        },
        "tableCascaderProps": {},
        "tableFileProps": {},
        "rowButton": [{
          "tag": "default",
          "type": "success",
          "title": "同意退款",
          "id": ""
        }],
        "stripe": false,
        "border": true,
        "showPagination": true,
        "showSelection": false,
        "showSummary": false,
        "title": "订单",
        "tableName": "t_order",
        "isTree": false,
        "headerButton": [],
        "tableRadioProps": {}
      },
      remoteFunc: {
        modelConditionFunc_3: function modelConditionFunc_3(tag, relationData, resolve) {
          //筛选条件数据
          //[[{key:"type",value:"1"}]]
          var data = [];
          data = [[{
            key: "add_time",
            value: []
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 0, true)
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 1, true)
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 7, true)
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 30, true)
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 365, true)
          }], [{
            key: "add_time",
            value: []
          }]];
          resolve(tag, data);
        }
      }
    };
  },
  mounted: function mounted() {
    this.table = this.$refs.ref_1001;
    this.search = this.$refs.ref_1000;
  },
  methods: {
    reqTableData: function reqTableData(params, resolve) {
      var where = {};

      if (this.search) {
        where = this.search.parseWhere();
        params.where = where;
      }

      resolve({
        total: 0,
        data: []
      });
    },
    reqAdd: function reqAdd(isEdit, params, row, resolve) {//if (isEdit){
      //  params.id = row.id
      //}
      //xxxAdd(params).then(async res=>{
      //  resolve()
      //})
    },
    reqDel: function reqDel(params, resolve) {
      var ids = params.map(function (item) {
        return item.id;
      }); //xxxDel({id:ids}).then(async res=>{
      //  resolve()
      //})
    },
    onRowButtonShow: function onRowButtonShow(btnIndex, row) {
      if (btnIndex === 0 || btnIndex === 1) {
        if (row.status === -1 && row.refund_status === 0) {
          return true;
        }
      }

      return false;
    },
    onClickLink: function onClickLink(prop, row) {//点击列表link类型回调
    },
    onClickRowButton: function onClickRowButton(btnIndex, row) {
      //点击列表按钮回调
      this.$refs['orderRefund'].show(true, row, function () {});
    },
    onClickHeaderButton: function onClickHeaderButton(btnIndex, row) {//点击列表头部按钮回调
    },
    onSummary: function onSummary(param) {//列表尾部合计回调
    },
    onSwitchChange: function onSwitchChange(val, prop, row) {//列表中switch变化事件
    },
    onClickSearchOperate: function onClickSearchOperate(btnIndex, btnData) {
      //条件菜单按钮点击事件
      if (btnData.tag === this.$tempModel.buttonTag.search) {
        this.table.reloadData();
      } else if (btnData.tag === this.$tempModel.buttonTag.export) {
        this.table.exportExcel();
      } else if (btnData.tag === this.$tempModel.buttonTag.import) {
        var header = btnData.header;
        var results = btnData.results;
      }
    },
    onConditionChange: function onConditionChange(tag, index) {
      this.table.reloadData();
    }
  }
};