import "core-js/modules/es.array.map";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.search";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tempTable from "@/components/rw/tempTable/index";
import tempSearch from "@/components/rw/tempSearch/index";
import tempForm from "@/components/rw/tempForm/index";
import tempChart from "@/components/rw/tempChart/index";
import { trainPreset, trainPresetHandle, trainPresetDel, trainPresetImport, trainConfig, expressImport } from '@/api/apis';
import activeSelect from '@/views/pages/active/train/trainPreset/activeSelect.vue';
export default {
  components: {
    tempTable: tempTable,
    tempSearch: tempSearch,
    tempForm: tempForm,
    tempChart: tempChart,
    activeSelect: activeSelect
  },
  data: function data() {
    var _this = this;

    return {
      searchModel_1000: {
        "del": false,
        "id": "id_1000",
        "isCollapse": false,
        "operateData": [{
          "icon": "el-icon-search",
          "tag": "search",
          "title": "搜索",
          "type": "primary"
        }, {
          "icon": "el-icon-position",
          "tag": "export",
          "title": "导出",
          "type": "primary"
        }, {
          "tag": "import",
          "type": "success",
          "title": "导入会员",
          "id": "",
          "icon": ""
        }],
        "searchData": [{
          "children": [{
            "field": [{
              "desc": "类型一",
              "key": "type",
              "value": "0"
            }, {
              "desc": "类型二",
              "key": "type",
              "value": "1"
            }],
            "multiple": false,
            "type": "select"
          }],
          "id": 1,
          "relation": false,
          "relationId": "",
          "remote": true,
          "remoteType": 1,
          "select": 0,
          "title": "培训批次",
          "type": 3,
          "remoteFunc": ["modelConditionFunc_1"]
        }, {
          "children": [{
            "field": [{
              "desc": "会员编号",
              "key": "user_number",
              "value": ""
            }],
            "operator": ["gte", "lte", "gt", "lt", "eq", "ne", "like", "notLike", "in", "between"],
            "placeholder": "请输入",
            "type": "input-complex"
          }],
          "id": 2,
          "relation": false,
          "relationId": "",
          "remote": false,
          "remoteType": 0,
          "select": 0,
          "title": "按条件",
          "type": 2,
          "remoteFunc": []
        }, {
          "children": [{
            "field": [],
            "title": "全部",
            "type": "button"
          }, {
            "field": [],
            "title": "今天",
            "type": "button"
          }, {
            "field": [],
            "title": "昨天",
            "type": "button"
          }, {
            "field": [],
            "title": "最近7天",
            "type": "button"
          }, {
            "field": [],
            "title": "本月",
            "type": "button"
          }, {
            "field": [],
            "title": "本年",
            "type": "button"
          }, {
            "field": [],
            "title": "自定义",
            "type": "custom-datetime-range"
          }],
          "id": 3,
          "relation": false,
          "relationId": "",
          "remote": true,
          "remoteType": 1,
          "select": 0,
          "title": "报名日期",
          "type": 1,
          "remoteFunc": ["modelConditionFunc_3"]
        }],
        "type": "searchTemplate"
      },
      tableModel_1001: {
        "border": true,
        "del": false,
        "headerButton": [{
          "id": "",
          "tag": "add",
          "title": "添加",
          "type": "primary"
        }],
        "id": "id_1001",
        "isGroup": false,
        "isTree": false,
        "rowButton": [{
          "id": "",
          "tag": "del",
          "title": "删除",
          "type": "danger"
        }],
        "showPagination": true,
        "showSelection": false,
        "showSummary": false,
        "stripe": true,
        "tableAutocompleteProps": {},
        "tableCascaderProps": {},
        "tableFileProps": {},
        "tableModel": [{
          "add": 0,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "ID",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "id"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "培训批次",
          "required": 1,
          "rules": [],
          "show": 1,
          "type": "select",
          "width": null,
          "prop": "activity_id"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "会员编号",
          "required": 1,
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "user_number"
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "添加日期",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "add_time"
        }],
        "tableName": "",
        "tableRadioProps": {},
        "tableSelectProps": {
          "activity_id": {
            "multiple": 0,
            "data": [{
              "key": null,
              "value": ""
            }],
            "remote": true,
            "id": 0,
            "relation": false,
            "relationProps": [],
            "remoteFunc": "tableFunc_select_activity_id"
          }
        },
        "tableSwitchProps": {},
        "title": "参会名单",
        "type": "tableTemplate"
      },
      remoteFunc: {
        modelConditionFunc_1: function modelConditionFunc_1(tag, relationData, resolve) {
          //筛选条件数据
          trainConfig({
            page: 1,
            limit: 10000
          }).then(function _callee(res) {
            var data, i, item;
            return regeneratorRuntime.async(function _callee$(_context) {
              while (1) {
                switch (_context.prev = _context.next) {
                  case 0:
                    data = [];
                    data.push({
                      key: 'activity_id',
                      value: '',
                      desc: '全部'
                    });

                    for (i in res.rows) {
                      item = res.rows[i];
                      data.push({
                        key: 'activity_id',
                        value: item.id,
                        desc: '(' + item.id + ')' + item.activity_name
                      });
                    }

                    resolve(tag, [data]);

                  case 4:
                  case "end":
                    return _context.stop();
                }
              }
            });
          });
        },
        modelConditionFunc_3: function modelConditionFunc_3(tag, relationData, resolve) {
          //筛选条件数据
          //[[{key:"type",value:"1"}]]
          var data = [];
          data = [[{
            key: "add_time",
            value: []
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 0, true)
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 1, true)
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 7, true)
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 30, true)
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 365, true)
          }], [{
            key: "add_time",
            value: []
          }]];
          resolve(tag, data);
        },
        tableFunc_select_activity_id: function tableFunc_select_activity_id(tag, relationData, resolve) {
          //筛选项模型数组
          trainConfig({
            page: 1,
            limit: 100000
          }).then(function _callee2(res) {
            var data, i, item;
            return regeneratorRuntime.async(function _callee2$(_context2) {
              while (1) {
                switch (_context2.prev = _context2.next) {
                  case 0:
                    data = [];

                    for (i in res.rows) {
                      item = res.rows[i];
                      data.push({
                        key: item.id,
                        value: '(' + item.id + ')' + item.activity_name
                      });
                    }

                    resolve(tag, data);

                  case 3:
                  case "end":
                    return _context2.stop();
                }
              }
            });
          });
        }
      }
    };
  },
  mounted: function mounted() {
    this.table = this.$refs.ref_1001;
    this.search = this.$refs.ref_1000;
  },
  methods: {
    reqTableData: function reqTableData(params, resolve) {
      var where = {};

      if (this.search) {
        where = this.search.parseWhere();
        params.where = where;
      }

      trainPreset(params).then(function _callee3(res) {
        return regeneratorRuntime.async(function _callee3$(_context3) {
          while (1) {
            switch (_context3.prev = _context3.next) {
              case 0:
                resolve({
                  total: res.count,
                  data: res.rows
                });

              case 1:
              case "end":
                return _context3.stop();
            }
          }
        });
      });
    },
    reqAdd: function reqAdd(isEdit, params, row, resolve) {
      if (isEdit) {
        params.id = row.id;
      }

      trainPresetHandle(params).then(function _callee4(res) {
        return regeneratorRuntime.async(function _callee4$(_context4) {
          while (1) {
            switch (_context4.prev = _context4.next) {
              case 0:
                resolve();

              case 1:
              case "end":
                return _context4.stop();
            }
          }
        });
      });
    },
    reqDel: function reqDel(params, resolve) {
      var ids = params.map(function (item) {
        return item.id;
      });
      trainPresetDel({
        id: ids
      }).then(function _callee5(res) {
        return regeneratorRuntime.async(function _callee5$(_context5) {
          while (1) {
            switch (_context5.prev = _context5.next) {
              case 0:
                resolve();

              case 1:
              case "end":
                return _context5.stop();
            }
          }
        });
      });
    },
    onClickLink: function onClickLink(prop, row) {//点击列表link类型回调
    },
    onClickRowButton: function onClickRowButton(btnIndex, row) {//点击列表按钮回调
    },
    onClickHeaderButton: function onClickHeaderButton(btnIndex, row) {//点击列表头部按钮回调
    },
    onSummary: function onSummary(param) {//列表尾部合计回调
    },
    onSwitchChange: function onSwitchChange(val, prop, row) {//列表中switch变化事件
    },
    onClickSearchOperate: function onClickSearchOperate(btnIndex, btnData) {
      var _this2 = this;

      //条件菜单按钮点击事件
      if (btnData.tag === this.$tempModel.buttonTag.search) {
        this.table.reloadData();
      } else if (btnData.tag === this.$tempModel.buttonTag.export) {
        this.table.exportExcel();
      } else if (btnData.tag === this.$tempModel.buttonTag.import) {
        var header = btnData.header;
        var results = btnData.results;
        this.$refs.select.show(function (activeId) {
          var params = {};
          var data = [];

          try {
            for (var i in results) {
              var item = results[i];

              if (!item.hasOwnProperty('会员编号')) {
                _this2.$alert('表格格式错误，请却表列中含有"会员编号"', '提示', {
                  confirmButtonText: '确定'
                });

                return;
              }

              data.push({
                user_number: item['会员编号']
              });
            }

            params.activity_id = activeId;
            params.data = data;
            trainPresetImport(params).then(function _callee6(res) {
              return regeneratorRuntime.async(function _callee6$(_context6) {
                while (1) {
                  switch (_context6.prev = _context6.next) {
                    case 0:
                      _this2.table.reloadData();

                      _this2.$alert('导入成功', '提示', {
                        confirmButtonText: '确定'
                      });

                    case 2:
                    case "end":
                      return _context6.stop();
                  }
                }
              });
            });
          } catch (err) {
            _this2.$alert('表格格式错误，请却表列中含有"会员编号"', '提示', {
              confirmButtonText: '确定'
            });
          }
        });
      }
    },
    onConditionChange: function onConditionChange(tag, index) {
      this.table.reloadData();
    },
    onRowButtonShow: function onRowButtonShow(btnIndex, row) {
      return true;
    }
  }
};