import "core-js/modules/es.array.map";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.search";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tempTable from "@/components/rw/tempTable/index";
import tempSearch from "@/components/rw/tempSearch/index";
import tempForm from "@/components/rw/tempForm/index";
import tempChart from "@/components/rw/tempChart/index";
import { memberInfoApplyAdd, memberInfoApplyList } from '../../../../api/apis';
export default {
  name: 'memberManage',
  components: {
    tempTable: tempTable,
    tempSearch: tempSearch,
    tempForm: tempForm,
    tempChart: tempChart
  },
  data: function data() {
    var _this = this;

    return {
      activeNames: 'search',
      searchModel_1000: {
        "type": "searchTemplate",
        "id": "id_1000",
        "del": false,
        "searchData": [{
          "title": "审核状态",
          "select": 0,
          "type": 0,
          "id": 0,
          "relation": false,
          "relationId": "",
          "remote": false,
          "remoteType": 0,
          "children": [{
            "type": "button",
            "title": "全部",
            "field": []
          }, {
            "type": "button",
            "title": "未处理",
            "field": [{
              "key": "status",
              "value": "0"
            }]
          }, {
            "type": "button",
            "title": "已同意",
            "field": [{
              "key": "status",
              "value": "1"
            }]
          }, {
            "type": "button",
            "title": "已拒绝",
            "total": 0,
            "field": [{
              "key": "status",
              "value": "2"
            }]
          }],
          "remoteFunc": []
        }, {
          "title": "申请日期",
          "select": 0,
          "type": 1,
          "id": 1,
          "relation": false,
          "relationId": "",
          "remote": true,
          "remoteType": 1,
          "children": [{
            "type": "button",
            "title": "全部",
            "field": []
          }, {
            "type": "button",
            "title": "今天",
            "field": []
          }, {
            "type": "button",
            "title": "昨天",
            "field": []
          }, {
            "type": "button",
            "title": "最近7天",
            "field": []
          }, {
            "type": "button",
            "title": "本月",
            "field": []
          }, {
            "type": "button",
            "title": "本年",
            "field": []
          }, {
            "type": "custom-datetime-range",
            "title": "自定义",
            "field": []
          }],
          "remoteFunc": ["modelConditionFunc_1"]
        }, {
          "title": "审核日期",
          "select": 0,
          "type": 1,
          "id": 2,
          "relation": false,
          "relationId": "",
          "remote": true,
          "remoteType": 1,
          "children": [{
            "type": "button",
            "title": "全部",
            "field": []
          }, {
            "type": "button",
            "title": "今天",
            "field": []
          }, {
            "type": "button",
            "title": "昨天",
            "field": []
          }, {
            "type": "button",
            "title": "最近7天",
            "field": []
          }, {
            "type": "button",
            "title": "本月",
            "field": []
          }, {
            "type": "button",
            "title": "本年",
            "field": []
          }, {
            "type": "custom-datetime-range",
            "title": "自定义",
            "field": []
          }],
          "remoteFunc": ["modelConditionFunc_2"]
        }, {
          "title": "按条件",
          "select": 0,
          "type": 2,
          "id": 3,
          "relation": false,
          "relationId": "",
          "remote": false,
          "remoteType": 0,
          "children": [{
            "type": "input-complex",
            "placeholder": "请输入",
            "field": [{
              "key": "number",
              "value": "",
              "desc": "会员编号"
            }, {
              "key": "op_name",
              "value": "",
              "desc": "操作员姓名"
            }, {
              "key": "op",
              "value": "",
              "desc": "操作员ID"
            }],
            "operator": ["gte", "lte", "gt", "lt", "eq", "ne", "like", "notLike", "in", "between"]
          }],
          "remoteFunc": []
        }],
        "operateData": [{
          "title": "搜索",
          "type": "primary",
          "tag": "search",
          "icon": "el-icon-search"
        }, {
          "title": "导出",
          "type": "primary",
          "tag": "export",
          "icon": "el-icon-position"
        }],
        "isCollapse": true
      },
      tableModel_1001: {
        "type": "tableTemplate",
        "id": "id_1001",
        "del": false,
        "tableModel": [{
          "label": "ID",
          "show": 1,
          "add": 1,
          "detail": 1,
          "excel": 1,
          "width": "80",
          "type": "text",
          "rowAddField": "",
          "groupId": "",
          "rules": [],
          "prop": "id"
        }, {
          "label": "状态",
          "show": 1,
          "add": 1,
          "detail": 1,
          "excel": 1,
          "width": "80",
          "type": "select",
          "rowAddField": "",
          "groupId": "",
          "rules": [],
          "prop": "status"
        }, {
          "label": "会员编号",
          "show": 1,
          "add": 1,
          "detail": 1,
          "excel": 1,
          "width": "100",
          "type": "text",
          "rowAddField": "",
          "groupId": "",
          "rules": [],
          "prop": "number"
        }, {
          "label": "姓名",
          "show": 1,
          "add": 1,
          "detail": 1,
          "excel": 1,
          "width": "80",
          "type": "text",
          "rowAddField": "",
          "groupId": "",
          "rules": [],
          "prop": "real_name"
        }, {
          "label": "电话",
          "show": 1,
          "add": 1,
          "detail": 1,
          "excel": 1,
          "width": "100",
          "type": "text",
          "rowAddField": "",
          "groupId": "",
          "rules": [],
          "prop": "phone"
        }, {
          "label": "申请时间",
          "show": 1,
          "add": 1,
          "detail": 1,
          "excel": 1,
          "width": "150",
          "type": "text",
          "rowAddField": "",
          "groupId": "",
          "rules": [],
          "prop": "add_time"
        }, {
          "label": "审核时间",
          "show": 1,
          "add": 1,
          "detail": 1,
          "excel": 1,
          "width": "150",
          "type": "text",
          "rowAddField": "",
          "groupId": "",
          "rules": [],
          "prop": "handle_time"
        }, {
          "label": "操作员ID",
          "show": 1,
          "add": 1,
          "detail": 1,
          "excel": 1,
          "width": 0,
          "type": "text",
          "rowAddField": "",
          "groupId": "",
          "rules": [],
          "prop": "op"
        }, {
          "label": "操作员姓名",
          "show": 1,
          "add": 1,
          "detail": 1,
          "excel": 1,
          "width": 0,
          "type": "text",
          "rowAddField": "",
          "groupId": "",
          "rules": [],
          "prop": "op_name"
        }],
        "tableSwitchProps": {},
        "tableSelectProps": {
          "status": {
            "multiple": 0,
            "data": [{
              "key": 0,
              "value": "未处理"
            }, {
              "key": 1,
              "value": "同意"
            }, {
              "key": 2,
              "value": "拒绝"
            }],
            "remote": false,
            "id": 0,
            "relation": false,
            "relationProps": []
          }
        },
        "tableCascaderProps": {},
        "tableFileProps": {},
        "rowButton": [],
        "stripe": true,
        "border": true,
        "showPagination": true,
        "showSelection": true,
        "showSummary": false,
        "title": "申请",
        "tableName": "t_profile_apply",
        "isGroup": false,
        "isTree": false,
        "headerButton": [{
          "title": "批量同意",
          "type": "success",
          "tag": "default",
          "id": ""
        }, {
          "tag": "default",
          "type": "danger",
          "title": "批量拒绝",
          "id": ""
        }],
        "tableRadioProps": {}
      },
      remoteFunc: {
        modelConditionFunc_1: function modelConditionFunc_1(tag, relationData, resolve) {
          //筛选条件数据
          //[[{key:"type",value:"1"}]]
          var data = [];
          data = [[{
            key: "add_time",
            value: []
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 0, true)
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 1, true)
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 7, true)
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 30, true)
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 365, true)
          }], [{
            key: "add_time",
            value: []
          }]];
          resolve(tag, data);
        },
        modelConditionFunc_2: function modelConditionFunc_2(tag, relationData, resolve) {
          //筛选条件数据
          //[[{key:"type",value:"1"}]]
          var data = [];
          data = [[{
            key: "handle_time",
            value: []
          }], [{
            key: "handle_time",
            value: _this.$g.fun.getDateRange(new Date(), 0, true)
          }], [{
            key: "handle_time",
            value: _this.$g.fun.getDateRange(new Date(), 1, true)
          }], [{
            key: "handle_time",
            value: _this.$g.fun.getDateRange(new Date(), 7, true)
          }], [{
            key: "handle_time",
            value: _this.$g.fun.getDateRange(new Date(), 30, true)
          }], [{
            key: "handle_time",
            value: _this.$g.fun.getDateRange(new Date(), 365, true)
          }], [{
            key: "handle_time",
            value: []
          }]];
          resolve(tag, data);
        }
      }
    };
  },
  mounted: function mounted() {
    this.table = this.$refs.ref_1001;
    this.search = this.$refs.ref_1000;
  },
  methods: {
    reqTableData: function reqTableData(params, resolve) {
      var where = {};

      if (this.search) {
        where = this.search.parseWhere();
        params.where = where;
      }

      memberInfoApplyList(params).then(function _callee(res) {
        return regeneratorRuntime.async(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                resolve({
                  total: res.count,
                  data: res.rows
                });

              case 1:
              case "end":
                return _context.stop();
            }
          }
        });
      });
    },
    reqAdd: function reqAdd(isEdit, params, row, resolve) {//if (isEdit){
      //  params.id = row.id
      //}
      //xxxAdd(params).then(async res=>{
      //  resolve()
      //})
    },
    reqDel: function reqDel(params, resolve) {
      var ids = params.map(function (item) {
        return item.id;
      }); //xxxDel({id:ids}).then(async res=>{
      //  resolve()
      //})
    },
    onClickLink: function onClickLink(prop, row) {//点击列表link类型回调
    },
    onClickRowButton: function onClickRowButton(btnIndex, row) {//点击列表按钮回调
    },
    onClickHeaderButton: function onClickHeaderButton(btnIndex, row) {
      var _this2 = this;

      //点击列表头部按钮回调
      if (row.length <= 0) {
        this.$message({
          message: '请至少选择一条申请',
          type: 'warning'
        });
        return;
      }

      var msg = '';

      if (btnIndex === 0) {
        msg = '同意';
      } else if (btnIndex === 1) {
        msg = '拒绝';
      }

      var ids = row.map(function (item) {
        return item.id;
      });
      this.$prompt(this.tableModel_1001.headerButton[btnIndex].title, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPlaceholder: '请输入备注',
        inputValue: msg
      }).then(function (_ref) {
        var value = _ref.value;
        memberInfoApplyAdd({
          id: ids,
          status: btnIndex === 0 ? 1 : 2,
          remark: value
        }).then(function _callee2(res) {
          return regeneratorRuntime.async(function _callee2$(_context2) {
            while (1) {
              switch (_context2.prev = _context2.next) {
                case 0:
                  _this2.$message({
                    type: 'success',
                    message: '提交成功'
                  });

                  _this2.table.reloadData();

                case 2:
                case "end":
                  return _context2.stop();
              }
            }
          });
        });
      }).catch(function () {});
    },
    onSummary: function onSummary(param) {//列表尾部合计回调
    },
    onSwitchChange: function onSwitchChange(val, prop, row) {//列表中switch变化事件
    },
    onClickSearchOperate: function onClickSearchOperate(btnIndex, btnData) {
      //条件菜单按钮点击事件
      if (btnData.tag === this.$tempModel.buttonTag.search) {
        this.table.reloadData();
      } else if (btnData.tag === this.$tempModel.buttonTag.export) {
        this.table.exportExcel();
      } else if (btnData.tag === this.$tempModel.buttonTag.import) {
        var header = btnData.header;
        var results = btnData.results;
      }
    },
    onConditionChange: function onConditionChange(tag, index) {
      this.table.reloadData();
    }
  }
};