var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        display: "flex",
        "flex-direction": "row",
        "align-items": "center",
        "justify-content": "right",
        "margin-right": "20px"
      },
      on: { click: _vm.clickSys }
    },
    [
      _c(
        "span",
        {
          staticStyle: {
            color: "white",
            "font-size": "15px",
            "text-decoration": "underline",
            cursor: "pointer"
          }
        },
        [_vm._v(_vm._s("当前系统：" + _vm.systemName()))]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "切换系统数据",
            visible: _vm.showSelect,
            width: "300px",
            "append-to-body": true,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "show-close": true
          },
          on: {
            "update:visible": function($event) {
              _vm.showSelect = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticStyle: { padding: "20px" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.selectSystemId,
                    callback: function($$v) {
                      _vm.selectSystemId = $$v
                    },
                    expression: "selectSystemId"
                  }
                },
                _vm._l(_vm.systemList, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.showSelect = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.clickSubmit } },
                [_vm._v("切 换")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }