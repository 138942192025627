var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c("phone-view", {
                ref: "phone",
                attrs: {
                  "phone-url": _vm.phoneUrl,
                  name: "personal",
                  "on-load": _vm.reloadData
                }
              })
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 16 } },
            [
              _c(
                "el-card",
                [
                  _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                    _vm._v(" 个人中心选项配置 ")
                  ]),
                  _c(
                    "el-radio-group",
                    {
                      on: { change: _vm.reloadData },
                      model: {
                        value: _vm.styleTag,
                        callback: function($$v) {
                          _vm.styleTag = $$v
                        },
                        expression: "styleTag"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [
                        _vm._v("样式一")
                      ]),
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("样式二")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-card",
                { staticStyle: { "margin-top": "15px" } },
                [
                  _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                    _vm._v(" 个人中心选项配置 ")
                  ]),
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini" },
                          on: { click: _vm.clickAdd }
                        },
                        [_vm._v("添加")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    {
                      staticClass: "tableDataPer",
                      staticStyle: { width: "100%", "margin-top": "15px" },
                      attrs: {
                        "row-key": "key",
                        size: "mini",
                        border: "",
                        data: _vm.tableData
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: { prop: "key", label: "KEY" }
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "name", label: "功能" }
                      }),
                      _c("el-table-column", {
                        attrs: { label: "是否可见" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("el-checkbox", {
                                  attrs: { "true-label": 1, "false-label": 0 },
                                  model: {
                                    value: scope.row.show,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "show", $$v)
                                    },
                                    expression: "scope.row.show"
                                  }
                                })
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: { label: "验证二级密码" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("el-checkbox", {
                                  attrs: { "true-label": 1, "false-label": 0 },
                                  model: {
                                    value: scope.row.pw2,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "pw2", $$v)
                                    },
                                    expression: "scope.row.pw2"
                                  }
                                })
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "sort", label: "排序" }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "verify",
                          fixed: "right",
                          label: "操作"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "mini" },
                                    on: {
                                      click: function($event) {
                                        return _vm.clickEdit(scope.row)
                                      }
                                    }
                                  },
                                  [_vm._v("编辑")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "mini" },
                                    on: {
                                      click: function($event) {
                                        return _vm.clickDel(scope.row)
                                      }
                                    }
                                  },
                                  [_vm._v("删除")]
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        float: "right",
                        "margin-top": "20px",
                        "margin-bottom": "20px"
                      }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.clickSubmit }
                        },
                        [_vm._v("保存")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("temp-add", {
        ref: "add",
        attrs: {
          title: "配置",
          "table-model": _vm.tableModel_1000.tableModel,
          "select-props": _vm.tableModel_1000.tableSelectProps,
          "switch-props": _vm.tableModel_1000.tableSwitchProps,
          "cascader-props": _vm.tableModel_1000.tableCascaderProps,
          "radio-props": _vm.tableModel_1000.tableRadioProps,
          "table-group-props": _vm.tableModel_1000.tableGroupProps,
          "remote-func": _vm.remoteFunc,
          "on-submit": _vm.onReqAdd
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }