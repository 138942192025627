import "core-js/modules/es.array.map";
import "core-js/modules/es.object.to-string";

/* eslint-disable */
import FileSaver from 'file-saver';
import * as Excel from './_export2Excel';
var exportCustomExcel = {
  // 导出 excel
  excelMulSheet: function excelMulSheet(params) {
    return new Promise(function (resolve, reject) {
      // 默认值
      var paramsDefault = {
        columns: [],
        data: [],
        title: 'table',
        sheets: [],
        header: null,
        merges: []
      }; // 合并参数

      var _params = Object.assign({}, paramsDefault, params); // 从参数中派生数据


      var header = [];

      for (var i in _params.columns) {
        var item = _params.columns[i].map(function (e) {
          return e.label;
        });

        header.push(item);
      }

      var data = [];

      var _loop = function _loop(_i) {
        var item = _params.data[_i].map(function (row) {
          return _params.columns[_i].map(function (col) {
            return row[col.prop];
          });
        });

        data.push(item);
      };

      for (var _i in _params.data) {
        _loop(_i);
      }

      console.log('ccc', data); // 导出

      Excel.export_json_to_excel_mul_sheet(header, data, _params.title, _params.sheets, {
        merges: _params.merges,
        header: _params.header
      }); // 完成

      resolve();
    });
  }
};
export default exportCustomExcel;