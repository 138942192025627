import "core-js/modules/es.array.map";
import "core-js/modules/es.number.constructor";
import "core-js/modules/web.dom-collections.for-each";
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    data: {
      type: Array
    },
    tableProps: {
      type: Array
    }
  },
  methods: {
    onSummary: function onSummary(param) {
      //列表尾部合计回调
      var columns = param.columns,
          data = param.data;
      console.log('para', param);
      var sums = [];
      columns.forEach(function (column, index) {
        if (index === 0) {
          sums[index] = '总计';
          return;
        }

        if (column.property === 'total_price' || column.property === 'total_pv') {
          var values = data.map(function (item) {
            return Number(item[column.property]);
          });
          sums[index] = values.reduce(function (prev, curr) {
            var value = Number(curr);

            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
        }
      });
      return sums;
    }
  }
};