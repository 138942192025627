var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c("phone-view", {
                ref: "phone",
                attrs: {
                  "phone-url": _vm.phoneUrl,
                  name: "reg",
                  "on-load": _vm.reloadData
                }
              })
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 16 } },
            [
              _c(
                "el-card",
                [
                  _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                    _vm._v(" 注册选项配置 ")
                  ]),
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini" },
                          on: { click: _vm.clickAdd }
                        },
                        [_vm._v("添加")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    {
                      staticClass: "tableData",
                      staticStyle: { width: "100%", marginTop: "15px" },
                      attrs: {
                        size: "mini",
                        border: "",
                        "row-key": "key",
                        data: _vm.tableData
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: { prop: "key", label: "KEY" }
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "name", label: "项目" }
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "regShow", label: "注册可见项" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("el-checkbox", {
                                  attrs: { "true-label": 1, "false-label": 0 },
                                  model: {
                                    value: scope.row.regShow,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "regShow", $$v)
                                    },
                                    expression: "scope.row.regShow"
                                  }
                                })
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "regRequire", label: "注册必选项" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("el-checkbox", {
                                  attrs: {
                                    "true-label": 1,
                                    "false-label": 0,
                                    disabled: !scope.row.regShow
                                  },
                                  model: {
                                    value: scope.row.regRequire,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "regRequire", $$v)
                                    },
                                    expression: "scope.row.regRequire"
                                  }
                                })
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "webShow", label: "前台可查看项" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                scope.row.webShow >= 0
                                  ? _c("el-checkbox", {
                                      attrs: {
                                        "true-label": 1,
                                        "false-label": 0
                                      },
                                      model: {
                                        value: scope.row.webShow,
                                        callback: function($$v) {
                                          _vm.$set(scope.row, "webShow", $$v)
                                        },
                                        expression: "scope.row.webShow"
                                      }
                                    })
                                  : _vm._e()
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "webEdit", label: "前台可修改项" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                scope.row.webEdit >= 0
                                  ? _c("el-checkbox", {
                                      attrs: {
                                        "true-label": 1,
                                        "false-label": 0,
                                        disabled: !scope.row.webShow
                                      },
                                      model: {
                                        value: scope.row.webEdit,
                                        callback: function($$v) {
                                          _vm.$set(scope.row, "webEdit", $$v)
                                        },
                                        expression: "scope.row.webEdit"
                                      }
                                    })
                                  : _vm._e()
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "unique", label: "唯一" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                scope.row.unique >= 0
                                  ? _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          disabled: !scope.row.regShow,
                                          size: "mini",
                                          placeholder: "请选择"
                                        },
                                        model: {
                                          value: scope.row.unique,
                                          callback: function($$v) {
                                            _vm.$set(scope.row, "unique", $$v)
                                          },
                                          expression: "scope.row.unique"
                                        }
                                      },
                                      _vm._l(_vm.uniqueList, function(item) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.label,
                                            value: item.value
                                          }
                                        })
                                      }),
                                      1
                                    )
                                  : _vm._e()
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "verify", label: "真实性" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                scope.row.verify >= 0
                                  ? _c("el-checkbox", {
                                      attrs: {
                                        "true-label": 1,
                                        "false-label": 0
                                      },
                                      model: {
                                        value: scope.row.verify,
                                        callback: function($$v) {
                                          _vm.$set(scope.row, "verify", $$v)
                                        },
                                        expression: "scope.row.verify"
                                      }
                                    })
                                  : _vm._e()
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "sort", label: "排序" }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "verify",
                          fixed: "right",
                          label: "操作"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "mini" },
                                    on: {
                                      click: function($event) {
                                        return _vm.clickEdit(scope.row)
                                      }
                                    }
                                  },
                                  [_vm._v("编辑")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "mini" },
                                    on: {
                                      click: function($event) {
                                        return _vm.clickDel(scope.row)
                                      }
                                    }
                                  },
                                  [_vm._v("删除")]
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        float: "right",
                        "margin-top": "20px",
                        "margin-bottom": "20px"
                      }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.clickSubmit }
                        },
                        [_vm._v("保存")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("temp-add", {
        ref: "add",
        attrs: {
          title: "配置",
          "table-model": _vm.tableModel_1000.tableModel,
          "select-props": _vm.tableModel_1000.tableSelectProps,
          "switch-props": _vm.tableModel_1000.tableSwitchProps,
          "cascader-props": _vm.tableModel_1000.tableCascaderProps,
          "radio-props": _vm.tableModel_1000.tableRadioProps,
          "table-group-props": _vm.tableModel_1000.tableGroupProps,
          "on-submit": _vm.onReqAdd
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }