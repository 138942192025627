import "core-js/modules/es.object.to-string";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { systemSwitchAdd, systemSwitchList } from '../../../../api/apis';
export default {
  data: function data() {
    return {
      tableData: []
    };
  },
  mounted: function mounted() {
    var _this = this;

    systemSwitchList().then(function _callee(res) {
      return regeneratorRuntime.async(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _this.tableData = res;

            case 1:
            case "end":
              return _context.stop();
          }
        }
      });
    });
  },
  methods: {
    clickSubmit: function clickSubmit() {
      var _this2 = this;

      var params = {
        data: this.tableData
      };
      systemSwitchAdd(params).then(function _callee2(res) {
        return regeneratorRuntime.async(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                _this2.$message({
                  message: '保存成功',
                  type: 'success'
                });

              case 1:
              case "end":
                return _context2.stop();
            }
          }
        });
      });
    }
  }
};