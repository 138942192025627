'use strict';

import "core-js/modules/es.object.to-string";
import "regenerator-runtime/runtime";
import setting from '@/setting';
import request from '@/plugin/axios';
export default {
  /**
   * 文件上传
   * @param option 参考csdn: https://blog.csdn.net/qq_27626333/article/details/81463139
   */
  uploadFile: function uploadFile(file, exData, suc, fail) {
    var data = new FormData();
    data.append('file', file);
    data.append('type', exData.type);
    request({
      url: setting.appApi.uploadFile,
      method: 'post',
      data: data,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(function _callee(res) {
      return regeneratorRuntime.async(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              if (suc) {
                suc(res);
              }

            case 1:
            case "end":
              return _context.stop();
          }
        }
      });
    }).catch(function (err) {
      if (fail) {
        fail({
          code: err.code,
          msg: err.message
        });
      }
    });
  }
};