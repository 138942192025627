var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "el-card",
        [
          _c(
            "el-collapse",
            {
              model: {
                value: _vm.activeNames,
                callback: function($$v) {
                  _vm.activeNames = $$v
                },
                expression: "activeNames"
              }
            },
            [
              _c(
                "el-collapse-item",
                { attrs: { name: "search" } },
                [
                  _c("template", { slot: "title" }, [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-size": "16px",
                          "font-weight": "normal"
                        }
                      },
                      [_vm._v("搜索条件")]
                    )
                  ]),
                  _c(
                    "temp-search",
                    _vm._b(
                      {
                        ref: "ref_1000",
                        attrs: {
                          "remote-func": _vm.remoteFunc,
                          "on-click-operate": _vm.onClickSearchOperate,
                          "on-condition-change": _vm.onConditionChange
                        }
                      },
                      "temp-search",
                      _vm.searchModel_1000,
                      false
                    )
                  )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-card",
        { staticStyle: { "margin-top": "20px" } },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c("span", [_vm._v("产品列表")])
          ]),
          _c(
            "temp-table",
            _vm._b(
              {
                ref: "ref_1001",
                attrs: {
                  "remote-func": _vm.remoteFunc,
                  "req-table-data": _vm.reqTableData,
                  "req-add": _vm.reqAdd,
                  "req-del": _vm.reqDel,
                  "on-click-link": _vm.onClickLink,
                  "on-click-row-button": _vm.onClickRowButton,
                  "on-click-header-button": _vm.onClickHeaderButton,
                  "on-summary": _vm.onSummary,
                  "on-switch-change": _vm.onSwitchChange,
                  "on-add-show": _vm.onAddShow
                }
              },
              "temp-table",
              _vm.tableModel_1001,
              false
            ),
            [
              _c(
                "template",
                { slot: "add_tab" },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "眼镜专区", name: "眼镜专区" } },
                    [
                      _c(
                        "el-row",
                        [
                          _vm._l(_vm.forms, function(form, index) {
                            return _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-card",
                                  { staticStyle: { margin: "10px" } },
                                  [
                                    _c(
                                      "el-row",
                                      {
                                        attrs: { slot: "header" },
                                        slot: "header"
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s("分类" + (index + 1)))
                                        ]),
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: { float: "right" },
                                            attrs: {
                                              size: "mini",
                                              type: "danger"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.onClickDel(index)
                                              }
                                            }
                                          },
                                          [_vm._v("删除")]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-form",
                                      {
                                        staticClass: "custom-form",
                                        attrs: {
                                          size: "mini",
                                          model: form,
                                          "label-width": "80px"
                                        }
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "分类名称" } },
                                          [
                                            _c("el-input", {
                                              attrs: { placeholder: "度数" },
                                              model: {
                                                value: form.title,
                                                callback: function($$v) {
                                                  _vm.$set(form, "title", $$v)
                                                },
                                                expression: "form.title"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "分类类型" } },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                model: {
                                                  value: form.type,
                                                  callback: function($$v) {
                                                    _vm.$set(form, "type", $$v)
                                                  },
                                                  expression: "form.type"
                                                }
                                              },
                                              [
                                                _c("el-option", {
                                                  attrs: {
                                                    label: "手动输入",
                                                    value: "input"
                                                  }
                                                }),
                                                _c("el-option", {
                                                  attrs: {
                                                    label: "下拉选择",
                                                    value: "choose"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        form.type === "choose"
                                          ? _c(
                                              "el-form-item",
                                              { attrs: { label: "选择内容" } },
                                              [
                                                _vm._l(form.values, function(
                                                  item,
                                                  subIndex
                                                ) {
                                                  return _c(
                                                    "el-row",
                                                    {
                                                      staticStyle: {
                                                        border:
                                                          "1px solid #e2e2e2",
                                                        padding: "10px",
                                                        "margin-bottom": "5px"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "el-row",
                                                        [
                                                          _c("el-input", {
                                                            attrs: {
                                                              placeholder:
                                                                "请输入选择项描述。例：蓝色"
                                                            },
                                                            model: {
                                                              value: item.desc,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  item,
                                                                  "desc",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "item.desc"
                                                            }
                                                          }),
                                                          _c(
                                                            "el-button",
                                                            {
                                                              staticStyle: {
                                                                float: "right"
                                                              },
                                                              attrs: {
                                                                size: "mini",
                                                                type: "danger"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.onClickContentDel(
                                                                    form.values,
                                                                    subIndex
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [_vm._v("删除内容")]
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "el-row",
                                                        [
                                                          _c(
                                                            "el-checkbox",
                                                            {
                                                              model: {
                                                                value:
                                                                  item.is_need_image,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "is_need_image",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "item.is_need_image"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "是否需要图片"
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      item.is_need_image
                                                        ? _c(
                                                            "el-row",
                                                            [
                                                              _c(
                                                                "el-upload",
                                                                {
                                                                  staticClass:
                                                                    "avatar-uploader",
                                                                  attrs: {
                                                                    action: "",
                                                                    multiple: false,
                                                                    "show-file-list": true,
                                                                    limit: 1,
                                                                    "list-type":
                                                                      "picture-card",
                                                                    "file-list":
                                                                      item.img_url,
                                                                    "before-upload":
                                                                      _vm.beforeUpload,
                                                                    "http-request":
                                                                      _vm.uploadRequest,
                                                                    "on-error": function(
                                                                      err,
                                                                      file,
                                                                      fileList
                                                                    ) {
                                                                      return _vm.onError(
                                                                        err,
                                                                        file,
                                                                        fileList,
                                                                        item
                                                                      )
                                                                    },
                                                                    "on-success": function(
                                                                      response,
                                                                      file,
                                                                      fileList
                                                                    ) {
                                                                      return _vm.onSuccess(
                                                                        response,
                                                                        file,
                                                                        fileList,
                                                                        item
                                                                      )
                                                                    },
                                                                    "on-remove": function(
                                                                      file,
                                                                      fileList
                                                                    ) {
                                                                      return _vm.onRemove(
                                                                        file,
                                                                        fileList,
                                                                        item
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _c("i", {
                                                                    staticClass:
                                                                      "el-icon-plus avatar-uploader-icon"
                                                                  })
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  )
                                                }),
                                                _c(
                                                  "el-row",
                                                  [
                                                    _c(
                                                      "el-button",
                                                      {
                                                        attrs: {
                                                          type: "primary",
                                                          size: "mini"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.clickAddChooseItem(
                                                              form
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [_vm._v(" 添加选择项 ")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              2
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          }),
                          _c(
                            "el-col",
                            {
                              staticStyle: { margin: "10px" },
                              attrs: { span: 8 }
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "center-content",
                                  staticStyle: {
                                    width: "80px",
                                    height: "80px"
                                  },
                                  on: { click: _vm.clickAddForm }
                                },
                                [_c("i", { staticClass: "el-icon-plus" })]
                              )
                            ],
                            1
                          )
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            2
          )
        ],
        1
      ),
      _c("config-info", { ref: "packageInfo" }),
      _c("product-grade-price", { ref: "gradePrice" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }