var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-switch", {
    attrs: {
      disabled: _vm.disabled,
      "active-value": _vm._activeValue(_vm.prop),
      "inactive-value": _vm._inactiveValue(_vm.prop)
    },
    on: {
      change: function($event) {
        return _vm.onSwitchChange($event, _vm.prop, _vm.model)
      }
    },
    model: {
      value: _vm.model[_vm.prop],
      callback: function($$v) {
        _vm.$set(_vm.model, _vm.prop, $$v)
      },
      expression: "model[prop]"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }