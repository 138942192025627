//
//
//
//
//
//
export default {
  methods: {
    handleClick: function handleClick() {
      this.$emit('click');
    }
  }
};