var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "批量区代",
        visible: _vm.dialogVisible,
        width: "500px",
        "append-to-body": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "show-close": false
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            "max-height": "50vh",
            "overflow-y": "auto",
            padding: "15px 30px",
            "background-color": "#f6f6f6"
          }
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-radio",
                {
                  attrs: { label: "0" },
                  model: {
                    value: _vm.type,
                    callback: function($$v) {
                      _vm.type = $$v
                    },
                    expression: "type"
                  }
                },
                [_vm._v("按会员编号更新")]
              ),
              _c(
                "el-radio",
                {
                  attrs: { label: "1" },
                  model: {
                    value: _vm.type,
                    callback: function($$v) {
                      _vm.type = $$v
                    },
                    expression: "type"
                  }
                },
                [_vm._v("按手机号更新")]
              )
            ],
            1
          ),
          _vm.isWrong > 0
            ? _c(
                "el-tag",
                {
                  staticStyle: { margin: "15px 0" },
                  attrs: { type: "danger" }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.isWrong == 1
                        ? "区域名称错误"
                        : _vm.isWrong == 2
                        ? "表格内会员编号不存在"
                        : "表格内区域相同"
                    )
                  )
                ]
              )
            : _vm._e(),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%", "margin-top": "15px" },
              attrs: { data: _vm.tableData }
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "phone",
                  label: _vm.type == "0" ? "会员编号" : "手机号"
                }
              }),
              _c("el-table-column", {
                attrs: { prop: "direct_city", label: "市" }
              }),
              _c("el-table-column", {
                attrs: { prop: "direct_district", label: "区" }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.dialogVisible = false
                }
              }
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", disabled: _vm.isWrong > 0 },
              on: { click: _vm.clickSubmit }
            },
            [_vm._v("批量设置")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }