var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-popover",
    {
      attrs: {
        placement: "top-start",
        title: _vm.label,
        width: _vm.width ? _vm.width : 200,
        trigger: "click",
        content: _vm.model[_vm.prop]
      }
    },
    [
      _c(
        "span",
        {
          staticClass: "content",
          attrs: { slot: "reference" },
          slot: "reference"
        },
        [_vm._v(_vm._s(_vm.model[_vm.prop]))]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }