var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title + "详情",
        visible: _vm.dialogVisible,
        width: _vm.width,
        "append-to-body": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "show-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            "max-height": "50vh",
            "overflow-y": "auto",
            padding: "0 30px"
          }
        },
        [
          _c(
            "el-form",
            { attrs: { "label-width": "100px" } },
            _vm._l(_vm.detailData, function(item) {
              return _c(
                "el-form-item",
                { attrs: { label: item[_vm.detailProps.label] } },
                [
                  item.type === _vm.$tempModel.showType.avatar ||
                  item.type === _vm.$tempModel.showType.image
                    ? _c("el-image", {
                        staticStyle: { width: "60px", height: "60px" },
                        attrs: {
                          size: "large",
                          fit: "cover",
                          src: item[_vm.detailProps.value],
                          "preview-src-list": [item[_vm.detailProps.value]]
                        }
                      })
                    : item.type === _vm.$tempModel.showType.switch
                    ? _c("el-switch", {
                        attrs: {
                          "active-value": _vm.switchProps.activeValue,
                          "inactive-value": _vm.switchProps.inactiveValue,
                          value: item[_vm.detailProps.value]
                        }
                      })
                    : item.type === _vm.$tempModel.showType.images
                    ? _c(
                        "div",
                        { staticClass: "grid" },
                        _vm._l(item[_vm.detailProps.value], function(img) {
                          return _c("el-image", {
                            staticClass: "grid-item",
                            attrs: {
                              fit: "cover",
                              src: img,
                              "preview-src-list": item[_vm.detailProps.value]
                            }
                          })
                        }),
                        1
                      )
                    : _c(
                        "el-tag",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { type: "info", size: "medium" }
                        },
                        [_vm._v(_vm._s(item[_vm.detailProps.value]))]
                      )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  _vm.dialogVisible = false
                }
              }
            },
            [_vm._v("确 定")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }