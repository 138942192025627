//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'smsConfig',
  data: function data() {
    return {
      juheSMS: ''
    };
  }
};