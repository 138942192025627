var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.isAdd ? "算法添加" : "算法编辑",
        visible: _vm.dialogVisible,
        width: "60%",
        "append-to-body": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "show-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _vm.row
        ? _c(
            "div",
            {
              staticStyle: {
                "overflow-y": "auto",
                padding: "15px 30px",
                "background-color": "#f6f6f6"
              }
            },
            [
              _c(
                "el-card",
                { staticClass: "row-card", attrs: { shadow: "never" } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: { size: "mini", "label-position": "top" }
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "算法名称" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.row.name,
                              callback: function($$v) {
                                _vm.$set(_vm.row, "name", $$v)
                              },
                              expression: "row.name"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "算法描述" } },
                        [
                          _c("el-input", {
                            attrs: { type: "textarea" },
                            model: {
                              value: _vm.row.remark,
                              callback: function($$v) {
                                _vm.$set(_vm.row, "remark", $$v)
                              },
                              expression: "row.remark"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "奖励代数" } },
                        [
                          _c("el-input-number", {
                            attrs: { min: 0, max: 100, label: "描述文字" },
                            model: {
                              value: _vm.row.config.layer,
                              callback: function($$v) {
                                _vm.$set(_vm.row.config, "layer", $$v)
                              },
                              expression: "row.config.layer"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "计算类型" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.row.config.bonusMode,
                                callback: function($$v) {
                                  _vm.$set(_vm.row.config, "bonusMode", $$v)
                                },
                                expression: "row.config.bonusMode"
                              }
                            },
                            [
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("比率")
                              ]),
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("金额")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "计算方式" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.row.config.computeType,
                                callback: function($$v) {
                                  _vm.$set(_vm.row.config, "computeType", $$v)
                                },
                                expression: "row.config.computeType"
                              }
                            },
                            [
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("非级差计算")
                              ]),
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("级差计算")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "生效的订单类型" } },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              model: {
                                value: _vm.row.config.orderTypes,
                                callback: function($$v) {
                                  _vm.$set(_vm.row.config, "orderTypes", $$v)
                                },
                                expression: "row.config.orderTypes"
                              }
                            },
                            [
                              _c(
                                "el-checkbox",
                                { attrs: { label: 1, name: "type" } },
                                [_vm._v("注册单")]
                              ),
                              _c(
                                "el-checkbox",
                                { attrs: { label: 2, name: "type" } },
                                [_vm._v("复销单")]
                              ),
                              _c(
                                "el-checkbox",
                                { attrs: { label: 3, name: "type" } },
                                [_vm._v("升级单")]
                              ),
                              _c(
                                "el-checkbox",
                                { attrs: { label: 4, name: "type" } },
                                [_vm._v("激活单")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "影响奖金因素" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                multiple: "",
                                clearable: "",
                                placeholder: "请选择影响的因素"
                              },
                              model: {
                                value: _vm.row.config.factor,
                                callback: function($$v) {
                                  _vm.$set(_vm.row.config, "factor", $$v)
                                },
                                expression: "row.config.factor"
                              }
                            },
                            _vm._l(_vm.factorList, function(item) {
                              return _c("el-option", {
                                key: item.tag,
                                attrs: { label: item.name, value: item.tag }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.dialogVisible = false
                }
              }
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.clickSubmit } },
            [_vm._v(_vm._s(_vm.isAdd ? "添 加" : "保 存"))]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }