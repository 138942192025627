import "core-js/modules/es.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    type: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: '标题'
    },
    selected: {
      type: Boolean,
      default: false
    },
    total: {
      type: Number,
      default: 0
    },
    onClick: {
      type: Function
    },
    field: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  computed: {
    buttonType: function buttonType() {
      if (this.selected) {
        return 'primary';
      }

      return null;
    }
  },
  data: function data() {
    return {};
  },
  methods: {
    where: function where() {
      var where = {};

      for (var i in this.field) {
        var item = this.field[i];

        if (item.value != null && item.value !== '') {
          where[item.key] = item.value;
        }
      }

      return where;
    }
  }
};