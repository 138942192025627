var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    { ref: "form", attrs: { "label-width": "80px" } },
    [
      _c(
        "el-form-item",
        { attrs: { label: "赠品选择" } },
        [
          _c(
            "el-row",
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择", filterable: "" },
                  model: {
                    value: _vm.productCode,
                    callback: function($$v) {
                      _vm.productCode = $$v
                    },
                    expression: "productCode"
                  }
                },
                _vm._l(_vm.productList, function(item, index) {
                  return _c("el-option", {
                    key: item.code,
                    attrs: {
                      label: item.code + item.store_name,
                      value: item.code
                    }
                  })
                }),
                1
              ),
              _c("el-input-number", {
                staticStyle: { "margin-left": "10px" },
                attrs: { min: 1 },
                model: {
                  value: _vm.productNum,
                  callback: function($$v) {
                    _vm.productNum = $$v
                  },
                  expression: "productNum"
                }
              }),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "10px" },
                  on: { click: _vm.addProduct }
                },
                [_vm._v("添加")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "" } },
        [
          _c(
            "el-table",
            { staticStyle: { width: "100%" }, attrs: { data: _vm.tableData } },
            [
              _c("el-table-column", {
                attrs: { prop: "store_name", label: "产品名称" }
              }),
              _c("el-table-column", {
                attrs: { prop: "code", label: "产品编码" }
              }),
              _c("el-table-column", {
                attrs: { prop: "product_num", label: "产品数量" }
              }),
              _c("el-table-column", {
                attrs: { label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-button", {
                          attrs: {
                            icon: "el-icon-delete",
                            circle: "",
                            size: "mini"
                          },
                          on: {
                            click: function($event) {
                              return _vm.clickDel(scope.$index, scope.row)
                            }
                          }
                        })
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "el-row",
            {
              staticStyle: { "margin-top": "10px" },
              attrs: { type: "flex", justify: "end" }
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger", size: "mini" },
                  on: {
                    click: function($event) {
                      return _vm.reload()
                    }
                  }
                },
                [_vm._v("重新加载商品数据")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }