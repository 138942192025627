//
//
//
//
export default {
  props: {
    model: {
      type: Object,
      required: true
    },
    prop: {
      type: String,
      required: true
    }
  }
};