//
//
//
//
import articleBase from '../articleBase';
export default {
  name: 'xuanChuan',
  components: {
    articleBase: articleBase
  }
};