var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    {
      staticStyle: { width: "500px" },
      attrs: {
        slot: "prepend",
        filterable: "",
        size: "mini",
        placeholder: "请选择",
        multiple: _vm.multiple
      },
      on: { change: _vm.onChange },
      slot: "prepend",
      model: {
        value: _vm.key,
        callback: function($$v) {
          _vm.key = $$v
        },
        expression: "key"
      }
    },
    _vm._l(_vm.field, function(item) {
      return _c("el-option", { attrs: { label: item.desc, value: item.value } })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }