//
//
//
//
//
//
export default {
  props: {
    model: {
      type: Object,
      required: true
    },
    prop: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      required: true
    },
    switchProps: {
      type: Object,
      required: true
    },
    onSwitchChange: {
      type: Function
    }
  },
  methods: {
    _activeValue: function _activeValue(prop) {
      if (this.switchProps.hasOwnProperty(prop)) {
        return this.switchProps[prop].activeValue;
      } else {
        return 1;
      }
    },
    _inactiveValue: function _inactiveValue(prop) {
      if (this.switchProps.hasOwnProperty(prop)) {
        return this.switchProps[prop].inactiveValue;
      } else {
        return 0;
      }
    }
  }
};