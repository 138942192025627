//
//
//
//
//
//
//
//
//
//
//
//
//
//
import scroll from './mixins/normal';
export default {
  name: 'd2-container-ghost',
  mixins: [scroll],
  mounted: function mounted() {
    // 增加滚动事件监听
    this.addScrollListener();
  },
  beforeDestroy: function beforeDestroy() {
    // 移除滚动事件监听
    this.removeScrollListener();
  }
};