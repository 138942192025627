//
//
//
//
//
//
export default {
  props: {
    type: {
      type: String,
      required: true
    },
    field: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    multiple: {
      type: Boolean,
      default: false
    },
    onChange: {
      type: Function
    }
  },
  data: function data() {
    return {
      key: ''
    };
  },
  watch: {
    multiple: function multiple(nVal, oVal) {
      if (nVal) {
        this.key = [];
      } else {
        this.key = '';
      }
    },
    field: function field(nVal, oVal) {
      if (this.multiple) {
        this.key = [];
      } else {
        this.key = '';
      }
    }
  },
  methods: {
    where: function where() {
      var where = {};
      var field = null;

      for (var i in this.field) {
        field = this.field[i].key;
        break;
      }

      if (field) {
        where[field] = this.key;
      }

      return where;
    }
  }
};