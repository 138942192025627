import "core-js/modules/es.object.to-string";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { configGradeList, systemWebConfig, systemWebConfigAdd } from '../../../../api/apis';
import tempAdd from '@/components/rw/tempAdd/index';
import Sortable from "sortablejs";
import phoneView from './phoneView';
import setting from '../../../../setting';
export default {
  name: 'personalConfig',
  components: {
    phoneView: phoneView,
    tempAdd: tempAdd
  },
  data: function data() {
    var _this = this;

    return {
      phoneUrl: setting.appApi.previewUrl + '/pages/index/previewHome',
      config: {
        bannerTag: 0,
        classifyTag: 0,
        productTag: 0
      }
    };
  },
  watch: {
    tableData: {
      deep: true,
      handler: function handler(val) {
        this.reloadData();
      }
    }
  },
  mounted: function mounted() {},
  methods: {
    reloadData: function reloadData() {
      this.$refs.phone.reloadPhone(this.config);
    },
    clickSubmit: function clickSubmit() {
      var _this2 = this;

      var params = [{
        id: 'home',
        value: JSON.stringify(this.config)
      }];
      systemWebConfigAdd(params).then(function _callee(res) {
        return regeneratorRuntime.async(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                _this2.$message({
                  message: '保存成功',
                  type: 'success'
                });

              case 1:
              case "end":
                return _context.stop();
            }
          }
        });
      });
    }
  }
};