import util from '@/libs/util.js';
export default {
  methods: {
    handleMenuSelect: function handleMenuSelect(index, indexPath) {
      if (/^d2-menu-empty-\d+$/.test(index) || index === undefined) {
        this.$message.warning('临时菜单');
      } else if (/^https:\/\/|http:\/\//.test(index)) {
        util.open(index);
      } else {
        this.$router.push({
          path: index
        });
      }
    }
  }
};