var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content" }, [
    _vm.forceUpdatePhone
      ? _c("div", { staticClass: "phone" }, [
          _c("div", { staticClass: "iframe" }, [
            _c("iframe", {
              ref: "phone",
              attrs: {
                id: "phone-iframe" + _vm.name,
                frameborder: "0",
                name: _vm.name,
                scrolling: "auto",
                width: "300px",
                height: "650px",
                src: _vm.phoneUrl
              }
            })
          ])
        ])
      : _vm._e(),
    _c(
      "div",
      {
        staticStyle: {
          display: "flex",
          "flex-direction": "row",
          "align-items": "center",
          "justify-content": "center"
        }
      },
      [
        _c(
          "el-button",
          { attrs: { type: "text" }, on: { click: _vm.refreshPhone } },
          [_vm._v("重新加载")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }