var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "el-card",
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c("span", [_vm._v("结算时间设置")])
          ]),
          _c(
            "el-form",
            { ref: "form", attrs: { size: "mini", model: _vm.config } },
            [
              _c("el-form-item", {
                attrs: { prop: "serverRealTime", label: "服务器实际时间" }
              }),
              _c("el-form-item", {
                attrs: { prop: "serverBalanceTime", label: "服务器结算时间" }
              }),
              _c(
                "el-form-item",
                { attrs: { label: "偏移日期" } },
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "formItemInput",
                      model: {
                        value: _vm.config.day,
                        callback: function($$v) {
                          _vm.$set(_vm.config, "day", $$v)
                        },
                        expression: "config.day"
                      }
                    },
                    [_c("template", { slot: "append" }, [_vm._v("天")])],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }