//
//
//
//
//
//
//
export default {
  props: {
    model: {
      type: Object,
      required: true
    },
    prop: {
      type: String,
      required: true
    }
  },
  computed: {
    v: function v() {
      return this.model[this.prop];
    }
  },
  data: function data() {
    return {
      colors: ['#99A9BF', '#F7BA2A', '#FF9900'] // 等同于 { 2: '#99A9BF', 4: { value: '#F7BA2A', excluded: true }, 5: '#FF9900' }

    };
  }
};