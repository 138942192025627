import "core-js/modules/es.array.map";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.search";
import "core-js/modules/web.dom-collections.for-each";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tempTable from "@/components/rw/tempTable/index";
import tempSearch from "@/components/rw/tempSearch/index";
import tempForm from "@/components/rw/tempForm/index";
import tempChart from "@/components/rw/tempChart/index";
import { areaList, goodsOrder, goodsOrderHandle } from '@/api/apis';
export default {
  components: {
    tempTable: tempTable,
    tempSearch: tempSearch,
    tempForm: tempForm,
    tempChart: tempChart
  },
  data: function data() {
    var _this = this;

    return {
      activeNames: 'search',
      searchModel_1000: {
        "del": false,
        "id": "id_1000",
        "isCollapse": true,
        "operateData": [{
          "icon": "el-icon-search",
          "tag": "search",
          "title": "搜索",
          "type": "primary"
        }, {
          "icon": "el-icon-position",
          "id": "118",
          "tag": "export",
          "title": "导出",
          "type": "primary"
        }],
        "searchData": [{
          "children": [{
            "field": [],
            "title": "全部",
            "type": "button"
          }, {
            "field": [{
              "key": "status",
              "value": "0"
            }],
            "title": "未处理",
            "type": "button"
          }, {
            "field": [{
              "key": "status",
              "value": "1"
            }],
            "title": "已处理",
            "type": "button"
          }],
          "id": 1,
          "relation": false,
          "relationId": "",
          "remote": false,
          "remoteType": 0,
          "select": 0,
          "title": "订单状态",
          "type": 0,
          "remoteFunc": []
        }, {
          "children": [{
            "field": [],
            "title": "全部",
            "type": "button"
          }, {
            "field": [],
            "title": "今天",
            "type": "button"
          }, {
            "field": [],
            "title": "昨天",
            "type": "button"
          }, {
            "field": [],
            "title": "最近7天",
            "type": "button"
          }, {
            "field": [],
            "title": "本月",
            "type": "button"
          }, {
            "field": [],
            "title": "本年",
            "type": "button"
          }, {
            "field": [],
            "title": "自定义",
            "type": "custom-datetime-range"
          }],
          "id": 3,
          "relation": false,
          "relationId": "",
          "remote": true,
          "remoteType": 1,
          "select": 0,
          "title": "订单日期",
          "type": 1,
          "remoteFunc": ["modelConditionFunc_3"]
        }, {
          "children": [{
            "field": [],
            "title": "全部",
            "type": "button"
          }, {
            "field": [],
            "title": "今天",
            "type": "button"
          }, {
            "field": [],
            "title": "昨天",
            "type": "button"
          }, {
            "field": [],
            "title": "最近7天",
            "type": "button"
          }, {
            "field": [],
            "title": "本月",
            "type": "button"
          }, {
            "field": [],
            "title": "本年",
            "type": "button"
          }, {
            "field": [],
            "title": "自定义",
            "type": "custom-datetime-range"
          }],
          "id": 14,
          "relation": false,
          "relationId": "",
          "remote": true,
          "remoteType": 1,
          "select": 0,
          "title": "支付日期",
          "type": 1,
          "remoteFunc": ["modelConditionFunc_14"]
        }, {
          "children": [{
            "field": [{
              "desc": "订单号",
              "key": "order_id",
              "value": ""
            }, {
              "desc": "会员编号",
              "key": "user_number",
              "value": ""
            }, {
              "desc": "推荐人编号",
              "key": "direct",
              "value": ""
            }],
            "operator": [{
              "key": "like",
              "value": "模糊"
            }],
            "placeholder": "请输入",
            "type": "input-mul-complex"
          }, {
            "field": [{
              "desc": "订单金额",
              "key": "total_price"
            }, {
              "desc": "产品数量",
              "key": "total_num",
              "value": ""
            }],
            "operator": [{
              "key": "gte",
              "value": ">="
            }, {
              "key": "lte",
              "value": "<="
            }, {
              "key": "gt",
              "value": ">"
            }, {
              "key": "lt",
              "value": "<"
            }, {
              "key": "eq",
              "value": "="
            }, {
              "key": "ne",
              "value": "!="
            }],
            "placeholder": "请输入",
            "type": "input-mul-complex"
          }, {
            "field": [{
              "desc": "顶点编号",
              "key": "top_number"
            }],
            "operator": [],
            "placeholder": "请输入",
            "type": "input-complex"
          }, {
            "field": [{
              "desc": "会员电话",
              "key": "t_user.phone"
            }],
            "operator": [],
            "placeholder": "请输入",
            "type": "input-complex"
          }],
          "id": 2,
          "relation": false,
          "relationId": "",
          "remote": false,
          "remoteType": 0,
          "select": 0,
          "title": "按条件",
          "type": 2,
          "remoteFunc": []
        }, {
          "children": [{
            "field": [{
              "desc": "省",
              "key": "address_province",
              "value": ""
            }],
            "operator": [{
              "key": "like",
              "value": "模糊"
            }],
            "placeholder": "请输入",
            "type": "input-mul-complex"
          }, {
            "field": [{
              "desc": "市",
              "key": "address_city"
            }],
            "operator": [{
              "key": "like",
              "value": "模糊"
            }],
            "placeholder": "请输入",
            "type": "input-mul-complex"
          }, {
            "field": [{
              "desc": "区",
              "key": "address_district"
            }],
            "operator": [{
              "key": "like",
              "value": "模糊"
            }],
            "placeholder": "请输入",
            "type": "input-mul-complex"
          }],
          "id": 13,
          "relation": false,
          "relationId": "",
          "remote": false,
          "remoteType": 0,
          "select": 0,
          "title": "按地区",
          "type": 2,
          "remoteFunc": []
        }],
        "type": "searchTemplate"
      },
      tableModel_1001: {
        "border": true,
        "del": false,
        "headerButton": [],
        "id": "id_1001",
        "isTree": false,
        "rowButton": [{
          "icon": "",
          "id": "",
          "tag": "edit",
          "title": "变更地址",
          "type": "primary"
        }],
        "showPagination": true,
        "showSelection": false,
        "showSummary": true,
        "stripe": false,
        "tableAutocompleteProps": {},
        "tableCascaderProps": {
          "address": {
            "data": [],
            "id": 7,
            "multiple": 0,
            "remote": true,
            "remoteFunc": "tableFunc_cascader_address"
          }
        },
        "tableFileProps": {},
        "tableModel": [{
          "add": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "订单号",
          "required": 0,
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "150",
          "prop": "order_id"
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "会员编号",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "100",
          "prop": "user_number"
        }, {
          "label": "报单店号",
          "show": 1,
          "add": 0,
          "detail": 1,
          "width": "100",
          "type": "text",
          "desc": "",
          "groupId": "",
          "rules": [],
          "excel": 1,
          "prop": "shop_no"
        }, {
          "label": "店铺编号",
          "show": 1,
          "add": 0,
          "detail": 1,
          "width": "100",
          "type": "text",
          "desc": "",
          "groupId": "",
          "rules": [],
          "excel": 1,
          "prop": "shop_number"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "套餐数量",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "total_num"
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "会员姓名",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "100",
          "prop": "t_user.real_name"
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "会员电话",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "120",
          "prop": "t_user.phone"
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "直属编号",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "100",
          "prop": "direct"
        }, {
          "add": 0,
          "desc": "",
          "detail": 0,
          "excel": 0,
          "groupId": "",
          "label": "套餐信息",
          "rules": [],
          "show": 0,
          "type": "custom",
          "width": "300",
          "prop": "package_info"
        }, {
          "add": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "订单金额",
          "required": 0,
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "total_price"
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "运费",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "express_fee"
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "订单PV",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "total_pv"
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "订单日期",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "150",
          "prop": "add_time"
        }, {
          "add": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "支付日期",
          "required": 0,
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "150",
          "prop": "pay_time"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "收货人",
          "required": 0,
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "100",
          "prop": "real_name"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "收货电话",
          "required": 0,
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "100",
          "prop": "user_phone"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "groupId": "",
          "label": "省市区",
          "required": 1,
          "rules": [],
          "show": 0,
          "type": "cascader",
          "width": "100",
          "prop": "address",
          "data": {
            "data": [],
            "id": 7,
            "key": "address",
            "multiple": 0,
            "remote": true
          }
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "收货地址",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "300",
          "prop": "address_detail"
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "省",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "address_province"
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "市",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "address_city"
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "区",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "address_district"
        }, {
          "add": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "备注",
          "required": 0,
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "120",
          "prop": "remark"
        }, {
          "label": "期数",
          "show": 1,
          "add": 0,
          "detail": 1,
          "width": "80",
          "type": "text",
          "desc": "",
          "groupId": "",
          "rules": [],
          "excel": 1,
          "prop": "expect_num"
        }],
        "tableName": "t_order",
        "tableRadioProps": {},
        "tableSelectProps": {},
        "tableSwitchProps": {},
        "title": "订单",
        "type": "tableTemplate"
      },
      remoteFunc: {
        modelConditionFunc_3: function modelConditionFunc_3(tag, relationData, resolve) {
          //筛选条件数据
          //[[{key:"type",value:"1"}]]
          var data = [];
          data = [[{
            key: "add_time",
            value: []
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 0, true)
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 1, true)
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 7, true)
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 30, true)
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 365, true)
          }], [{
            key: "add_time",
            value: []
          }]];
          resolve(tag, data);
        },
        modelConditionFunc_14: function modelConditionFunc_14(tag, relationData, resolve) {
          //筛选条件数据
          //[[{key:"type",value:"1"}]]
          var data = [];
          data = [[{
            key: "pay_time",
            value: []
          }], [{
            key: "pay_time",
            value: _this.$g.fun.getDateRange(new Date(), 0, true)
          }], [{
            key: "pay_time",
            value: _this.$g.fun.getDateRange(new Date(), 1, true)
          }], [{
            key: "pay_time",
            value: _this.$g.fun.getDateRange(new Date(), 7, true)
          }], [{
            key: "pay_time",
            value: _this.$g.fun.getDateRange(new Date(), 30, true)
          }], [{
            key: "pay_time",
            value: _this.$g.fun.getDateRange(new Date(), 365, true)
          }], [{
            key: "pay_time",
            value: []
          }]];
          resolve(tag, data);
        },
        tableFunc_cascader_address: function tableFunc_cascader_address(tag, relationData, resolve) {
          //筛选项模型数组
          areaList({}).then(function _callee(res) {
            var i, data;
            return regeneratorRuntime.async(function _callee$(_context) {
              while (1) {
                switch (_context.prev = _context.next) {
                  case 0:
                    for (i in res) {
                      _this.loopArea(res[i]);
                    }

                    data = res;
                    resolve(tag, data);

                  case 3:
                  case "end":
                    return _context.stop();
                }
              }
            });
          });
        }
      }
    };
  },
  mounted: function mounted() {
    this.table = this.$refs.ref_1001;
    this.search = this.$refs.ref_1000;
  },
  methods: {
    loopArea: function loopArea(dic) {
      dic.key = dic.name;
      dic.value = dic.name;

      if (!dic.hasOwnProperty('children')) {
        return;
      }

      for (var i in dic.children) {
        this.loopArea(dic.children[i]);
      }
    },
    reqTableData: function reqTableData(params, resolve) {
      var _this2 = this;

      var where = {};

      if (this.search) {
        where = this.search.parseWhere();
        params.where = where;
      }

      goodsOrder(params).then(function _callee2(res) {
        var i, orderDetail, orderDelivery, msg, omsg1, omsg2, j, item, store_name, price;
        return regeneratorRuntime.async(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                for (i in res.rows) {
                  orderDetail = res.rows[i]['t_order_packages'];
                  orderDelivery = res.rows[i]['t_order_expresses'];
                  msg = '';
                  omsg1 = '';
                  omsg2 = '';

                  for (j in orderDetail) {
                    item = orderDetail[j];
                    store_name = item.ex_package_product ? item.ex_package_product.store_name : '订单异常';
                    price = item.ex_package_product ? item.ex_package_product.price : '订单异常';
                    msg += '<div>';
                    msg += '<span style="color:red">[' + item.quantity + ']</span>';
                    msg += ' ' + store_name + ' ' + price + '￥';
                    msg += '</div>';
                    omsg1 += '[' + item.quantity + ']';
                    omsg1 += ' ' + store_name + ' ' + price;
                  }

                  res.rows[i]['package_info'] = msg; // msg = ''
                  // for (let j in orderDelivery){
                  //   msg += '<div>'
                  //   msg += '<span style="color:red">['+(parseInt(j)+1)+']</span>'
                  //   msg += ' '+orderDelivery[j].express_code+orderDelivery[j].express_no+' '+orderDelivery[j].add_time
                  //   msg += '</div>'
                  //   omsg2 += '['+(parseInt(j)+1)+']'
                  //   omsg2 += ' '+orderDelivery[j].express_code+orderDelivery[j].express_no+' '+orderDelivery[j].add_time
                  // }
                  // res.rows[i]['delivery_info'] = msg
                  // res.rows[i]['package_info_o'] = omsg1
                  // res.rows[i]['delivery_info_o'] = omsg2

                  res.rows[i]['address'] = [];
                  res.rows[i]['address'].push(res.rows[i]['address_province']);
                  res.rows[i]['address'].push(res.rows[i]['address_city']);
                  res.rows[i]['address'].push(res.rows[i]['address_district']);
                }

                _this2.sum = res.sum;
                resolve({
                  total: res.count,
                  data: res.rows
                });

              case 3:
              case "end":
                return _context2.stop();
            }
          }
        });
      });
    },
    reqAdd: function reqAdd(isEdit, params, row, resolve) {
      if (isEdit) {
        params.order_id = row.order_id;
      }

      params.address_province = params.address[0];
      params.address_city = params.address[1];
      params.address_district = params.address[2];
      goodsOrderHandle(params).then(function _callee3(res) {
        return regeneratorRuntime.async(function _callee3$(_context3) {
          while (1) {
            switch (_context3.prev = _context3.next) {
              case 0:
                resolve();

              case 1:
              case "end":
                return _context3.stop();
            }
          }
        });
      });
    },
    reqDel: function reqDel(params, resolve) {
      var ids = params.map(function (item) {
        return item.id;
      }); //xxxDel({id:ids}).then(async res=>{
      //  resolve()
      //})
    },
    onClickLink: function onClickLink(prop, row) {//点击列表link类型回调
    },
    onClickRowButton: function onClickRowButton(btnIndex, row) {//点击列表按钮回调
    },
    onClickHeaderButton: function onClickHeaderButton(btnIndex, row) {//点击列表头部按钮回调
    },
    onSummary: function onSummary(param) {
      var _this3 = this;

      //列表尾部合计回调
      var columns = param.columns,
          data = param.data;
      console.log('para', param);
      var sums = [];
      columns.forEach(function (column, index) {
        if (index === 0) {
          sums[index] = '总计';
          return;
        }

        if (column.property === 'total_price') {
          sums[index] = _this3.sum.total_price;
        } else if (column.property === 'money1_price') {
          sums[index] = _this3.sum.money1_price;
        } else if (column.property === 'money4_price') {
          sums[index] = _this3.sum.money4_price;
        } else if (column.property === 'total_num') {
          sums[index] = _this3.sum.total_num;
        } else if (column.property === 'wechat_price') {
          sums[index] = _this3.sum.wechat_price;
        } else if (column.property === 'total_pv') {
          sums[index] = _this3.sum.total_pv;
        }
      });
      return sums;
    },
    onSwitchChange: function onSwitchChange(val, prop, row) {//列表中switch变化事件
    },
    onClickSearchOperate: function onClickSearchOperate(btnIndex, btnData) {
      //条件菜单按钮点击事件
      if (btnData.tag === this.$tempModel.buttonTag.search) {
        this.table.reloadData();
      } else if (btnData.tag === this.$tempModel.buttonTag.export) {
        this.table.exportExcel();
      } else if (btnData.tag === this.$tempModel.buttonTag.import) {
        var header = btnData.header;
        var results = btnData.results;
      }
    },
    onConditionChange: function onConditionChange(tag, index) {
      this.table.reloadData();
    },
    onRowButtonShow: function onRowButtonShow(btnIndex, row) {
      return false; // if (btnIndex === 0){
      //   if (row.status === 0){
      //     return true
      //   }
      //   return false;
      // }
    }
  }
};