import "core-js/modules/es.array.map";
import "core-js/modules/es.string.search";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tempTable from "@/components/rw/tempTable/index";
import tempSearch from "@/components/rw/tempSearch/index";
import tempForm from "@/components/rw/tempForm/index";
import tempChart from "@/components/rw/tempChart/index";
import algoAdd from "./algoAdd";
export default {
  components: {
    tempTable: tempTable,
    tempSearch: tempSearch,
    tempForm: tempForm,
    tempChart: tempChart,
    algoAdd: algoAdd
  },
  data: function data() {
    var _this = this;

    return {
      tableModel_1000: {
        "type": "tableTemplate",
        "id": "id_1000",
        "del": false,
        "tableModel": [{
          "label": "ID",
          "show": 1,
          "add": 1,
          "detail": 1,
          "excel": 1,
          "width": 80,
          "type": "text",
          "rowAddField": "",
          "groupId": "",
          "rules": [],
          "prop": "id"
        }, {
          "label": "算法名称",
          "show": 1,
          "add": 1,
          "detail": 1,
          "excel": 1,
          "width": 160,
          "type": "text",
          "rowAddField": "",
          "groupId": "",
          "rules": [],
          "prop": "name"
        }, {
          "label": "算法描述",
          "show": 1,
          "add": 1,
          "detail": 1,
          "excel": 1,
          "width": "500",
          "type": "textarea",
          "rowAddField": "",
          "groupId": "",
          "rules": [],
          "prop": "remark"
        }, {
          "label": "算法逻辑",
          "show": 0,
          "add": 1,
          "detail": 1,
          "excel": 1,
          "width": 160,
          "type": "text",
          "rowAddField": "",
          "groupId": "",
          "rules": [],
          "prop": "config"
        }],
        "tableSwitchProps": {},
        "tableSelectProps": {},
        "tableCascaderProps": {},
        "tableFileProps": {},
        "rowButton": [{
          "title": "编辑",
          "type": "primary",
          "tag": "default",
          "id": ""
        }, {
          "title": "删除",
          "type": "danger",
          "tag": "del",
          "id": ""
        }],
        "stripe": false,
        "border": true,
        "showPagination": false,
        "showSelection": false,
        "showSummary": false,
        "title": "算法",
        "tableName": "t_system_algo_generate",
        "isGroup": false,
        "isTree": false,
        "headerButton": [{
          "title": "添加",
          "type": "primary",
          "tag": "default",
          "id": ""
        }],
        "tableAutocompleteProps": {},
        "tableRadioProps": {}
      },
      remoteFunc: {}
    };
  },
  mounted: function mounted() {
    this.table = this.$refs.ref_1000;
  },
  methods: {
    reqTableData: function reqTableData(params, resolve) {
      var where = {};

      if (this.search) {
        where = this.search.parseWhere();
        params.where = where;
      }

      resolve({
        total: 0,
        data: []
      });
    },
    reqAdd: function reqAdd(isEdit, params, row, resolve) {//if (isEdit){
      //  params.id = row.id
      //}
      //xxxAdd(params).then(async res=>{
      //  resolve()
      //})
    },
    reqDel: function reqDel(params, resolve) {
      var ids = params.map(function (item) {
        return item.id;
      }); //xxxDel({id:ids}).then(async res=>{
      //  resolve()
      //})
    },
    onClickLink: function onClickLink(prop, row) {//点击列表link类型回调
    },
    onClickRowButton: function onClickRowButton(btnIndex, row) {
      //点击列表按钮回调
      if (btnIndex === 0) {
        this.$refs['add'].show(row, function () {});
      }
    },
    onClickHeaderButton: function onClickHeaderButton(btnIndex, row) {
      //点击列表头部按钮回调
      this.$refs['add'].show(null, function () {});
    },
    onSummary: function onSummary(param) {//列表尾部合计回调
    },
    onSwitchChange: function onSwitchChange(val, prop, row) {//列表中switch变化事件
    },
    onClickSearchOperate: function onClickSearchOperate(btnIndex, btnData) {
      //条件菜单按钮点击事件
      if (btnData.tag === this.$tempModel.buttonTag.search) {
        this.table.reloadData();
      } else if (btnData.tag === this.$tempModel.buttonTag.export) {
        this.table.exportExcel();
      } else if (btnData.tag === this.$tempModel.buttonTag.import) {
        var header = btnData.header;
        var results = btnData.results;
      }
    },
    onConditionChange: function onConditionChange(tag, index) {
      this.table.reloadData();
    },
    onRowButtonShow: function onRowButtonShow(btnIndex, row) {
      return true;
    }
  }
};