import "core-js/modules/es.regexp.constructor";
import "core-js/modules/es.regexp.to-string";
export default {
  integer: {
    desc: '整数',
    validator: function validator(rule, value, callback) {
      //整数
      var reg = new RegExp('^-?\\d+$');

      if (value !== '' && !reg.test(value)) {
        return callback(new Error('请输入整数'));
      }

      callback();
    }
  },
  decimal: {
    desc: '浮点数',
    validator: function validator(rule, value, callback) {
      var reg = new RegExp('^(-?\\d+)(\\.\\d+)?$');

      if (value !== '' && !reg.test(value)) {
        return callback(new Error('请输入正确的浮点数'));
      }

      callback();
    }
  },
  money: {
    desc: '金额，非负浮点数',
    validator: function validator(rule, value, callback) {
      //金额，非负浮点数（正浮点数 + 0）
      var reg = new RegExp('^\\d+(\\.\\d+)?$');

      if (value !== '' && !reg.test(value)) {
        return callback(new Error('请输入正确的金额'));
      }

      callback();
    }
  },
  idCard: {
    desc: '18位身份证号',
    validator: function validator(rule, value, callback) {
      //身份证
      var reg = new RegExp(/^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/);

      if (value !== '' && !reg.test(value)) {
        return callback(new Error('请输入正确的身份证号'));
      }

      callback();
    }
  },
  carNumber: {
    desc: '',
    validator: function validator(rule, value, callback) {
      //车牌号
      var reg = new RegExp(/^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1}$/);

      if (value !== '' && !reg.test(value)) {
        return callback(new Error('请输入正确的车牌号'));
      }

      callback();
    }
  },
  url: {
    desc: '',
    validator: function validator(rule, value, callback) {
      //url
      var reg = new RegExp(/^((https?|ftp|file):\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/);

      if (value !== '' && !reg.test(value)) {
        return callback(new Error('请输入正确的链接地址'));
      }

      callback();
    }
  },
  account: {
    desc: '4到16位（字母，数字，下划线，减号）',
    validator: function validator(rule, value, callback) {
      //账号 4到16位（字母，数字，下划线，减号）
      var reg = new RegExp(/^[a-zA-Z0-9_-]{4,16}$/);

      if (value !== '' && !reg.test(value)) {
        return callback(new Error('4到16位（字母，数字，下划线，减号）'));
      }

      callback();
    }
  },
  pwd1: {
    desc: '密码中必须包含大小写 字母、数字、特称字符，至少8个字符，最多30个字符',
    validator: function validator(rule, value, callback) {
      //密码中必须包含大小写 字母、数字、特称字符，至少8个字符，最多30个字符；
      var pwdRegex = new RegExp('(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z0-9]).{8,30}');

      if (value !== '' && !pwdRegex.test(value)) {
        return callback(new Error('密码中必须包含大小写 字母、数字、特称字符，至少8个字符，最多30个字符'));
      }

      callback();
    }
  },
  pwd2: {
    desc: '密码中必须包含字母（不区分大小写）、数字、特称字符，至少8个字符，最多30个字符',
    validator: function validator(rule, value, callback) {
      //密码中必须包含字母（不区分大小写）、数字、特称字符，至少8个字符，最多30个字符
      var pwdRegex = new RegExp('(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,30}');

      if (value !== '' && !pwdRegex.test(value)) {
        return callback(new Error('密码中必须包含字母（不区分大小写）、数字、特称字符，至少8个字符，最多30个字符'));
      }

      callback();
    }
  },
  pwd3: {
    desc: '密码中必须包含字母（不区分大小写）、数字，至少8个字符，最多30个字符',
    validator: function validator(rule, value, callback) {
      //密码中必须包含字母（不区分大小写）、数字，至少8个字符，最多30个字符；
      var pwdRegex = new RegExp('(?=.*[0-9])(?=.*[a-zA-Z]).{8,30}');

      if (value !== '' && !pwdRegex.test(value)) {
        return callback(new Error('密码中必须包含字母（不区分大小写）、数字，至少8个字符，最多30个字符'));
      }

      callback();
    }
  },
  email: {
    desc: '',
    validator: function validator(rule, value, callback) {
      //邮箱验证
      var reg = new RegExp('^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$');

      if (value !== '' && !reg.test(value)) {
        return callback(new Error('请输入正确的邮箱格式'));
      }

      callback();
    }
  },
  phone: {
    desc: '11位移动电话号码',
    validator: function validator(rule, value, callback) {
      var reg = new RegExp(/^[1][3,4,5,7,8,9][0-9]{9}$/);

      if (value !== '' && !reg.test(value)) {
        return callback(new Error('请输入正确的手机号'));
      }

      callback();
    }
  }
};