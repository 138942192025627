var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            size: "small",
            model: _vm.desc,
            "label-position": "left",
            "label-width": "130px"
          }
        },
        _vm._l(_vm.desc, function(value, key) {
          return _c(
            "div",
            [
              _vm.isRadio(key)
                ? _c(
                    "el-form-item",
                    { attrs: { label: _vm.desc[key].name } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.desc[key].value,
                            callback: function($$v) {
                              _vm.$set(_vm.desc[key], "value", $$v)
                            },
                            expression: "desc[key].value"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("关闭")
                          ]),
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("开启")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-tag",
                        {
                          staticClass: "itemPrompt",
                          attrs: { type: "primary", size: "mini" }
                        },
                        [_vm._v(_vm._s(_vm.desc[key].desc))]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isCheckBox(key)
                ? _c(
                    "el-form-item",
                    { attrs: { label: _vm.desc[key].name } },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          model: {
                            value: _vm.desc[key].value,
                            callback: function($$v) {
                              _vm.$set(_vm.desc[key], "value", $$v)
                            },
                            expression: "desc[key].value"
                          }
                        },
                        _vm._l(_vm.desc[key].data, function(item) {
                          return _c(
                            "el-checkbox",
                            { attrs: { label: item.key } },
                            [_vm._v(_vm._s(item.value))]
                          )
                        }),
                        1
                      ),
                      _c(
                        "el-tag",
                        {
                          staticClass: "itemPrompt",
                          attrs: { type: "primary", size: "mini" }
                        },
                        [_vm._v(_vm._s(_vm.desc[key].desc))]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isDateTime(key)
                ? _c(
                    "el-form-item",
                    { attrs: { label: _vm.desc[key].name } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "datetimerange",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期"
                        },
                        model: {
                          value: _vm.desc[key].value,
                          callback: function($$v) {
                            _vm.$set(_vm.desc[key], "value", $$v)
                          },
                          expression: "desc[key].value"
                        }
                      }),
                      _c(
                        "el-tag",
                        {
                          staticClass: "itemPrompt",
                          attrs: { type: "primary", size: "mini" }
                        },
                        [_vm._v(_vm._s(_vm.desc[key].desc))]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isInput(key)
                ? _c(
                    "el-form-item",
                    { attrs: { label: _vm.desc[key].name } },
                    [
                      _c("el-input", {
                        staticClass: "formItemInput",
                        model: {
                          value: _vm.desc[key].value,
                          callback: function($$v) {
                            _vm.$set(_vm.desc[key], "value", $$v)
                          },
                          expression: "desc[key].value"
                        }
                      }),
                      _c(
                        "el-tag",
                        {
                          staticClass: "itemPrompt",
                          attrs: { type: "primary", size: "mini" }
                        },
                        [_vm._v(_vm._s(_vm.desc[key].desc))]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isUpload(key)
                ? _c(
                    "el-form-item",
                    { attrs: { label: _vm.desc[key].name } },
                    [
                      _c(
                        "el-upload",
                        {
                          attrs: {
                            multiple: false,
                            action: "",
                            accept: ".png,.jpg,.jpeg",
                            limit: 1,
                            "http-request": function(option) {
                              return _vm.onUpload(key, option)
                            },
                            "before-upload": function(file) {
                              return _vm.onBeforeUpload(key, file)
                            },
                            "on-error": function(err, file, fileList) {
                              return _vm.onError(key, err, file, fileList)
                            },
                            "on-success": function(response, file, fileList) {
                              return _vm.onSuccess(
                                key,
                                response,
                                file,
                                fileList
                              )
                            },
                            "on-remove": function(file, fileList) {
                              return _vm.onRemove(key, file, fileList)
                            },
                            "list-type": "picture-card",
                            "file-list": _vm.getFileList(key)
                          }
                        },
                        [_c("i", { staticClass: "el-icon-plus" })]
                      ),
                      _c(
                        "el-tag",
                        {
                          staticClass: "itemPrompt",
                          attrs: { type: "primary", size: "mini" }
                        },
                        [_vm._v(_vm._s(_vm.desc[key].desc))]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        }),
        0
      ),
      _c(
        "div",
        { staticStyle: { float: "right", "margin-top": "20px" } },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.clickSubmit } },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }