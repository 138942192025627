import "core-js/modules/es.object.to-string";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import packageInfo from './packageInfo';
import giftInfo from './giftInfo';
import { productList, productPackage } from '../../../../api/apis';
export default {
  components: {
    packageInfo: packageInfo,
    giftInfo: giftInfo
  },
  data: function data() {
    return {
      dialogVisible: false,
      activeName: 'package',
      productList: [],
      giftList: []
    };
  },
  mounted: function mounted() {},
  methods: {
    show: function show(row, finish) {
      var _this = this;

      this.dialogVisible = true;
      this.finish = finish;
      this.row = row;
      var params = {
        page: 1,
        limit: 100000,
        where: {
          is_main: 0
        }
      };
      productList(params).then(function _callee(res) {
        var tmp, i;
        return regeneratorRuntime.async(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                _this.productList = res.rows;
                tmp = [];

                for (i in res.rows) {
                  if (_this.productList[i].is_show) {
                    tmp.push(res.rows[i]);
                  }
                }

                _this.productList = tmp;
                _this.giftList = _this.$tempModel.deepCopy(tmp);

              case 5:
              case "end":
                return _context.stop();
            }
          }
        });
      });

      if (this.row.is_stage) {
        var _params = {
          page: 1,
          limit: 100000,
          where: {
            is_main: 1
          }
        };
        productList(_params).then(function _callee2(res) {
          var tmp, i;
          return regeneratorRuntime.async(function _callee2$(_context2) {
            while (1) {
              switch (_context2.prev = _context2.next) {
                case 0:
                  tmp = [];

                  for (i in res.rows) {
                    if (_this.productList[i].is_show) {
                      tmp.push(res.rows[i]);
                    }
                  }

                  _this.giftList = tmp;

                case 3:
                case "end":
                  return _context2.stop();
              }
            }
          });
        });
      }

      setTimeout(function () {
        var table1 = [];
        var table2 = [];
        console.log('row', row);

        for (var i in row.t_product_packages) {
          var item = row.t_product_packages[i];

          if (item.is_gift === 1) {
            table2.push({
              store_name: item.t_product.store_name,
              product_num: item.quantity,
              code: item.t_product.code,
              id: item.sub_product
            });
          } else {
            table1.push({
              store_name: item.t_product.store_name,
              product_num: item.quantity,
              code: item.t_product.code,
              id: item.sub_product
            });
          }
        }

        _this.$refs['package'].reloadData(table1);

        _this.$refs['gift'].reloadData(table2);
      }, 300);
    },
    clickSubmit: function clickSubmit() {
      var _this2 = this;

      var packages = this.$refs['package'].getParams();
      var gifts = this.$refs['gift'].getParams();
      var data = [];

      for (var i in packages) {
        data.push({
          sub_product: packages[i].id,
          quantity: packages[i].product_num,
          is_gift: 0
        });
      }

      for (var _i in gifts) {
        data.push({
          sub_product: gifts[_i].id,
          quantity: gifts[_i].product_num,
          is_gift: 1,
          is_main: this.row.is_stage ? 1 : 0
        });
      } // console.log({
      //   main_product:this.row.id,
      //   data:data
      // })
      // return


      this.$g.fun.startLoading();
      productPackage({
        main_product: this.row.id,
        data: data
      }).then(function _callee3(res) {
        return regeneratorRuntime.async(function _callee3$(_context3) {
          while (1) {
            switch (_context3.prev = _context3.next) {
              case 0:
                _this2.$message({
                  message: '配置成功',
                  type: 'success'
                });

                _this2.dialogVisible = false;

                _this2.$g.fun.endLoading();

                _this2.finish();

              case 4:
              case "end":
                return _context3.stop();
            }
          }
        });
      }, function () {
        _this2.$g.fun.endLoading();
      });
    }
  }
};