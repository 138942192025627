import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.index-of";
import "core-js/modules/es.array.splice";
import "core-js/modules/es.function.name";
import "core-js/modules/web.dom-collections.for-each";
import _toConsumableArray from "/Users/suifeng/Desktop/projects/2022/sdSdm/sdSdmAdmin/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import conditionCreator from '../conditionCreator';
export default {
  components: {
    conditionCreator: conditionCreator
  },
  props: {
    config: {
      type: Object
    }
  },
  watch: {
    'conditions.factorClassify': {
      handler: function handler(val, oldVal) {
        for (var i in val) {
          var item = val[i];
          this.conditions.cacheKv[item.factor.tag] = item.select;
        }
      },
      deep: true
    },
    'conditions.factorClassifyBonus': {
      handler: function handler(val, oldVal) {
        for (var i in val) {
          var item = val[i];
          var key = '';
          var value = {};

          for (var j = 0; j < item.length; j++) {
            //分类的id组成key
            if (j === item.length - 1) {
              value['bv'] = item[j].classifyValue;
            } else {
              var cv = item[j].classifyValue;
              key += cv + '';
              value[cv] = item[j].classifyOp;
            }
          }

          this.conditions.cacheKv[key] = value;
        }
      },
      deep: true
    }
  },
  computed: {
    showAfterGrade: function showAfterGrade() {
      if (this.conditions.factor.indexOf('give_week_pov') >= 0 || this.conditions.factor.indexOf('give_month_pov') >= 0 || this.conditions.factor.indexOf('give_total_pov') >= 0) {
        return true;
      }

      return false;
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      row: null,
      factorList: [{
        name: '奖励会员等级',
        tag: 'give_grade',
        type: 'grade'
      }],
      conditions: {
        layer: 1,
        computeType: 0,
        //0非极差 1极差
        bonusMode: 0,
        //收益类型：0比率 1金额
        orderTypes: [1, 2, 3],
        //生效的订单类型
        factorBonusKv: {},
        //记录生成好的给结算用的数据
        bdGradeType: 0,
        //报单人自己升级时使用的是定级前等级还是定级后等级
        factor: [],
        //影响因素
        factorClassify: [],
        //每个因素对应的分类
        factorClassifyBonus: [],
        //每个因素分类组合对应的奖金
        cacheKv: {},
        //记录所有的选择
        isAfterGrade: 0,
        //是否按照奖励会员定级时间开始结算业绩
        afterGrade: 0,
        //定级开始计算的最小等级
        povIsSelf: 0,
        //团队业绩是否包含本人业绩
        povOrder: 0,
        //团队业绩方式
        povType: [1, 2, 3],
        //团队业绩订单类型
        orderGiveSelf: 0,
        //奖励人是否要大于下单人等级
        greaterOrderGrade: 0 //订单是否会对本人产生收益

      }
    };
  },
  mounted: function mounted() {},
  methods: {
    factorName: function factorName(item, index, conditions) {
      var ret = item.factorName;

      if (index === conditions.factorClassifyBonus[0].length - 1) {
        return ret + (conditions.bonusMode === 0 ? '(比率)' : '(金额)');
      }

      return ret;
    },
    onClose: function onClose() {
      this.finish();
    },
    onFactorChange: function onFactorChange(value) {
      var data = [];

      for (var i in this.conditions.factor) {
        var tmp = {};
        var factorData = this.getFactorByTag(this.conditions.factor[i]);
        tmp.factor = factorData;
        tmp.select = [];
        tmp.cid = 0;
        var children = [];
        tmp.children = children;
        var hasSelectCache = this.conditions.cacheKv.hasOwnProperty(factorData.tag);

        if (hasSelectCache) {
          tmp.select = this.conditions.cacheKv[factorData.tag];
        }

        if (factorData.type === 'grade') {
          for (var _i in this.config.gradeData) {
            var grade = this.config.gradeData[_i];

            if (!hasSelectCache) {
              tmp.select.push(grade.tag);
            }

            children.push({
              name: grade.name,
              tag: grade.tag,
              op: '=',
              value: grade.tag
            });
          }
        } else if (factorData.type === 'level') {
          for (var _i2 in this.config.levelData) {
            var level = this.config.levelData[_i2];

            if (!hasSelectCache) {
              tmp.select.push(level.tag);
            }

            children.push({
              name: level.name,
              tag: level.tag,
              op: '=',
              value: level.tag
            });
          }
        } else if (factorData.type === 'store') {
          for (var _i3 in this.config.storeData) {
            var store = this.config.storeData[_i3];

            if (!hasSelectCache) {
              tmp.select.push(store.tag);
            }

            children.push({
              name: store.name,
              tag: store.tag,
              op: '=',
              value: store.tag
            });
          }
        } else if (factorData.type === 'pv') {
          var tid = tmp.cid++;
          children.push({
            name: 0,
            tag: tid,
            op: '>=',
            value: 0
          });

          if (!hasSelectCache) {
            tmp.select.push(tid);
          }
        }

        data.push(tmp);
      }

      this.conditions.factorClassify = data;
    },
    clickAddFactorPvChildren: function clickAddFactorPvChildren(item) {
      item.children.push({
        name: 0,
        tag: item.cid++,
        op: '>=',
        value: 0
      });
    },
    clickDelFactorClassify: function clickDelFactorClassify(children, index) {
      children.splice(index, 1);
    },
    getFactorByTag: function getFactorByTag(tag) {
      for (var i in this.factorList) {
        if (this.factorList[i].tag === tag) {
          return this.factorList[i];
        }
      }

      return null;
    },
    clearFactorData: function clearFactorData() {
      this.conditions.factorClassifyBonus = [];
      this.conditions.cacheKv = {};
      this.conditions.factorClassify = [];
      this.conditions.factor = [];
    },
    resetFactorData: function resetFactorData() {
      var tmp = [];

      for (var i in this.conditions.factorClassify) {
        var data = this.conditions.factorClassify[i];
        var factor = data.factor;
        var children = data.children;
        var itemList = [];

        for (var j in children) {
          if (data.select.indexOf(children[j].tag) < 0) {
            continue;
          }

          var item = {};
          item.factorName = factor.name;
          item.factorTag = factor.tag;
          item.classifyName = children[j].name;

          if (factor.type === 'pv') {
            //由于pv类型数据是后期手动填的，需要动态赋值，就是他的名字
            item.classifyValue = children[j].name;
          } else {
            item.classifyValue = children[j].value;
          }

          item.classifyOp = children[j].op;
          item.classifyCount = data.select.length;
          itemList.push(item);
        }

        tmp.push(itemList);
      }

      tmp.push([{
        factorName: '奖励',
        factorTag: '',
        classifyName: '',
        classifyValue: 0,
        classifyOp: '',
        classifyCount: 1
      }]);
      tmp = this.calcDescartes(tmp);

      for (var _i4 in tmp) {
        var _item = tmp[_i4];
        var key = '';

        for (var _j = 0; _j < _item.length - 1; _j++) {
          //分类的id组成key
          var cv = _item[_j].classifyValue;
          key += cv + '';
        }

        if (this.conditions.cacheKv.hasOwnProperty(key)) {
          var value = this.conditions.cacheKv[key];

          for (var _j2 = 0; _j2 < _item.length; _j2++) {
            //分类的id组成key
            if (_j2 === _item.length - 1) {
              _item[_j2].classifyValue = value['bv'];
            } else {
              _item[_j2].classifyOp = value[_item[_j2].classifyValue];
            }
          }
        }
      }

      this.conditions.factorClassifyBonus = tmp;
    },
    objectSpanMethod: function objectSpanMethod(_ref) {
      var row = _ref.row,
          column = _ref.column,
          rowIndex = _ref.rowIndex,
          columnIndex = _ref.columnIndex;

      if (columnIndex < row.length - 2) {
        var count = 1;

        for (var i = columnIndex + 1; i < row.length; i++) {
          count *= row[i].classifyCount;
        }

        if (rowIndex % count === 0) {
          return {
            rowspan: count,
            colspan: 1
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
    },
    calcDescartes: function calcDescartes(array) {
      var _this = this;

      //笛卡尔积算法
      if (array.length < 2) return array[0] || [];
      return array.reduce(function (total, currentValue) {
        var res = [];
        total.forEach(function (t) {
          currentValue.forEach(function (cv) {
            if (t instanceof Array) // 或者使用 Array.isArray(t)
              {
                res.push([].concat(_toConsumableArray(t), [_this.$tempModel.deepCopy(cv)]));
              } else {
              res.push([t, _this.$tempModel.deepCopy(cv)]);
            }
          });
        });
        return res;
      });
    },
    createBonusPv: function createBonusPv() {
      //生成最终的奖金数据
      var kv = {};

      for (var i in this.conditions.factorClassifyBonus) {
        var item = this.conditions.factorClassifyBonus[i];
        var key = '';
        var value = '';

        for (var j = 0; j < item.length; j++) {
          var classify = item[j];

          if (j === item.length - 1) {
            value = classify.classifyValue;
          } else {
            key += classify.classifyOp + classify.classifyValue;
          }
        }

        kv[key] = value;
      }

      return kv;
    },
    show: function show(row, finish) {
      this.dialogVisible = true;
      this.finish = finish;
      this.row = row;

      if (row.conditions) {
        var tmp = this.$tempModel.deepCopy(row.conditions);

        for (var key in this.conditions) {
          if (!tmp.hasOwnProperty(key)) {
            tmp[key] = this.conditions[key];
          }
        }

        this.conditions = tmp;
      }
    },
    clickSubmit: function clickSubmit() {
      this.conditions.factorBonusKv = this.createBonusPv();
      console.log(' this.conditions.factorBonusKv ', this.conditions);
      this.row.conditions = this.conditions;
      this.dialogVisible = false;
    }
  }
};