require("core-js/modules/es.array.join");

var crypto = require('crypto');

var key = "9tEx2X51E8Kf9X92MDXH0zTvwzmeKKO96wWwIl7zcgwU9oZtrGlQc8sRzHAo8YTm";
key = key.substring(0, 16);
/**
 * 加密方法
 * @param key 加密key
 * @param iv       向量
 * @param data     需要加密的数据
 * @returns string
 */

var encrypt = function encrypt(data) {
  var algorithm = 'aes-128-ecb';
  var clearEncoding = 'utf8';
  var cipherEncoding = 'base64';
  var iv = '';
  var cipher = crypto.createCipheriv(algorithm, key, iv);
  var cipherChunks = [];
  cipherChunks.push(cipher.update(data, clearEncoding, cipherEncoding));
  cipherChunks.push(cipher.final(cipherEncoding));
  return cipherChunks.join('');
};
/**
 * 解密方法
 * @param key      解密的key
 * @param iv       向量
 * @param crypted  密文
 * @returns string
 */


var decrypt = function decrypt(crypted) {
  var algorithm = 'aes-128-ecb';
  var clearEncoding = 'utf8';
  var cipherEncoding = 'base64';
  var iv = '';
  var decipher = crypto.createDecipheriv(algorithm, key, iv);
  var plainChunks = [];
  plainChunks.push(decipher.update(crypted, cipherEncoding, clearEncoding));
  plainChunks.push(decipher.final(clearEncoding));
  return plainChunks.join('');
};

module.exports = {
  encrypt: encrypt,
  decrypt: decrypt
};