'use strict';

import * as qiniu from 'qiniu-js';
export default {
  /**
   * 创建随机字符串
   * @param num
   * @returns {string}
   */
  randomString: function randomString(num) {
    var chars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
    var res = '';

    for (var i = 0; i < num; i++) {
      var id = Math.ceil(Math.random() * 35);
      res += chars[id];
    }

    return res;
  },

  /**
   * 创建oss客户端对象
   * @returns {*}
   */
  getToken: function getToken() {
    return '';
  },
  // Vue.prototype.aliurl = 'https://shujiecaiwu.oss-cn-beijing.aliyuncs.com/'

  /**
   * 文件上传
   * @param option 参考csdn: https://blog.csdn.net/qq_27626333/article/details/81463139
   */
  uploadFile: function uploadFile(file, fileName, progress, suc, fail) {
    var observable = qiniu.upload(file, fileName, this.getToken());
    observable.subscribe(function (p) {
      console.log('next');

      if (progress) {
        progress(p);
      }
    }, function (err) {
      if (fail) {
        fail({
          code: -100,
          msg: '上传失败'
        });
      }

      console.log('err', err);
    }, function (val) {
      if (suc) {
        suc(val);
      }

      console.log('complet');
    }); // 这样传参形式也可以
  }
};